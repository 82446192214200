import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { formatShowWeekDays } from 'shared/helpers/formatShowWeekDays';
import { getFormattedPhone } from 'shared/helpers/getFormattedPhone';
import { useConfirmationDialog } from 'shared/hooks/useConfirmationDialog';
import { TextColor } from 'shared/theme/colors';
import { CardLayout } from 'shared/ui/CardLayout';
import { Col } from 'shared/ui/Col';
import { RemovalConfirmationCard } from 'shared/ui/RemovalConfirmationCard';
import { Row } from 'shared/ui/Row';
import { SmallSquareButton } from 'shared/ui/SmallSquareButton';
import { SalesOffice } from '../model/types/salesOfficeSchema';

type SalesOfficeInfoCardProps = {
  salesOffice: SalesOffice;
  onEditClick: (salesOffice: SalesOffice) => void;
  onRemoveClick: (salesOffice: SalesOffice) => void;
};

export const SalesOfficeInfoCard: FC<SalesOfficeInfoCardProps> = ({
  salesOffice,
  onEditClick,
  onRemoveClick,
}) => {
  const { t } = useTranslation();
  const {
    isShowRemoveConfirmation,
    showRemoveConfirmation,
    hideRemoveConfirmation,
    confirmRemoveAction,
  } = useConfirmationDialog({
    onConfirm: () => onRemoveClick(salesOffice),
  });

  const handleEditClick = useCallback(() => {
    onEditClick(salesOffice);
  }, [onEditClick, salesOffice]);

  return (
    <CardLayout variant="secondary">
      <Col spacing={3}>
        <Typography variant={'headerXS'}>{salesOffice.address}</Typography>
        {salesOffice?.schedules?.map((schedule, index) => (
          <Typography
            key={index}
            variant={'accentM'}
            color={TextColor.Secondary}
          >
            {`${formatShowWeekDays(schedule.workingDays, t)}${
              schedule.workingDays && schedule.workingHours && ';'
            } ${schedule.workingHours}`}
          </Typography>
        ))}
      </Col>
      <Typography variant={'accentM'} color={TextColor.Secondary}>
        {`${getFormattedPhone(salesOffice.phone || '')}${
          salesOffice.phone && salesOffice.email ? ',' : ''
        } ${salesOffice.email || ''}`}
      </Typography>
      {isShowRemoveConfirmation ? (
        <RemovalConfirmationCard
          titleEnding={t('salesOffice.card.deleteEnding')}
          onRemoveClick={confirmRemoveAction}
          onCancelClick={hideRemoveConfirmation}
        />
      ) : (
        <Row spacing={5}>
          <SmallSquareButton icon="edit" onClick={handleEditClick} />
          <SmallSquareButton icon="delete" onClick={showRemoveConfirmation} />
        </Row>
      )}
    </CardLayout>
  );
};
