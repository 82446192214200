import { ReactNode } from 'react';
import { Provider } from 'react-redux';
import { DeepPartial, ReducersMapObject } from '@reduxjs/toolkit';
import { StateSchema } from 'shared/config/StateSchema';
import { createReduxStore } from 'shared/config/store';

export const StoreProvider =
  (
    component: () => ReactNode,
    initialState?: DeepPartial<StateSchema>,
    asyncReducers?: DeepPartial<ReducersMapObject<StateSchema>>
  ) =>
  // eslint-disable-next-line react/display-name
  () => {
    const store = createReduxStore(
      initialState as StateSchema,
      asyncReducers as ReducersMapObject<StateSchema>
    );

    return <Provider store={store}>{component()}</Provider>;
  };
