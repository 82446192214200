/**
 * Декодируем символы &, < и > в строке, а также переводим переводы строк из <br> в \n
 *
 * @param {string} str - The string to decode.
 * @return {string} The decoded string.
 */
export function decodeHtmlEntities(encodedStr?: string) {
  if (!encodedStr) {
    return '';
  }
  return encodedStr
    .replace(/<br\s*\/?>/gi, '\n')
    .replace(/&gt;/g, '>')
    .replace(/&lt;/g, '<')
    .replace(/&amp;/g, '&');
}
