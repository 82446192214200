export const getRoute = {
  Main: () => `/`,

  AuthLogin: () => `/login`,
  AuthRestorePassword: () => `/login/restorePassword`,
  AuthRestorePasswordDone: () => `/login/restorePasswordDone`,

  //region Developer Pages
  DeveloperFirstAuth: () => `/first_auth`,
  Developers: () => `/developers`,
  Developer: (developerId: number | string) => `/developers/${developerId}`,
  DeveloperObjects: (developerId: number | string) =>
    `/developers/${developerId}/objects`,
  DeveloperObject: (developerId: string | number, objectId: string | number) =>
    `/developers/${developerId}/objects/${objectId}`,
  DeveloperDetails: (developerId: number | string) =>
    `/developers/${developerId}/details`,
  DeveloperUsers: (developerId: number | string) =>
    `/developers/${developerId}/users`,
  CreateDeveloper: () => `/developers/create`,
  EditDeveloper: (developerId: number | string) =>
    `/developers/${developerId}/edit`,
  EditDeveloperMainDataTab: (developerId: number | string) =>
    `/developers/${developerId}/edit/general`,
  EditDeveloperAdditionalDataTab: (developerId: number | string) =>
    `/developers/${developerId}/edit/additional`,
  EditDeveloperDocumentsTab: (developerId: number | string) =>
    `/developers/${developerId}/edit/documents`,
  EditDeveloperAuthDataTab: (developerId: number | string) =>
    `/developers/${developerId}/edit/auth`,
  //endregion

  //region Object Pages
  Objects: () => `/objects`,
  CreateObject: (developerId: number | string) =>
    `/developers/${developerId}/objects/create`,
  EditObject: (objectId: string | number) => `/objects/${objectId}/edit`,

  EditObjectDetails: (objectId: string | number) =>
    `/objects/${objectId}/edit/details`,
  EditObjectDetailsGeneral: (objectId: string | number) =>
    `/objects/${objectId}/edit/details/general`,
  EditObjectDetailsLocation: (objectId: string | number) =>
    `/objects/${objectId}/edit/details/location`,
  EditObjectDetailsCharacteristics: (objectId: string | number) =>
    `/objects/${objectId}/edit/details/characteristics`,

  EditObjectBuildings: (objectId: string | number) =>
    `/objects/${objectId}/edit/buildings`,
  EditObjectBuilding: (
    objectId: string | number,
    buildingId: string | number
  ) => `/objects/${objectId}/edit/buildings/${buildingId}/edit`,
  EditObjectBuildingCreate: (objectId: string | number) =>
    `/objects/${objectId}/edit/buildings/create`,
  EditObjectPhotos: (objectId: string | number) =>
    `/objects/${objectId}/edit/photos`,
  EditObjectDocuments: (objectId: string | number) =>
    `/objects/${objectId}/edit/documents`,

  Object: (objectId: string | number) => `/objects/${objectId}`,
  ObjectAbout: (objectId: string | number) => `/objects/${objectId}/about`,
  ObjectBuildings: (objectId: string | number) =>
    `/objects/${objectId}/buildings`,
  ObjectBuilding: (objectId: string | number, buildingId: string | number) =>
    `/objects/${objectId}/buildings/${buildingId}/edit`,
  ObjectBuildingCreate: (objectId: string | number) =>
    `/objects/${objectId}/buildings/create`,
  ObjectChessboards: (objectId: string | number) =>
    `/objects/${objectId}/chess`,
  ObjectChessboard: (
    objectId: string | number,
    buildingId: string | number,
    entranceId: string | number
  ) => `/objects/${objectId}/chess/${buildingId}/${entranceId}`,
  ObjectPlans: (
    objectId: string | number,
    buildingId: string | number,
    entranceId: string | number
  ) => `/objects/${objectId}/chess/${buildingId}/${entranceId}/plans`,
  ObjectPhotos: (objectId: string | number) => `/objects/${objectId}/photos`,
  ObjectDocuments: (objectId: string | number) =>
    `/objects/${objectId}/documents`,
  ObjectNews: (objectId: string | number) => `/objects/${objectId}/news`,
  ObjectAnalytics: (objectId: string | number) =>
    `/objects/${objectId}/analytics`,
  ObjectChanges: (objectId: string | number) => `/objects/${objectId}/changes`,
  //endregion

  //region Agency Pages
  Agencies: () => `/agencies`,
  Agency: (agencyId: number | string) => `/agencies/${agencyId}`,
  CreateAgency: () => `/agencies/create`,
  AgencyDetails: (agencyId: number | string) => `/agencies/${agencyId}/details`,
  AgencyUsers: (agencyId: number | string) => `/agencies/${agencyId}/users`,
  CreateAgencyUser: (agencyId: number | string) =>
    `/agencies/${agencyId}/users/create`,
  AgencyUserDetails: (agencyId: number | string, userId: number | string) =>
    `/agencies/${agencyId}/users/${userId}/details`,
  //endregion

  //region User Pages
  Users: () => `/users`,
  User: (userId: number | string) => `/users/${userId}`,
  CreateUser: () => `/users/create`,
  UserDetails: (userId: number | string) => `/users/${userId}/details`,
  //endregion

  //region Lots Pages
  LotsPlanSchema: (
    objectId: string | number,
    buildingId: string | number,
    entranceId: string | number,
    planId: string | number
  ) =>
    `/objects/${objectId}/buildings/${buildingId}/lots/${entranceId}/plans/${planId}`,
  LotsPlanMatrix: (
    objectId: string | number,
    buildingId: string | number,
    entranceId: string | number,
    planId: string | number,
    polygonId: string | number
  ) =>
    `/objects/${objectId}/buildings/${buildingId}/lots/${entranceId}/plans/${planId}/matrix/${polygonId}`,
  //endregion
};
