import { FC } from 'react';
import { Button, ButtonProps, styled } from '@mui/material';
import { TextColor } from 'shared/theme/colors';
import {
  CloseIcon,
  DeleteBuckitIcon,
  EditButtonIcon,
  LogOutIcon,
  SearchIcon,
  StarAddIcon,
  StarRemoveIcon,
} from './Icons';

interface SmallSquareButtonProps extends ButtonProps {
  icon?:
    | 'delete'
    | 'edit'
    | 'starAdd'
    | 'starRemove'
    | 'logout'
    | 'close'
    | 'search';
  iconSize?: number;
}

const StyledButton = styled(Button)(({ theme, color }) => {
  const isPrimary = color === 'primary';

  return {
    background: isPrimary ? theme.palette.primary.main : TextColor.StaticWhite,
    color: isPrimary ? TextColor.StaticWhite : TextColor.Secondary,
    padding: theme.spacing(2),
    width: 36,
    minWidth: 0,
    border: isPrimary
      ? `1px solid ${theme.palette.primary.main}`
      : `1px solid ${TextColor.Tertiary}`,
    '&:hover': {
      border: isPrimary
        ? `1px solid ${theme.palette.primary.main}`
        : `1px solid ${TextColor.Tertiary}`,
      background: isPrimary ? '#494949' : '#c9c9c9',
    },
  };
});

export const SmallSquareButton: FC<SmallSquareButtonProps> = (props) => {
  const { icon = 'delete', iconSize, ...otherProps } = props;

  let IconComponent;
  switch (icon) {
    case 'delete':
      IconComponent = DeleteBuckitIcon;
      break;
    case 'edit':
      IconComponent = EditButtonIcon;
      break;
    case 'starAdd':
      IconComponent = StarAddIcon;
      break;
    case 'starRemove':
      IconComponent = StarRemoveIcon;
      break;
    case 'logout':
      IconComponent = LogOutIcon;
      break;
    case 'close':
      IconComponent = CloseIcon;
      break;
    case 'search':
      IconComponent = SearchIcon;
      break;
    default:
      IconComponent = DeleteBuckitIcon;
  }

  return (
    <StyledButton {...otherProps} variant="outlined" size="small">
      <IconComponent sx={{ fontSize: iconSize }} />
    </StyledButton>
  );
};
