import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'shared/ui/Col';
import { NumericField } from 'shared/ui/NumericField';
import { Row } from 'shared/ui/Row';
import { StyledTextField } from 'shared/ui/StyledTextField';

type EditEntranceFormProps = {
  isCreating: boolean;
};

export const EditEntranceForm: FC<EditEntranceFormProps> = ({ isCreating }) => {
  const { t } = useTranslation();

  return (
    <Col spacing={8}>
      <StyledTextField
        name="name"
        maxLength={30}
        showCharacterHintAtRemaining={10}
        label={t('entrance.form.label.name')}
        required
      />
      {isCreating && (
        <Row spacing={5} equalsChildrenWidth>
          <NumericField
            name="floorCount"
            valueIsNumericString
            maxLength={2}
            showCharacterHintAtRemaining={1}
            label={t('entrance.form.label.floorCount')}
            required
            allowNegative={false}
            decimalScale={0}
          />
          <NumericField
            name="riserCount"
            valueIsNumericString
            maxLength={2}
            showCharacterHintAtRemaining={1}
            label={t('entrance.form.label.riserCount')}
            required
            allowNegative={false}
            decimalScale={0}
          />
        </Row>
      )}
    </Col>
  );
};
