import compose from 'compose-function';
import { DayjsProvider } from './DayjsProvider';
import { I18nProvider } from './I18nProvider';
import { StoreProvider } from './StoreProvider';
import { ThemeProvider } from './ThemeProvider';

export const withProviders = compose(
  StoreProvider,
  DayjsProvider,
  ThemeProvider,
  I18nProvider
);
