import { $rtkApi, ApiTag } from 'shared/api/rtkApi';
import { ListData, ResponseData } from 'shared/types';
import {
  mapAgencyUserFromDto,
  mapDtoFromAgencyUser,
} from '../../lib/mapAgencyUser';
import {
  AgencyUser,
  AgencyUserDto,
  NewAgencyUser,
} from '../types/agencyUserSchema';

export const agencyUserApi = $rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    createAgencyUser: builder.mutation<AgencyUser, NewAgencyUser>({
      query: (user) => ({
        url: '/agency/users',
        method: 'POST',
        body: mapDtoFromAgencyUser(user),
      }),
      transformResponse: (response: ResponseData<AgencyUserDto>) =>
        mapAgencyUserFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, user) => [
        { type: ApiTag.agencyUser, id: ApiTag.agencyUser },
      ],
    }),
    updateAgencyUser: builder.mutation<AgencyUser, AgencyUser>({
      query: (user) => ({
        url: `/agency/users/${user.id}`,
        method: 'PUT',
        body: mapDtoFromAgencyUser(user),
      }),
      transformResponse: (response: ResponseData<AgencyUserDto>) =>
        mapAgencyUserFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, user) => [
        { type: ApiTag.agencyUser, id: user.id },
        { type: ApiTag.agencyUser, id: ApiTag.agencyUser },
      ],
    }),
    getAgencyUsers: builder.query<
      ListData<AgencyUser>,
      {
        search: string;
        limit: number;
        offset: number;
        agency_id?: number;
      }
    >({
      query: (params) => ({
        url: `/agency/users`,
        method: 'GET',
        params,
      }),
      providesTags: (result, error, page) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: ApiTag.agencyUser,
                id,
              })),
              { type: ApiTag.agencyUser, id: ApiTag.agencyUser },
            ]
          : [{ type: ApiTag.agencyUser, id: ApiTag.agencyUser }],
      transformResponse: (response: ResponseData<ListData<AgencyUserDto>>) => ({
        total: response.data.total,
        items: response.data.items.map((data) => mapAgencyUserFromDto(data)),
      }),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
    }),
  }),
});

export const {
  useCreateAgencyUserMutation,
  useUpdateAgencyUserMutation,
  useGetAgencyUsersQuery,
} = agencyUserApi;
