import { $rtkApi, ApiTag } from 'shared/api/rtkApi';
import { ListData, ResponseData } from 'shared/types';
import {
  mapBuildingFromDto,
  mapDtoFromCreateBuilding,
  mapDtoFromUpdateBuilding,
} from '../../lib/mapBuilding';
import { mapDtoFromBuildingsPositions } from '../../lib/mapBuildingsPositions';
import {
  Building,
  BuildingDto,
  BuildingsPositions,
  CreateBuilding,
} from '../types/buildingSchema';

export const buildingApi = $rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    createBuilding: builder.mutation<Building, CreateBuilding>({
      query: (building) => ({
        url: '/pavilions',
        method: 'POST',
        body: mapDtoFromCreateBuilding(building),
      }),
      transformResponse: (response: ResponseData<BuildingDto>) =>
        mapBuildingFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, building) => [
        { type: ApiTag.building },
        { type: ApiTag.objectPublicationErrors, id: building.objectId },
        { type: ApiTag.object, id: building.objectId },
      ],
    }),

    updateBuilding: builder.mutation<Building, Building>({
      query: (building) => ({
        url: `/pavilions/${building.id}`,
        method: 'PUT',
        body: mapDtoFromUpdateBuilding(building),
      }),
      transformResponse: (response: ResponseData<BuildingDto>) =>
        mapBuildingFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, building) => [
        { type: ApiTag.building },
        { type: ApiTag.objectPublicationErrors, id: building.objectId },
        { type: ApiTag.object, id: building.objectId },
      ],
    }),

    getBuildings: builder.query<
      ListData<Building>,
      { objectId: number; limit: number; offset: number; search: string }
    >({
      query: (params) => ({
        url: '/pavilions',
        method: 'GET',
        params: {
          building_id: params.objectId,
          name: params.search,
          limit: params.limit,
          offset: params.offset,
        },
      }),
      providesTags: (result, error, page) =>
        result
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTag.building, id })),
              { type: ApiTag.building },
            ]
          : [{ type: ApiTag.building }],
      transformResponse: (response: ResponseData<ListData<BuildingDto>>) => ({
        total: response.data.total,
        items: response.data.items.map((data) => mapBuildingFromDto(data)),
      }),
    }),

    getBuilding: builder.query<Building, string | number>({
      query: (id) => ({
        url: `/pavilions/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: ResponseData<BuildingDto>) =>
        mapBuildingFromDto(response.data),
      providesTags: (result, error, id) => [{ type: ApiTag.building, id }],
    }),

    deleteBuilding: builder.mutation<void, Building>({
      query: (building) => ({
        url: `/pavilions/${building.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, building) => [
        { type: ApiTag.building },
        { type: ApiTag.objectPublicationErrors, id: building.objectId },
        { type: ApiTag.object, id: building.objectId },
      ],
    }),

    changeBuildingsPositions: builder.mutation<
      void,
      {
        objectId: number;
        buildingsPositions: BuildingsPositions;
        versionCheck: () => Promise<boolean>;
      }
    >({
      queryFn: async (params, _queryApi, _extraOptions, fetchWithBQ) => {
        const isVersionChanged = await params.versionCheck();

        if (isVersionChanged) {
          return {
            error: {
              status: 409,
              statusText: 'Conflict',
              data: 'Version mismatch detected',
            },
          };
        }

        const response = await fetchWithBQ({
          url: `/pavilions/positions/change/${params.objectId}`,
          method: 'POST',
          body: mapDtoFromBuildingsPositions(params.buildingsPositions),
        });

        if (response.error) {
          return { error: response.error };
        }

        return { data: undefined };
      },
      async onQueryStarted(
        updatedBuildingsPositions,
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          buildingApi.util.updateQueryData(
            'getBuildings',
            {
              objectId: updatedBuildingsPositions.objectId,
              limit: 30,
              offset: 0,
              search: '',
            },
            (draft) => {
              draft.items = draft.items.sort(
                (a, b) =>
                  updatedBuildingsPositions.buildingsPositions.buildingsIds.indexOf(
                    a.id
                  ) -
                  updatedBuildingsPositions.buildingsPositions.buildingsIds.indexOf(
                    b.id
                  )
              );
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, data) => {
        if (error?.status === 409) {
          return [];
        }

        return [
          { type: ApiTag.building },
          { type: ApiTag.object, id: data.objectId },
        ];
      },
    }),

    duplicateBuilding: builder.mutation<Building, Building>({
      query: (building) => ({
        url: `/pavilions/replicate/${building.id}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, building) => [
        { type: ApiTag.building },
        { type: ApiTag.object, id: building.objectId },
      ],
    }),
  }),
});

export const {
  useCreateBuildingMutation,
  useUpdateBuildingMutation,
  useGetBuildingsQuery,
  useGetBuildingQuery,
  useDeleteBuildingMutation,
  useChangeBuildingsPositionsMutation,
  useDuplicateBuildingMutation,
} = buildingApi;
