import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { getPolygonImage } from 'shared/helpers/getImageUrl';
import { useConfirmationDialog } from 'shared/hooks/useConfirmationDialog';
import { BorderColor, TextColor } from 'shared/theme/colors';
import { ButtonLoadingSpinner } from 'shared/ui/ButtonLoadingSpinner';
import { Col } from 'shared/ui/Col';
import { RemovalConfirmationCard } from 'shared/ui/RemovalConfirmationCard';
import { Row } from 'shared/ui/Row';

type PolygonLotsDialogProps = {
  open: boolean;
  showRemoveLotFromPolygonButton: boolean;
  showDeletePolygonButton: boolean;
  showSaveButton: boolean;
  title: string;
  subtitle: string;
  polygonId?: number | string;
  onSaveClick: () => void;
  onCancelClick: () => void;
  onRemoveLotFromPolygonClick: () => void;
  onDeletePolygonClick: () => void;
  isPolygonUpdating: boolean;
  isPolygonDeleting: boolean;
};

export const PolygonLotsDialog: FC<PolygonLotsDialogProps> = ({
  open,
  title,
  subtitle,
  polygonId,
  showRemoveLotFromPolygonButton,
  showDeletePolygonButton,
  showSaveButton,
  onDeletePolygonClick,
  onRemoveLotFromPolygonClick,
  onSaveClick,
  onCancelClick,
  isPolygonUpdating,
  isPolygonDeleting,
}) => {
  const { t } = useTranslation();
  const {
    isShowRemoveConfirmation,
    showRemoveConfirmation,
    hideRemoveConfirmation,
    confirmRemoveAction,
  } = useConfirmationDialog({
    onConfirm: () => onDeletePolygonClick(),
  });

  const handleCancelClick = () => {
    onCancelClick();
  };

  const handleAddLotsToPolygon = () => {
    onSaveClick();
  };

  return (
    <Dialog
      open={open}
      hideBackdrop
      scroll={'paper'}
      sx={{
        pointerEvents: 'none',
        '& .MuiDialog-container': {
          justifyContent: 'flex-end',
        },
      }}
      PaperProps={{
        sx: {
          width: 450,
          height: 773,
          pointerEvents: 'all',
          border: 'none',
          marginRight: 11,
          boxShadow: `0 0 0 1px ${BorderColor.Gray}, 0px 0px 8px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.08)`,
        },
      }}
    >
      <DialogContent
        dividers
        sx={{ p: 0, mt: 9, mx: 8, mb: 4, borderTop: 'none' }}
      >
        <Col spacing={9}>
          <Col spacing={8}>
            <Typography variant={'headerM'}>{title}</Typography>
            <Typography variant="paragraphM" color={TextColor.Secondary}>
              {subtitle}
            </Typography>
          </Col>
          {isShowRemoveConfirmation ? (
            <RemovalConfirmationCard
              titleEnding="область"
              onRemoveClick={confirmRemoveAction}
              onCancelClick={hideRemoveConfirmation}
            />
          ) : (
            <Row spacing={5}>
              {showRemoveLotFromPolygonButton && (
                <Button
                  size="small"
                  variant="outlined"
                  onClick={onRemoveLotFromPolygonClick}
                >
                  {t('plan.dialog.unbind')}
                </Button>
              )}
              {showDeletePolygonButton && (
                <Button
                  size="small"
                  variant="outlined"
                  onClick={showRemoveConfirmation}
                  disabled={isPolygonDeleting}
                >
                  {t('plan.dialog.deleteArea')}
                  {isPolygonDeleting && <ButtonLoadingSpinner />}
                </Button>
              )}
            </Row>
          )}
          {polygonId && (
            <Box
              sx={{
                justifyContent: 'center',
                display: 'flex',
                width: '100%',
              }}
            >
              <img
                src={getPolygonImage(polygonId)}
                alt={`plan`}
                style={{
                  maxWidth: '100%',
                  minWidth: 100,
                  objectFit: 'fill',
                  minHeight: 150,
                  maxHeight: 270,
                  border: '1px solid #ccc',
                }}
              />
            </Box>
          )}
        </Col>
      </DialogContent>

      <DialogActions
        sx={{
          p: 8,
          pb: 9,
          justifyContent: 'start',
          backgroundColor: TextColor.StaticWhite,
        }}
      >
        {showSaveButton && (
          <Button
            color="secondary"
            onClick={handleAddLotsToPolygon}
            disabled={isPolygonUpdating}
          >
            {t('plan.dialog.bindArea')}
            {isPolygonUpdating && <ButtonLoadingSpinner />}
          </Button>
        )}
        <Button variant="outlined" onClick={handleCancelClick}>
          {t('ui.common.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
