import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { developerApi } from 'entities/developer';
import { getRoute } from 'shared/const';
import { getApiImage } from 'shared/helpers/getImageUrl';
import { useDebounce } from 'shared/hooks/useDebounce';
import { useScrollPaginator } from 'shared/hooks/useScrollPaginator';
import { Col } from 'shared/ui/Col';
import {
  PreviewCardsGrid,
  PreviewInfoCardProps,
} from 'shared/ui/PreviewCardsGrid';
import { Row } from 'shared/ui/Row';
import { SearchInput } from 'shared/ui/SearchInput';

export const DevelopersTab: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const form = useForm<{
    search: string;
  }>({
    defaultValues: {
      search: '',
    },
  });

  const params = form.watch();
  const debouncedSearchText = useDebounce(params.search);

  const { items, isAllItemsLoaded } = useScrollPaginator(
    developerApi.useGetDevelopersQuery,
    { search: debouncedSearchText },
    { headerHeight: 400, previewCardHeight: 100, amountPerRow: 3 }
  );

  const handleAddDeveloperClick = () => {
    navigate(getRoute.CreateDeveloper());
  };

  const handleDeveloperClick = useCallback(
    (developerId: number) => () => {
      navigate(getRoute.DeveloperDetails(developerId));
    },
    [navigate]
  );

  const formattedDataForPreviewCards: PreviewInfoCardProps[] = useMemo(() => {
    return items.map((developer) => ({
      id: developer.id,
      logoSrc: developer.logo ? getApiImage(developer.logo) : '',
      title: developer.name,
      subtitle: developer.inn
        ? t('developer.tab.previewCard.subtitle', {
            inn: developer.inn,
          })
        : '',
      text: developer.address,
      onLinkClick: handleDeveloperClick(developer.id),
    }));
  }, [handleDeveloperClick, items, t]);

  return (
    <Col spacing={8}>
      <Row
        sx={{
          justifyContent: 'space-between',
          flexDirection: { xs: 'column-reverse', sm: 'row' },
          gap: 6,
        }}
      >
        <Button
          variant={'contained'}
          color={'primary'}
          size={'small'}
          onClick={handleAddDeveloperClick}
          sx={{ width: '100%' }}
        >
          {t('developer.tab.button.addDeveloper')}
        </Button>

        <FormProvider {...form}>
          <SearchInput
            name={'search'}
            searchPlaceholder={t('developer.tab.input.search')}
            sx={{ width: { xs: '100%', sm: 280 } }}
          />
        </FormProvider>
      </Row>
      <PreviewCardsGrid
        data={formattedDataForPreviewCards}
        isShowSkeleton={!isAllItemsLoaded}
      />
    </Col>
  );
};
