import { Lot, LotDto, LotPosition, NewLot } from '../model/types/lotSchema';
import { defaultNewLotDtoData } from './mapChess';

export const mapLotFromDto = (
  lotDto: LotDto,
  positions: LotPosition[]
): Lot => ({
  id: lotDto.id,
  row: lotDto.row,
  headId: lotDto.head_id ?? 0,
  status:
    !lotDto.status || lotDto.status === '0' || lotDto.status === 'zeroStatus'
      ? ''
      : lotDto.status,
  totalPrice: lotDto.price_all?.toString() ?? '',
  pricePerMeter: lotDto.price_m?.toString() ?? '',
  colspan: lotDto.colspan || 1,
  rowspan: lotDto.rowspan || 1,
  step: lotDto.step,
  type: lotDto.type,
  numberOfFloors: lotDto.floors ?? '',
  numberOfRooms:
    !lotDto.cnt_apart ||
    lotDto.cnt_apart === '0' ||
    lotDto.cnt_apart === 'zeroAp'
      ? ''
      : lotDto.cnt_apart,
  totalArea: lotDto.area_all?.toString() || '',
  landArea: lotDto.total_area ?? '',
  decoration:
    !lotDto.interior || lotDto.interior === 'zeroInt' ? '' : lotDto.interior,
  windowView: lotDto.view_type ?? '',
  promotion: lotDto.view_action ?? '0',
  lotType:
    !lotDto.type_room ||
    lotDto.type_room === 'zeroRoom' ||
    lotDto.type_room === '0'
      ? ''
      : lotDto.type_room,
  disabled: Boolean(lotDto.disabled),
  actualSaleDate: lotDto.date_sold ?? undefined,
  kitchenArea: lotDto.kitchen_area?.toString() ?? undefined,
  registration: lotDto.checkout2?.map((item) => item.toString()),

  buyingOptions: [
    lotDto.ipoteka ? 'ipoteka' : '',
    lotDto.military_ipoteka ? 'military_ipoteka' : '',
    lotDto.mat_capital ? 'mat_capital' : '',
    lotDto.cash ? 'cash' : '',
    lotDto.taxes ? 'taxes' : '',
    lotDto.installments ? 'installments' : '',
    lotDto.is_ipoteka_family ? 'is_ipoteka_family' : '',
    lotDto.is_ipoteka_state_support ? 'is_ipoteka_state_support' : '',
    lotDto.is_ipoteka_it ? 'is_ipoteka_it' : '',
    lotDto.is_ipoteka_tranche ? 'is_ipoteka_tranche' : '',
    lotDto.is_ipoteka_subsidized ? 'is_ipoteka_subsidized' : '',
    lotDto.is_ipoteka_preferential ? 'is_ipoteka_preferential' : '',
  ].filter((value) => value !== ''),
  installmentTerms: lotDto.installments_info ?? '',

  panorama: lotDto.is_panoramic_glazing?.toString() ?? '',
  lotNumber: lotDto.num_apart?.toString() ?? '',
  lotNumberingFormat: '',
  positions,
  plan_id: lotDto.plan_id,
  poligon_id: lotDto.poligon_id,
  number_id: lotDto.number_id,
});

export const mapDtoFromLot = ({
  status,
  totalPrice,
  pricePerMeter,
  numberOfFloors,
  numberOfRooms,
  totalArea,
  landArea,
  decoration,
  windowView,
  promotion,
  disabled,
  actualSaleDate,
  kitchenArea,
  lotNumber,
  type,
  lotType,
  panorama,
  balconyArea,
  row,
  colspan,
  rowspan,
  step,
  registration,
  buyingOptions,
  installmentTerms,
  number_id,
  plan_id,
  poligon_id,
  ...rest
}: Lot | NewLot): LotDto => {
  const id = 'id' in rest ? rest.id : undefined;
  const headId = 'headId' in rest ? rest.headId : undefined;

  return {
    id: id as number,
    ...defaultNewLotDtoData,
    status,
    row,
    price_all: totalPrice ? Number(totalPrice) : undefined,
    price_m: pricePerMeter ? Number(pricePerMeter) : undefined,
    type,
    floors: numberOfFloors,
    cnt_apart: numberOfRooms,
    area_all: totalArea ? Number(totalArea) : undefined,
    total_area: landArea,
    interior: decoration,
    view_type: windowView,
    view_action: promotion,
    disabled: disabled ? 1 : 0,
    date_sold: actualSaleDate,
    kitchen_area: kitchenArea ? Number(kitchenArea) : undefined,
    type_room: lotType,
    balcony: balconyArea ? 1 : null,
    is_panoramic_glazing: panorama ? Number(panorama) : undefined,
    checkout2: registration ? registration.map((item) => Number(item)) : null,

    // buyingOptions
    ipoteka: buyingOptions?.includes('ipoteka') || false,
    military_ipoteka: buyingOptions?.includes('military_ipoteka') || false,
    mat_capital: buyingOptions?.includes('mat_capital') || false,
    cash: buyingOptions?.includes('cash') || false,
    taxes: buyingOptions?.includes('taxes') || false,
    installments: buyingOptions?.includes('installments') || false,
    installments_info: installmentTerms,
    is_ipoteka_family: buyingOptions?.includes('is_ipoteka_family') || false,
    is_ipoteka_state_support:
      buyingOptions?.includes('is_ipoteka_state_support') || false,
    is_ipoteka_it: buyingOptions?.includes('is_ipoteka_it') || false,
    is_ipoteka_tranche: buyingOptions?.includes('is_ipoteka_tranche') || false,
    is_ipoteka_subsidized:
      buyingOptions?.includes('is_ipoteka_subsidized') || false,
    is_ipoteka_preferential:
      buyingOptions?.includes('is_ipoteka_preferential') || false,

    colspan,
    rowspan,
    step,
    num_apart: lotNumber,
    head_id: headId,
    number_id,
    plan_id,
    poligon_id,
  };
};
