import { FC, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useGetBuildingQuery } from 'entities/building';
import { Lot } from 'entities/chess';
import { useGetChessQuery } from 'entities/chess/model/api/ChessService';
import { useVersionCheck } from 'entities/object';
import {
  useDeletePolygonMutation,
  useGetPlanQuery,
  useGetPolygonsQuery,
  useUpdatePolygonMutation,
} from 'entities/plan';
import { getRoute } from 'shared/const';
import { LotsGrid } from 'shared/ui/LotsGrid/LotsGrid';
/* eslint-disable boundaries/element-types */
import { Row } from 'shared/ui/Row';
import { SmallChip } from 'shared/ui/SmallChip';
import {
  getSelectedLots,
  GridSelection,
  GridSelectionMode,
} from 'widgets/Chessboard/lib/getSelectedLots';
import { toggleSelection } from 'widgets/Chessboard/lib/toggleSelection';
import { PlanMatrixSkeleton } from './PlanMatrixSkeleton';
import { PolygonLotsDialog } from './PolygonLotsDialog';
/* eslint-enable boundaries/element-types */

const initialGridSelection: GridSelection = {
  selectedLots: [],
  selection: [],
  selectionMode: 'lots',
};

export const PlanMatrix: FC = () => {
  const { t } = useTranslation();
  const { objectId, buildingId, entranceId, planId, polygonId } = useParams<{
    objectId: string;
    buildingId: string;
    entranceId: string;
    planId: string;
    polygonId: string;
  }>();
  const navigate = useNavigate();
  const [deletePolygon, { isLoading: isPolygonDeleting }] =
    useDeletePolygonMutation();
  const [updatePolygon, { isLoading: isPolygonUpdating }] =
    useUpdatePolygonMutation();
  const [gridSelection, setGridSelection] =
    useState<GridSelection>(initialGridSelection);

  const { checkVersion, showVersionChangedPopup } = useVersionCheck(objectId);

  const { data: plan, isLoading: isPlanLoading } = useGetPlanQuery(
    planId ?? -1,
    { skip: !planId }
  );
  const { data: polygonsList, isLoading: isPolygonsLoading } =
    useGetPolygonsQuery(planId ?? -1, { skip: !planId });

  const { data: building, isLoading: isBuildingLoading } = useGetBuildingQuery(
    buildingId ?? skipToken
  );

  const entrance = building?.entrances?.find(
    ({ id }) => id.toString() === entranceId
  );

  const { data: chess, isLoading: isLotsLoading } = useGetChessQuery(
    entrance?.id ?? skipToken
  );

  const { filteredLotsIds, filteredFloors } = useMemo(() => {
    if (!chess || !plan) {
      return {};
    }

    const filteredFloors = plan.floorIds.map(
      (floorId) =>
        chess.floors.find((floor) => floor.id === floorId)!.positions[0].floor
    );
    const filteredLotsIds = getSelectedLots(
      'floors',
      filteredFloors,
      chess.lots
    ).map(({ id }) => id);

    return { filteredLotsIds, filteredFloors };
  }, [chess, plan]);

  const dialogOptions = useMemo(() => {
    const selectedLots = gridSelection.selectedLots;
    const lotsCount = selectedLots.length;
    const isAllLotsWithNumbering = selectedLots.every(({ lotNumber }) =>
      Boolean(lotNumber)
    );
    const title =
      lotsCount > 3 || !isAllLotsWithNumbering
        ? t('plan.dialog.lotCount', { count: selectedLots.length })
        : t('plan.dialog.title.lotNumbers', {
            numbers: selectedLots.map(({ lotNumber }) => lotNumber).join(', '),
          });

    const getSubtitle = () => {
      const positions = selectedLots.flatMap(({ positions }) => positions);
      const floors = Array.from(
        new Set(positions.map(({ floor }) => floor))
      ).join(', ');
      const risers = Array.from(
        new Set(positions.map(({ riser }) => riser))
      ).join(', ');

      return `${t('plan.dialog.entrance', {
        entrance: entrance?.name,
      })}, ${t('plan.dialog.floors', {
        floors: floors,
        count: floors.length,
      })}, ${t('plan.dialog.risers', {
        risers: risers,
        count: risers.length,
      })}`;
    };

    if (!polygonsList) {
      return {
        title,
        subtitle: getSubtitle(),
        showDeletePolygonButton: false,
        showRemoveLotFromPolygonButton: false,
        showSaveButton: false,
      };
    }
    const selectedLotsIds = selectedLots.map(({ id }) => id);

    const polygonsWithSelectedLots = polygonsList.items.filter((polygon) =>
      selectedLotsIds.every((lotId) => polygon.chess.includes(lotId))
    );

    const allLotsInSamePolygon = polygonsWithSelectedLots.length === 1;
    let allLotsWithoutPolygon = true;

    for (const lotId of selectedLotsIds) {
      if (
        polygonsList.items
          .filter((polygon) => polygon.id !== Number(polygonId))
          .some((polygon) => polygon.chess.includes(lotId))
      ) {
        allLotsWithoutPolygon = false;
        break;
      }
    }

    const anyLotWithoutPolygon = selectedLotsIds.some(
      (lotId) =>
        !polygonsList.items.some((polygon) => polygon.chess.includes(lotId))
    );

    allLotsWithoutPolygon = allLotsWithoutPolygon && anyLotWithoutPolygon;

    return {
      title,
      subtitle: getSubtitle(),
      showDeletePolygonButton: allLotsInSamePolygon,
      showRemoveLotFromPolygonButton: allLotsInSamePolygon,
      showSaveButton: allLotsWithoutPolygon,
    };
  }, [entrance?.name, gridSelection.selectedLots, polygonId, polygonsList, t]);

  if (isBuildingLoading || isLotsLoading || isPlanLoading || isPolygonsLoading)
    return <PlanMatrixSkeleton />;

  if (
    !objectId ||
    !buildingId ||
    !entranceId ||
    !planId ||
    !building ||
    !entrance ||
    !chess ||
    !plan
  ) {
    return (
      <Typography
        variant="caption"
        sx={{
          p: 2,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        The entrance, plan or checkerboard do not exist
      </Typography>
    );
  }

  const select = (value: number, mode: GridSelectionMode) => {
    const newSelection =
      gridSelection.selectionMode != mode
        ? [value]
        : toggleSelection(value, gridSelection.selection);
    const selectedLots = getSelectedLots(mode, newSelection, chess.lots);
    setGridSelection({
      selection: newSelection,
      selectionMode: mode,
      selectedLots:
        mode === 'risers' && filteredLotsIds
          ? selectedLots.filter((lot) => filteredLotsIds.includes(lot.id))
          : selectedLots,
    });
  };

  const handleLotClick = (lotId: number) => () => {
    if (filteredLotsIds && filteredLotsIds.includes(lotId)) {
      select(lotId, 'lots');
    }
  };

  const clearSelection = () => {
    setGridSelection(initialGridSelection);
  };

  const handleFloorCellClick = (rowIndex: number) => () => {
    if (filteredFloors && filteredFloors.includes(rowIndex)) {
      select(rowIndex, 'floors');
    }
  };

  const handleRiserCellClick = (columnIndex: number) => {
    select(columnIndex, 'risers');
  };

  const handleDeletePolygon = async () => {
    const isVersionChanged = await checkVersion();

    if (!isVersionChanged) {
      if (polygonsList) {
        const polygon = polygonsList?.items?.find(({ chess }) =>
          chess.includes(gridSelection.selectedLots[0].id)
        );
        if (polygon) {
          deletePolygon({ objectId, polygonId: polygon.id });
          if (polygonsList.items.length > 1) {
            navigate(
              getRoute.LotsPlanMatrix(
                objectId,
                buildingId,
                entranceId,
                planId,
                polygonsList.items.filter(({ id }) => id !== polygon.id)[0].id
              )
            );
          } else {
            navigate(
              getRoute.LotsPlanSchema(objectId, buildingId, entranceId, planId)
            );
          }
        }
      }
    }
  };

  const handleAddLotsToPolygon = async () => {
    const isVersionChanged = await checkVersion();

    if (!isVersionChanged) {
      const polygon = polygonsList?.items?.find(
        ({ id }) => id === Number(polygonId)
      );
      if (polygon) {
        updatePolygon({
          objectId,
          polygon: {
            ...polygon,
            lotIds: Array.from(
              new Set([
                ...gridSelection.selectedLots.map(({ id }) => id),
                ...polygon.chess,
              ])
            ),
          },
        });
      }
    }
  };

  const handleRemoveLotFromPolygon = async () => {
    const isVersionChanged = await checkVersion();

    if (!isVersionChanged) {
      const polygon = polygonsList?.items?.find(({ chess }) =>
        chess.includes(gridSelection.selectedLots[0].id)
      );
      if (polygon) {
        updatePolygon({
          objectId,
          polygon: {
            ...polygon,
            lotIds: polygon.chess.filter((lotId) =>
              gridSelection.selectedLots.every(({ id }) => id !== lotId)
            ),
          },
        });
      }
    }
  };

  const polygonsChips = polygonsList
    ? polygonsList.items.map((polygon, index) => (
        <SmallChip
          key={polygon.id}
          label={t('plan.chipText.area', { polygon: index + 1 })}
          variant={'process'}
          icon="clip"
          onClick={() =>
            navigate(
              getRoute.LotsPlanMatrix(
                objectId,
                buildingId,
                entranceId,
                planId,
                polygon.id
              )
            )
          }
        />
      ))
    : null;

  return (
    <Box>
      {showVersionChangedPopup()}
      <Row spacing={5} sx={{ py: 5 }}>
        {polygonsChips}
      </Row>
      <LotsGrid<Lot>
        lots={chess.lots}
        floors={chess.floors}
        risers={chess.risers}
        filteredLotsIds={filteredLotsIds}
        selectedLots={gridSelection.selectedLots.map(({ id }) => id)}
        selectedFloors={
          gridSelection.selectionMode === 'floors'
            ? gridSelection.selection
            : []
        }
        selectedRisers={
          gridSelection.selectionMode === 'risers'
            ? gridSelection.selection
            : []
        }
        onFloorCellClick={handleFloorCellClick}
        onRiserCellClick={handleRiserCellClick}
        onLotClick={handleLotClick}
        polygons={polygonsList?.items}
      />
      <PolygonLotsDialog
        open={gridSelection.selectedLots.length > 0}
        title={dialogOptions.title}
        subtitle={dialogOptions.subtitle}
        polygonId={polygonId}
        onSaveClick={handleAddLotsToPolygon}
        onCancelClick={clearSelection}
        showDeletePolygonButton={dialogOptions.showDeletePolygonButton}
        showRemoveLotFromPolygonButton={
          dialogOptions.showRemoveLotFromPolygonButton
        }
        showSaveButton={dialogOptions.showSaveButton}
        onDeletePolygonClick={handleDeletePolygon}
        onRemoveLotFromPolygonClick={handleRemoveLotFromPolygon}
        isPolygonUpdating={isPolygonUpdating}
        isPolygonDeleting={isPolygonDeleting}
      />
    </Box>
  );
};
