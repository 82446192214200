import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Button, Typography } from '@mui/material';
import { getRoute } from 'shared/const';
import { CardLayout } from 'shared/ui/CardLayout';
import { Col } from 'shared/ui/Col';

export const RestorePasswordDone: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <CardLayout maxWidth={368} variant="secondary" paddingY={32}>
      <Col spacing={5}>
        <Typography variant={'headerXS'}>
          {t('auth.restoreFormDone.title')}
        </Typography>
        <Typography variant={'paragraphM'}>
          {t('auth.restoreFormDone.subtitle')}
        </Typography>
      </Col>
      <Button onClick={() => navigate(getRoute.AuthLogin())}>
        {t('auth.restoreFormDone.toLoginForm')}
      </Button>
    </CardLayout>
  );
};
