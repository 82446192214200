import { $rtkApi, ApiTag } from 'shared/api/rtkApi';
import { ResponseData } from 'shared/types';
import {
  mapDtoFromSalesOffice,
  mapSalesOfficeFromDto,
} from '../../lib/mapSalesOffice';
import { SalesOffice, SalesOfficeDto } from '../types/salesOfficeSchema';

export const salesOfficeApi = $rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    createSalesOffice: builder.mutation<
      SalesOffice,
      SalesOffice & { objectId?: number }
    >({
      query: (salesOffice) => ({
        url: '/sales-offices',
        method: 'POST',
        body: mapDtoFromSalesOffice(salesOffice),
      }),
      transformResponse: (response: ResponseData<SalesOfficeDto>) =>
        mapSalesOfficeFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, salesOffice) =>
        salesOffice.objectId
          ? [{ type: ApiTag.object, id: salesOffice.objectId }]
          : [],
    }),
    updateSalesOffice: builder.mutation<
      SalesOffice,
      { salesOffice: SalesOffice; objectId: number }
    >({
      query: ({ salesOffice }) => ({
        url: `/sales-offices/${salesOffice.id}`,
        method: 'PUT',
        body: mapDtoFromSalesOffice(salesOffice),
      }),
      transformResponse: (response: ResponseData<SalesOfficeDto>) =>
        mapSalesOfficeFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, salesOffice) =>
        salesOffice.objectId
          ? [{ type: ApiTag.object, id: salesOffice.objectId }]
          : [],
    }),
    deleteSalesOffice: builder.mutation<
      void,
      { objectId?: number; officeId: number }
    >({
      query: (params) => ({
        url: `/sales-offices/${params.officeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, salesOffice) =>
        salesOffice.objectId
          ? [{ type: ApiTag.object, id: salesOffice.objectId }]
          : [],
    }),
  }),
});

export const {
  useCreateSalesOfficeMutation,
  useUpdateSalesOfficeMutation,
  useDeleteSalesOfficeMutation,
} = salesOfficeApi;
