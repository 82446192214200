import { $rtkApi, ApiTag } from 'shared/api/rtkApi';
import { ListData, ResponseData } from 'shared/types';
import { mapAgencyFromDto, mapDtoFromAgency } from '../../lib/mapAgency';
import { Agency, AgencyDto } from '../types/agencySchema';

export const agencyApi = $rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    createAgency: builder.mutation<Agency, Agency>({
      query: (agency) => ({
        url: '/agencies',
        method: 'POST',
        body: mapDtoFromAgency(agency),
      }),
      transformResponse: (response: ResponseData<AgencyDto>) =>
        mapAgencyFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, agency) => [
        { type: ApiTag.agency, id: ApiTag.agency },
      ],
    }),
    updateAgency: builder.mutation<Agency, Agency>({
      query: (agency) => ({
        url: `/agencies/${agency.id}`,
        method: 'PUT',
        body: mapDtoFromAgency(agency),
      }),
      transformResponse: (response: ResponseData<AgencyDto>) =>
        mapAgencyFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, agency) => [
        { type: ApiTag.agency, id: agency.id },
        { type: ApiTag.agency, id: ApiTag.agency },
      ],
    }),
    getAgencies: builder.query<
      ListData<Agency>,
      {
        search?: string;
        limit: number;
        offset: number;
      }
    >({
      query: (params) => ({
        url: `/agencies`,
        method: 'GET',
        params: {
          ...params,
          search: params.search !== '' ? params.search : undefined,
        },
      }),
      providesTags: (result, error, page) =>
        result
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTag.agency, id })),
              { type: ApiTag.agency, id: ApiTag.agency },
            ]
          : [{ type: ApiTag.agency, id: ApiTag.agency }],
      transformResponse: (response: ResponseData<ListData<AgencyDto>>) => ({
        total: response.data.total,
        items: response.data.items.map((data) => mapAgencyFromDto(data)),
      }),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
    }),
    getAgency: builder.query<Agency, string | number>({
      query: (id) => ({
        url: `/agencies/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: ResponseData<AgencyDto>) =>
        mapAgencyFromDto(response.data),
      providesTags: (result, error, id) => [{ type: ApiTag.agency, id }],
    }),
  }),
});

export const {
  useCreateAgencyMutation,
  useUpdateAgencyMutation,
  useGetAgenciesQuery,
  useGetAgencyQuery,
} = agencyApi;
