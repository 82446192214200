import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import i18n from 'i18next';
import { Button, Card, CardContent, Typography } from '@mui/material';
import { getRoute } from 'shared/const';
import { Col } from './Col';
import { ResultErrorIcon, ResultSuccessIcon } from './Icons';

interface ButtonProps {
  label?: string;
  navigateTo?: string;
}

interface ResultCardProps {
  isSuccess: boolean;

  successProps?: {
    title?: string;
    subtitle?: string;
    primaryButton?: ButtonProps;
    secondaryButton?: ButtonProps;
  };

  errorProps?: {
    title?: string;
    subtitle?: string;
    primaryButton?: ButtonProps;
    secondaryButton?: ButtonProps;
  };
}

const defaultSuccessProps = {
  title: i18n.t('ui.common.dataSaved'),
  subtitle: i18n.t('ui.common.dataUpdateInFewMinutes'),
  primaryButton: {
    label: i18n.t('ui.common.fine'),
    navigateTo: getRoute.Main(),
  },
};

const defaultErrorProps = {
  title: i18n.t('ui.resultCard.error.title'),
  subtitle: i18n.t('ui.resultCard.error.subtitle'),
  primaryButton: {
    label: i18n.t('ui.common.returnToMain'),
    navigateTo: getRoute.Main(),
  },
};

export const ResultCard: FC<ResultCardProps> = ({
  isSuccess,
  successProps = {},
  errorProps = {},
}) => {
  const navigate = useNavigate();

  const currentProps = isSuccess
    ? { ...defaultSuccessProps, ...successProps }
    : { ...defaultErrorProps, ...errorProps };

  const handleNavigate = (navigateTo?: string) => {
    if (navigateTo) {
      navigate(navigateTo);
    } else {
      navigate(getRoute.Main());
    }
  };

  const renderButton = (buttonProps?: ButtonProps, isSecondary = false) => {
    const { label = i18n.t('ui.common.fine'), navigateTo } = buttonProps || {};
    return (
      <Button
        sx={{ minWidth: 274 }}
        variant={isSecondary ? 'outlined' : 'contained'}
        onClick={() => handleNavigate(navigateTo)}
      >
        {label}
      </Button>
    );
  };

  return (
    <Card sx={{ m: 'auto', mt: '100px', maxWidth: 443 }}>
      <CardContent sx={{ mt: '15px' }}>
        <Col sx={{ alignItems: 'center', textAlign: 'center' }} spacing={6}>
          {isSuccess ? (
            <ResultSuccessIcon sx={{ fontSize: 48 }} />
          ) : (
            <ResultErrorIcon sx={{ fontSize: 48 }} />
          )}
          <Typography variant="headerM">{currentProps.title}</Typography>
          <Typography align="center" sx={{ px: 5, mb: 8 }}>
            {currentProps.subtitle}
          </Typography>
          <Col spacing={5}>
            {renderButton(currentProps.primaryButton)}
            {currentProps.secondaryButton &&
              renderButton(currentProps.secondaryButton, true)}
          </Col>
        </Col>
      </CardContent>
    </Card>
  );
};
