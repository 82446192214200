import { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { t } from 'i18next';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import MaskedInput, { conformToMask } from 'react-text-mask';
// eslint-disable-next-line boundaries/element-types
import { useGetObjectQuery } from 'entities/object';
import {
  calculatePricePerMeter,
  calculateTotalPrice,
} from 'shared/helpers/fieldsCalculation';
import { getLabelByValue } from 'shared/helpers/getLabelByValue';
import {
  decorationOptions,
  numberOfRoomsOptions,
  promotionOptions,
  purchaseTypeOptions,
  registrationOptions,
  statusOptions,
  typeOptions,
  windowViewOptions,
} from 'shared/selectOptions/chessOptions';
import { NumericField } from 'shared/ui/NumericField';
import { Row } from 'shared/ui/Row';
import { SelectField } from 'shared/ui/SelectField';
import { SelectWithChips } from 'shared/ui/SelectWithChips';
import { StyledDatePicker } from 'shared/ui/StyledDatePicker';
import { StyledTextField } from 'shared/ui/StyledTextField';
import { getFormatByType, getMask } from '../lib/numberingHelpers';
import { numberingMaskOption, numberingMaskPlaceholder } from '../model/consts';
import { Lot, NumberingFormatType } from '../model/types/lotSchema';

const OBJECT_TYPE_RESIDENTIAL_COMPLEX = '1';

type EditAboutLotForm = {
  allFieldOptional: Boolean;
};

export const EditAboutLotForm: FC<EditAboutLotForm> = ({
  allFieldOptional,
}) => {
  const { objectId, buildingId, entranceId } = useParams<{
    objectId: string;
    buildingId: string;
    entranceId: string;
  }>();
  const { data: object } = useGetObjectQuery(objectId ?? skipToken);
  const form = useFormContext<Lot>();

  const [isSoldStatus, setIsSoldStatus] = useState<boolean>(false);
  const maskType = useWatch<Lot>({
    name: 'lotNumberingFormat',
  }) as NumberingFormatType;
  const buyingOptions = form.watch('buyingOptions');

  const isCalculatingRef = useRef(false);

  const { format, placeholder } = getFormatByType(maskType);

  const lotStatus = form.watch('status');

  useEffect(() => {
    if (lotStatus !== 'soldStatus') {
      form.setValue('actualSaleDate', '');
    }
    setIsSoldStatus(lotStatus === 'soldStatus');
  }, [lotStatus, form.setValue, form]);

  useEffect(() => {
    const lotNumber = form.getValues('lotNumber') ?? '';
    const maskedLotNumber = conformToMask(
      lotNumber,
      getMask(lotNumber, format),
      { guide: false }
    );
    form.setValue('lotNumber', maskedLotNumber.conformedValue);
  }, [format]);

  const rowElementSx = {
    minWidth: { xs: 150, sm: 158, md: 166 },
    flex: 1,
  };

  return (
    <Row equalsChildrenWidth spacing={5} sx={{ flexWrap: 'wrap' }}>
      <SelectField
        options={statusOptions}
        label={t('chess.dialog.status')}
        name={'status'}
        required={!allFieldOptional}
        sx={rowElementSx}
      />
      {isSoldStatus && (
        <StyledDatePicker
          label={t('chess.dialog.actualSaleDate')}
          name="actualSaleDate"
          required={!allFieldOptional}
          sx={rowElementSx}
        />
      )}
      <NumericField
        label={t('chess.dialog.totalArea')}
        name={'totalArea'}
        allowNegative={false}
        onValueChange={(v) => {
          if (isCalculatingRef.current) return;
          isCalculatingRef.current = true;
          const totalArea = v.value;

          if (totalArea && Number(totalArea) > 0) {
            const totalPrice = form.getValues('totalPrice');
            const pricePerMeter = form.getValues('pricePerMeter');
            if (pricePerMeter) {
              const newTotalPrice = calculateTotalPrice(
                totalArea,
                pricePerMeter
              );
              form.setValue('totalPrice', newTotalPrice.toString(), {
                shouldDirty: true,
                shouldValidate: true,
              });
            } else if (totalPrice) {
              const pricePerMeterValue = calculatePricePerMeter(
                totalArea,
                totalPrice
              );

              form.setValue('pricePerMeter', pricePerMeterValue.toString(), {
                shouldDirty: true,
                shouldValidate: true,
              });
            }
          }

          setTimeout(() => {
            isCalculatingRef.current = false;
          }, 30);
        }}
        valueIsNumericString
        maxLength={6}
        showCharacterHintAtRemaining={1}
        required={!allFieldOptional}
        sx={rowElementSx}
      />
      {object?.objectType === OBJECT_TYPE_RESIDENTIAL_COMPLEX && (
        <NumericField
          label={t('chess.dialog.kitchenArea')}
          name={'kitchenArea'}
          valueIsNumericString
          maxLength={5}
          showCharacterHintAtRemaining={1}
          allowNegative={false}
          sx={rowElementSx}
        />
      )}
      <NumericField
        label={t('chess.dialog.pricePerMeter')}
        required={!allFieldOptional}
        name={'pricePerMeter'}
        allowNegative={false}
        decimalScale={0}
        onValueChange={(v) => {
          if (isCalculatingRef.current) return;
          isCalculatingRef.current = true;
          const totalArea = form.getValues('totalArea');
          const pricePerMeter = v.value;

          if (pricePerMeter && totalArea) {
            const totalPrice = calculateTotalPrice(totalArea, pricePerMeter);

            form.setValue('totalPrice', totalPrice.toString(), {
              shouldDirty: true,
              shouldValidate: true,
            });
          }
          setTimeout(() => {
            isCalculatingRef.current = false;
          }, 30);
        }}
        valueIsNumericString
        maxLength={8}
        showCharacterHintAtRemaining={1}
        sx={rowElementSx}
      />
      <NumericField
        label={t('chess.dialog.totalPrice')}
        required={!allFieldOptional}
        name={'totalPrice'}
        allowNegative={false}
        decimalScale={0}
        onValueChange={(v) => {
          if (isCalculatingRef.current) return;
          isCalculatingRef.current = true;
          const totalArea = form.getValues('totalArea');
          const totalPrice = v.value;

          if (totalPrice && totalArea) {
            const pricePerMeter = calculatePricePerMeter(totalArea, totalPrice);

            form.setValue('pricePerMeter', pricePerMeter.toString(), {
              shouldDirty: true,
              shouldValidate: true,
            });
          }
          setTimeout(() => {
            isCalculatingRef.current = false;
          }, 30);
        }}
        valueIsNumericString
        maxLength={11}
        showCharacterHintAtRemaining={1}
        sx={rowElementSx}
      />
      <SelectField
        options={typeOptions}
        label={t('chess.dialog.lotType')}
        name={'lotType'}
        required={!allFieldOptional}
        sx={rowElementSx}
      />
      <SelectField
        options={numberOfRoomsOptions}
        label={t('chess.dialog.numberOfRooms')}
        name={'numberOfRooms'}
        sx={rowElementSx}
      />
      <SelectField
        options={decorationOptions}
        label={t('chess.dialog.decoration')}
        name={'decoration'}
        sx={rowElementSx}
      />
      <SelectField
        options={windowViewOptions}
        label={t('chess.dialog.windowView')}
        name={'windowView'}
        sx={rowElementSx}
      />
      <SelectWithChips
        label={t('chess.dialog.registration')}
        name={'registration'}
        options={registrationOptions}
        required={!allFieldOptional}
        showCount
        sx={{ ...rowElementSx }}
      />
      <SelectWithChips
        options={purchaseTypeOptions}
        label={t('chess.dialog.buyingOption')}
        name={'buyingOptions'}
        showCount
        sx={rowElementSx}
      />
      {buyingOptions?.includes('installments') && (
        <StyledTextField
          label={t('object.form.label.installmentTerms')}
          required={!allFieldOptional}
          name="installmentTerms"
          multiline
          maxLength={200}
          showCharacterHintAtRemaining={30}
          sx={rowElementSx}
        />
      )}
      <SelectField
        label={t('chess.dialog.promotion')}
        name="promotion"
        options={promotionOptions}
        sx={rowElementSx}
      />
      {object?.objectType !== OBJECT_TYPE_RESIDENTIAL_COMPLEX && (
        <NumericField
          label={t('chess.dialog.landArea')}
          name={'landArea'}
          maxLength={8}
          showCharacterHintAtRemaining={1}
          allowNegative={false}
          sx={rowElementSx}
        />
      )}
      <NumericField
        label={t('chess.dialog.numberOfFloors')}
        name={'numberOfFloors'}
        maxLength={2}
        showCharacterHintAtRemaining={1}
        allowNegative={false}
        sx={rowElementSx}
      />
      <SelectField
        label={t('chess.dialog.valueType')}
        name="lotNumberingFormat"
        options={numberingMaskOption}
        sx={rowElementSx}
      />

      <Controller
        name={'lotNumber'}
        control={form.control}
        render={({ field: { ref, ...rest }, fieldState: { error } }) => (
          <MaskedInput
            key={format}
            keepCharPositions
            placeholderChar={' '}
            guide={false}
            mask={(value) => getMask(value, format)}
            {...rest}
            render={(ref, props) => (
              <StyledTextField
                innerRef={ref}
                label={getLabelByValue(numberingMaskPlaceholder, maskType)}
                {...props}
                sx={rowElementSx}
              />
            )}
          />
        )}
      />
    </Row>
  );
};
