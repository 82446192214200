import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Toolbar } from '@mui/material';
import { useAppSelector } from 'shared/hooks/hooks';
import { TextColor } from 'shared/theme/colors';
import { Row } from 'shared/ui/Row';
import { HeaderLogo } from './HeaderLogo';
import { HeaderSearch } from './HeaderSearch';
import { LogoutButton } from './LogoutButton';

export const Header: FC = () => {
  const user = useAppSelector((state) => state.profile.profile);
  const searchText = useAppSelector((state) => state.search.searchText);
  const location = useLocation();
  const [isSearchExpanded, setIsSearchExpanded] = useState<boolean>(false);

  const searchForm = useForm<{
    searchText: string;
  }>({ values: { searchText: searchText } });

  useEffect(() => {
    setIsSearchExpanded(false);
  }, [location]);

  return (
    <Box
      position="static"
      sx={{
        mt: { xs: 6, sm: 11 },
        mb: { xs: 9, sm: 11 },
        borderRadius: 2,
        background: 'rgba(255, 255, 255, 0.50)',
        backdropFilter: 'blur(6px)',
        width: '100%',
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          px: 6,
          height: 64,
        }}
      >
        <Row
          justifyContent={'space-between'}
          alignItems={'center'}
          color={TextColor.Primary}
          width={'100%'}
          equalsChildrenWidth={isSearchExpanded}
        >
          {!isSearchExpanded && <HeaderLogo />}
          <Row spacing={6} sx={{ alignItems: 'center' }} equalsChildrenWidth>
            {user?.isNedvexUserOrAdmin && (
              <FormProvider {...searchForm}>
                <HeaderSearch
                  isSearchExpanded={isSearchExpanded}
                  onToggleSearchExpansion={() =>
                    setIsSearchExpanded(!isSearchExpanded)
                  }
                />
              </FormProvider>
            )}
            {!isSearchExpanded && <LogoutButton />}
          </Row>
        </Row>
      </Toolbar>
    </Box>
  );
};
