export const getBorderForCell = (
  mergedToLeft?: boolean,
  mergedToTop?: boolean,
  mergedToRight?: boolean,
  mergedToBottom?: boolean
) => {
  const boxShadow = [];

  // If not merged to left, add left border
  if (!mergedToLeft) boxShadow.push('-1px 0 0 0 #DBDBDB');

  // If not merged to top, add top border
  if (!mergedToTop) boxShadow.push('0 -1px 0 0 #DBDBDB');

  // If not merged to right, add right border
  if (!mergedToRight) boxShadow.push('1px 0 0 0 #DBDBDB');

  // If not merged to bottom, add bottom border
  if (!mergedToBottom) boxShadow.push('0 1px 0 0 #DBDBDB');
  //
  // // If merged with all neighbors, remove all borders
  // if (mergedToLeft && mergedToTop && mergedToRight && mergedToBottom) boxShadow.length = 0;
  return boxShadow.join(', ');
};
