import { FC, MouseEvent, ReactNode } from 'react';
import { Button } from '@mui/material';
import { ArrowLeft } from './Icons';

interface BackButtonProps {
  children?: ReactNode;
  onBackClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const BackButton: FC<BackButtonProps> = ({
  children,
  onBackClick,
  ...props
}) => {
  return (
    <Button
      onClick={onBackClick}
      variant={'text'}
      startIcon={
        <ArrowLeft
          color="primary"
          fontSize="large"
          sx={{
            width: 20,
            height: 20,
            marginRight: 3,
          }}
        />
      }
      size={'small'}
      sx={{
        height: 20,
        padding: 0,
        ':hover': {
          background: 'none',
        },
        fontSize: { xs: 14, sm: 16 },
      }}
      {...props}
    >
      {children}
    </Button>
  );
};
