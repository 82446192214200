import { FieldErrors, FieldValues } from 'react-hook-form';

export const getFormErrorsArray = (
  errorsInput: FieldErrors<FieldValues>,
  includeFields?: string[]
): string[] => {
  const errorsArray: string[] = [];
  for (const key in errorsInput) {
    const field = errorsInput[key];
    if (!includeFields || includeFields.includes(key)) {
      if (Array.isArray(field)) {
        field.forEach((object) => {
          for (const objectKey in object) {
            const errorMessage = object[objectKey]?.message;
            errorsArray.push(errorMessage);
          }
        });
      } else {
        if (field && 'root' in field && field.root?.message) {
          const errorMessage = field.root.message.toString();
          errorsArray.push(errorMessage);
        }
      }

      const errorMessage = field?.message;
      if (typeof errorMessage === 'string') {
        errorsArray.push(errorMessage);
      }
    }
  }

  return errorsArray;
};
