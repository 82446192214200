import {
  SalesOffice,
  SalesOfficeDto,
  SaveSalesOffice,
  SaveSalesOfficeDto,
} from '../model/types/salesOfficeSchema';

export const mapSalesOfficeFromDto = ({
  id,
  address,
  email,
  phone,
  sales_office_schedules,
  users,
}: SalesOfficeDto): SalesOffice => ({
  id,
  address: address || '',
  phone: phone?.toString() || '',
  email: email || '',
  employees: (users || []).map((user) => ({
    employeeId: user.id,
    firstName: user.first_name || '',
    lastName: user.last_name || '',
    email: user.email || '',
    phone: user.phone || '',
    order: user.order,
    position: user.position || '',
  })),
  schedules: sales_office_schedules
    ? sales_office_schedules.map((schedule) => ({
        workingDays: schedule.schedule,
        workingHours: `${schedule.time_from?.substring(
          0,
          5
        )} - ${schedule.time_to?.substring(0, 5)}`,
      }))
    : [],
});

export const mapDtoFromSalesOffice = ({
  address,
  employees,
  phone,
  email,
  schedules,
}: SaveSalesOffice): SaveSalesOfficeDto => ({
  address,
  email,
  phone: Number(phone),
  users: employees.map((employee, index) => ({
    id: employee.employeeId,
    order: index + 1,
    position: employee.position,
  })),
  sales_office_schedules: schedules
    ? schedules.map((schedule) => ({
        schedule: schedule.workingDays,
        time_from: schedule.workingHours
          ? schedule.workingHours.split(' - ')[0]
          : '',
        time_to: schedule.workingHours
          ? schedule.workingHours.split(' - ')[1]
          : '',
      }))
    : [],
});
