import { $rtkApi } from 'shared/api/rtkApi';
import { ResponseData } from 'shared/types';
import { mapUserFromDto } from '../../lib/mapUser';
import { Credentials, Profile, ProfileDto } from '../types/profileSchema';

export const authApi = $rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<Profile, Credentials>({
      query: (credentials) => ({
        url: '/auth/login',
        method: 'POST',
        body: credentials,
      }),
      transformResponse: (response: ResponseData<ProfileDto>) =>
        mapUserFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
    }),
    profile: builder.query<Profile, void>({
      query: () => ({
        url: '/auth/profile',
        method: 'GET',
      }),
      transformResponse: (response: ResponseData<ProfileDto>) =>
        mapUserFromDto(response.data),
    }),
    logout: builder.mutation<boolean, void>({
      query: () => ({
        url: '/auth/logout',
        method: 'POST',
      }),
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation, useProfileQuery } = authApi;
