import { FC, ReactNode } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { useHoverPopover } from 'shared/hooks/useHoverPopover';
import { TextColor } from 'shared/theme/colors';
import { Col } from '../Col';
import { NoteIcon } from '../Icons';
import { Row } from '../Row';

type AnalyticsBlockCardProps = {
  title: string;
  afterTitleSpacing?: number;
  children?: ReactNode;
  height?: number;
  tooltipText?: ReactNode;
  tooltipWidth?: number;
};

export const AnalyticsBlockCard: FC<AnalyticsBlockCardProps> = ({
  title,
  afterTitleSpacing = 9,
  children,
  height,
  tooltipText,
  tooltipWidth,
}) => {
  const popoverContent = (
    <Typography
      variant="paragraphS"
      color={TextColor.Primary}
      sx={{ m: 6, width: tooltipWidth }}
    >
      {tooltipText}
    </Typography>
  );
  const tooltipIcon = <NoteIcon />;

  const { HoverPopoverComponent: TooltipComponent } = useHoverPopover({
    popoverContent,
    popoverAnchorElement: tooltipIcon,
  });

  return (
    <Card sx={{ height: height }}>
      <CardContent
        sx={{
          '&.MuiCardContent-root': {
            py: 9,
          },
          px: 8,
        }}
      >
        <Col spacing={afterTitleSpacing}>
          <Row spacing={5}>
            <Typography variant="headerXS">{title}</Typography>
            {tooltipText && <TooltipComponent />}
          </Row>
          {children}
        </Col>
      </CardContent>
    </Card>
  );
};
