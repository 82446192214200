import type { SVGProps } from 'react';
import { TextColor } from 'shared/theme/colors';

interface SpinnerProps extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

export function Spinner({
  size = 20,
  color = TextColor.Primary,
  ...props
}: SpinnerProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask id="path-1-inside-1_8242_3068" fill={color}>
        <path d="M10 1.10349C10 0.494051 9.50446 -0.00629848 8.89872 0.0608173C7.31049 0.236791 5.78184 0.791589 4.4443 1.6853C2.79981 2.78412 1.51808 4.3459 0.761204 6.17317C0.00432781 8.00043 -0.193705 10.0111 0.192147 11.9509C0.577999 13.8907 1.53041 15.6725 2.92893 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.2084 14.2182 19.7632 12.6895 19.9392 11.1013C20.0063 10.4955 19.5059 10 18.8965 10C18.2871 10 17.801 10.4965 17.715 11.0998C17.5509 12.2507 17.1307 13.3552 16.4797 14.3296C15.6233 15.6111 14.4062 16.61 12.9823 17.1998C11.5583 17.7896 9.99135 17.944 8.47966 17.6433C6.96796 17.3426 5.57938 16.6004 4.48951 15.5105C3.39964 14.4206 2.65743 13.032 2.35673 11.5203C2.05604 10.0086 2.21036 8.44173 2.8002 7.01774C3.39003 5.59376 4.38888 4.37666 5.67044 3.52035C6.64478 2.86931 7.74927 2.44906 8.90016 2.28499C9.5035 2.19897 10 1.71294 10 1.10349Z" />
      </mask>
      <path
        d="M10 1.10349C10 0.494051 9.50446 -0.00629848 8.89872 0.0608173C7.31049 0.236791 5.78184 0.791589 4.4443 1.6853C2.79981 2.78412 1.51808 4.3459 0.761204 6.17317C0.00432781 8.00043 -0.193705 10.0111 0.192147 11.9509C0.577999 13.8907 1.53041 15.6725 2.92893 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.2084 14.2182 19.7632 12.6895 19.9392 11.1013C20.0063 10.4955 19.5059 10 18.8965 10C18.2871 10 17.801 10.4965 17.715 11.0998C17.5509 12.2507 17.1307 13.3552 16.4797 14.3296C15.6233 15.6111 14.4062 16.61 12.9823 17.1998C11.5583 17.7896 9.99135 17.944 8.47966 17.6433C6.96796 17.3426 5.57938 16.6004 4.48951 15.5105C3.39964 14.4206 2.65743 13.032 2.35673 11.5203C2.05604 10.0086 2.21036 8.44173 2.8002 7.01774C3.39003 5.59376 4.38888 4.37666 5.67044 3.52035C6.64478 2.86931 7.74927 2.44906 8.90016 2.28499C9.5035 2.19897 10 1.71294 10 1.10349Z"
        stroke="url(#paint0_linear_8242_3068)"
        strokeWidth="4"
        mask="url(#path-1-inside-1_8242_3068)"
      >
        <animateTransform
          attributeName="transform"
          dur="0.75s"
          repeatCount="indefinite"
          type="rotate"
          values="0 10 10;360 10 10"
        />
      </path>
      <defs>
        <linearGradient
          id="paint0_linear_8242_3068"
          x1="-4.37114e-07"
          y1="10"
          x2="20"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} />
          <stop offset="1" stopColor={color} stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
