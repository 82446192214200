import { FC } from 'react';
import { AnalyticsPieChart } from 'shared/ui/Analytics/AnalyticsPieChart';
import { LotStatusesStatistics } from '../../../model/types/objectAnalyticsSchema';

enum Color {
  SOLD = '#E1C4D2',
  SALE = '#D1EEF7',
  RESERVE = '#F2EDC2',
  FUND = '#F6D4D8',
}

interface LotStatusesStatisticsChartProps {
  data: LotStatusesStatistics;
}

export const LotStatusesStatisticsChart: FC<
  LotStatusesStatisticsChartProps
> = ({ data }) => {
  const pieData = [
    { name: 'Sold', value: data.applePie.sold, color: Color.SOLD },
    { name: 'Sale', value: data.applePie.sale, color: Color.SALE },
    { name: 'Reserve', value: data.applePie.reserve, color: Color.RESERVE },
    { name: 'Fund', value: data.applePie.fund, color: Color.FUND },
  ];

  const totalLots = pieData.reduce((acc, item) => acc + item.value, 0);

  return (
    <AnalyticsPieChart
      width={165}
      height={165}
      pieData={pieData}
      ringThickness={12}
      offsetX={78}
      offsetY={78}
      centralLabel={totalLots}
    />
  );
};
