import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ObjectCharacteristicsEditForm } from 'entities/object';
import { CardLayout } from 'shared/ui/CardLayout';

type StepThreeProps = {
  onDone: () => void;
  onReturn: () => void;
  isObjectCreating: boolean;
  onStepValidityChange: (isStepValid: boolean) => void;
};

export const StepThree: FC<StepThreeProps> = ({
  onDone,
  onReturn,
  isObjectCreating,
  onStepValidityChange,
}) => {
  const { t } = useTranslation();
  const form = useFormContext();

  useEffect(() => {
    onStepValidityChange(form.formState.isValid);
  }, [form.formState.isValid, onStepValidityChange]);

  return (
    <CardLayout
      header={{
        title: t('object.create.stepThree.title'),
        subtitle: t('object.create.stepThree.form.subtitle'),
      }}
      footer={{
        onOkClick: onDone,
        onCancelClick: onReturn,
        okLabel: t('object.form.label.createObject'),
        cancelLabel: t('ui.common.return'),
        okButtonColor: 'secondary',
        okButtonLoading: isObjectCreating,
      }}
      width={648}
    >
      <ObjectCharacteristicsEditForm />
    </CardLayout>
  );
};
