import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { objectTypeOptions } from 'entities/object';
import { objectApi } from 'entities/object/model/api/ObjectService';
import { getRoute } from 'shared/const';
import { getMainPhotoUrl } from 'shared/helpers/getImageUrl';
import { getLabelByValue } from 'shared/helpers/getLabelByValue';
import { useDebounce } from 'shared/hooks/useDebounce';
import { useScrollPaginator } from 'shared/hooks/useScrollPaginator';
import { Col } from 'shared/ui/Col';
import {
  PreviewCardsGrid,
  PreviewInfoCardProps,
} from 'shared/ui/PreviewCardsGrid';
import { Row } from 'shared/ui/Row';
import { SearchInput } from 'shared/ui/SearchInput';

export const ObjectsTab: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const form = useForm<{
    search: string;
  }>({
    defaultValues: {
      search: '',
    },
  });

  const params = form.watch();
  const debouncedSearchText = useDebounce(params.search);

  const { items, isAllItemsLoaded } = useScrollPaginator(
    objectApi.useGetObjectsQuery,
    { search: debouncedSearchText },
    { headerHeight: 400, previewCardHeight: 110, amountPerRow: 3 }
  );

  const handleObjectClick = useCallback(
    (objectId: number) => () => {
      navigate(getRoute.ObjectAbout(objectId));
    },
    [navigate]
  );

  const formattedDataForPreviewCards: PreviewInfoCardProps[] = useMemo(() => {
    return items.map((object) => ({
      ...object,
      id: object.id,
      logoSrc: object.mainPhotoHash
        ? getMainPhotoUrl(object.mainPhotoHash)
        : '',
      title: object.name,
      text: object.address,
      subtitle: t(getLabelByValue(objectTypeOptions, object.objectType)),
      onLinkClick: handleObjectClick(object.id),
    }));
  }, [handleObjectClick, items, t]);

  return (
    <Col spacing={8}>
      <Row
        sx={{
          justifyContent: 'end',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
        }}
      >
        <FormProvider {...form}>
          <SearchInput
            name="search"
            searchPlaceholder={t('ui.searchInput.byName')}
            sx={{ width: { xs: '100%', sm: 280 } }}
          />
        </FormProvider>
      </Row>
      <PreviewCardsGrid
        data={formattedDataForPreviewCards}
        isShowSkeleton={!isAllItemsLoaded}
      />
    </Col>
  );
};
