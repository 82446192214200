import { RouteObject } from 'react-router-dom';
import { Login } from 'features/auth/ui/Login';
import { RestorePassword } from 'features/auth/ui/RestorePassword';
import { RestorePasswordDone } from 'features/auth/ui/RestorePasswordDone';
import { AgencyDetailsPage } from 'pages/AgencyDetailsPage';
import { AuthPage } from 'pages/AuthPage';
import { CreateAgencyPage } from 'pages/CreateAgencyPage';
import { CreateDeveloperPage } from 'pages/CreateDeveloperPage';
import { CreateObjectPage } from 'pages/CreateObjectPage';
import { CreateUserPage } from 'pages/CreateUserPage';
import { DeveloperDetailsPage } from 'pages/DeveloperDetailsPage';
import { EditDeveloperPage } from 'pages/EditDeveloperPage';
import { EditObjectPage } from 'pages/EditObjectPage';
import { FirstAuthPage } from 'pages/FirstAuthPage';
import { MainPage } from 'pages/MainPage';
import { NotFoundPage } from 'pages/NotFoundPage';
import { ObjectDetailsPage } from 'pages/ObjectDetailsPage';
import { PageLayout } from 'pages/PageLayout';
import { PlanPage } from 'pages/PlanPage';
import { UserDetailsPage } from 'pages/UserDetailsPage';
import { getRoute } from 'shared/const';
import { PlanMatrix, PlanSchema } from 'widgets/Plan';
import { RequireAuth } from '../ui/RequireAuth';
import { agencySubRoutes } from './agencyPageSubRoutes';
import {
  developerSubRoutes,
  editDeveloperSubRoutes,
} from './developerPageSubRoutes';
import { mainPageSubRoutes } from './mainPageSubRoutes';
import {
  editObjectTabsRoutes,
  objectDetailsTabsRoutes,
} from './objectPageSubRoutes';
import { userSubRoutes } from './userPageSubRoutes';

export const routes: RouteObject[] = [
  {
    element: <RequireAuth />,
    children: [
      {
        element: <PageLayout />,
        children: [
          {
            path: '*',
            element: <NotFoundPage />,
          },
          {
            path: getRoute.Main(),
            element: <MainPage />,
            children: mainPageSubRoutes,
          },
          {
            path: getRoute.DeveloperFirstAuth(),
            element: <FirstAuthPage />,
          },
          {
            path: getRoute.CreateObject(':developerId'),
            element: <CreateObjectPage />,
          },
          {
            path: getRoute.CreateDeveloper(),
            element: <CreateDeveloperPage />,
          },
          {
            path: getRoute.EditDeveloper(':developerId'),
            element: <EditDeveloperPage />,
            children: editDeveloperSubRoutes,
          },
          {
            path: getRoute.EditObject(':objectId'),
            element: <EditObjectPage />,
            children: editObjectTabsRoutes,
          },
          {
            path: getRoute.Object(':objectId'),
            element: <ObjectDetailsPage />,
            children: objectDetailsTabsRoutes,
          },
          {
            path: getRoute.Developer(':developerId'),
            element: <DeveloperDetailsPage />,
            children: developerSubRoutes,
          },
          {
            path: getRoute.Agency(':agencyId'),
            element: <AgencyDetailsPage />,
            children: agencySubRoutes,
          },
          {
            path: getRoute.CreateAgency(),
            element: <CreateAgencyPage />,
          },
          {
            path: getRoute.User(':userId'),
            element: <UserDetailsPage />,
            children: userSubRoutes,
          },
          {
            path: getRoute.CreateUser(),
            element: <CreateUserPage />,
          },
          {
            path: getRoute.LotsPlanSchema(
              ':objectId',
              ':buildingId',
              ':entranceId',
              ':planId'
            ),
            element: <PlanPage />,
            children: [
              {
                index: true,
                element: <PlanSchema />,
              },
              {
                path: getRoute.LotsPlanMatrix(
                  ':objectId',
                  ':buildingId',
                  ':entranceId',
                  ':planId',
                  ':polygonId'
                ),
                element: <PlanMatrix />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: getRoute.AuthLogin(),
    element: <AuthPage />,
    children: [
      {
        index: true,
        element: <Login />,
      },
      {
        path: getRoute.AuthRestorePassword(),
        element: <RestorePassword />,
      },
      {
        path: getRoute.AuthRestorePasswordDone(),
        element: <RestorePasswordDone />,
      },
    ],
  },
];
