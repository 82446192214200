import PopupState, { bindPopover, bindHover } from 'material-ui-popup-state';
import { PopupState as PopupStateProps } from 'material-ui-popup-state/hooks';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { Box } from '@mui/material';

type UsePopoverProps = {
  popoverContent: React.ReactNode;
  popoverAnchorElement: React.ReactNode;
  hidePopover?: boolean;
};

export const useHoverPopover = ({
  popoverContent,
  popoverAnchorElement,
  hidePopover = false,
}: UsePopoverProps) => {
  const HoverPopoverComponent = () => (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState: PopupStateProps) => (
        <div>
          <Box {...bindHover(popupState)}>{popoverAnchorElement}</Box>

          {!hidePopover && (
            <HoverPopover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {popoverContent}
            </HoverPopover>
          )}
        </div>
      )}
    </PopupState>
  );

  return {
    HoverPopoverComponent,
  };
};
