import { FC } from 'react';
import { Skeleton, SkeletonProps, SxProps } from '@mui/material';
import { Row } from '../Row';

interface RectSkeletonProps extends SkeletonProps {
  width?: number;
  maxWidth?: number;
  height?: number;
  spacing?: number;
  count?: number;
  sx?: SxProps;
}

export const RectSkeleton: FC<RectSkeletonProps> = ({
  width,
  maxWidth,
  height = 120,
  spacing = 5,
  count = 1,
  sx,
}) => {
  return (
    <Row spacing={spacing} equalsChildrenWidth>
      {Array.from({ length: count }).map((_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          width={width}
          height={height}
          sx={{ maxWidth: maxWidth, borderRadius: '8px', ...sx }}
        />
      ))}
    </Row>
  );
};
