import {
  PhotosPositions,
  PhotosPositionsDto,
} from '../model/types/albumSchema';

export const mapDtoFromPhotosPositions = ({
  photosIds,
}: PhotosPositions): PhotosPositionsDto => ({
  photos_ids: photosIds,
});
