import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { useGetContractorsQuery } from 'entities/contractor';
import { useDebounce } from 'shared/hooks/useDebounce';
import { BorderColor } from 'shared/theme/colors';
import { SelectOption } from 'shared/types';
import { SelectWithSearch } from 'shared/ui/SelectWithSearch';
import { CreateContractorCard } from './CreateContractorCard';

interface SelectContractorProps {
  name: string;
  options: SelectOption[];
}

export const SelectContractor: FC<SelectContractorProps> = ({
  name,
  options,
}) => {
  const { t } = useTranslation();
  const [creatingContractor, setIsCreatingContractor] =
    useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');

  const debouncedSearchText = useDebounce(searchText);

  const { data: searchData } = useGetContractorsQuery(
    {
      limit: 7,
      offset: 0,
      search: debouncedSearchText,
    },
    { skip: debouncedSearchText.length > 0 && debouncedSearchText.length < 3 }
  );

  const handleContractorCreated = () => {
    setIsCreatingContractor(false);
  };

  const handleContractorCreatingCancel = () => {
    setIsCreatingContractor(false);
  };

  if (creatingContractor) {
    return (
      <CreateContractorCard
        onSaved={handleContractorCreated}
        onCancel={handleContractorCreatingCancel}
      />
    );
  }

  const getOptions = () => {
    const foundOptions =
      searchData?.items.map((item) => ({
        value: item.id,
        label: item.contractorName,
      })) || [];
    return [...foundOptions, ...options];
  };

  return (
    <SelectWithSearch
      label={t('contractor.title')}
      name={name}
      searchPlaceholder={t('contractor.form.label.name')}
      options={getOptions()}
      onSearchChange={setSearchText}
      menuFooter={
        <Box
          sx={{ borderTop: 1, borderColor: BorderColor.Gray, p: 5 }}
          onMouseDown={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              setIsCreatingContractor(true);
            }}
          >
            {t('contractor.card.addNew')}
          </Button>
        </Box>
      }
    />
  );
};
