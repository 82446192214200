import { FC, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { hexToRGBA } from 'shared/helpers/hexToRGBA';
import { AccentColor } from 'shared/theme/colors';
import { ClipIcon, ClockFullIcon } from './Icons';

export interface SmallChipProps {
  variant?: 'done' | 'process' | 'temporary' | 'attention' | 'negative';
  color?: string;
  icon?: 'clock' | 'clip' | 'none';
  onClick?: () => void;
  label: string;
  textColor?: string;
  bgColor?: string;
}

const variantStyles = {
  done: {
    color: AccentColor.Positive,
    backgroundColor: hexToRGBA(AccentColor.Positive, 0.15),
  },
  process: {
    color: AccentColor.Process,
    backgroundColor: hexToRGBA(AccentColor.Process, 0.15),
  },
  temporary: {
    color: AccentColor.Temporary,
    backgroundColor: hexToRGBA(AccentColor.Temporary, 0.15),
  },
  attention: {
    color: AccentColor.Attention,
    backgroundColor: hexToRGBA(AccentColor.Attention, 0.15),
  },
  negative: {
    color: AccentColor.Negative,
    backgroundColor: hexToRGBA(AccentColor.Negative, 0.15),
  },
};

export const SmallChip: FC<SmallChipProps> = ({
  variant,
  color,
  textColor,
  bgColor,
  label,
  onClick,
  icon = 'none',
}) => {
  const renderIcon = () => {
    if (icon === 'clock') {
      return <ClockFullIcon style={{ width: 15, height: 15 }} />;
    } else if (icon === 'clip') {
      return (
        <ClipIcon
          style={{
            width: 15,
            height: 15,
            color: textColor ? textColor : AccentColor.Process,
          }}
        />
      );
    } else {
      return null;
    }
  };

  const colorStyles = useMemo(() => {
    if (color) {
      return {
        color: color,
        backgroundColor: hexToRGBA(color, 0.15),
      };
    }
    if (variant) {
      return {
        color: variantStyles[variant].color,
        backgroundColor: variantStyles[variant].backgroundColor,
      };
    }
    return {
      color: 'black',
      backgroundColor: 'white',
    };
  }, [color, variant]);

  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        padding: '4px 8px',
        borderRadius: '8px',
        gap: '8px',
        backgroundColor: bgColor ? bgColor : colorStyles.backgroundColor,
        cursor: onClick ? 'pointer' : 'default',
      }}
      onClick={onClick}
    >
      {icon !== 'none' && renderIcon()}
      <Typography
        sx={{
          fontSize: 12,
          fontWeight: 500,
          color: textColor ? textColor : colorStyles.color,
          height: '15px',
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};
