import { $rtkApi, ApiTag } from 'shared/api/rtkApi';
import { ListData, ResponseData } from 'shared/types';
import {
  mapDtoFromDeveloperUser,
  mapDeveloperUserFromDto,
} from '../../lib/mapDeveloperUser';
import {
  DeveloperUser,
  DeveloperUserDto,
  NewDeveloperUser,
} from '../types/developerUserSchema';

export const developerUserApi = $rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    createDeveloperUser: builder.mutation<DeveloperUser, NewDeveloperUser>({
      query: (user) => ({
        url: '/users',
        method: 'POST',
        body: mapDtoFromDeveloperUser(user),
      }),
      transformResponse: (response: ResponseData<DeveloperUserDto>) =>
        mapDeveloperUserFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, user) => [
        { type: ApiTag.agencyUser, id: ApiTag.agencyUser },
      ],
    }),
    updateDeveloperUser: builder.mutation<DeveloperUser, DeveloperUser>({
      query: (user) => ({
        url: `/users/${user.id}`,
        method: 'PUT',
        body: mapDtoFromDeveloperUser(user),
      }),
      transformResponse: (response: ResponseData<DeveloperUserDto>) =>
        mapDeveloperUserFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, user) => [
        { type: ApiTag.agencyUser, id: user.id },
        { type: ApiTag.agencyUser, id: ApiTag.agencyUser },
      ],
    }),
    getDeveloperUsers: builder.query<
      ListData<DeveloperUser>,
      {
        search: string;
        limit: number;
        offset: number;
        developer_id?: number;
      }
    >({
      query: (params) => ({
        url: `/users`,
        method: 'GET',
        params,
      }),
      providesTags: (result, error, page) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: ApiTag.agencyUser,
                id,
              })),
              { type: ApiTag.agencyUser, id: ApiTag.agencyUser },
            ]
          : [{ type: ApiTag.agencyUser, id: ApiTag.agencyUser }],
      transformResponse: (
        response: ResponseData<ListData<DeveloperUserDto>>
      ) => ({
        total: response.data.total,
        items: response.data.items.map((data) => mapDeveloperUserFromDto(data)),
      }),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
    }),
    deleteDeveloperUser: builder.mutation<void, DeveloperUser>({
      query: (user) => ({
        url: `/users/${user.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, user) => [
        { type: ApiTag.developerUser, id: user.id },
        { type: ApiTag.agencyUser, id: ApiTag.agencyUser },
      ],
    }),
  }),
});

export const {
  useCreateDeveloperUserMutation,
  useUpdateDeveloperUserMutation,
  useGetDeveloperUsersQuery,
  useDeleteDeveloperUserMutation,
} = developerUserApi;
