import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { checkIsOptionExists } from 'shared/helpers/checkIsOptionExists';
import { Col } from 'shared/ui/Col';
import { ExpandButton } from 'shared/ui/ExpandButton';
import { Row } from 'shared/ui/Row';
import { TextViewField } from 'shared/ui/TextViewField';
import { agencyTariffOptions } from '../model/consts';
import { Agency } from '../model/types/agencySchema';

interface AgencyInfoFieldsProps {
  onToggleAdditionalData: (isShowAdditionalData: boolean) => void;
  isShowAdditionalData: boolean;
}

export const AgencyInfoFields: FC<AgencyInfoFieldsProps> = ({
  onToggleAdditionalData,
  isShowAdditionalData,
}) => {
  const { t } = useTranslation();
  const form = useFormContext<Agency>();
  const {
    facebook,
    instagram,
    ok,
    vk,
    youtube,
    inn,
    kpp,
    website,
    description,
    officeName,
    address,
    phone,
    mail,
    tariff,
  } = form.getValues();
  const isAdditionalData = Boolean(
    facebook || instagram || ok || vk || youtube
  );

  return (
    <Col spacing={8}>
      <Row
        equalsChildrenWidth
        sx={{
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: 8, sm: 5 },
        }}
      >
        <TextViewField
          variant="filled"
          name="name"
          title={t('agency.form.label.agencyName')}
        />
        {website && (
          <TextViewField
            variant="filled"
            name="website"
            title={t('ui.common.site')}
          />
        )}
      </Row>
      {(inn || kpp) && (
        <Row
          equalsChildrenWidth
          sx={{
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: 8, sm: 5 },
          }}
        >
          {inn && (
            <TextViewField
              variant="filled"
              title={t('ui.common.INN')}
              name="inn"
            />
          )}
          {kpp && (
            <TextViewField
              variant="filled"
              title={t('ui.common.KPP')}
              name="kpp"
            />
          )}
        </Row>
      )}
      {description && (
        <TextViewField
          variant="filled"
          title={t('agency.form.label.agencyDescription')}
          name="description"
        />
      )}
      {(officeName || address) && (
        <Row
          equalsChildrenWidth
          sx={{
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: 8, sm: 5 },
          }}
        >
          {officeName && (
            <TextViewField
              variant="filled"
              title={t('agency.form.label.officeName')}
              name="officeName"
            />
          )}
          {address && (
            <TextViewField
              variant="filled"
              title={t('agency.form.label.officeAddress')}
              name="address"
            />
          )}
        </Row>
      )}
      {(phone || mail) && (
        <Row
          equalsChildrenWidth
          sx={{
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: 8, sm: 5 },
          }}
        >
          {phone && (
            <TextViewField
              variant="filled"
              title={t('agency.form.label.officePhone')}
              name="phone"
              isPhone
            />
          )}

          {address && (
            <TextViewField
              variant="filled"
              title={t('agency.form.label.officeEmail')}
              name="mail"
            />
          )}
        </Row>
      )}
      {tariff && checkIsOptionExists(tariff, agencyTariffOptions) && (
        <TextViewField
          variant="filled"
          title={t('agency.form.label.currentTariff')}
          name="tariff"
          labelByOptions={agencyTariffOptions}
        />
      )}
      {isAdditionalData && (
        <Col spacing={8}>
          <ExpandButton
            expanded={isShowAdditionalData}
            onClick={() => onToggleAdditionalData(!isShowAdditionalData)}
          >
            {t('user.form.label.additionalData')}
          </ExpandButton>
          {isShowAdditionalData && (
            <Col spacing={8}>
              {(vk || ok) && (
                <Row
                  equalsChildrenWidth
                  sx={{
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: { xs: 8, sm: 5 },
                  }}
                >
                  {vk && (
                    <TextViewField
                      variant="filled"
                      title={t('ui.common.social.vk')}
                      name="vk"
                    />
                  )}
                  {ok && (
                    <TextViewField
                      variant="filled"
                      title={t('ui.common.social.ok')}
                      name="ok"
                    />
                  )}
                </Row>
              )}
              {(facebook || instagram) && (
                <Row
                  equalsChildrenWidth
                  sx={{
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: { xs: 8, sm: 5 },
                  }}
                >
                  {facebook && (
                    <TextViewField
                      variant="filled"
                      title={t('ui.common.social.facebook')}
                      name="facebook"
                    />
                  )}
                  {instagram && (
                    <TextViewField
                      variant="filled"
                      title={t('ui.common.social.instagram')}
                      name="instagram"
                    />
                  )}
                </Row>
              )}
              {youtube && (
                <TextViewField
                  variant="filled"
                  title={t('ui.common.social.youtube')}
                  name="youtube"
                  sx={{ width: '100%' }}
                />
              )}
            </Col>
          )}
        </Col>
      )}
      {/* {tariffId && (
        <SelectChipField
          name="tariffId"
          chipOptions={mockChipOptions}
          label={t('user.form.label.subscriptionDays')}
          readonly
        />
      )} */}
    </Col>
  );
};
