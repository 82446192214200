import { PaletteOptions } from '@mui/material';
import { BgColor, PaletteColor } from './colors';

export const palette: PaletteOptions = {
  primary: { main: PaletteColor.Primary },
  secondary: { main: PaletteColor.Secondary },
  warning: { main: PaletteColor.Warning },
  error: { main: PaletteColor.Error },
  success: { main: PaletteColor.Success },

  background: { default: BgColor.Gray },
};
