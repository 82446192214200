// eslint-disable-next-line boundaries/element-types
import { buildingApi } from 'entities/building/model/api/BuildingService';
import { $rtkApi, ApiTag } from 'shared/api/rtkApi';
import { ResponseData } from 'shared/types';
import { mapDtoFromEntrance, mapEntranceFromDto } from '../../lib/mapEntrance';
import { Entrance, EntranceDto } from '../types/entranceSchema';

export const entranceApi = $rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    createEntrance: builder.mutation<
      Entrance,
      { entrance: Entrance; objectId: number }
    >({
      query: ({ entrance }) => ({
        url: '/sections',
        method: 'POST',
        body: mapDtoFromEntrance(entrance),
      }),
      transformResponse: (response: ResponseData<EntranceDto>) =>
        mapEntranceFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, { entrance, objectId }) => [
        { type: ApiTag.building, id: entrance.buildingId },
        { type: ApiTag.building, id: ApiTag.building },
        { type: ApiTag.objectPublicationErrors, id: objectId },
      ],
    }),
    updateEntrance: builder.mutation<Entrance, Entrance>({
      query: (entrance) => ({
        url: `/sections/${entrance.id}`,
        method: 'PUT',
        body: mapDtoFromEntrance(entrance),
      }),
      async onQueryStarted(
        { buildingId, ...patch },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          buildingApi.util.updateQueryData(
            'getBuilding',
            buildingId.toString(),
            (draft) => {
              if (draft.entrances) {
                const entranceIndex = draft.entrances.findIndex(
                  ({ id }) => id === patch.id
                );
                if (entranceIndex > -1) {
                  Object.assign(draft.entrances[entranceIndex], patch);
                }
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      transformResponse: (response: ResponseData<EntranceDto>) =>
        mapEntranceFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, entrance) => [
        { type: ApiTag.building, id: entrance.buildingId },
      ],
    }),
    deleteEntrance: builder.mutation<
      void,
      { entrance: Entrance; objectId: number }
    >({
      query: ({ entrance }) => ({
        url: `/sections/${entrance.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { entrance, objectId }) => [
        { type: ApiTag.building, id: entrance.buildingId },
        { type: ApiTag.building, id: ApiTag.building },
        { type: ApiTag.objectPublicationErrors, id: objectId },
      ],
    }),
    duplicateEntrance: builder.mutation<void, Entrance>({
      query: (entrance) => ({
        url: `/sections/duplicate/${entrance.id}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, entrance) => [
        { type: ApiTag.building, id: entrance.buildingId },
        { type: ApiTag.building, id: ApiTag.building },
      ],
    }),
  }),
});

export const {
  useCreateEntranceMutation,
  useUpdateEntranceMutation,
  useDeleteEntranceMutation,
  useDuplicateEntranceMutation,
} = entranceApi;
