import { type FC, useCallback, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IconButton, InputAdornment, Typography } from '@mui/material';
import {
  Credentials,
  credentialsZod,
  useLoginMutation,
} from 'entities/profile';
import { PaletteColor } from 'shared/theme/colors';
import { CardLayout } from 'shared/ui/CardLayout';
import { Col } from 'shared/ui/Col';
import { ShowPasswordIcon } from 'shared/ui/Icons';
import { StyledTextField } from 'shared/ui/StyledTextField';

export const Login: FC = () => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [login, { isLoading }] = useLoginMutation();

  const form = useForm<Credentials>({
    resolver: zodResolver(credentialsZod),
    mode: 'onSubmit',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogInClick = useCallback(
    async (data: Credentials) => {
      if (data) {
        try {
          await login(data).unwrap();
        } catch (e) {
          console.error(e);
          if (e === 'USER_NOT_FOUND' || e === 'USER_HAS_NO_PERMISSION') {
            form.setError('root', {
              message: t('auth.loginForm.errorNoPermission'),
            });
          } else {
            form.setError('root', {
              message: t('auth.loginForm.errorAuth'),
            });
          }
        }
      }
    },
    [form, login, t]
  );

  return (
    <CardLayout
      maxWidth={368}
      footer={{
        onOkClick: form.handleSubmit(handleLogInClick),
        okLabel: t('auth.loginForm.buttonLogin'),
        okButtonColor: 'secondary',
        okButtonLoading: isLoading,
      }}
    >
      <Col spacing={5}>
        <Typography variant={'headerM'}>{t('auth.loginForm.title')}</Typography>
        <Typography variant={'paragraphM'}>
          {t('auth.loginForm.subtitle')}
        </Typography>
      </Col>
      <FormProvider {...form}>
        <Col spacing={5} sx={{ width: 320 }}>
          <StyledTextField
            label={t('ui.common.email')}
            type="email"
            name="email"
          />
          <StyledTextField
            label={t('ui.common.password')}
            name="password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                    size="small"
                  >
                    <ShowPasswordIcon fontSize={'small'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* <ButtonLink onClick={() => navigate(getRoute.AuthRestorePassword())}>
            Забыл пароль
          </ButtonLink> */}
          <Typography variant={'paragraphS'} color={PaletteColor.Error}>
            {form.formState.errors.root?.message}
          </Typography>
          {/* <LanguageSwitcher /> */}
        </Col>
      </FormProvider>
    </CardLayout>
  );
};
