import { FC } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { downloadFile } from 'shared/helpers/downloadFile';
import { getFileUrl } from 'shared/helpers/getFileUrl';
import { TextColor } from 'shared/theme/colors';
import { DocumentIcon } from './Icons';
import { Row } from './Row';

type DocumentViewFieldProp = {
  description?: string;
  hash: string;
};

export const DocumentViewField: FC<DocumentViewFieldProp> = ({
  description,
  hash,
}) => {
  return (
    <Card>
      <CardContent
        sx={{
          padding: 6,
          ':last-child': {
            paddingBottom: 6,
          },
        }}
      >
        <Row spacing={5} flexWrap={'nowrap'} flexGrow={1}>
          <DocumentIcon
            onClick={() => downloadFile(getFileUrl(hash), description || hash)}
            style={{
              cursor: 'pointer',
              width: '60px',
              height: '60px',
              marginBottom: '-4px',
              color: TextColor.Secondary,
            }}
          />
          <Typography variant="paragraphS" color={TextColor.Tertiary}>
            {description}
          </Typography>
        </Row>
      </CardContent>
    </Card>
  );
};
