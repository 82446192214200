// eslint-disable-next-line boundaries/element-types
import { Polygon } from 'entities/plan';
import { FlexGridBox } from './FlexGridBox';
import { GridHeader } from './GridHeader';
import { RowsGenerator } from './RowsGenerator';
import { GridLot } from './types';

//TODO: фикс импорта полигонов,
// вместо Polygon использовать другой тип, тут нам нужна только инфа,
// какие id лотов в каком полигоне находятся например polygons: [[1,23], [], [3,45]]
type LotsGridProps<T> = {
  lots: T[];
  floors: T[];
  risers: T[];
  filteredLotsIds?: number[];
  polygons?: Polygon[];
  onAddRiser?: (riser: number) => void;
  onAddFloor?: (floor: number) => void;
  selectedLots: number[];
  selectedFloors: number[];
  selectedRisers: number[];
  onRiserCellClick: (riser: number) => void;
  onRiserRename?: (riser: number, name: string) => void;
  onFloorRename?: (floor: number, name: string) => void;
  onLotClick: (lotId: number) => () => void;
  onFloorCellClick: (floor: number) => () => void;
  editBarHeight?: number;
  isMdDown: boolean;
  editBarAndChessSpacingSum: number;
};

export const LotsGrid = <T extends GridLot>({
  lots,
  floors,
  risers,
  polygons,
  filteredLotsIds,
  onAddRiser,
  onAddFloor,
  selectedLots,
  selectedFloors,
  selectedRisers,
  onRiserCellClick,
  onRiserRename,
  onFloorRename,
  onFloorCellClick,
  onLotClick,
  editBarHeight,
  isMdDown,
  editBarAndChessSpacingSum,
}: LotsGridProps<T>) => {
  return (
    <FlexGridBox
      rowsCount={floors.length}
      risers={risers}
      editBarHeight={editBarHeight}
      isMdDown={isMdDown}
      editBarAndChessSpacingSum={editBarAndChessSpacingSum}
    >
      <GridHeader
        selectedRisers={selectedRisers}
        risers={risers}
        lots={lots}
        onHeaderCellClick={onRiserCellClick}
        onHeaderCellRename={onRiserRename}
        onAddRiser={onAddRiser}
      />
      <RowsGenerator
        filteredLotsIds={filteredLotsIds}
        lots={lots}
        floors={floors}
        polygons={polygons}
        selectedFloors={selectedFloors}
        selectedLots={selectedLots}
        handleCellClick={onLotClick}
        handleRowCellClick={onFloorCellClick}
        onRowRename={onFloorRename}
        onAddFloor={onAddFloor}
        risersCount={risers.length}
      />
    </FlexGridBox>
  );
};
