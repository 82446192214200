import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getRoute } from 'shared/const';
import { CardLayout } from 'shared/ui/CardLayout';
import { Row } from 'shared/ui/Row';
import { SmallSquareButton } from 'shared/ui/SmallSquareButton';
import { TextViewField } from 'shared/ui/TextViewField';
import { Developer } from '../../../model/types/developerSchema';

type MainDataCardProps = {
  developer: Developer;
  onEditClick?: () => void;
};

export const MainDataCard: FC<MainDataCardProps> = ({ developer }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <CardLayout
      variant="secondary"
      paddingY={32}
      header={{ title: t('developer.edit.tabLabel.mainData') }}
    >
      <Row equalsChildrenWidth>
        <TextViewField
          title={t('developer.form.label.name')}
          text={developer.name}
        />
        {developer.inn && (
          <TextViewField
            title={t('developer.form.label.inn')}
            text={developer.inn}
          />
        )}
      </Row>
      {developer.description && (
        <TextViewField
          title={t('ui.common.description')}
          text={developer.description}
        />
      )}
      {developer.address && (
        <TextViewField
          title={t('ui.common.address')}
          text={developer.address}
        />
      )}
      <SmallSquareButton
        icon="edit"
        onClick={() => navigate(getRoute.EditDeveloper(developer.id))}
      />
    </CardLayout>
  );
};
