import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getRoute } from 'shared/const';
import {
  Cities,
  extractMicrodistrictsByCityName,
} from 'shared/helpers/getCityMicrodistrictOptions';
import { getLabelByValue } from 'shared/helpers/getLabelByValue';
import { SelectOption } from 'shared/types';
import { CardLayout } from 'shared/ui/CardLayout';
import { Row } from 'shared/ui/Row';
import { SmallSquareButton } from 'shared/ui/SmallSquareButton';
import { TextViewField } from 'shared/ui/TextViewField';
import { useGetCitiesQuery } from '../../../model/api/CitiesService';
import { DeveloperObject } from '../../../model/types/objectSchema';
import { SalesOfficeCard } from './SalesOfficeCard';

type LocationCardProps = {
  object: DeveloperObject;
};

export const LocationCard: FC<LocationCardProps> = ({ object }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const citiesDataResult = useGetCitiesQuery();
  const citiesData = citiesDataResult.data;
  let sochiMicrodistrictOptions: SelectOption[] = [];

  if (citiesData) {
    sochiMicrodistrictOptions = extractMicrodistrictsByCityName(
      Cities.Sochi,
      citiesData
    );
  }

  const microdistrictLabel =
    sochiMicrodistrictOptions.length > 0
      ? getLabelByValue(sochiMicrodistrictOptions, object.microdistrict)
      : null;

  return (
    <CardLayout
      maxWidth={344}
      header={{ title: t('object.create.stepTwo.title') }}
    >
      {object.address && (
        <TextViewField title={t('object.card.address')} text={object.address} />
      )}
      {object.microdistrict && microdistrictLabel && (
        <TextViewField
          title={t('object.form.label.microdistrict')}
          text={microdistrictLabel}
        />
      )}
      {object.seaDistance && (
        <Row spacing={8}>
          <TextViewField
            title={t('object.card.toSea')}
            text={object.seaDistance}
          />
        </Row>
      )}
      {object.salesOffices?.length > 0 &&
        object.salesOffices.map((salesOffice) => (
          <SalesOfficeCard key={salesOffice.id} salesOffice={salesOffice} />
        ))}
      <SmallSquareButton
        icon="edit"
        onClick={() => navigate(getRoute.EditObjectDetailsLocation(object.id!))}
      />
    </CardLayout>
  );
};
