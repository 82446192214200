import { FC } from 'react';
import { Button, styled } from '@mui/material';

const FadeInButton = styled(Button)(() => ({
  animationName: 'fadeIn',
  animationDuration: '0.25s',
  animationTimingFunction: 'ease-out',
  animationFillMode: 'forwards',

  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
}));

export const PlusButton: FC<{
  left?: number;
  top?: number;
  disabled?: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}> = ({ left = 0, top = 0, disabled, onClick, onMouseEnter, onMouseLeave }) => {
  return (
    <FadeInButton
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={disabled}
      sx={{
        position: 'absolute',
        width: 36,
        minWidth: 36,
        height: 36,
        padding: 0,
        top: top,
        left: left,
        zIndex: 3,
      }}
    >
      +
    </FadeInButton>
  );
};
