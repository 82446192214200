import {
  BuildingsPositions,
  BuildingsPositionsDto,
} from '../model/types/buildingSchema';

export const mapDtoFromBuildingsPositions = ({
  buildingsIds,
}: BuildingsPositions): BuildingsPositionsDto => ({
  pavilion_ids: buildingsIds,
});
