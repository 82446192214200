import { z } from 'zod';
// eslint-disable-next-line boundaries/element-types
import {
  SalesOffice,
  SaveSalesOfficeDto,
  SalesOfficeDto,
  salesOfficeZod,
} from 'entities/salesOffice';

type NumBool = 1 | 0;

export type SaveObjectContractorsDto = {
  contractors: number[];
};

export type ObjectContractorsDto = {
  contractors: {
    id: number;
    name: string;
    inn: string | null;
  }[];
};

type HouseTypeDto = {
  building_type: {
    id: number;
    name: string;
  } | null;
};

type SaveHouseTypeDto = { building_type_id: number | null };

type HouseCommunicationsDto = {
  housing_gas: {
    id: number;
    name: string;
  };
  housing_sewage: {
    id: number;
    name: string;
  };
  housing_heating: {
    id: number;
    name: string;
  };
  housing_water_supply: {
    id: number;
    name: string;
  };
  housing_electricity: {
    id: number;
    name: string;
  };
};

type SaveHouseCommunicationsDto = {
  housing_heating_id: number | null;
  housing_water_supply_id: number | null;
  housing_electricity_id: number | null;
  housing_sewage_id: number | null;
  housing_gas_id: number | null;
};

type BaseObjectDto = {
  zhk_name: string;
  published: number;
  latitude: number | null;
  longitude: number | null;
  status: string;
  region_id: number | null;
  developer_id: number | null;
  checkout: string;
  house_territory: string;
  house_parking: boolean;
  parking_underground: boolean;
  parking_multilevel: boolean;
  landscape_decore: NumBool;
  children_area: boolean;
  sport_area: boolean;
  spa: boolean;
  fitness: boolean;
  new: number | null;
  pool: boolean;
  kindergarten: boolean;
  surcharge_gas: boolean;
  children_garden: boolean;
  distance_sea: number | null;
  service_cost: number | null;
  description: string;
  address: string;
  class_id: string;
  gas_surcharge_price: number | null;
  building_material_id: number | null;
  concierge: boolean;
  elevator: boolean;
  tennis_court: boolean;
  recreation_zone: boolean;
  fountain: boolean;
  garden: boolean;
  helicopter_landing_area: boolean;
  commercial_property: boolean;
  cctv_surveillance: boolean;
  parking_type_roofed: boolean;
  official_site: string;
  commission: string;
  ceiling_height: number | null;
  surcharge_docs: boolean;
  docs_surcharge_price: number | null;
  surcharge_trash: boolean;
  surcharge_communications: boolean;
  communications_surcharge_price: number | null;
  surcharge_balcony: boolean;
  commission_note: string;
  type_id: number | null;
  house_road_id: number | null;
  sauna: NumBool;
  restaurant: NumBool;
  billiards: NumBool;
  kpp: NumBool;
  nedvex?: number | null;
  is_children_room: NumBool;
  is_conference_hall: NumBool;
  is_wellness_building: NumBool;
  is_beach: NumBool;
  is_walking_area: NumBool;
  is_garage: boolean | null;
  is_landscape_park: boolean | null;
  is_acs_system: boolean | null;
  is_management_company: boolean | null;
  is_operated_roof: NumBool;
  main_photo_hash: string | null;
};

export type ObjectDto = BaseObjectDto &
  ObjectContractorsDto & {
    id: number;

    sales_offices: SalesOfficeDto[];
    building_create_manager: string | null;
    building_update_manager: string | null;
    date_create: string | null;
    date_update: string | null;
    version: string;
  } & HouseTypeDto &
  HouseCommunicationsDto;

export type SaveObjectDto = BaseObjectDto &
  SaveObjectContractorsDto & {
    id?: number;

    sales_offices: SaveSalesOfficeDto[] | number[];
  } & SaveHouseTypeDto &
  SaveHouseCommunicationsDto;

export const objectContractorZod = z.object({
  id: z.number(),
  contractorName: z.string(),
  inn: z.string().optional(),
});

const ceilingHeightValidator = z
  .string()
  .optional()
  .refine(
    (data) => {
      if (data === undefined || data === '') return true;
      const regex = /^\d{1}(\.\d{0,2})?$/;
      return regex.test(data);
    },
    {
      message: 'object.form.error.maxCeilingHeight',
    }
  );

export const objectZod = z.object({
  id: z.number(),
  name: z.string().min(1, 'object.form.error.name'),
  published: z.number(),
  site: z.string().optional(),
  objectType: z.string().min(1, 'object.form.error.objectType'),
  houseType: z.string(),
  saleStatus: z.string().min(1, 'object.form.error.saleStatus'),
  objectDescription: z.string().min(1, 'object.form.error.objectDescription'),
  address: z.string().min(1, 'object.form.error.address'),
  latitude: z.number().nullable(),
  longitude: z.number().nullable(),
  microdistrict: z.string().min(1, 'object.form.error.microdistrict'),
  seaDistance: z.string().optional(),
  federalLow: z.string().optional(),
  commission: z.string().optional(),
  houseClass: z.string().min(1, 'object.form.error.houseClass'),
  material: z.string().min(1, 'object.form.error.material'),
  territory: z.string().optional(),
  parking: z.array(z.string()).optional(),
  advantages: z.array(z.string()).optional(),
  gas: z.string().min(1, 'ui.common.error.selectValue'),
  canalization: z.string().min(1, 'ui.common.error.selectValue'),
  heating: z.string().min(1, 'ui.common.error.selectValue'),
  waterSupply: z.string().min(1, 'ui.common.error.selectValue'),
  electricity: z.string().min(1, 'ui.common.error.selectValue'),
  entrances: z.string().optional(),
  isNewObject: z.boolean(),
  ceilingHeight: ceilingHeightValidator,
  maintenancePrice: z.string().optional(),
  gasPrice: z.string().optional(),
  communicationsPrice: z.string().optional(),
  documentsPrice: z.string().optional(),
  otherData: z.string().optional(),
  developerId: z.number().optional(),

  nedvex: z.number().nullable().optional(),

  salesOffices: z.array(salesOfficeZod),
  contractors: z.array(objectContractorZod).optional(),

  mainPhotoHash: z.string().optional(),

  buildingCreateManager: z.string().nullable(),
  buildingUpdateManager: z.string().nullable(),
  dateCreate: z.string().nullable(),
  dateUpdate: z.string().nullable(),
  version: z.string(),
});

export type DeveloperObject = z.infer<typeof objectZod>;

export const editObjectZod = z
  .object({
    id: z.number().optional(),
    name: z.string().min(1, 'object.form.error.name').max(90),
    published: z.number(),
    site: z.string().max(255).optional(),
    objectType: z.string().min(1, 'object.form.error.objectType'),
    houseType: z.string(),
    saleStatus: z.string().min(1, 'object.form.error.saleStatus'),
    objectDescription: z
      .string()
      .min(1, 'object.form.error.objectDescription')
      .max(3000),
    address: z.string().min(1, 'object.form.error.address'),
    latitude: z.number().nullable(),
    longitude: z.number().nullable(),
    microdistrict: z.string().min(1, 'object.form.error.microdistrict'),
    seaDistance: z.string().optional(),
    federalLow: z.string().optional(),
    commission: z.string().max(255).optional(),
    houseClass: z.string().min(1, 'object.form.error.houseClass'),
    material: z.string().min(1, 'object.form.error.material'),
    territory: z.string().optional(),
    parking: z.array(z.string()).optional(),
    advantages: z.array(z.string()).optional(),
    gas: z.string().min(1, 'ui.common.error.selectValue'),
    canalization: z.string().min(1, 'ui.common.error.selectValue'),
    heating: z.string().min(1, 'ui.common.error.selectValue'),
    waterSupply: z.string().min(1, 'ui.common.error.selectValue'),
    electricity: z.string().min(1, 'ui.common.error.selectValue'),
    entrances: z.string().optional(),
    isNewObject: z.boolean(),
    ceilingHeight: ceilingHeightValidator,
    maintenancePrice: z.string().optional(),
    gasPrice: z.string().optional(),
    communicationsPrice: z.string().optional(),
    documentsPrice: z.string().optional(),
    otherData: z.string().max(255).optional(),
    developerId: z.number().optional(),

    nedvex: z.number().nullable().optional(),

    salesOffices: z.array(z.number().or(salesOfficeZod)),
    contractors: z.array(z.number().or(z.string())),

    mainPhotoHash: z.string().optional(),

    buildingCreateManager: z.string().nullable().optional(),
    buildingUpdateManager: z.string().nullable().optional(),
    dateCreate: z.string().nullable().optional(),
    dateUpdate: z.string().nullable().optional(),
  })
  .refine(
    (values) => {
      if (values.objectType === '1' && !values.houseType) {
        return false;
      }
      return true;
    },
    {
      message: 'object.form.error.houseType',
      path: ['houseType'],
    }
  );

export type EditDeveloperObject = z.infer<typeof editObjectZod>;

export type DeveloperObjectOutletData = {
  storedObject: DeveloperObject;
  objectSalesOffices: SalesOffice[];
  handleAddSalesOffice: (salesOffice: SalesOffice | number) => void;
  handleDeleteSalesOffice: (salesOffice: SalesOffice | number) => void;
};
