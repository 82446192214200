import { FC } from 'react';
import i18n from 'i18next';
import { NumericPatternField } from './NumericPatternField';

type PhoneFieldProps = {
  label?: string;
  name: string;
  required?: boolean;
  readOnly?: boolean;
};

export const PhoneField: FC<PhoneFieldProps> = ({
  label = i18n.t('ui.phoneInput.defaultLabel'),
  name,
  required,
  readOnly,
}) => {
  return (
    <NumericPatternField
      label={label}
      name={name}
      required={required}
      format="+# (###) ###-##-##"
      mask="x"
      maxLength={11}
      readOnly={readOnly}
    />
  );
};
