import { FC } from 'react';
import { Col } from 'shared/ui/Col';
import { RectSkeleton } from 'shared/ui/Skeleton/RectSkeleton';

export const PlanMatrixSkeleton: FC = () => {
  return (
    <Col spacing={8} sx={{ mt: 5 }}>
      <RectSkeleton width={100} height={23} />
      <RectSkeleton height={400} />
    </Col>
  );
};
