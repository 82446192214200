import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TextColor } from 'shared/theme/colors';
import { LotStatusesStatistics } from '../../../model/types/objectAnalyticsSchema';

const StatusesColor = ['#88154C', '#1987AA', '#CCB90B', '#DA4D5E'];
const StatusNames = ['sold', 'sale', 'reserve', 'fund'];
enum ColumnName {
  COUNT = 'count',
  AREA = 'area',
  PRICE = 'price',
  PERCENT = 'percent',
}

const formatNumber = (number: number | null | undefined) => {
  if (number === null || number === undefined) return '—';
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const formatValue = (value: number | null | undefined, type: ColumnName) => {
  if (value === null || value === undefined) return '—';

  switch (type) {
    case ColumnName.AREA:
      return `${formatNumber(parseFloat(value.toFixed(0)))} м²`;
    case ColumnName.PRICE:
      return `${formatNumber(
        parseFloat((value / 1000000).toFixed(1))
      )}`.replace('.', ',');
    case ColumnName.PERCENT:
      return value === 0 ? '—' : `${value}%`;
    default:
      return formatNumber(value);
  }
};

interface LotStatusesStatisticsTableProps {
  data: LotStatusesStatistics;
}

export const LotStatusesStatisticsTable: FC<
  LotStatusesStatisticsTableProps
> = ({ data }) => {
  const { t } = useTranslation();

  const tableData = Object.entries(data.table).map(([key, value], index) => ({
    status: StatusNames[index],
    count: formatValue(value.quantity, ColumnName.COUNT),
    area: formatValue(value.area, ColumnName.AREA),
    price: formatValue(value.price, ColumnName.PRICE),
    percent: formatValue(value.percent, ColumnName.PERCENT),
  }));

  return (
    <Table sx={{ width: 397, height: 205 }} size="small">
      <TableHead>
        <TableRow sx={{ height: 33 }}>
          <TableCell sx={{ p: 0, pb: 4, width: 94.25 }}>
            <Typography variant="accentS" color={TextColor.Tertiary}>
              {t('analytics.table.label.status')}
            </Typography>
          </TableCell>
          <TableCell sx={{ p: 0, pb: 4, width: 70 }}>
            <Typography variant="accentS" color={TextColor.Tertiary}>
              {t('analytics.table.label.amount')}
            </Typography>
          </TableCell>
          <TableCell sx={{ p: 0, pb: 4, width: 94.25 }}>
            <Typography variant="accentS" color={TextColor.Tertiary}>
              {t('analytics.table.label.area')}
            </Typography>
          </TableCell>
          <TableCell sx={{ p: 0, pb: 4, width: 70 }}>
            <Typography variant="accentS" color={TextColor.Tertiary}>
              {t('analytics.table.label.millionRubles')}
            </Typography>
          </TableCell>
          <TableCell sx={{ p: 0, pb: 4 }}>
            <Typography variant="accentS" color={TextColor.Tertiary}>
              {t('analytics.table.label.percentOfTotal')}
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tableData.map((row, index) => (
          <TableRow
            key={index}
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
            }}
          >
            <TableCell sx={{ p: 0, pb: 4, pt: 4 }}>
              <Typography variant="paragraphS" color={StatusesColor[index]}>
                {t(`analytics.table.label.${row.status}`)}
              </Typography>
            </TableCell>
            <TableCell sx={{ p: 0, pb: 4, pt: 4 }}>
              <Typography variant="paragraphS">{row.count}</Typography>
            </TableCell>
            <TableCell sx={{ p: 0, pb: 4, pt: 4 }}>
              <Typography variant="paragraphS">{row.area}</Typography>
            </TableCell>
            <TableCell sx={{ p: 0, pb: 4, pt: 4 }}>
              <Typography variant="paragraphS">{row.price}</Typography>
            </TableCell>
            <TableCell sx={{ p: 0, pb: 4, pt: 4 }}>
              <Typography variant="paragraphS">{row.percent}</Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
