import { FC } from 'react';
import { Container } from '@mui/material';
import { Col } from 'shared/ui/Col';
import { RectSkeleton } from 'shared/ui/Skeleton/RectSkeleton';

export const PlansSkeleton: FC = () => {
  return (
    <Container>
      <Col spacing={8} sx={{ mt: 5 }}>
        <RectSkeleton width={200} height={37} />
        <RectSkeleton width={330} height={360} />
      </Col>
    </Container>
  );
};
