export const calculateTotalPrice = (
  totalArea: number | string,
  pricePerMeter: number | string
) => {
  return Math.round(Number(totalArea) * Number(pricePerMeter));
};

export const calculatePricePerMeter = (
  totalArea: number | string,
  totalPrice: number | string
) => {
  return Math.round(Number(totalPrice) / Number(totalArea));
};

export const calculateTotalArea = (
  totalPrice: number | string,
  pricePerMeter: number | string
) => {
  return Math.round(Number(totalPrice) / Number(pricePerMeter));
};
