import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Snap from 'snapsvg';
import { Box, Button, Popper } from '@mui/material';
import { useVersionCheck } from 'entities/object';
import {
  Plan,
  Polygon,
  useCreatePolygonMutation,
  useDeletePolygonMutation,
} from 'entities/plan';
import { getRoute } from 'shared/const';
import { getPlanImage } from 'shared/helpers/getImageUrl';
import { useConfirmationDialog } from 'shared/hooks/useConfirmationDialog';
import { useInitialEffect } from 'shared/hooks/useInitialEffect';
import { ClipIcon } from 'shared/ui/Icons';
import { RemovalConfirmationCard } from 'shared/ui/RemovalConfirmationCard';
import { Row } from 'shared/ui/Row';
import { SmallSquareButton } from 'shared/ui/SmallSquareButton';

const SVG_WIDTH = 1000;
const SVG_HEIGHT = 700;

// const data =
//   '1600:1131:1347.5035540097642,617.1849602502339,1348.384190536502,965.9286785159684,1161.6986102541741,970.6463696284487,1161.6986102541741,922.7955530217871,1012.0805639629224,922.7955530217871,1005.3410123281813,607.3845365159049,1346.3623250460796,612.1022226602236';
export const PlanSchema: FC = () => {
  const { t } = useTranslation();
  const { objectId, buildingId, entranceId, planId } = useParams<{
    objectId: string;
    buildingId: string;
    entranceId: string;
    planId: string;
  }>();

  const { plan, polygonsData } = useOutletContext<{
    plan: Plan;
    polygonsData: Polygon[];
  }>();

  const { checkVersion, showVersionChangedPopup } = useVersionCheck(objectId);

  const [hoveredPolygonData, setHoveredPolygonData] = useState<{
    polygonData: Polygon;
    snapPolygon: Snap.Element;
  } | null>(null);

  const svgRef = useRef<SVGSVGElement>(null);
  const popperRef = useRef<HTMLDivElement | null>(null);
  const gRef = useRef<Snap.Paper | null>(null);
  const snapRef = useRef<Snap.Paper | null>(null);
  const patternRef = useRef<{
    rect?: Snap.Element;
    pattern?: Snap.Element;
    rectBig?: Snap.Element;
    patternBig?: Snap.Element;
  }>({});
  const savedPolygonsRef = useRef<Snap.Element[]>([]);
  const points = useRef<Snap.Element[]>([]);
  const linesRef = useRef<Snap.Element[]>([]);
  const pointsCoordsRef = useRef<number[]>([]);
  const initialViewBoxSize = useRef<{ w: number; h: number }>({
    w: SVG_WIDTH,
    h: SVG_HEIGHT,
  });
  const isPanning = useRef<boolean>(false);
  const wasMoved = useRef<boolean>(false);
  const startPanPoint = useRef<{ x: number; y: number }>({ x: 0, y: 0 });
  const clickTimeout = useRef<NodeJS.Timeout>();

  const [lastPointRef, setLastPointRef] = useState<HTMLElement>();
  const [popperKey, setPopperKey] = useState(0);

  const [createPolygon] = useCreatePolygonMutation();
  const [deletePolygon] = useDeletePolygonMutation();

  const navigate = useNavigate();

  const updatePopperKey = () => {
    setPopperKey((p) => p + 1);
  };

  const getScale = () => {
    const { w, h } = snapRef.current?.attr('viewBox') ?? { w: 1, h: 1 };
    return Math.min(SVG_WIDTH / w, SVG_HEIGHT / h);
  };

  const applyBounds = (newViewBox: {
    x: number;
    y: number;
    w: number;
    h: number;
  }) => {
    // Ограничения по ширине
    if (newViewBox.w > initialViewBoxSize.current.w) {
      newViewBox.x = 0;
      newViewBox.w = initialViewBoxSize.current.w;
    } else {
      const maxX = initialViewBoxSize.current.w - newViewBox.w;
      newViewBox.x = Math.min(Math.max(0, newViewBox.x), maxX);
    }

    // Ограничения по высоте
    if (newViewBox.h > initialViewBoxSize.current.h) {
      newViewBox.y = 0;
      newViewBox.h = initialViewBoxSize.current.h;
    } else {
      const maxY = initialViewBoxSize.current.h - newViewBox.h;
      newViewBox.y = Math.min(Math.max(0, newViewBox.y), maxY);
    }

    return newViewBox;
  };

  const updateViewBox = (viewBox: {
    x?: number;
    y?: number;
    w?: number;
    h?: number;
  }) => {
    if (!snapRef.current) return;

    const { x, y, w, h } = applyBounds({
      ...snapRef.current.attr('viewBox'),
      ...viewBox,
    });

    snapRef.current.attr({
      viewBox: `${x} ${y} ${w} ${h}`,
    });

    updateGrid();
  };

  const {
    isShowRemoveConfirmation,
    showRemoveConfirmation,
    hideRemoveConfirmation,
    confirmRemoveAction,
  } = useConfirmationDialog({
    onConfirm: async () => {
      const isVersionChanged = await checkVersion();

      if (!isVersionChanged) {
        if (hoveredPolygonData) {
          hoveredPolygonData.snapPolygon.remove();
          deletePolygon({
            objectId,
            polygonId: hoveredPolygonData.polygonData.id,
          });
        }
        setHoveredPolygonData(null);
      }
    },
  });

  const pointsToPolygon = useCallback(async () => {
    const isVersionChanged = await checkVersion();

    if (!isVersionChanged) {
      createPolygon({
        objectId,
        polygon: {
          planId: plan.id,
          data: `${plan.width}:${plan.height}:${pointsCoordsRef.current.join(
            ','
          )}`,
        },
      });
      pointsCoordsRef.current = [];
      gRef.current?.selectAll('rect').remove();
      gRef.current?.selectAll('line').remove();
      points.current = [];
      linesRef.current = [];
      setLastPointRef(undefined);
    }
  }, [checkVersion, createPolygon, objectId, plan.height, plan.id, plan.width]);

  const handleSvgClick = (e: MouseEvent) => {
    if (!snapRef.current || !gRef.current) return;

    const svgElement = snapRef.current.node as unknown as SVGSVGElement;
    const pt = svgElement.createSVGPoint();
    pt.x = e.clientX;
    pt.y = e.clientY;

    // Преобразуем координаты клика в систему координат SVG
    const tempSvgPoint = pt.matrixTransform(
      svgElement.getScreenCTM()?.inverse()
    );

    const scale = getScale();

    const pointSize = 12 / getScale();

    const svgPoint = gRef.current
      .rect(tempSvgPoint.x, tempSvgPoint.y, pointSize, pointSize)
      .attr({
        fill: 'rgb(223, 37, 20)',
        style: `translate: -${6 / scale}px -${6 / scale}px;`,
      });

    pointsCoordsRef.current = [
      ...pointsCoordsRef.current,
      tempSvgPoint.x,
      tempSvgPoint.y,
    ];

    if (points.current.length) {
      const count = points.current.length;
      const line = gRef.current
        .line(
          Number(points.current[count - 1].getBBox().x),
          Number(points.current[count - 1].getBBox().y),
          tempSvgPoint.x,
          tempSvgPoint.y
        )
        .attr({
          fill: 'none',
          stroke: '#D00',
          strokeWidth: 3 / scale,
          'stroke-opacity': 0.5,
        });
      linesRef.current.push(line);

      points.current[0].before(svgPoint);
      points.current[0].before(line);
    }
    points.current.push(svgPoint);
    setLastPointRef(svgPoint.node);
  };

  const createSnapPolygon = (
    polygonData: Polygon,
    points: string,
    hasLots: boolean
  ) => {
    const createdSnapPolygon = gRef
      .current!.polygon(points)
      .attr({
        fill: hasLots ? '#1964AA' : '#DF2514',
        stroke: hasLots ? '#1964AA' : '#DF2514',
        'stroke-width': 2,
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
        'fill-opacity': 0.15,
      })
      .hover(
        () => {
          setHoveredPolygonData({
            polygonData,
            snapPolygon: createdSnapPolygon,
          });
        },
        (e) => {
          if (
            popperRef.current &&
            !popperRef.current.contains(e.relatedTarget as Node)
          ) {
            setHoveredPolygonData(null);
          }
        }
      );
    return createdSnapPolygon;
  };

  const updateGrid = () => {
    if (!snapRef.current) return;

    const viewBox = snapRef.current.attr('viewBox');
    const gridSize = Math.max(viewBox.w, viewBox.h);

    const scale = getScale();
    const smallGridStep = 15 / scale;
    const bigGridStep = smallGridStep * 10;

    const gridPatternString = Snap.format(
      `M0 {smallGridStep} L{smallGridStep} {smallGridStep} M{smallGridStep} 0 L{smallGridStep} {smallGridStep}`,
      { smallGridStep }
    );
    const bigGridPatternString = Snap.format(
      `M0 {bigGridStep} L{bigGridStep} {bigGridStep} M{bigGridStep} 0 L{bigGridStep} {bigGridStep}`,
      { bigGridStep }
    );

    patternRef.current.rect?.remove();
    patternRef.current.pattern?.remove();
    patternRef.current.rectBig?.remove();
    patternRef.current.patternBig?.remove();

    patternRef.current.pattern = snapRef.current
      .path(gridPatternString)
      .attr({
        fill: 'none',
        stroke: '#ccc',
        strokeWidth: 1 / scale,
        'stroke-opacity': 0.5,
      })
      .pattern(0, 0, smallGridStep, smallGridStep);

    patternRef.current.patternBig = snapRef.current
      .path(bigGridPatternString)
      .attr({
        fill: 'none',
        stroke: '#ccc',
        strokeWidth: 3 / scale,
        'stroke-opacity': 0.5,
      })
      .pattern(0, 0, bigGridStep, bigGridStep);

    patternRef.current.rect = snapRef.current
      .rect(viewBox.x, viewBox.y, gridSize, gridSize)
      .attr({
        style: 'pointer-events: none;',
        fill: patternRef.current.pattern,
      });

    patternRef.current.rectBig = snapRef.current
      .rect(viewBox.x, viewBox.y, gridSize, gridSize)
      .attr({
        style: 'pointer-events: none;',
        fill: patternRef.current.patternBig,
      });
  };

  useInitialEffect(() => {
    if (svgRef.current && !gRef.current) {
      const { width, height } = plan;

      const aspectRatioImage = width / height;
      const aspectRatioSVG = SVG_WIDTH / SVG_HEIGHT;

      if (aspectRatioImage > aspectRatioSVG) {
        initialViewBoxSize.current.w = width;
        initialViewBoxSize.current.h = width / aspectRatioSVG;
      } else {
        initialViewBoxSize.current.h = height;
        initialViewBoxSize.current.w = height * aspectRatioSVG;
      }

      snapRef.current = Snap(svgRef.current);
      gRef.current = snapRef.current.g();

      updateViewBox({
        x: 0,
        y: 0,
        w: initialViewBoxSize.current.w,
        h: initialViewBoxSize.current.h,
      });

      gRef.current.image(
        getPlanImage(plan.id),
        0,
        0,
        Number(width),
        Number(height)
      );

      snapRef.current.node.addEventListener('mousedown', handleMouseDown);
      snapRef.current.node.addEventListener('mousemove', handlePan);
      snapRef.current.node.addEventListener('mouseup', handleMouseUp);
      snapRef.current.node.addEventListener('wheel', handleZoom);
    }
  });

  const handleMouseDown = (event: MouseEvent) => {
    isPanning.current = true;
    wasMoved.current = false;

    startPanPoint.current.x = event.clientX;
    startPanPoint.current.y = event.clientY;

    clearTimeout(clickTimeout.current);
    clickTimeout.current = setTimeout(() => {
      wasMoved.current = true;
    }, 300);
  };

  const handleMouseUp = (event: MouseEvent) => {
    clearTimeout(clickTimeout.current);

    if (!wasMoved.current) {
      if (points.current.length) {
        const firstPoint = points.current[0].node;
        if (event.target === firstPoint) {
          if (points.current.length >= 3) {
            pointsToPolygon();
          }
        } else {
          handleSvgClick(event);
        }
      } else {
        handleSvgClick(event);
      }
    }
    isPanning.current = false;
  };

  const handlePan = (event: MouseEvent) => {
    if (isPanning.current && snapRef.current) {
      wasMoved.current = true;
      const viewBox = snapRef.current.attr('viewBox');

      const dx =
        (event.clientX - startPanPoint.current.x) *
        (viewBox.w / snapRef.current.node.clientWidth);
      const dy =
        (event.clientY - startPanPoint.current.y) *
        (viewBox.h / snapRef.current.node.clientHeight);

      startPanPoint.current.x = event.clientX;
      startPanPoint.current.y = event.clientY;

      updateViewBox({
        x: viewBox.x - dx,
        y: viewBox.y - dy,
      });

      setLastPointRef(points.current[points.current.length - 1]?.node);
      updatePopperKey();
    }
  };

  const handleZoom = (e: WheelEvent) => {
    if (snapRef.current) {
      e.preventDefault();
      const scaleFactor = 1.1;
      const viewBox = snapRef.current.attr('viewBox');

      let newWidth: number, newHeight: number, newX: number, newY;

      if (e.deltaY < 0) {
        // Zoom In
        newWidth = viewBox.w / scaleFactor;
        newHeight = viewBox.h / scaleFactor;
        newX = viewBox.x + (viewBox.w - newWidth) / 2;
        newY = viewBox.y + (viewBox.h - newHeight) / 2;
      } else {
        // Zoom Out
        newWidth = viewBox.w * scaleFactor;
        newHeight = viewBox.h * scaleFactor;
        newX = viewBox.x - (newWidth - viewBox.w) / 2;
        newY = viewBox.y - (newHeight - viewBox.h) / 2;
      }

      const widthRatio = initialViewBoxSize.current.w / newWidth;
      const heightRatio = initialViewBoxSize.current.h / newHeight;

      const maxZoom = 40;
      const minZoom = 0.9;

      const allowZoom =
        widthRatio <= maxZoom &&
        heightRatio <= maxZoom &&
        widthRatio >= minZoom &&
        heightRatio >= minZoom;

      if (!allowZoom) return;

      updateViewBox({ x: newX, y: newY, w: newWidth, h: newHeight });

      const scale = getScale();
      const pointSize = 12 / scale;

      points.current.forEach((point) => {
        point.attr({
          width: pointSize,
          height: pointSize,
          style: `translate: -${6 / scale}px -${6 / scale}px;`,
        });
      });

      linesRef.current.forEach((line) => {
        line.attr({ strokeWidth: 3 / scale });
      });

      setLastPointRef(points.current[points.current.length - 1]?.node);
      updatePopperKey();
    }
  };

  useEffect(() => {
    if (polygonsData) {
      savedPolygonsRef.current.forEach((polygon) => {
        polygon.remove();
      });
      polygonsData.forEach((polygonData) => {
        const savedPolygon = createSnapPolygon(
          polygonData,
          polygonData.data.split(':')[2],
          polygonData.chess.length > 0
        );
        savedPolygonsRef.current.push(savedPolygon);
      });
    }
  }, [polygonsData]);

  if (!objectId || !buildingId || !entranceId || !planId) {
    navigate(getRoute.Objects());
    return null;
  }

  const polygonActions = isShowRemoveConfirmation ? (
    <RemovalConfirmationCard
      titleEnding={t('plan.dialog.area')}
      onRemoveClick={confirmRemoveAction}
      onCancelClick={hideRemoveConfirmation}
      isModal
    />
  ) : (
    <Row spacing={4}>
      {!hoveredPolygonData?.polygonData.chess.length ? (
        <Button
          variant="contained"
          size={'small'}
          sx={{ px: 5, py: 4 }}
          onClick={() => {
            if (hoveredPolygonData) {
              navigate(
                getRoute.LotsPlanMatrix(
                  objectId,
                  buildingId,
                  entranceId,
                  planId,
                  hoveredPolygonData.polygonData.id
                )
              );
            }
          }}
          color={'primary'}
        >
          <ClipIcon sx={{ mr: 5 }} />
          {t('plan.dialog.bind')}
        </Button>
      ) : (
        <SmallSquareButton
          variant="contained"
          color="primary"
          icon="edit"
          onClick={() => {
            if (hoveredPolygonData) {
              navigate(
                getRoute.LotsPlanMatrix(
                  objectId,
                  buildingId,
                  entranceId,
                  planId,
                  hoveredPolygonData.polygonData.id
                )
              );
            }
          }}
        />
      )}
      <SmallSquareButton onClick={showRemoveConfirmation} />
    </Row>
  );

  const polygonTooltip = (
    <Popper
      key={'polygonTooltip' + popperKey}
      open={isShowRemoveConfirmation || !!hoveredPolygonData}
      ref={popperRef}
      anchorEl={hoveredPolygonData?.snapPolygon?.node}
      placement={'top'}
      sx={{
        translate: `0 ${
          hoveredPolygonData
            ? hoveredPolygonData.snapPolygon.node.getBoundingClientRect()
                .height /
                2 +
              20
            : 0
        }px`,
      }}
      onMouseLeave={() => setHoveredPolygonData(null)}
    >
      {polygonActions}
    </Popper>
  );

  const undoButton = (
    <Popper
      key={'undoButton' + popperKey}
      open={Boolean(lastPointRef)}
      anchorEl={lastPointRef}
      sx={{ translate: '10px -10px' }}
      placement={'right-end'}
    >
      <SmallSquareButton
        onClick={() => {
          points.current[points.current.length - 1].remove();
          points.current.pop();

          setLastPointRef(points.current[points.current.length - 1]?.node);
          updatePopperKey();

          pointsCoordsRef.current.pop();
          pointsCoordsRef.current.pop();
          if (linesRef.current.length) {
            linesRef.current[linesRef.current.length - 1].remove();
            linesRef.current.pop();
          }
        }}
      />
    </Popper>
  );

  return (
    <Box>
      {polygonTooltip}
      {undoButton}
      {showVersionChangedPopup()}
      <svg
        ref={svgRef}
        version="1.1"
        preserveAspectRatio="xMidYMid meet"
        xmlns="http://www.w3.org/2000/svg"
        style={{ overflow: 'scroll', position: 'relative' }}
        width="100%"
        height="700px"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        // xmlns="http://www.w3.org/2001/xml-events"
      ></svg>
    </Box>
  );
};
