import { FC } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, CardContent } from '@mui/material';
import {
  Album,
  CONSTRUCTION_PROGRESS_TYPE,
  useDeleteAlbumPhotoMutation,
} from 'entities/album';
// eslint-disable-next-line boundaries/element-types
import { useVersionCheck } from 'entities/object';
import { getImageUrl } from 'shared/helpers/getImageUrl';
import { BorderColor } from 'shared/theme/colors';
import { PhotoData } from 'shared/types';
import { Row } from 'shared/ui/Row';
import { RectSkeleton } from 'shared/ui/Skeleton/RectSkeleton';
import { SmallSquareButton } from 'shared/ui/SmallSquareButton';
import { GridSortableList } from 'shared/ui/SortableList';
import { EditAlbum, editAlbumZod } from '../model/types/albumSchema';

type AlbumDetailsProps = {
  album: Album;
  sortMode: boolean;
  objectId: number;
  sortedPhotos: PhotoData[];
  onPhotosSort: (photos: PhotoData[]) => void;
  isDataUpdating: boolean;
};

export const AlbumDetails: FC<AlbumDetailsProps> = ({
  album,
  sortMode,
  objectId,
  sortedPhotos,
  onPhotosSort,
  isDataUpdating,
}) => {
  const [deleteAlbumPhoto] = useDeleteAlbumPhotoMutation();

  const { checkVersion, showVersionChangedPopup } = useVersionCheck(objectId);

  const form = useForm<EditAlbum>({
    resolver: zodResolver(editAlbumZod),
    mode: 'onTouched',
    defaultValues: {
      name: album.name || '',
      type: album.type || '',
      objectId: objectId,
      year: album.year || '',
      month: album.month || '',
      order: album.order,
    },
  });

  const sortablePhotosData = album.albumImages.toSorted(
    (a, b) => a.place - b.place
  );

  const handleDeletePhotoClick = async (photoId: string | number) => {
    const isVersionChanged = await checkVersion();

    if (!isVersionChanged) {
      deleteAlbumPhoto({ objectId, albumId: album.id, photoId });
    }
  };

  return (
    <FormProvider {...form}>
      {showVersionChangedPopup()}
      <CardContent
        sx={{
          px: 8,
          pt: sortMode && album.type !== CONSTRUCTION_PROGRESS_TYPE ? 0 : 8,
        }}
      >
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
          {isDataUpdating ? (
            sortablePhotosData.map((item) => (
              <RectSkeleton key={item.place} width={191} height={120} />
            ))
          ) : (
            <GridSortableList
              items={sortedPhotos.length ? sortedPhotos : sortablePhotosData}
              onChange={onPhotosSort}
              renderItem={(photo) => (
                <GridSortableList.Item id={photo.id}>
                  <Box
                    key={photo.id}
                    sx={{
                      width: '191px',
                      height: '120px',
                      position: 'relative',
                      '&:hover .imageOverlay': { opacity: 1 },
                    }}
                  >
                    <img
                      src={getImageUrl(photo.path)}
                      alt={'photo'}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: '8px',
                        border: `1px solid ${BorderColor.Gray}`,
                        transition: 'opacity 0.3s',
                      }}
                    />
                    {sortMode && (
                      <Box
                        sx={{
                          position: 'absolute',
                          zIndex: 100,
                          top: 15,
                          right: 5,
                        }}
                      >
                        <GridSortableList.DragHandle />
                      </Box>
                    )}

                    {!sortMode && (
                      <Box
                        className="imageOverlay"
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          backgroundColor: 'rgba(0, 0, 0, 0.2)',
                          opacity: 0,
                          transition: 'opacity 0.3s',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '8px',
                        }}
                      >
                        <Row spacing={4}>
                          <SmallSquareButton
                            onClick={() => handleDeletePhotoClick(photo.id)}
                          />
                        </Row>
                      </Box>
                    )}
                  </Box>
                </GridSortableList.Item>
              )}
            />
          )}
        </Box>
      </CardContent>
    </FormProvider>
  );
};
