import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { getRoute } from 'shared/const';
import { formatShowWeekDays } from 'shared/helpers/formatShowWeekDays';
import { getFormattedPhone } from 'shared/helpers/getFormattedPhone';
import { TextColor } from 'shared/theme/colors';
import { CardLayout } from 'shared/ui/CardLayout';
import { Col } from 'shared/ui/Col';
import { Row } from 'shared/ui/Row';
import { SmallSquareButton } from 'shared/ui/SmallSquareButton';
import { TextViewField } from 'shared/ui/TextViewField';
import { Developer } from '../../../model/types/developerSchema';

type AdditionalDataCardProps = {
  developer: Developer;
  onEditClick?: () => void;
};

export const AdditionalDataCard: FC<AdditionalDataCardProps> = ({
  developer,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <CardLayout
      variant="secondary"
      paddingY={32}
      header={{ title: t('developer.edit.tabLabel.additionalData') }}
    >
      {developer.schedules.length > 0 && (
        <Col spacing={4}>
          <Typography variant="paragraphS" color={TextColor.Secondary}>
            {t('developer.card.label.schedule')}
          </Typography>
          <Col spacing={2}>
            {developer.schedules.map((schedule, index) => {
              return (
                <Row spacing={3} key={index}>
                  <Typography variant="paragraphM" color={TextColor.Primary}>
                    {formatShowWeekDays(schedule.workingDays, t)};
                  </Typography>

                  <Typography variant="paragraphM" color={TextColor.Primary}>
                    {schedule.workingHours}
                  </Typography>
                </Row>
              );
            })}
          </Col>
        </Col>
      )}
      {(developer.phone || developer.corpEmail) && (
        <Row equalsChildrenWidth>
          {developer.phone && (
            <TextViewField
              title={t('ui.common.generalPhone')}
              text={getFormattedPhone(developer.phone)}
            />
          )}
          {developer.corpEmail && (
            <TextViewField
              title={t('ui.common.generalEmail')}
              text={developer.corpEmail}
            />
          )}
        </Row>
      )}

      {developer.website && (
        <TextViewField title={t('ui.common.site')} text={developer.website} />
      )}
      <SmallSquareButton
        icon="edit"
        onClick={() =>
          navigate(getRoute.EditDeveloperAdditionalDataTab(developer.id))
        }
      />
    </CardLayout>
  );
};
