import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { TextColor } from 'shared/theme/colors';
import { ButtonLoadingSpinner } from 'shared/ui/ButtonLoadingSpinner';
import { CardLayout } from 'shared/ui/CardLayout';
import { Col } from 'shared/ui/Col';
import { Row } from 'shared/ui/Row';
import { SmallSquareButton } from 'shared/ui/SmallSquareButton';
import { Entrance } from '../model/types/entranceSchema';

export type EntranceInfoCardProps = {
  entrance: Entrance;
  onEditClick: (entrance: Entrance) => void;
  onDeleteClick: () => void;
  onDuplicateEntrance: (entrance: Entrance) => void;
  isEntranceDuplicating: boolean;
};

export const EntranceInfoCard: FC<EntranceInfoCardProps> = ({
  entrance,
  onEditClick,
  onDeleteClick,
  onDuplicateEntrance,
  isEntranceDuplicating,
}) => {
  const { t } = useTranslation();

  return (
    <CardLayout>
      <Col spacing={3}>
        <Typography variant="headerXS">{entrance.name}</Typography>
        <Typography variant="accentM" color={TextColor.Secondary}>
          {`${t('entrance.card.subtitle.floor', {
            count: Number(entrance.floorCount),
          })}, ${t('entrance.card.subtitle.riser', {
            count: Number(entrance.riserCount),
          })}`}
        </Typography>
      </Col>
      <Row spacing={5}>
        <Button
          variant={'outlined'}
          size={'small'}
          onClick={() => onDuplicateEntrance(entrance)}
          disabled={isEntranceDuplicating}
        >
          {t('building.card.duplicate')}
          {isEntranceDuplicating && <ButtonLoadingSpinner />}
        </Button>
        <SmallSquareButton icon="edit" onClick={() => onEditClick(entrance)} />
        <SmallSquareButton onClick={() => onDeleteClick()} />
      </Row>
    </CardLayout>
  );
};
