import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormHelperText,
  SxProps,
  Typography,
} from '@mui/material';
/* eslint-disable boundaries/element-types */
import {
  useUploadDocumentMutation,
  useUploadImageMutation,
} from 'entities/file';
import { downloadFile } from 'shared/helpers/downloadFile';
import { getFileUrl } from 'shared/helpers/getFileUrl';
import { getApiImage } from 'shared/helpers/getImageUrl';
import { BgColor, PaletteColor, TextColor } from 'shared/theme/colors';
import { ButtonLink } from './ButtonLink';
import { Col } from './Col';
import { DocumentIcon, ImageIcon } from './Icons';
import { Row } from './Row';

type UploadMode = 'document' | 'image';

interface FileUploadFieldProps {
  title?: string;
  hideTitle?: boolean;
  description?: string;
  fileType?: UploadMode;
  accept?: string;
  fileExtensions?: string[];
  showFileExtensions?: boolean;
  hideFileExtensionsForUploaded?: boolean;
  multiple?: boolean;
  name: string;
  maxFileSize?: number;
  onLoadingChange?: (loading: boolean) => void;
  isSkipUpload?: boolean;
  sx?: SxProps;
}

const loaderIcon = (
  <CircularProgress
    style={{
      width: '60px',
      height: '60px',
      marginBottom: '-4px',
      color: TextColor.Secondary,
    }}
  />
);

const imageIcon = (
  <ImageIcon
    style={{
      width: '60px',
      height: '60px',
      marginBottom: '-4px',
    }}
  />
);

export const FileUploadField: FC<FileUploadFieldProps> = ({
  title,
  hideTitle = false,
  description,
  accept,
  fileType = 'document',
  fileExtensions,
  multiple = false,
  showFileExtensions,
  hideFileExtensionsForUploaded,
  onLoadingChange,
  maxFileSize,
  isSkipUpload = false,
  name,
  sx,
}) => {
  const { t } = useTranslation();
  const form = useFormContext();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileNames, setFileNames] = useState<string[] | null>(null);
  const [isDragActive, setDragActive] = useState<boolean>(false);
  const [uploadImage, { isLoading: isImageLoading }] = useUploadImageMutation();
  const [uploadDocument, { isLoading: isDocumentLoading }] =
    useUploadDocumentMutation();

  const isLoading = isImageLoading || isDocumentLoading;

  useEffect(() => {
    onLoadingChange?.(isLoading);
  }, [isLoading, onLoadingChange]);

  const onUploadButtonClick = () => {
    fileInputRef.current?.click();
  };

  const onDeleteButtonClick = () => {
    try {
      form.setValue(name, multiple ? [] : '', {
        shouldDirty: true,
        shouldValidate: true,
      });
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      setFileNames(null);
    } catch (error) {
      console.error(error);
    }
  };

  const getAccept = () => {
    if (accept) {
      return accept;
    }

    return fileType === 'document' ? '.pdf' : '.png,.jpg';
  };

  const getFileExtensions = () => {
    if (fileExtensions) {
      return fileExtensions;
    }

    return fileType === 'document' ? ['.pdf'] : ['.png', '.jpg'];
  };

  const validateExtension = (fileName: string) => {
    const allowedExtensions = new RegExp(
      `(${getFileExtensions().join('|').replace('.', '\\.')})$`,
      'i'
    );

    return allowedExtensions.exec(fileName);
  };

  const onFileChange =
    (onChange: (...event: unknown[]) => void) =>
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files?.length) {
        processFiles(event.target.files, onChange);
      }
    };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop =
    (onChange: (...event: unknown[]) => void) =>
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        processFiles(e.dataTransfer.files, onChange);
      }
    };

  const processFiles = async (
    files: FileList,
    onChange: (...event: unknown[]) => void
  ) => {
    const fileData = Array.from(files);
    const upload = fileType === 'document' ? uploadDocument : uploadImage;
    const nameType = fileType === 'document' ? 'file' : 'image';
    const selectedFileNames = fileData.map((file) => file.name);
    form.clearErrors(name);

    if (maxFileSize) {
      if (fileData.some((file) => file.size > maxFileSize * 1024 * 1024)) {
        form.setError(name, {
          message: t('ui.fileUploadField.errorMaxSize', { size: maxFileSize }),
        });
        return;
      }
    }

    if (!selectedFileNames.every(validateExtension)) {
      form.setError(name, {
        message: t('ui.fileUploadField.errorFormat'),
      });
      return;
    }
    setFileNames(selectedFileNames);

    if (multiple) {
      try {
        const loadedFiles: Array<{ order: number; hash: string }> = [];
        for (let i = 0; i < fileData.length; i++) {
          const formData = new FormData();
          formData.append(nameType, fileData[i]);
          const loadedFile = await upload(formData).unwrap();
          loadedFiles.push({ order: i + 1, hash: loadedFile.hash });
        }
        onChange(loadedFiles);
      } catch (error) {
        console.error(error);
        const rtkError = error as FetchBaseQueryError;
        if (rtkError.status === 413) {
          form.setError(name, {
            message: t('ui.fileUploadField.serverErrorMaxSize'),
          });
        } else {
          form.setError(name, {
            message: t('ui.common.error.fetchDataError'),
          });
        }
      }
    } else {
      try {
        const formData = new FormData();
        formData.append(nameType, fileData[0]);
        if (!isSkipUpload) {
          const loadedFile = await upload(formData).unwrap();
          onChange(loadedFile.hash);
        } else {
          onChange(fileData[0]);
        }
      } catch (error) {
        console.error(error);
        const rtkError = error as FetchBaseQueryError;
        if (rtkError.status === 413) {
          form.setError(name, {
            message: t('ui.fileUploadField.serverErrorMaxSize'),
          });
        } else {
          form.setError(name, {
            message: t('ui.common.error.fetchDataError'),
          });
        }
      }
    }
  };

  const renderIcon = (value?: string | { hash: string }[]) => {
    if (isLoading) {
      return loaderIcon;
    }

    if (fileType === 'document') {
      const hash = Array.isArray(value) ? value[0].hash : value;
      return (
        <DocumentIcon
          onClick={() =>
            hash && downloadFile(getFileUrl(hash), description || hash)
          }
          style={{
            cursor: hash ? 'pointer' : 'default',
            width: '60px',
            height: '60px',
            marginBottom: '-4px',
          }}
        />
      );
    }

    if (value?.length) {
      return (
        <img
          style={{
            width: '60px',
            height: '60px',
            marginBottom: '-4px',
            borderRadius: '8px',
          }}
          src={
            Array.isArray(value)
              ? getApiImage(value[0].hash)
              : getApiImage(value)
          }
          alt={name}
        />
      );
    } else {
      return imageIcon;
    }
  };

  const renderTitle = (value: string | File) => {
    if (isLoading) {
      return (
        <ButtonLink underline="none">
          {t('ui.fileUploadField.uploading')}
        </ButtonLink>
      );
    }

    const isFileAttached =
      value instanceof File || (typeof value === 'string' && value.length > 0);

    if (isFileAttached) {
      return (
        <ButtonLink underlineType="dotted" onClick={onDeleteButtonClick}>
          {t('ui.common.delete')}
        </ButtonLink>
      );
    }

    return (
      <ButtonLink underlineType="dotted" onClick={onUploadButtonClick}>
        {title ?? t('ui.fileUploadField.upload')}
      </ButtonLink>
    );
  };

  const getShowFileExtension = (value: string | File) => {
    const isFileAttached =
      value instanceof File || (typeof value === 'string' && value.length > 0);

    if (isFileAttached) {
      return !hideFileExtensionsForUploaded;
    }
    return showFileExtensions;
  };

  return (
    <Controller
      name={name || ''}
      control={form.control}
      render={({ field: { ref, ...rest }, fieldState: { error } }) => (
        <FormControl variant="filled" fullWidth error={Boolean(error)}>
          <Card
            sx={{
              ...(error ? { borderColor: PaletteColor.Error } : {}),
              ...sx,
            }}
          >
            <CardContent
              sx={{
                padding: 6,
                position: 'relative',
                ':last-child': {
                  paddingBottom: 6,
                },
              }}
              onDragEnter={handleDragEnter}
            >
              {isDragActive && (
                <Box
                  onDragEnter={handleDragEnter}
                  onDragOver={handleDragEnter}
                  onDragLeave={handleDragEnter}
                  onDrop={handleDrop(rest.onChange)}
                  sx={{
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    background: BgColor.LightGray,
                  }}
                >
                  {t('ui.common.dropFile')}
                </Box>
              )}
              <Row flexWrap={'nowrap'} flexGrow={1} spacing={4}>
                {renderIcon(rest.value)}

                <Col spacing={2}>
                  {!hideTitle && renderTitle(rest.value)}

                  <input
                    ref={fileInputRef}
                    type="file"
                    style={{ display: 'none' }}
                    accept={getAccept()}
                    onChange={onFileChange(rest.onChange)}
                    multiple={multiple}
                  />

                  {(fileNames || getShowFileExtension(rest.value)) && (
                    <Typography variant="caption" color={TextColor.Tertiary}>
                      {(fileNames && fileNames.join(' ')) ||
                        getFileExtensions()}
                    </Typography>
                  )}

                  <Typography variant="paragraphS" color={TextColor.Secondary}>
                    {description}
                  </Typography>
                </Col>
              </Row>
            </CardContent>
          </Card>
          {error && error.message && (
            <FormHelperText>{t(error.message)}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};
