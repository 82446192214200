import { $rtkApi, ApiTag } from 'shared/api/rtkApi';
import { ListData, ResponseData } from 'shared/types';
import { mapDtoFromNewsPost, mapNewsPostFromDto } from '../../lib/mapNewsPost';
import { NewsPost, NewsPostDto } from '../types/newsPostSchema';

export const newsPostApi = $rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    createNewsPost: builder.mutation<
      NewsPost,
      { objectId?: string | number; newsPost: NewsPost }
    >({
      query: ({ newsPost }) => ({
        url: '/news',
        method: 'POST',
        body: mapDtoFromNewsPost(newsPost),
      }),
      transformResponse: (response: ResponseData<NewsPostDto>) =>
        mapNewsPostFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, { objectId }) => [
        { type: ApiTag.newsPost },
        { type: ApiTag.object, id: objectId },
      ],
    }),

    updateNewsPost: builder.mutation<
      NewsPost,
      { objectId?: string | number; newsPost: NewsPost }
    >({
      query: ({ newsPost }) => ({
        url: `/news/${newsPost.id}`,
        method: 'PUT',
        body: mapDtoFromNewsPost(newsPost),
      }),
      transformResponse: (response: ResponseData<NewsPostDto>) =>
        mapNewsPostFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, { objectId, newsPost }) => [
        { type: ApiTag.newsPost },
        { type: ApiTag.newsPost, id: newsPost.id },
        { type: ApiTag.object, id: objectId },
      ],
    }),

    getNewsPosts: builder.query<
      ListData<NewsPost>,
      { objectId: number; limit: number; offset: number }
    >({
      query: (params) => ({
        url: '/news',
        method: 'GET',
        params: {
          building_id: params.objectId,
          limit: params.limit,
          offset: params.offset,
        },
      }),
      providesTags: (result, error, page) =>
        result
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTag.newsPost, id })),
              { type: ApiTag.newsPost },
            ]
          : [{ type: ApiTag.newsPost }],
      transformResponse: (response: ResponseData<ListData<NewsPostDto>>) => ({
        total: response.data.total,
        items: response.data.items.map((data) => mapNewsPostFromDto(data)),
      }),
    }),

    deleteNewsPost: builder.mutation<
      void,
      { objectId?: string | number; newsPostId: string | number }
    >({
      query: ({ newsPostId }) => ({
        url: `/news/${newsPostId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { objectId }) => [
        { type: ApiTag.newsPost },
        { type: ApiTag.object, id: objectId },
      ],
    }),
  }),
});

export const {
  useCreateNewsPostMutation,
  useUpdateNewsPostMutation,
  useGetNewsPostsQuery,
  useDeleteNewsPostMutation,
} = newsPostApi;
