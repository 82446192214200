import { createRoot } from 'react-dom/client';
import 'typeface-inter';
import { withProviders } from 'app/providers';
import { SnackbarProvider } from 'shared/hooks/useSnackbar';
import { App } from './app/App';
import { reportWebVitals } from './reportWebVitals';

const container = document.getElementById('root');

if (!container) {
  throw new Error("root container doesn't found");
}

const root = createRoot(container);

function StrictModeApp() {
  // TODO: NEED <React.StrictMode> HERE!
  return (
    <SnackbarProvider>
      <App />
    </SnackbarProvider>
  );
}

const WithProvidersApp = withProviders(StrictModeApp);

root.render(WithProvidersApp());

reportWebVitals();
