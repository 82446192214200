import { FC } from 'react';
import { TextColor } from 'shared/theme/colors';
import { Spinner } from './Spinner';

interface ButtonLoadingSpinnerProps {
  color?: string;
}

export const ButtonLoadingSpinner: FC<ButtonLoadingSpinnerProps> = ({
  color = TextColor.StaticWhite,
}) => {
  return (
    <Spinner
      color={color}
      size={20}
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-10px',
        marginLeft: '-10px',
      }}
    />
  );
};
