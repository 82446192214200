import { FC, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SxProps,
  Typography,
} from '@mui/material';
import { PaletteColor, TextColor } from 'shared/theme/colors';
import { SelectOption } from 'shared/types';
import { SearchInput } from './SearchInput';

type SelectWithSearchProps = {
  label: string;
  name: string;
  searchPlaceholder?: string;
  options: SelectOption[];
  onChange?: (value: string | null) => void;
  onSearchChange: (value: string) => void;
  menuFooter?: ReactNode;
  sx?: SxProps;
};

export const SelectWithSearch: FC<SelectWithSearchProps> = ({
  label,
  searchPlaceholder = 'ui.common.search',
  options,
  name,
  onChange,
  onSearchChange,
  menuFooter,
  sx,
}) => {
  const { t } = useTranslation();
  const form = useFormContext();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <Controller
      name={name || ''}
      control={form.control}
      defaultValue={form.formState.defaultValues?.[name] ?? ''}
      render={({ field: { ref, ...rest }, fieldState: { error } }) => (
        <FormControl variant="filled" fullWidth sx={sx}>
          <InputLabel>{label}</InputLabel>
          <Select
            onMouseDown={handleClick}
            MenuProps={{
              autoFocus: false,
              disableAutoFocusItem: true,
              disableRestoreFocus: true,
            }}
            {...rest}
            displayEmpty={false}
            onChange={(e) => {
              onChange?.(e.target.value);
              rest.onChange(e.target.value);
            }}
            renderValue={(value) => {
              const selected = options.find((option) => option.value === value);
              return selected ? selected.label : '';
            }}
            sx={{
              border: error ? `1px solid ${PaletteColor.Error}` : 'none',
            }}
          >
            <ListSubheader sx={{ display: 'flex', alignItems: 'center', p: 5 }}>
              <SearchInput
                searchPlaceholder={searchPlaceholder}
                onSearchChange={onSearchChange}
                name={name + '_search'}
                sx={{ width: '100%' }}
              />
            </ListSubheader>
            {options
              .filter((item) => !item.hidden)
              .map((option, index) => (
                <MenuItem
                  key={index}
                  value={option.value}
                  onClick={() => rest.onChange('')}
                  sx={{ justifyContent: 'space-between' }}
                >
                  {option.label}
                  {option.sublabel && (
                    <Typography
                      sx={{
                        color: TextColor.Secondary,
                      }}
                    >
                      {t(option.sublabel)}
                    </Typography>
                  )}
                </MenuItem>
              ))}
            {menuFooter}
          </Select>
          {error && error.message && (
            <FormHelperText sx={{ color: PaletteColor.Error }}>
              {t(error.message)}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};
