import { z } from 'zod';

export type ContractorDto = {
  id: number;
  name: string;
  inn: string | null;
};

export const contractorZod = z.object({
  id: z.number(),
  contractorName: z.string().min(1, 'ui.common.error.enterName').max(30),
  inn: z
    .string()
    .min(10, { message: 'ui.common.error.innLength' })
    .max(12, { message: 'ui.common.error.innLength' }),
});

export const addContractorZod = contractorZod.pick({
  contractorName: true,
  inn: true,
});

export type AddContractor = z.infer<typeof addContractorZod>;
export type Contractor = z.infer<typeof contractorZod>;
