import { FC } from 'react';
import i18n from 'i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import { NumberFormatValues } from 'react-number-format/types/types';
import { FormHelperText, TextField } from '@mui/material';
import { appendAsterisk } from 'shared/helpers/appendAsterisk';
import { PaletteColor } from 'shared/theme/colors';

type NumericPatternFieldProps = {
  label?: string;
  name: string;
  required?: boolean;
  format: string;
  mask?: string;
  maxLength: number;
  readOnly?: boolean;
};

export const NumericPatternField: FC<NumericPatternFieldProps> = ({
  label,
  name,
  required,
  format,
  mask = 'x',
  maxLength,
  readOnly,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { ref, onChange, onBlur, ...rest },
        fieldState: { error },
      }) => (
        <>
          <PatternFormat
            {...rest}
            customInput={TextField}
            variant={'filled'}
            fullWidth
            label={appendAsterisk(label, required, !!error)}
            error={Boolean(error)}
            onValueChange={(values: NumberFormatValues) => {
              onChange(values.value);
            }}
            inputProps={{
              readOnly: readOnly,
              style: {
                borderRadius: '8px',
                border: error ? `1px solid ${PaletteColor.Error}` : 'none',
              },
              onBlur: () => {
                if ((rest.value as string)?.length < maxLength) {
                  onChange('');
                }
                onBlur();
              },
            }}
            format={format}
            mask={mask}
          />
          {error && error.message && (
            <FormHelperText sx={{ color: PaletteColor.Error }}>
              {i18n.t(error.message)}
            </FormHelperText>
          )}
        </>
      )}
    />
  );
};
