import { FC } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  EditSalesOfficeForm,
  SalesOffice,
  salesOfficeZod,
} from 'entities/salesOffice';
import { getFormErrorsArray } from 'shared/helpers/getFormErrorsArray';
import { useSnackbar } from 'shared/hooks/useSnackbar';
import { CardLayout } from 'shared/ui/CardLayout';

type AddSalesOfficeCardProps = {
  salesOffice?: SalesOffice;
  onSaved: (createdSalesOffice: SalesOffice) => void;
  onCancel: () => void;
  isSalesOfficeCreating?: boolean;
};

export const AddSalesOfficeCard: FC<AddSalesOfficeCardProps> = ({
  salesOffice,
  onSaved,
  onCancel,
  isSalesOfficeCreating,
}) => {
  const { t } = useTranslation();
  const form = useForm<SalesOffice>({
    resolver: zodResolver(salesOfficeZod),
    mode: 'onSubmit',
    defaultValues: {
      id: salesOffice?.id || Date.now(),
      phone: salesOffice?.phone || '',
      email: salesOffice?.email || '',
      address: salesOffice?.address || '',
      schedules: salesOffice?.schedules || [
        { workingDays: '', workingHours: '' },
      ],
      employees: salesOffice?.employees
        ? salesOffice.employees
            .toSorted((a, b) => a.order - b.order)
            .map((employee, index) => ({
              employeeId: employee.employeeId,
              position: employee.position,
              order: index + 1,
              firstName: employee.firstName,
              lastName: employee.lastName,
              phone: employee.phone,
            }))
        : [{ employeeId: -1, position: '', order: 1 }],
    },
  });

  const { showSnackbar } = useSnackbar();

  const handleSaveClick = async (salesOfficeData: SalesOffice) => {
    const preparedSalesOffice = {
      ...salesOffice,
      ...salesOfficeData,
      employees: salesOfficeData.employees.map((employee, index) => ({
        ...employee,
        order: index + 1,
      })),
    };
    try {
      onSaved(preparedSalesOffice);
    } catch (error) {
      console.error(error);
      showSnackbar();
    }
  };

  return (
    <CardLayout
      variant="secondary"
      footer={{
        onOkClick: form.handleSubmit(handleSaveClick),
        okLabel: t('ui.common.save'),
        cancelLabel: t('ui.common.cancel'),
        onCancelClick: onCancel,
        okButtonLoading: isSalesOfficeCreating,
      }}
      errors={getFormErrorsArray(form.formState.errors, ['employees'])}
    >
      <FormProvider {...form}>
        <EditSalesOfficeForm salesOffice={salesOffice} />
      </FormProvider>
    </CardLayout>
  );
};
