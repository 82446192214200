import { FC, MouseEvent, ReactNode } from 'react';
import { Link as MuiLink, LinkProps, styled } from '@mui/material';
import { TextColor } from 'shared/theme/colors';
import { fontInter } from 'shared/theme/typography';

type UnderlineType = 'dotted' | 'solid';

type ButtonLinkProps = {
  underlineType?: UnderlineType;
  children?: ReactNode;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
} & LinkProps;

const StyledLink = styled(MuiLink, {
  shouldForwardProp: (prop) => prop !== 'underlineType',
  name: 'StyledLink',
})<ButtonLinkProps>(({ underlineType = 'solid' }) => ({
  color: 'inherit',
  fontFamily: fontInter,
  fontWeight: 500,
  fontSize: '14px',
  cursor: 'pointer',
  textDecorationColor: TextColor.Quaternary,
  lineHeight: '16.94px',
  textDecoration:
    underlineType === 'dotted'
      ? `1.5px underline dotted ${TextColor.Quaternary}`
      : `underline ${TextColor.Quaternary}`,
  textUnderlineOffset: '3px',
  ':hover': {
    textDecoration:
      underlineType === 'dotted'
        ? `1.5px underline dotted ${TextColor.Quaternary}`
        : `underline ${TextColor.Quaternary}`,
    textUnderlineOffset: '3.8px',
  },
}));

export const ButtonLink: FC<ButtonLinkProps> = ({
  underlineType,
  children,
  onClick,
  ...props
}) => (
  <StyledLink underlineType={underlineType} onClick={onClick} {...props}>
    {children}
  </StyledLink>
);
