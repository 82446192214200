import { z } from 'zod';
import { emailValidator } from 'shared/helpers/emailValidator';
import { phoneValidator } from 'shared/helpers/phoneValidator';
import { timeRangeValidator } from 'shared/helpers/timeRangeValidator';

type BaseSalesOfficeDto = {
  id: number;
  phone: number;
  email: string | null;
  address: string;
  sales_office_schedules: {
    schedule: string;
    time_from: string | null;
    time_to: string | null;
  }[];
};

type SalesOfficeEmployeeDto = {
  users: {
    id: number;
    first_name: string | null;
    last_name: string | null;
    email: string | null;
    phone: string | null;
    order: number;
    position: string;
  }[];
};

type SaveSalesOfficeEmployeeDto = {
  users:
    | {
        id: number;
        order: number;
        position: string;
      }[]
    | [];
};

export type SalesOfficeDto = BaseSalesOfficeDto & SalesOfficeEmployeeDto;
export type SaveSalesOfficeDto = Omit<BaseSalesOfficeDto, 'id'> &
  SaveSalesOfficeEmployeeDto;

export const salesOfficeScheduleZod = z.object({
  workingDays: z.string().min(1, 'salesOffice.form.error.workingDays'),
  workingHours: timeRangeValidator.required,
});

export type SalesOfficeSchedule = z.infer<typeof salesOfficeScheduleZod>;

export const salesOfficeZod = z.object({
  id: z.number(),
  phone: phoneValidator.required,
  email: emailValidator.optional,
  address: z.string().min(1, 'salesOffice.form.error.address'),
  schedules: z.array(salesOfficeScheduleZod),
  employees: z
    .array(
      z.object({
        employeeId: z.number().min(1, 'salesOffice.form.error.selectEmployee'),
        position: z.string(),
        order: z.number(),
        email: z.string().optional(),
        phone: z.string().optional(),
        firstName: z.string().optional(),
        lastName: z.string().optional(),
      })
    )
    .min(1, 'salesOffice.form.error.addEmployee'),
  isEdited: z.boolean().optional(),
  isDeleted: z.boolean().optional(),
});

export type SalesOffice = z.infer<typeof salesOfficeZod>;
