export enum PaletteColor {
  'Primary' = '#2B313B',
  'Secondary' = '#0D69F2',
  'Warning' = '#D08815',
  'Error' = '#FF5959',
  'Success' = '#19AA69',
}

export enum TextColor {
  'Primary' = '#2B313B',
  'Secondary' = '#626F84',
  'Tertiary' = '#A7B0BE',
  'Quaternary' = '#C3C9D5',
  'StaticWhite' = '#FFFFFF',
}

export enum AccentColor {
  'Positive' = '#19AA69',
  'Attention' = '#D08815',
  'Negative' = '#DF2514',
  'Process' = '#0C64E6',
  'Temporary' = '#616F84',
}

export enum BgColor {
  'Input' = '#F4F4F4',
  'Gray' = '#E7E9EB',
  'LightGray' = '#F8F8F8',
}

export enum BorderColor {
  'Gray' = 'rgba(134, 134, 134, 0.3)',
  'Scrollbar' = 'rgba(134, 134, 134, 0.6)',
}
