export const getMergedCellBounds = (
  positions: { floor: number; riser: number }[],
  floor: number,
  riser: number
) => {
  const result = {
    mergedToLeft: false,
    mergedToRight: false,
    mergedToTop: false,
    mergedToBottom: false,
  };

  const isMerged = positions.length > 1;

  if (isMerged) {
    result.mergedToTop = isMergedSide(positions, floor, riser, 'up');
    result.mergedToBottom = isMergedSide(positions, floor, riser, 'down');
    result.mergedToLeft = isMergedSide(positions, floor, riser, 'left');
    result.mergedToRight = isMergedSide(positions, floor, riser, 'right');
  }
  return result;
};

type Side = 'left' | 'right' | 'up' | 'down';

const isMergedSide = (
  positions: { floor: number; riser: number }[],
  floor: number,
  riser: number,
  side: Side
) => {
  let findRiser = riser;
  let findFloor = floor;

  switch (side) {
    case 'left':
      findRiser = riser - 1;
      break;
    case 'right':
      findRiser = riser + 1;
      break;
    case 'up':
      findFloor = floor + 1;
      break;
    case 'down':
      findFloor = floor - 1;
      break;
  }

  return (
    positions.findIndex(
      (pos: { floor: number; riser: number }) =>
        pos.floor === findFloor && pos.riser === findRiser
    ) > -1
  );
};
