export const workingDaysOptions: { value: string; label: string }[] = [
  {
    value: '1',
    label: 'ui.workScheduleInput.mon',
  },
  {
    value: '2',
    label: 'ui.workScheduleInput.tue',
  },
  {
    value: '3',
    label: 'ui.workScheduleInput.wed',
  },
  {
    value: '4',
    label: 'ui.workScheduleInput.thu',
  },
  {
    value: '5',
    label: 'ui.workScheduleInput.fri',
  },
  {
    value: '6',
    label: 'ui.workScheduleInput.sat',
  },
  {
    value: '7',
    label: 'ui.workScheduleInput.sun',
  },
];
