import { SelectOption } from 'shared/types';

export const statusOptions: SelectOption[] = [
  {
    value: 'reservStatus',
    label: 'chess.selectOptions.status.reservStatus',
  },
  {
    value: 'assignmentReservation',
    label: 'chess.selectOptions.status.assignmentReservation',
  },
  {
    value: 'saleStatus',
    label: 'chess.selectOptions.status.saleStatus',
  },
  {
    value: 'assignmentStatus',
    label: 'chess.selectOptions.status.assignmentStatus',
  },
  {
    value: 'soldStatus',
    label: 'chess.selectOptions.status.soldStatus',
  },
  {
    value: 'fundStatus',
    label: 'chess.selectOptions.status.fundStatus',
  },
];

export const numberOfRoomsOptions: SelectOption[] = [
  {
    value: 'oneAp',
    label: 'chess.selectOptions.numberOfRooms.oneAp',
  },
  {
    value: 'twoAp',
    label: 'chess.selectOptions.numberOfRooms.twoAp',
  },
  {
    value: 'threeAp',
    label: 'chess.selectOptions.numberOfRooms.threeAp',
  },
  {
    value: 'allAp',
    label: 'chess.selectOptions.numberOfRooms.allAp',
  },
  {
    value: 'penthaus',
    label: 'chess.selectOptions.numberOfRooms.penthaus',
  },
  {
    value: 'studioAp',
    label: 'chess.selectOptions.numberOfRooms.studioAp',
  },
];

export const typeOptions: SelectOption[] = [
  {
    value: 'apartRoom',
    label: 'chess.selectOptions.type.apartRoom',
  },
  {
    value: 'hotelRoom',
    label: 'chess.selectOptions.type.hotelRoom',
  },
  {
    value: 'partFlat',
    label: 'chess.selectOptions.type.partFlat',
  },
  {
    value: 'zhiloeRoom',
    label: 'chess.selectOptions.type.zhiloeRoom',
  },
  {
    value: 'zhiloeHouse',
    label: 'chess.selectOptions.type.zhiloeHouse',
  },
  {
    value: 'apartmentRoom',
    label: 'chess.selectOptions.type.apartmentRoom',
  },
  {
    value: 'storageRoom',
    label: 'chess.selectOptions.type.storageRoom',
  },
  {
    value: 'commercialPremises',
    label: 'chess.selectOptions.type.commercialPremises',
  },
  {
    value: 'nezhiloeRoom',
    label: 'chess.selectOptions.type.nezhiloeRoom',
  },
  {
    value: 'parking',
    label: 'chess.selectOptions.type.parking',
  },
];

export const decorationOptions: SelectOption[] = [
  {
    value: 'predChistInt',
    label: 'chess.selectOptions.decoration.predChistInt',
  },
  {
    value: 'remInt',
    label: 'chess.selectOptions.decoration.remInt',
  },
  {
    value: 'repair',
    label: 'chess.selectOptions.decoration.repair',
  },
  {
    value: 'chastRemInt',
    label: 'chess.selectOptions.decoration.chastRemInt',
  },
  {
    value: 'chernInt',
    label: 'chess.selectOptions.decoration.chernInt',
  },
  {
    value: 'chistInt',
    label: 'chess.selectOptions.decoration.chistInt',
  },
  {
    value: 'whiteBox',
    label: 'chess.selectOptions.decoration.whiteBox',
  },
];

export const windowViewOptions: SelectOption[] = [
  {
    value: 'intoYard',
    label: 'chess.selectOptions.windowView.intoYard',
  },
  {
    value: 'toMountains',
    label: 'chess.selectOptions.windowView.toMountains',
  },
  {
    value: 'onSea',
    label: 'chess.selectOptions.windowView.onSea',
  },
  {
    value: 'toPark',
    label: 'chess.selectOptions.windowView.toPark',
  },
  {
    value: 'toGarden',
    label: 'chess.selectOptions.windowView.toGarden',
  },
  {
    value: 'toForest',
    label: 'chess.selectOptions.windowView.toForest',
  },
  {
    value: 'onSocialObjects',
    label: 'chess.selectOptions.windowView.onSocialObjects',
  },
  {
    value: 'panoramicView',
    label: 'chess.selectOptions.windowView.panoramicView',
  },
];

export const panoramaOptions: SelectOption[] = [
  {
    value: '1',
    label: 'ui.common.yes',
  },
  {
    value: '0',
    label: 'ui.common.no',
  },
];

export const registrationOptions: SelectOption[] = [
  {
    value: '5',
    label: 'chess.selectOptions.registration.bookingAgreement',
  },
  {
    value: '14',
    label: 'chess.selectOptions.registration.participationAgreement',
  },
  {
    value: '12',
    label: 'chess.selectOptions.registration.depositAgreement',
  },
  {
    value: '6',
    label: 'chess.selectOptions.registration.loanAgreement',
  },
  {
    value: '3',
    label: 'chess.selectOptions.registration.investmentAgreement',
  },
  {
    value: '9',
    label: 'chess.selectOptions.registration.contractOfSale',
  },
  {
    value: '11',
    label: 'chess.selectOptions.registration.agreementOfIntent',
  },
  {
    value: '10',
    label: 'chess.selectOptions.registration.accumulationAgreement',
  },
  {
    value: '8',
    label: 'chess.selectOptions.registration.assignmentAgreement',
  },
  {
    value: '13',
    label: 'chess.selectOptions.registration.workAgreement',
  },
  {
    value: '7',
    label: 'chess.selectOptions.registration.simplePartnershipAgreement',
  },
  {
    value: '4',
    label: 'chess.selectOptions.registration.shareContribution',
  },
  {
    value: '2',
    label: 'chess.selectOptions.registration.preliminaryAgreement',
  },
  {
    value: '1',
    label: 'chess.selectOptions.registration.justice',
  },
];

export const purchaseTypeOptions: SelectOption[] = [
  {
    value: 'taxes',
    label: 'chess.selectOptions.purchaseType.refundNDS',
  },
  {
    value: 'military_ipoteka',
    label: 'chess.selectOptions.purchaseType.militaryMortgage',
  },
  {
    value: 'ipoteka',
    label: 'chess.selectOptions.purchaseType.mortgage',
  },
  {
    value: 'is_ipoteka_it',
    label: 'chess.selectOptions.purchaseType.ipoteka_it',
  },
  {
    value: 'is_ipoteka_preferential',
    label: 'chess.selectOptions.purchaseType.ipoteka_preferential',
  },
  {
    value: 'is_ipoteka_state_support',
    label: 'chess.selectOptions.purchaseType.ipoteka_state_support',
  },
  {
    value: 'is_ipoteka_family',
    label: 'chess.selectOptions.purchaseType.ipoteka_family',
  },
  {
    value: 'is_ipoteka_subsidized',
    label: 'chess.selectOptions.purchaseType.ipoteka_subsidized',
  },
  {
    value: 'is_ipoteka_tranche',
    label: 'chess.selectOptions.purchaseType.ipoteka_tranche',
  },
  {
    value: 'mat_capital',
    label: 'chess.selectOptions.purchaseType.maternalCapital',
  },
  {
    value: 'cash',
    label: 'chess.selectOptions.purchaseType.cash',
  },
  {
    value: 'installments',
    label: 'chess.selectOptions.purchaseType.installmentPlan',
  },
];

export const commissionOPOptions: SelectOption[] = [
  {
    value: '1',
    label: 'ui.common.yes',
  },
  {
    value: '2',
    label: 'ui.common.no',
  },
];

export const promotionOptions: SelectOption[] = [
  {
    value: '1',
    label: 'ui.common.yes',
  },
  {
    value: '0',
    label: 'ui.common.no',
  },
];
