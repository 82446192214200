import i18n from 'i18next';
import { SelectOption } from 'shared/types';

export const userTariffOptions: SelectOption[] = [
  { value: 10, label: i18n.t('ui.common.countDays', { count: 3 }) },
  { value: 12, label: i18n.t('ui.common.countDays', { count: 7 }) },
  { value: 2, label: i18n.t('ui.common.countDays', { count: 14 }) },
  { value: 1, label: i18n.t('ui.common.countDays', { count: 30 }) },
  { value: 35, label: i18n.t('ui.common.countDays', { count: 60 }) },
  { value: 37, label: i18n.t('ui.common.countDays', { count: 90 }) },
];
