import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { searchActions } from 'entities/search';
import { getRoute } from 'shared/const';
import { useAppDispatch } from 'shared/hooks/hooks';
import { NedvexLogoIcon } from 'shared/ui/Icons';

export const HeaderLogo: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleGoToMain = () => {
    dispatch(searchActions.updateSearchText(''));
    navigate(getRoute.Main());
  };

  return (
    <Box
      onClick={() => handleGoToMain()}
      sx={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <NedvexLogoIcon />
    </Box>
  );
};
