import type { ReactNode } from 'react';
import React, { useMemo, useState } from 'react';
import type { Active, DragEndEvent, UniqueIdentifier } from '@dnd-kit/core';
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { DragHandle, SortableItem } from './SortableItem';
import { SortableOverlay } from './SortableOverlay';

interface BaseItem {
  id: UniqueIdentifier;
}

interface Props<T extends BaseItem> {
  items: T[];
  onChange?: (items: T[]) => void;
  onMove?: (activeIndex: number, overIndex: number) => void;
  renderItem: (item: T, index: number) => ReactNode;
}

export function VerticalSortableList<T extends BaseItem>({
  items,
  onChange,
  onMove,
  renderItem,
}: Props<T>) {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const activeItem = useMemo(
    () => (activeIndex !== null ? items[activeIndex] : null),
    [activeIndex, items]
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = ({ active }: { active: Active }) => {
    const index = items.findIndex((item) => item.id === active.id);
    setActiveIndex(index);
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over.id) {
      const activeIdx = items.findIndex((item) => item.id === active.id);
      const overIdx = items.findIndex((item) => item.id === over.id);

      if (onMove) {
        onMove(activeIdx, overIdx);
      }

      if (onChange) {
        onChange(arrayMove(items, activeIdx, overIdx));
      }
    }
    setActiveIndex(null);
  };

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={() => {
        setActiveIndex(null);
      }}
    >
      <SortableContext items={items.map((item) => item.id)}>
        {items.map((item, index) => (
          <React.Fragment key={item.id}>
            {renderItem(item, index)}
          </React.Fragment>
        ))}
      </SortableContext>
      <SortableOverlay>
        {activeItem ? renderItem(activeItem, activeIndex!) : null}
      </SortableOverlay>
    </DndContext>
  );
}

VerticalSortableList.Item = SortableItem;
VerticalSortableList.DragHandle = DragHandle;
