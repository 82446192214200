import { FC } from 'react';
import i18n from 'i18next';
import { Button, Card, CardProps, Modal, Typography } from '@mui/material';
import { Col } from './Col';
import { Row } from './Row';

interface RemovalConfirmationCardProps extends CardProps {
  titleEnding: string;
  onRemoveClick?: () => void;
  onCancelClick?: () => void;
  isModal?: boolean;
}

export const RemovalConfirmationCard: FC<RemovalConfirmationCardProps> = ({
  titleEnding,
  onCancelClick,
  onRemoveClick,
  isModal,
  sx,
}) => {
  const cardContent = (
    <Col spacing={8}>
      <Typography variant="headerXS">
        {i18n.t('ui.removalConfirmationCard.message')} {titleEnding}?
      </Typography>
      <Row spacing={5}>
        <Button size="small" onClick={onRemoveClick}>
          {i18n.t('ui.common.delete')}
        </Button>
        <Button size="small" variant="outlined" onClick={onCancelClick}>
          {i18n.t('ui.common.cancel')}
        </Button>
      </Row>
    </Col>
  );

  return isModal ? (
    <Modal
      open={true}
      onClose={onCancelClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        sx={{
          p: 8,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          ...sx,
        }}
      >
        {cardContent}
      </Card>
    </Modal>
  ) : (
    <Card sx={{ p: 8, ...sx }}>{cardContent}</Card>
  );
};
