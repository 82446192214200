import {
  CreatePolygon,
  CreatePolygonDto,
  Polygon,
  PolygonDto,
  UpdatePolygon,
  UpdatePolygonDto,
} from '../model/types/planSchema';

export const mapPolygonFromDto = ({
  id,
  plan_id,
  data,
  chess,
}: PolygonDto): Polygon => ({
  id,
  planId: plan_id,
  chess,
  data,
});

export const mapDtoFromPolygon = ({
  id,
  chess,
  planId,
  data,
}: Polygon): PolygonDto => ({
  id,
  plan_id: planId,
  chess,
  data,
});

export const mapDtoFromCreatePolygon = ({
  planId,
  data,
}: CreatePolygon): CreatePolygonDto => ({
  plan_id: planId,
  data,
  lot_ids: [],
});
export const mapDtoFromUpdatePolygon = ({
  id,
  planId,
  data,
  lotIds,
}: UpdatePolygon): UpdatePolygonDto => ({
  id,
  plan_id: planId,
  data,
  lot_ids: lotIds,
});
