import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { User, userApi } from 'entities/user';
import { getRoute } from 'shared/const';
import { getFormattedPhone } from 'shared/helpers/getFormattedPhone';
import { useDebounce } from 'shared/hooks/useDebounce';
import { useScrollPaginator } from 'shared/hooks/useScrollPaginator';
import { TextColor } from 'shared/theme/colors';
import { Col } from 'shared/ui/Col';
import { NoUsersIcon } from 'shared/ui/Icons';
import {
  PreviewCardsGrid,
  PreviewInfoCardProps,
} from 'shared/ui/PreviewCardsGrid';
import { Row } from 'shared/ui/Row';
import { SearchInput } from 'shared/ui/SearchInput';

export const UsersTab: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const form = useForm<{
    search: string;
  }>({
    defaultValues: {
      search: '',
    },
  });

  const cleanPhoneNumber = (searchText: string): string => {
    const phoneNumberPattern = /^[+\-()0-9\s]+$/;
    if (phoneNumberPattern.test(searchText)) {
      return searchText.replace(/[+\-()\s]/g, '');
    }
    return searchText;
  };

  const params = form.watch();
  const debouncedSearchText = useDebounce(params.search);
  const debouncedCleanedSearchText = useMemo(
    () => cleanPhoneNumber(debouncedSearchText),
    [debouncedSearchText]
  );

  const { items: users, isAllItemsLoaded } = useScrollPaginator(
    userApi.useGetUsersQuery,
    { search: debouncedCleanedSearchText },
    { headerHeight: 400, previewCardHeight: 110, amountPerRow: 3 }
  );

  const handleAddUserClick = () => {
    navigate(getRoute.CreateUser());
  };

  const handleUserClick = useCallback(
    (user: User) => () => {
      navigate(getRoute.UserDetails(user.id));
    },
    [navigate]
  );

  const formattedDataForPreviewCards: PreviewInfoCardProps[] = useMemo(() => {
    return users.map((user) => ({
      ...user,
      id: user.id,
      title: `${user.firstName}${user.lastName ? ' ' + user.lastName : ''}`,
      subtitle: user.position,
      text: getFormattedPhone(user.phone),
      onLinkClick: handleUserClick(user),
    }));
  }, [handleUserClick, users]);

  return (
    <Col spacing={8}>
      <Row
        sx={{
          justifyContent: 'space-between',
          flexDirection: { xs: 'column-reverse', sm: 'row' },
          gap: 6,
        }}
      >
        <Button
          variant={'contained'}
          color={'primary'}
          size={'small'}
          onClick={handleAddUserClick}
          sx={{ width: '100%' }}
        >
          {t('user.tab.button.addUser')}
        </Button>
        <FormProvider {...form}>
          <SearchInput
            name={'search'}
            searchPlaceholder={t('user.tab.input.search')}
            sx={{ width: { xs: '100%', sm: 280 } }}
          />
        </FormProvider>
      </Row>

      {isAllItemsLoaded && !users.length ? (
        <Col
          spacing={9}
          sx={{ width: '252px', mt: '175px', mx: 'auto', alignItems: 'center' }}
        >
          <Col spacing={3} sx={{ alignItems: 'center' }}>
            <NoUsersIcon sx={{ fontSize: 40 }} />
            <Typography
              sx={{ textAlign: 'center' }}
              variant="accentS"
              color={TextColor.Secondary}
            >
              {t('user.tab.noUserTitle')}
            </Typography>
          </Col>
        </Col>
      ) : (
        <PreviewCardsGrid
          data={formattedDataForPreviewCards}
          isShowSkeleton={!isAllItemsLoaded}
        />
      )}
    </Col>
  );
};
