import { SelectOption } from 'shared/types';

export const MAX_GENERAL_CONTRACTORS = 6;
export const MAX_SALES_OFFICES = 2;

export const objectTypeOptions: SelectOption[] = [
  {
    value: '1',
    label: 'object.selectOptions.objectType.residentialComplex',
  },
  {
    value: '2',
    label: 'object.selectOptions.objectType.cottageCommunityOrTownhouse',
  },
];

export const houseTypeOptions: Record<string, SelectOption[]> = {
  1: [
    // {
    //   value: '1',
    //   label: 'Апарт комплекс',
    // },
    // {
    //   value: '2',
    //   label: 'Гостиничный комплекс',
    // },
    // {
    //   value: '3',
    //   label: 'Жилой комплекс',
    // },
    {
      value: '2',
      label: 'object.selectOptions.houseType.club',
    },
    {
      value: '1',
      label: 'object.selectOptions.houseType.apartment',
    },
  ],
  // 2: [
  //   {
  //     value: '1',
  //     label: 'Коттедж',
  //   },
  //   {
  //     value: '2',
  //     label: 'Коттедж и таунхаус',
  //   },
  //   {
  //     value: '3',
  //     label: 'Таунхаус',
  //   },
  // ],
};

export const saleStatusOptions: SelectOption[] = [
  {
    value: '1',
    label: 'object.selectOptions.saleStatus.activeSales',
  },
  {
    value: '2',
    label: 'object.selectOptions.saleStatus.salesStopped',
  },
  {
    value: '3',
    label: 'object.selectOptions.saleStatus.partiallyCompleted',
  },
];

export const federalLowOptions: SelectOption[] = [
  {
    value: 'ФЗ-214',
    label: 'object.selectOptions.federalLow.fz214',
  },
  {
    value: 'ФЗ-215',
    label: 'object.selectOptions.federalLow.fz215',
  },
];

export const escrowOptions: SelectOption[] = [
  {
    value: '1',
    label: 'ui.common.yes',
  },
  {
    value: '2',
    label: 'ui.common.no',
  },
];

export const houseClassOptions: SelectOption[] = [
  {
    value: '3',
    label: 'object.selectOptions.houseClass.business',
  },
  {
    value: '2',
    label: 'object.selectOptions.houseClass.comfort',
  },
  {
    value: '1',
    label: 'object.selectOptions.houseClass.economy',
  },
  {
    value: '4',
    label: 'object.selectOptions.houseClass.elite',
  },
];

export const materialOptions: SelectOption[] = [
  {
    value: '6',
    label: 'object.selectOptions.material.blocky',
  },
  {
    value: '7',
    label: 'object.selectOptions.material.wood',
  },
  {
    value: '4',
    label: 'object.selectOptions.material.brick',
  },
  {
    value: '8',
    label: 'object.selectOptions.material.metalCarcass',
  },
  {
    value: '3',
    label: 'object.selectOptions.material.monolithFrame',
  },
  {
    value: '2',
    label: 'object.selectOptions.material.monolithicBrick',
  },
  {
    value: '1',
    label: 'object.selectOptions.material.monolithic',
  },
  {
    value: '5',
    label: 'object.selectOptions.material.panel',
  },
];

export const territoryOptions: SelectOption[] = [
  {
    value: 'Закрытая',
    label: 'object.selectOptions.territory.close',
  },
  {
    value: 'Закрытая охраняемая',
    label: 'object.selectOptions.territory.security',
  },
  {
    value: 'Открытая',
    label: 'object.selectOptions.territory.open',
  },
];

export const parkingOptions: SelectOption[] = [
  {
    value: 'parking_type_roofed',
    label: 'object.selectOptions.parking.parking_type_roofed',
  },
  {
    value: 'parking_multilevel',
    label: 'object.selectOptions.parking.parking_multilevel',
  },
  {
    value: 'parking_underground',
    label: 'object.selectOptions.parking.parking_underground',
  },
  {
    value: 'house_parking',
    label: 'object.selectOptions.parking.house_parking',
  },
];

export const advantagesOptions: SelectOption[] = [
  {
    value: 'spa',
    label: 'object.selectOptions.advantages.spa',
  },
  {
    value: 'pool',
    label: 'object.selectOptions.advantages.pool',
  },
  {
    value: 'billiards',
    label: 'object.selectOptions.advantages.billiards',
  },
  {
    value: 'helicopter_landing_area',
    label: 'object.selectOptions.advantages.helicopter_landing_area',
  },
  {
    value: 'cctv_surveillance',
    label: 'object.selectOptions.advantages.cctv_surveillance',
  },
  {
    value: 'is_garage',
    label: 'object.selectOptions.advantages.is_garage',
  },
  {
    value: 'is_children_room',
    label: 'object.selectOptions.advantages.is_children_room',
  },
  {
    value: 'children_area',
    label: 'object.selectOptions.advantages.children_area',
  },
  {
    value: 'kindergarten',
    label: 'object.selectOptions.advantages.kindergarten',
  },
  {
    value: 'recreation_zone',
    label: 'object.selectOptions.advantages.recreation_zone',
  },
  {
    value: 'commercial_property',
    label: 'object.selectOptions.advantages.commercial_property',
  },
  {
    value: 'concierge',
    label: 'object.selectOptions.advantages.concierge',
  },
  {
    value: 'is_conference_hall',
    label: 'object.selectOptions.advantages.is_conference_hall',
  },
  {
    value: 'kpp',
    label: 'object.selectOptions.advantages.kpp',
  },
  {
    value: 'is_landscape_park',
    label: 'object.selectOptions.advantages.is_landscape_park',
  },
  {
    value: 'landscape_decore',
    label: 'object.selectOptions.advantages.landscape_decore',
  },
  {
    value: 'elevator',
    label: 'object.selectOptions.advantages.elevator',
  },
  {
    value: 'is_wellness_building',
    label: 'object.selectOptions.advantages.is_wellness_building',
  },
  {
    value: 'is_beach',
    label: 'object.selectOptions.advantages.is_beach',
  },
  {
    value: 'is_walking_area',
    label: 'object.selectOptions.advantages.is_walking_area',
  },
  {
    value: 'restaurant',
    label: 'object.selectOptions.advantages.restaurant',
  },
  {
    value: 'garden',
    label: 'object.selectOptions.advantages.garden',
  },
  {
    value: 'sauna',
    label: 'object.selectOptions.advantages.sauna',
  },
  {
    value: 'is_acs_system',
    label: 'object.selectOptions.advantages.is_asc_system',
  },
  {
    value: 'sport_area',
    label: 'object.selectOptions.advantages.sport_area',
  },
  {
    value: 'tennis_court',
    label: 'object.selectOptions.advantages.tennis_court',
  },
  {
    value: 'is_management_company',
    label: 'object.selectOptions.advantages.is_management_company',
  },
  {
    value: 'fitness',
    label: 'object.selectOptions.advantages.fitness',
  },
  {
    value: 'fountain',
    label: 'object.selectOptions.advantages.fountain',
  },
  {
    value: 'is_operated_roof',
    label: 'object.selectOptions.advantages.is_operated_roof',
  },
];

export const entrancesOptions: SelectOption[] = [
  {
    value: '1',
    label: 'object.selectOptions.entrances.asphaltRoad',
  },
  {
    value: '3',
    label: 'object.selectOptions.entrances.concreteRoad',
  },
  {
    value: '2',
    label: 'object.selectOptions.entrances.dirtRoad',
  },
];

export const electricityOptions: SelectOption[] = [
  {
    value: '1',
    label: 'object.selectOptions.electricity.available',
  },
  {
    value: '2',
    label: 'ui.common.no',
  },
];

export const waterSupplyOptions: SelectOption[] = [
  {
    value: '2',
    label: 'object.selectOptions.waterSupply.well',
  },
  {
    value: '1',
    label: 'object.selectOptions.waterSupply.central',
  },
];

export const heatingOptions: SelectOption[] = [
  {
    value: '3',
    label: 'object.selectOptions.heating.individualGasBoiler',
  },
  {
    value: '2',
    label: 'object.selectOptions.heating.boilerRoom',
  },
  {
    value: '7',
    label: 'object.selectOptions.heating.airConditioningSystem',
  },
  {
    value: '5',
    label: 'object.selectOptions.heating.solarPanels',
  },
  {
    value: '1',
    label: 'object.selectOptions.heating.central',
  },
  {
    value: '4',
    label: 'object.selectOptions.heating.electrical',
  },
  {
    value: '6',
    label: 'object.selectOptions.heating.electricBoiler',
  },
];

export const canalizationOptions: SelectOption[] = [
  {
    value: '3',
    label: 'object.selectOptions.canalization.sewageTreatmentPlant',
  },
  {
    value: '2',
    label: 'object.selectOptions.canalization.localTreatmentFacilities',
  },
  {
    value: '1',
    label: 'object.selectOptions.canalization.central',
  },
];

export const gasOptions: SelectOption[] = [
  {
    value: '1',
    label: 'object.selectOptions.gas.available',
  },
  {
    value: '2',
    label: 'ui.common.no',
  },
];
