import { redirect, RouteObject } from 'react-router-dom';
import { AgencyDetails, AgencyUsers } from 'features/agency';
import { AgencyUserDetailsCard, CreateAgencyUserCard } from 'features/user';
import { getRoute } from 'shared/const';

export const agencySubRoutes: RouteObject[] = [
  {
    index: true,
    loader: ({ params }) =>
      redirect(
        params.agencyId
          ? getRoute.AgencyDetails(params.agencyId)
          : getRoute.Agencies()
      ),
  },
  {
    path: getRoute.AgencyDetails(':agencyId'),
    element: <AgencyDetails />,
  },
  {
    path: getRoute.AgencyUsers(':agencyId'),
    element: <AgencyUsers />,
  },
  {
    path: getRoute.CreateAgencyUser(':agencyId'),
    element: <CreateAgencyUserCard />,
  },
  {
    path: getRoute.AgencyUserDetails(':agencyId', ':userId'),
    element: <AgencyUserDetailsCard />,
  },
];
