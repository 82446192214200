import { FC, ReactNode, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import {
  DeveloperObjectOutletData,
  EditDeveloperObject,
  houseTypeOptions,
  MAX_GENERAL_CONTRACTORS,
  objectTypeOptions,
  saleStatusOptions,
} from 'entities/object';
// eslint-disable-next-line boundaries/element-types
import { SelectContractor } from 'features/contractor/ui/SelectContractor';
import { SelectOption } from 'shared/types';
import { Col } from 'shared/ui/Col';
import { SelectField } from 'shared/ui/SelectField';
import { StyledTextField } from 'shared/ui/StyledTextField';

export const ObjectMainDataEditForm: FC = () => {
  const { t } = useTranslation();
  const form = useFormContext<EditDeveloperObject>();
  const outletData = useOutletContext<DeveloperObjectOutletData>();
  const [isContractorsValid, setIsContractorsValid] = useState<boolean>(true);

  let contractorsOptions: SelectOption[] = [];

  if (outletData) {
    const storedObject = outletData.storedObject;
    if (storedObject?.contractors) {
      contractorsOptions = storedObject.contractors.map((contractor) => ({
        label: contractor.contractorName,
        value: contractor.id,
        hidden: true,
      }));
    }
  }

  const formValues = form.watch();
  const [contractorsCount, setContractorsCount] = useState<number>(
    formValues.contractors.length
  );

  useEffect(() => {
    setContractorsCount(formValues.contractors.length);
    setIsContractorsValid(
      contractorsCount > 0 && formValues.contractors.includes('')
    );
  }, [contractorsCount, formValues]);

  const getContractorsFields = () => {
    const result: ReactNode[] = [];
    for (let index = 0; index < contractorsCount; index++) {
      result.push(
        <SelectContractor
          key={index}
          name={`contractors[${index}]`}
          options={contractorsOptions}
        />
      );
    }
    return result;
  };

  const handleAddContractor = () => {
    setContractorsCount((prev) => prev + 1);
  };

  return (
    <Col spacing={8}>
      <StyledTextField
        label={t('object.form.label.name')}
        required
        name="name"
        maxLength={90}
        showCharacterHintAtRemaining={10}
      />
      <StyledTextField
        label={t('object.form.label.site')}
        name="site"
        maxLength={255}
        showCharacterHintAtRemaining={50}
      />
      <SelectField
        options={objectTypeOptions}
        label={t('object.form.label.objectType')}
        required
        name={'objectType'}
      />

      {formValues.objectType === '1' && (
        <SelectField
          options={houseTypeOptions[formValues.objectType] || []}
          label={t('object.form.label.houseType')}
          required
          name={'houseType'}
        />
      )}

      <SelectField
        options={saleStatusOptions}
        label={t('object.form.label.saleStatus')}
        required
        name={'saleStatus'}
      />
      <StyledTextField
        label={t('object.form.label.objectDescription')}
        required
        name="objectDescription"
        multiline
        maxLength={3000}
        showCharacterHintAtRemaining={300}
      />

      {getContractorsFields()}

      {contractorsCount <= MAX_GENERAL_CONTRACTORS && (
        <Button
          size="small"
          onClick={handleAddContractor}
          disabled={isContractorsValid}
        >
          {t('object.form.label.addContractor')}
        </Button>
      )}
    </Col>
  );
};
