import {
  AlbumsPositions,
  AlbumsPositionsDto,
} from '../model/types/albumSchema';

export const mapDtoFromAlbumsPositions = ({
  albumsIds,
}: AlbumsPositions): AlbumsPositionsDto => ({
  album_ids: albumsIds,
});
