export function hexToRGBA(hex: string, alpha: number) {
  if (!hex || typeof hex !== 'string' || !hex.startsWith('#')) {
    return 'rgba(0,0,0,0)';
  }
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
