import { Lot } from 'entities/chess';
import { NewLot } from 'entities/chess/model/types/lotSchema';
import { sortByPositions } from './getSelectedLots';

export const addRiser = (
  lots: Lot[],
  risers: Lot[],
  addedRiser: number,
  floorCount: number
) => {
  // Увеличиваем значение riser на 1 для всех колонок, которые находятся правее или равны addedRiser
  const updateRisers = (lot: Lot) => {
    return lot.positions.map((pos) => {
      if (pos.riser >= addedRiser) {
        return {
          ...pos,
          riser: pos.riser + 1,
        };
      }
      return pos;
    });
  };

  const updatedRisers = risers.map((lot) => {
    return {
      ...lot,
      positions: updateRisers(lot),
    } as NewLot;
  });

  const updatedLots = lots.map((lot) => {
    return {
      ...lot,
      positions: updateRisers(lot),
    } as NewLot;
  });

  const newLots = new Array<Partial<Lot>>(Number(floorCount)).fill({}).map(
    (newLot, lotIndex) =>
      ({
        positions: [{ floor: lotIndex + 1, riser: addedRiser }],
        disabled: false,
        type: 'flat',
        colspan: 1,
        rowspan: 1,
        row: lotIndex + 1,
      }) as Partial<NewLot>
  );

  const sortedLots = [...(updatedLots as Lot[]), ...(newLots as Lot[])].sort(
    sortByPositions
  );

  const newRisers = [
    ...updatedRisers.slice(0, addedRiser - 1),
    {
      type: 'head',
      lotNumber: `${addedRiser}`,
      positions: [
        {
          floor: floorCount + 1,
          riser: addedRiser,
        },
      ],
    } as Lot,
    ...updatedRisers.slice(addedRiser - 1),
  ];

  return { updatedRisers: newRisers, updatedLots: sortedLots };
};

export const deleteRiser = (
  lots: Lot[],
  risers: Lot[],
  deletedRiser: number,
  deletedLotsIds: number[]
) => {
  // Уменьшаем значение riser на 1 для всех колонок, которые находятся правее или равны addedRiser
  const updatedLots = lots
    .filter(({ id }) => !deletedLotsIds.includes(id))
    .map((lot) => {
      return {
        ...lot,
        positions: lot.positions.map((pos) => {
          if (pos.riser >= deletedRiser) {
            return {
              ...pos,
              riser: pos.riser - 1,
            };
          }
          return pos;
        }),
      };
    });
  const updatedLotsIds = updatedLots.map(({ id }) => id);
  const sortedLots = [
    ...lots.filter(
      ({ id }) => !updatedLotsIds.includes(id) && !deletedLotsIds.includes(id)
    ),
    ...updatedLots,
  ].sort(sortByPositions);

  return {
    updatedRisers: risers.filter((_, i) => i !== deletedRiser - 1),
    updatedLots: sortedLots,
  };
};
