import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getRoute } from 'shared/const';
import { getLabelByValue } from 'shared/helpers/getLabelByValue';
import { CardLayout } from 'shared/ui/CardLayout';
import { SmallSquareButton } from 'shared/ui/SmallSquareButton';
import { TextViewField } from 'shared/ui/TextViewField';
import { objectTypeOptions, saleStatusOptions } from '../../../model/consts';
import { DeveloperObject } from '../../../model/types/objectSchema';

type MainDataCardProps = {
  object: DeveloperObject;
};

export const GeneralDataCard: FC<MainDataCardProps> = ({ object }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <CardLayout header={{ title: t('object.edit.tabLabel.mainData') }}>
      {object.objectType && (
        <TextViewField
          title={t('object.form.label.objectType')}
          text={t(getLabelByValue(objectTypeOptions, object.objectType))}
        />
      )}
      {object.contractors && object.contractors.length > 0 && (
        <TextViewField
          title={t('object.card.contractors')}
          text={object.contractors
            .map((contractor) => contractor.contractorName)
            .join(', ')}
        />
      )}
      {object.saleStatus && (
        <TextViewField
          title={t('object.form.label.saleStatus')}
          text={t(getLabelByValue(saleStatusOptions, object.saleStatus))}
        />
      )}
      {object.objectDescription && (
        <TextViewField
          title={t('object.form.label.objectDescription')}
          text={object.objectDescription}
        />
      )}
      <SmallSquareButton
        icon="edit"
        onClick={() => navigate(getRoute.EditObjectDetailsGeneral(object.id))}
      />
    </CardLayout>
  );
};
