import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { getRoute } from 'shared/const';
import { getLabelByValue } from 'shared/helpers/getLabelByValue';
import { CardLayout } from 'shared/ui/CardLayout';
import { ChipsViewField } from 'shared/ui/ChipsViewField';
import { Col } from 'shared/ui/Col';
import { Row } from 'shared/ui/Row';
import { SmallSquareButton } from 'shared/ui/SmallSquareButton';
import { TextViewField } from 'shared/ui/TextViewField';
import {
  advantagesOptions,
  canalizationOptions,
  electricityOptions,
  entrancesOptions,
  federalLowOptions,
  gasOptions,
  heatingOptions,
  houseClassOptions,
  houseTypeOptions,
  materialOptions,
  parkingOptions,
  territoryOptions,
  waterSupplyOptions,
} from '../../../model/consts';
import { DeveloperObject } from '../../../model/types/objectSchema';

type CharacteristicsCardProps = {
  object: DeveloperObject;
};

export const CharacteristicsCard: FC<CharacteristicsCardProps> = ({
  object,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const areThereAdditionalFields = [
    object.isNewObject,
    object.ceilingHeight,
    object.maintenancePrice,
    object.gasPrice,
    object.communicationsPrice,
    object.documentsPrice,
    object.otherData,
  ].some((field) => !!field);

  return (
    <CardLayout
      spacing={9}
      header={{ title: t('object.edit.tabLabel.characteristics') }}
    >
      <Col spacing={8}>
        <Col spacing={5}>
          <Typography variant="headerXS">
            {t('object.card.houseRegistration')}
          </Typography>
          <Row equalsChildrenWidth>
            {object.federalLow && (
              <TextViewField
                title={t('object.form.label.federalLow')}
                text={t(getLabelByValue(federalLowOptions, object.federalLow))}
              />
            )}
          </Row>
        </Col>
        {object.commission && (
          <TextViewField
            title={t('object.form.label.commission')}
            text={object.commission}
          />
        )}
      </Col>
      <Col spacing={8}>
        <Col spacing={5}>
          <Typography variant="headerXS">
            {t('object.card.houseCharacteristics')}
          </Typography>
          <Row equalsChildrenWidth>
            {object.houseType && (
              <TextViewField
                title={t('object.form.label.houseType')}
                text={t(
                  getLabelByValue(
                    houseTypeOptions[object.objectType],
                    object.houseType
                  )
                )}
              />
            )}
            {object.houseClass && (
              <TextViewField
                title={t('object.form.label.houseClass')}
                text={t(getLabelByValue(houseClassOptions, object.houseClass))}
              />
            )}
          </Row>
        </Col>
        <Row equalsChildrenWidth>
          {object.material && (
            <TextViewField
              title={t('object.form.label.material')}
              text={t(getLabelByValue(materialOptions, object.material))}
            />
          )}
          {object.territory && (
            <TextViewField
              title={t('object.form.label.territory')}
              text={t(getLabelByValue(territoryOptions, object.territory))}
            />
          )}
        </Row>
        {object.parking && object.parking.length > 0 && (
          <ChipsViewField
            title={t('object.form.label.parking')}
            options={parkingOptions}
            selected={object.parking}
          />
        )}
        {object.advantages && object.advantages.length > 0 && (
          <ChipsViewField
            title={t('object.card.facilities')}
            options={advantagesOptions}
            selected={object.advantages}
          />
        )}
        {object.gas && (
          <TextViewField
            title={t('object.form.label.gas')}
            text={t(getLabelByValue(gasOptions, object.gas))}
          />
        )}

        {object.canalization && (
          <TextViewField
            title={t('object.form.label.canalization')}
            text={t(getLabelByValue(canalizationOptions, object.canalization))}
          />
        )}
        {object.heating && (
          <TextViewField
            title={t('object.form.label.heating')}
            text={t(getLabelByValue(heatingOptions, object.heating))}
          />
        )}
        {object.waterSupply && (
          <TextViewField
            title={t('object.form.label.waterSupply')}
            text={t(getLabelByValue(waterSupplyOptions, object.waterSupply))}
          />
        )}
        {object.electricity && (
          <TextViewField
            title={t('object.form.label.electricity')}
            text={t(getLabelByValue(electricityOptions, object.electricity))}
          />
        )}
        {object.entrances && (
          <TextViewField
            title={t('object.form.label.entrances')}
            text={t(getLabelByValue(entrancesOptions, object.entrances))}
          />
        )}
      </Col>
      {areThereAdditionalFields && (
        <Col spacing={8}>
          <Col spacing={5}>
            <Typography variant="headerXS">
              {t('object.form.label.additionalCharacteristics')}
            </Typography>
            {object.isNewObject && (
              <TextViewField
                title={t('object.form.label.newObject')}
                text={
                  object.isNewObject ? t('ui.common.yes') : t('ui.common.no')
                }
              />
            )}
          </Col>
          {object.ceilingHeight && (
            <TextViewField
              title={t('object.form.label.ceilingHeight')}
              text={object.ceilingHeight}
            />
          )}
          {object.maintenancePrice && (
            <TextViewField
              title={t('object.form.label.maintenancePrice')}
              text={object.maintenancePrice}
            />
          )}

          {object.gasPrice && (
            <TextViewField
              title={t('object.form.label.gasPrice')}
              text={object.gasPrice}
            />
          )}
          {object.communicationsPrice && (
            <TextViewField
              title={t('object.form.label.communicationsPrice')}
              text={object.communicationsPrice}
            />
          )}
          {object.documentsPrice && (
            <TextViewField
              title={t('object.form.label.documentsPrice')}
              text={object.documentsPrice}
            />
          )}
          {object.otherData && (
            <TextViewField
              title={t('object.form.label.otherData')}
              text={object.otherData}
            />
          )}
        </Col>
      )}

      <SmallSquareButton
        icon="edit"
        onClick={() =>
          navigate(getRoute.EditObjectDetailsCharacteristics(object.id))
        }
      />
    </CardLayout>
  );
};
