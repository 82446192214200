/**
 * Экранируем символы &, < и > в строке, а также переводим переводы строк в <br>
 *
 * @param {string} str - The string to encode.
 * @return {string} The encoded string.
 */
export function encodeHtmlEntities(str: string) {
  return str
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/\n/g, '<br>');
}
