import { FC } from 'react';
import { Container } from '@mui/material';
import { Col } from 'shared/ui/Col';
import { RectSkeleton } from 'shared/ui/Skeleton/RectSkeleton';

export const ChessboardSkeleton: FC = () => {
  return (
    <Col spacing={8}>
      <Container>
        <RectSkeleton width={250} height={20} />
      </Container>
      <RectSkeleton height={208} />
      <RectSkeleton height={400} />
    </Col>
  );
};
