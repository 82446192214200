import { FC, ReactNode } from 'react';
import { PieChart, Pie, Cell, LabelList, Label } from 'recharts';
import { TextColor } from 'shared/theme/colors';

export interface PieChartData {
  name: string;
  value: number;
  color: string;
}

type AnalyticsPieChartProps = {
  width: number;
  height: number;
  pieData: PieChartData[];
  ringThickness: number;
  centralLabel?: ReactNode;
  percentLabels?: boolean;
  offsetX?: number;
  offsetY?: number;
};

const renderCustomizedLabelPercentage = (
  data: PieChartData,
  total: number
): string => {
  const percentageCalculated = Math.round((data.value / total) * 100);
  return `${percentageCalculated}%`;
};

export const AnalyticsPieChart: FC<AnalyticsPieChartProps> = ({
  width,
  height,
  pieData,
  ringThickness,
  centralLabel,
  percentLabels = false,
  offsetX = 0,
  offsetY = 0,
}) => {
  const outerRadius = width / 2;
  const innerRadius = width / 2 - ringThickness;

  const totalValue = pieData.reduce((sum, item) => sum + item.value, 0);

  return (
    <PieChart width={width} height={height}>
      <Pie
        data={pieData}
        cx={offsetX}
        cy={offsetY}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        paddingAngle={1}
        dataKey="value"
      >
        {percentLabels && (
          <LabelList
            style={{
              stroke: 'none',
              fill: TextColor.StaticWhite,
              fontSize: '14px',
              fontWeight: 500,
            }}
            dataKey={(entry: PieChartData) =>
              renderCustomizedLabelPercentage(entry, totalValue)
            }
          />
        )}
        {pieData.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={entry.color}
            style={{ outline: 'none' }}
          />
        ))}
        {centralLabel && (
          <Label
            value={`${centralLabel} лотов`}
            position="center"
            className="label-top"
            fontSize="16px"
            fontWeight={400}
            fill={TextColor.Quaternary}
          />
        )}
      </Pie>
    </PieChart>
  );
};
