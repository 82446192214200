import { FC, Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import { CssBaseline, LinearProgress } from '@mui/material';
import { router } from 'app/providers/AppRouter';
import { useProfileQuery } from 'entities/profile';

export const App: FC = () => {
  const { isFetching } = useProfileQuery();

  return (
    <div className="App">
      <CssBaseline />
      <Suspense fallback={<LinearProgress />}>
        {!isFetching && <RouterProvider router={router} />}
      </Suspense>
    </div>
  );
};
