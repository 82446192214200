import {
  Building,
  BuildingDto,
  CreateBuilding,
  CreateBuildingDto,
  UpdateBuildingDto,
} from '../model/types/buildingSchema';

export const mapBuildingFromDto = ({
  id,
  name,
  building_id,
  building_status_id,
  order_id,
  construction_ending,
  year_built,
  plan_date_built,
  sections,
}: BuildingDto): Building => ({
  id,
  objectId: building_id,
  order: order_id,
  name,
  completeQuarter: construction_ending ? construction_ending.toString() : '',
  completeYear: year_built ? year_built.toString() : '',
  actualCompleteDate: plan_date_built,
  status: building_status_id ? building_status_id.toString() : '',
  entrances: sections
    ? sections.map((section) => ({
        id: section.id,
        name: section.name || '',
        floorCount: section.floor_amount
          ? section.floor_amount?.toString()
          : '',
        riserCount: section.riser_amount
          ? section.riser_amount?.toString()
          : '',
        buildingId: section.pavilion_id,
        minAreaAll: section.min_area_all,
        minPriceAll: section.min_price_all,
        lotsCount: section.count_chess,
        isChessPresent: section.is_chess_present,
      }))
    : [],
});

export const mapDtoFromCreateBuilding = ({
  objectId,
  order,
  name,
  completeQuarter,
  completeYear,
  actualCompleteDate,
  status,
  entrances,
}: CreateBuilding): CreateBuildingDto => ({
  building_id: objectId,
  building_status_id: Number(status) || null,
  name,
  order_id: order,
  construction_ending: Number(completeQuarter) || null,
  year_built: Number(completeYear) || null,
  plan_date_built: actualCompleteDate || '',
  sections: entrances
    ? entrances.map((entrance) => ({
        name: entrance.name,
        floor_amount: Number(entrance.floorCount) || null,
        riser_amount: Number(entrance.riserCount) || null,
      }))
    : [],
});

export const mapDtoFromUpdateBuilding = ({
  id,
  objectId,
  order,
  name,
  completeQuarter,
  completeYear,
  actualCompleteDate,
  status,
}: Building): UpdateBuildingDto => ({
  id,
  building_id: objectId,
  building_status_id: Number(status) || null,
  name,
  order_id: order,
  construction_ending: Number(completeQuarter) || null,
  year_built: Number(completeYear) || null,
  plan_date_built: actualCompleteDate || '',
});
