import { FC } from 'react';
import { useOutletContext } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useAppSelector } from 'shared/hooks/hooks';
import { Col } from 'shared/ui/Col';
import { Row } from 'shared/ui/Row';
import { DeveloperObject } from '../../model/types/objectSchema';
import { CharacteristicsCard } from './infoCards/CharacteristicsCard';
import { GeneralDataCard } from './infoCards/GeneralDataCard';
import { LocationCard } from './infoCards/LocationCard';
import { ObjectDeveloperCard } from './infoCards/ObjectDeveloperCard';
import { UpdateInfoCard } from './infoCards/UpdateInfoCard';

export const ObjectDetails: FC = () => {
  const object = useOutletContext<DeveloperObject>();

  const user = useAppSelector((state) => state.profile.profile);

  if (!object) return <CircularProgress />;

  return (
    <Row wrap={false} spacing={9} maxWidth={1024} sx={{ m: 'auto' }}>
      <Col width={648} spacing={8}>
        <GeneralDataCard object={object} />
        <CharacteristicsCard object={object} />
      </Col>
      <Col spacing={8}>
        <UpdateInfoCard object={object} />
        <LocationCard object={object} />
        {user?.isNedvexUserOrAdmin && <ObjectDeveloperCard object={object} />}
      </Col>
    </Row>
  );
};
