import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Col } from 'shared/ui/Col';
import { NumericField } from 'shared/ui/NumericField';
import { Row } from 'shared/ui/Row';
import { StyledTextField } from 'shared/ui/StyledTextField';

export const AddContractorForm: FC = () => {
  const { t } = useTranslation();

  return (
    <Col spacing={8}>
      <Typography variant={'headerXS'}>{t('contractor.form.title')}</Typography>
      <Row spacing={5} equalsChildrenWidth>
        <StyledTextField
          label={t('contractor.form.label.name')}
          required
          name="contractorName"
          maxLength={30}
          showCharacterHintAtRemaining={10}
        />
        <NumericField
          label={t('contractor.form.label.inn')}
          required
          name="inn"
          valueIsNumericString
          maxLength={12}
          allowNegative={false}
          decimalScale={0}
        />
      </Row>
    </Col>
  );
};
