import { z } from 'zod';

export type Document = {
  id: number;
  hash: string;
  name: string;
};

export type SaveDocumentDto = {
  building_id: number;
  name: string;
  file: File;
};

export const createDocumentZod = z.object({
  objectId: z.number(),
  name: z.string().min(1, 'document.form.error.name').max(30),
  file: z.custom<File>(
    (val) => (val instanceof File ? true : false),
    'document.form.label.uploadFile'
  ),
});

export const updateDocumentZod = z.object({
  objectId: z.number(),
  name: z.string().min(1, 'document.form.error.name').max(30),
  file: z.custom<File>(() => true),
});

export type SaveDocument = z.infer<typeof createDocumentZod>;
export type UpdateDocument = z.infer<typeof updateDocumentZod>;
