import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardContent, Typography } from '@mui/material';
import { BorderColor, PaletteColor } from 'shared/theme/colors';
import { ButtonLoadingSpinner } from './ButtonLoadingSpinner';
import { Col } from './Col';
import { Row } from './Row';

type CardLayoutProps = {
  children?: ReactNode;
  spacing?: number;
  header?: {
    title?: string;
    subtitle?: string;
  };
  footer?: {
    onOkClick?: () => void;
    onCancelClick?: () => void;
    okLabel?: string;
    cancelLabel?: string;
    okButtonColor?: 'primary' | 'secondary';
    okButtonDisabled?: boolean;
    okButtonLoading?: boolean;
  };
  width?: number;
  maxWidth?: string | number;
  marginAuto?: boolean;
  disableBorder?: boolean;
  variant?: 'primary' | 'secondary';
  paddingY?: number;
  errors?: string[];
};

export const CardLayout: FC<CardLayoutProps> = ({
  children,
  spacing = 8,
  header,
  footer,
  disableBorder = false,
  variant = 'primary',
  paddingY,
  width,
  maxWidth,
  marginAuto,
  errors,
}) => {
  const { t } = useTranslation();
  const showHeader = Boolean(header?.title || header?.subtitle);
  const showFooter = Boolean(footer?.onOkClick || footer?.onCancelClick);
  const showFormErrors = errors?.length;
  const okLabel = footer?.okLabel || t('ui.cardLayout.next');
  const cancelLabel = footer?.cancelLabel || t('ui.cardLayout.next');
  const okButtonColor = footer?.okButtonColor || 'primary';
  const isPrimary = variant === 'primary';
  const isOkButtonColorPrimary = okButtonColor === 'primary';

  const verticalPadding = paddingY
    ? `${paddingY}px`
    : isPrimary
      ? '32px'
      : '24px';

  const headerContent = showHeader ? (
    <Col spacing={8}>
      {header?.title && (
        <Typography variant={isPrimary ? 'headerM' : 'headerXS'}>
          {header.title}
        </Typography>
      )}
      {header?.subtitle && (
        <Typography variant={'paragraphM'}>{header.subtitle}</Typography>
      )}
    </Col>
  ) : null;

  const footerContent = showFooter ? (
    <Row
      spacing={5}
      sx={{
        flexDirection: { xs: 'column', sm: 'row' },
        flex: { xs: 1, sm: 'unset' },
      }}
    >
      {footer?.onOkClick && (
        <Button
          variant="contained"
          size={isPrimary ? 'medium' : 'small'}
          onClick={footer.onOkClick}
          disabled={footer.okButtonDisabled || footer?.okButtonLoading}
          color={okButtonColor}
          sx={{
            width: '100%',
            '&.Mui-disabled': {
              background: isOkButtonColorPrimary
                ? PaletteColor.Primary
                : PaletteColor.Secondary,
              color: isOkButtonColorPrimary
                ? PaletteColor.Primary
                : PaletteColor.Secondary,
            },
          }}
        >
          <Typography>{okLabel}</Typography>
          {footer.okButtonLoading && <ButtonLoadingSpinner />}
        </Button>
      )}
      {footer?.onCancelClick && (
        <Button
          variant="outlined"
          size={isPrimary ? 'medium' : 'small'}
          onClick={footer?.onCancelClick}
          sx={{ width: '100%' }}
        >
          {cancelLabel}
        </Button>
      )}
    </Row>
  ) : null;

  const formErrorsContent = showFormErrors ? (
    <Col spacing={3}>
      {errors.map((error, index) => (
        <Typography key={index} color={PaletteColor.Error}>
          {t(error)}
        </Typography>
      ))}
    </Col>
  ) : null;

  return (
    <Card
      sx={{
        width: width,
        maxWidth: maxWidth,
        m: marginAuto ? 'auto' : '',
        border: disableBorder ? 'none' : `1px solid ${BorderColor.Gray}`,
      }}
    >
      <CardContent
        sx={{
          '&.MuiCardContent-root': {
            padding: `${verticalPadding} 24px`,
          },
        }}
      >
        <Col spacing={spacing}>
          {headerContent}
          {children}
          {footerContent}
          {formErrorsContent}
        </Col>
      </CardContent>
    </Card>
  );
};
