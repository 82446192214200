import { redirect, RouteObject } from 'react-router-dom';
import { UserDetailsCard } from 'features/user';
import { getRoute } from 'shared/const';

export const userSubRoutes: RouteObject[] = [
  {
    index: true,
    loader: ({ params }) =>
      redirect(
        params.userId ? getRoute.UserDetails(params.userId) : getRoute.Users()
      ),
  },
  {
    path: getRoute.UserDetails(':userId'),
    element: <UserDetailsCard />,
  },
];
