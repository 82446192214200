import { City, CityDto } from '../model/types/citiesSchema';

export const mapCityFromDto = ({ id, name, regions }: CityDto): City => ({
  id,
  name,
  districts: regions.map((region) => ({
    id: region.id,
    name: region.name,
    longitude: region.longitude,
    latitude: region.latitude,
    microdistricts: region.subregions.map((subregion) => ({
      id: subregion.id,
      name: subregion.name,
      longitude: subregion.longitude,
      latitude: subregion.latitude,
      position: subregion.position,
    })),
  })),
});
