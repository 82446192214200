import { FC, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  editObjectZod,
  useCreateObjectMutation,
  EditDeveloperObject,
} from 'entities/object';
import { getRoute } from 'shared/const';
import { useSnackbar } from 'shared/hooks/useSnackbar';
import {
  StepWithContentProps,
  useStepperWithContent,
} from 'shared/hooks/useStepperWithContent';
import { StepOne } from './steps/StepOne';
import { StepThree } from './steps/StepThree';
import { StepTwo } from './steps/StepTwo';

export const CreateObject: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [createObject, { isLoading: isObjectCreating }] =
    useCreateObjectMutation();
  const { developerId } = useParams<{ developerId: string }>();

  const { showSnackbar } = useSnackbar();

  const form = useForm<EditDeveloperObject>({
    resolver: zodResolver(editObjectZod),
    mode: 'all',
    defaultValues: {
      published: 0,
      name: '',
      site: '',
      objectType: '',
      houseType: '',
      saleStatus: '',
      objectDescription: '',
      address: '',
      microdistrict: '',
      seaDistance: '',
      federalLow: '',
      latitude: null,
      longitude: null,
      houseClass: '',
      material: '',
      territory: '',
      parking: [],
      advantages: [],
      entrances: '',
      commission: '',
      canalization: '',
      heating: '',
      waterSupply: '',
      electricity: '',
      gas: '',
      isNewObject: false,
      ceilingHeight: '',
      maintenancePrice: '',
      gasPrice: '',
      communicationsPrice: '',
      documentsPrice: '',
      otherData: '',

      id: undefined,
      developerId: Number(developerId),

      contractors: [],
      salesOffices: [],
    },
  });

  const handleStepThreeDone = useCallback(
    async (objectData: EditDeveloperObject) => {
      if (!developerId) {
        console.error('there is no developerId');
        return;
      }
      try {
        const result = await createObject(objectData).unwrap();
        navigate(getRoute.ObjectAbout(result.id));
      } catch (e) {
        console.error(e);
        showSnackbar();
      }
    },
    [developerId, createObject, navigate, showSnackbar]
  );

  const handleCancelStep = useCallback(() => {
    navigate(getRoute.Objects());
  }, [navigate]);

  const getSteps = (): StepWithContentProps[] => [
    {
      content: (
        <StepOne
          onDone={() => onNextStepClick()}
          onCancel={() => handleCancelStep()}
          onStepValidityChange={(isValid) => onStepValidityChange(isValid)}
        />
      ),
      label: t('object.create.stepOne.title'),
      subLabel: t('object.create.stepOne.description'),
    },
    {
      content: (
        <StepTwo
          onDone={() => onNextStepClick()}
          onReturn={() => onPreviousStepClick()}
          onStepValidityChange={(isValid) => onStepValidityChange(isValid)}
        />
      ),
      label: t('object.create.stepTwo.title'),
      subLabel: t('object.create.stepTwo.description'),
    },
    {
      content: (
        <StepThree
          onDone={form.handleSubmit(handleStepThreeDone)}
          onReturn={() => onPreviousStepClick()}
          isObjectCreating={isObjectCreating}
          onStepValidityChange={(isValid) => onStepValidityChange(isValid)}
        />
      ),
      label: t('object.create.stepThree.title'),
      subLabel: t('object.create.stepThree.description'),
    },
  ];

  const {
    StepperWithContent,
    onNextStepClick,
    onPreviousStepClick,
    onStepValidityChange,
  } = useStepperWithContent({
    steps: getSteps(),
  });

  return <FormProvider {...form}>{StepperWithContent}</FormProvider>;
};
