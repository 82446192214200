import { redirect, RouteObject } from 'react-router-dom';
import { getRoute } from 'shared/const';
import {
  AgenciesTab,
  DevelopersTab,
  ObjectsTab,
  UsersTab,
} from 'widgets/MainPageTabs';
import { AgenciesRoute } from '../ui/AgenciesRoute';
import { DevelopersRoute } from '../ui/DevelopersRoute';
import { ObjectsRoute } from '../ui/ObjectsRoute';
import { UsersRoute } from '../ui/UsersRoute';

export const mainPageSubRoutes: RouteObject[] = [
  {
    index: true,
    loader: () => redirect(getRoute.Developers()),
  },
  {
    path: getRoute.Developers(),
    element: (
      <DevelopersRoute>
        <DevelopersTab />
      </DevelopersRoute>
    ),
  },
  {
    path: getRoute.Objects(),
    element: (
      <ObjectsRoute>
        <ObjectsTab />
      </ObjectsRoute>
    ),
  },
  {
    path: getRoute.Agencies(),
    element: (
      <AgenciesRoute>
        <AgenciesTab />
      </AgenciesRoute>
    ),
  },
  {
    path: getRoute.Users(),
    element: (
      <UsersRoute>
        <UsersTab />
      </UsersRoute>
    ),
  },
];
