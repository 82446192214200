export enum PublicationErrorMessage {
  HAS_NO_ALBUMS = 'HAS_NO_ALBUMS',
  HAS_NO_SECTIONS = 'HAS_NO_SECTIONS',
  MUST_CREATE_PAVILION = 'MUST_CREATE_PAVILION',
  MUST_CREATE_CHESS = 'MUST_CREATE_CHESS',
  HAS_LOT_WITH_ERROR = 'HAS_LOT_WITH_ERROR',
  MUST_ADD_DOCUMENT = 'MUST_ADD_DOCUMENT',
}

export type ObjectPublicationErrorDto = {
  type: string;
  building_id: number;
  pavilion_id?: number;
  section_id?: number;
  name: string;
  messages: PublicationErrorMessage[];
  count_errors?: number;
};

export type ObjectPublicationError = {
  type: string;
  objectId: number;
  buildingId?: number;
  entranceId?: number;
  name: string;
  messages: PublicationErrorMessage[];
  errorsCount?: number;
};
