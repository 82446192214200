import { useState, useEffect, useCallback } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import { Backdrop, Button, Card, Typography } from '@mui/material';
import { useAppSelector } from 'shared/hooks/hooks';
import {
  useGetObjectQuery,
  useGetObjectVersionQuery,
} from '../model/api/ObjectService';

const POLLING_INTERVAL = 60;

interface Result {
  isVersionChanged: boolean;
  showVersionChangedPopup: () => JSX.Element | null;
  checkVersion: () => Promise<boolean>;
}

export const useVersionCheck = (
  objectId: string | number | undefined
): Result => {
  const { t } = useTranslation();

  const [isVersionChanged, setIsVersionChanged] = useState(false);

  const { isFetching: isObjectFetching } = useGetObjectQuery(
    objectId ?? skipToken,
    { skip: true }
  );

  const { refetch: refetchVersion } = useGetObjectVersionQuery(
    objectId ?? skipToken,
    {
      skip: isObjectFetching,
    }
  );

  const initialVersion = useAppSelector((state) => state.objectVersion.version);

  const checkVersion = useCallback(async () => {
    try {
      const { data } = await refetchVersion();
      if (data?.version && initialVersion !== data.version) {
        setIsVersionChanged(true);
        return true;
      }
      return false;
    } catch (error) {
      console.error('Failed to refetch version:', error);
      return false;
    }
  }, [initialVersion, refetchVersion]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      checkVersion();
    }, POLLING_INTERVAL * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [checkVersion]);

  const showVersionChangedPopup = () => {
    if (!isVersionChanged) return null;
    return (
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isVersionChanged}
      >
        <Card sx={{ p: 8, display: 'flex', flexDirection: 'column', gap: 5 }}>
          <Typography variant="headerXS">
            {t('object.popup.version.title')}
          </Typography>
          <Button
            variant="outlined"
            size="small"
            onClick={() => window.location.reload()}
          >
            {t('object.popup.version.button')}
          </Button>
        </Card>
      </Backdrop>
    );
  };

  return {
    isVersionChanged,
    showVersionChangedPopup,
    checkVersion,
  };
};
