import { FC, useMemo, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, IconButton } from '@mui/material';
// eslint-disable-next-line boundaries/element-types
import { DeveloperObjectOutletData } from 'entities/object';
// eslint-disable-next-line boundaries/element-types
import { useGetDeveloperUsersQuery } from 'entities/user';
import { getFormattedPhone } from 'shared/helpers/getFormattedPhone';
import { useAppSelector } from 'shared/hooks/hooks';
import { useDebounce } from 'shared/hooks/useDebounce';
import { TextColor } from 'shared/theme/colors';
import { SelectOption } from 'shared/types';
import { Col } from 'shared/ui/Col';
import { DeleteBuckitIcon } from 'shared/ui/Icons';
import { Row } from 'shared/ui/Row';
import { SelectWithSearch } from 'shared/ui/SelectWithSearch';
import { VerticalSortableList } from 'shared/ui/SortableList';
import { StyledTextField } from 'shared/ui/StyledTextField';
import { TextViewField } from 'shared/ui/TextViewField';
import { SalesOfficeEmployeeRowSkeleton } from './SalesOfficeEmployeeRowSkeleton';

interface SalesOfficeEmployeeRowProps {
  employeeFieldName: string;
  positionFieldName: string;
  firstNameFieldName: string;
  lastNameFieldName: string;
  phoneFieldName: string;
  onDeleteRow: () => void;
  getSelectedEmployee: (employeeId: number) => SelectOption | undefined;
  selectedEmployeeIds: number[];
}

export const SalesOfficeEmployeeRow: FC<SalesOfficeEmployeeRowProps> = ({
  employeeFieldName,
  positionFieldName,
  onDeleteRow,
  getSelectedEmployee,
  selectedEmployeeIds,
  firstNameFieldName,
  lastNameFieldName,
  phoneFieldName,
}) => {
  const { t } = useTranslation();
  const { getValues, setValue } = useFormContext();
  const user = useAppSelector((state) => state.profile.profile);
  const [searchText, setSearchText] = useState<string>('');

  const debouncedSearchText = useDebounce(searchText);

  const cleanSearchText = (text: string): string => {
    const hasLetters = /[a-zA-Zа-яА-Я]/.test(text);
    return hasLetters ? text : text.replace(/[^\d]/g, '');
  };

  const cleanedDebouncedSearchText = cleanSearchText(debouncedSearchText);

  const outletData = useOutletContext<DeveloperObjectOutletData>();
  const { developerId } = useParams<{ developerId: string }>();

  const queryOptions = {
    search: cleanedDebouncedSearchText,
    limit: 10,
    offset: 0,
    ...(user?.isNedvexUserOrAdmin
      ? {}
      : {
          developer_id:
            outletData?.storedObject?.developerId || Number(developerId),
        }),
  };

  const { data: employees, isLoading } =
    useGetDeveloperUsersQuery(queryOptions);

  const selectedEmployeeId = getValues(employeeFieldName);

  const employeesOptions: SelectOption[] = useMemo(() => {
    const selectedEmployee = getSelectedEmployee(selectedEmployeeId);
    const defaultOption = selectedEmployee ? [selectedEmployee] : [];

    const employeesWithHiddenFlag = (employees?.items ?? []).map(
      (employee) => ({
        value: employee.id,
        label: `${employee.lastName} ${employee.firstName}`,
        sublabel: employee.phone ? getFormattedPhone(employee.phone) : '',
        hidden: selectedEmployeeIds.includes(employee.id),
      })
    );

    return [...defaultOption, ...employeesWithHiddenFlag];
  }, [
    employees?.items,
    getSelectedEmployee,
    selectedEmployeeId,
    selectedEmployeeIds,
  ]);

  if (isLoading) return <SalesOfficeEmployeeRowSkeleton />;

  return (
    <Row spacing={5}>
      <Box sx={{ mt: 6 }}>
        <VerticalSortableList.DragHandle />
      </Box>
      <Col spacing={5}>
        <Row spacing={5}>
          <SelectWithSearch
            label={
              getValues(employeeFieldName)
                ? t('salesOffice.form.label.employee')
                : t('salesOffice.form.label.selectEmployee')
            }
            name={employeeFieldName}
            searchPlaceholder={t('salesOffice.form.label.searchEmployees')}
            options={employeesOptions}
            onSearchChange={setSearchText}
            onChange={(employee) => {
              const [firstName, lastName] = employeesOptions
                .find((item) => item.value === employee)
                ?.label?.split(' ') || ['', ''];
              const phone = employeesOptions.find(
                (item) => item.value === employee
              )?.sublabel;
              setValue(firstNameFieldName, firstName);
              setValue(lastNameFieldName, lastName);
              setValue(phoneFieldName, phone);
            }}
            sx={{ width: 260 }}
          />
          <Box
            sx={{
              width: 8,
              height: 28,
              borderBottom: `2px solid ${TextColor.Tertiary}`,
            }}
          />
          <TextViewField
            variant="filled"
            isPhone
            name={phoneFieldName}
            title={t('ui.common.phoneNumber')}
            sx={{ width: 180 }}
          />
        </Row>
        <StyledTextField
          label={t('user.form.label.position')}
          maxLength={30}
          showCharacterHintAtRemaining={10}
          name={positionFieldName || ''}
        />
      </Col>
      <IconButton sx={{ p: 0, mt: 6 }} onClick={() => onDeleteRow()}>
        <DeleteBuckitIcon sx={{ fontSize: 20 }} />
      </IconButton>
    </Row>
  );
};
