import React from 'react';
import { Box, BoxProps, useTheme } from '@mui/material';

interface ColProps extends BoxProps {
  spacing?: number;
  equalsChildrenWidth?: boolean;
}

export const Col = ({
  children,
  spacing,
  equalsChildrenWidth,
  ...rest
}: ColProps) => {
  const theme = useTheme();
  const spacingValue = theme.spacing(spacing || 0);

  return (
    <Box display="flex" flexDirection="column" gap={spacingValue} {...rest}>
      {React.Children.map(
        children,
        (child) =>
          child && <Box flex={equalsChildrenWidth ? 1 : 'unset'}>{child}</Box>
      )}
    </Box>
  );
};
