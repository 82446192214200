import {
  NewsPost,
  NewsPostDto,
  SaveNewsPostDto,
} from '../model/types/newsPostSchema';

export const mapNewsPostFromDto = ({
  id,
  name,
  manager,
  date_create,
  date_update,
}: NewsPostDto): NewsPost => ({
  id,
  text: name,
  dateCreate: date_create,
  dateUpdate: date_update,
  manager: manager
    ? {
        id: manager.id,
        firstName: manager.first_name || '',
        lastName: manager.last_name || '',
        email: manager.email || '',
      }
    : undefined,
});

export const mapDtoFromNewsPost = ({
  id,
  text,
}: NewsPost): SaveNewsPostDto => ({
  building_id: id,
  name: text,
});
