import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Building,
  BuildingEditForm,
  CreateBuilding,
  buildingZod,
  useCreateBuildingMutation,
  useGetBuildingQuery,
  useUpdateBuildingMutation,
} from 'entities/building';
import { useCreateChessMutation } from 'entities/chess';
import {
  Entrance,
  useCreateEntranceMutation,
  useDeleteEntranceMutation,
  useDuplicateEntranceMutation,
  useUpdateEntranceMutation,
} from 'entities/entrance';
import { useVersionCheck } from 'entities/object';
import { useSnackbar } from 'shared/hooks/useSnackbar';
import { CardLayout } from 'shared/ui/CardLayout';
import { EditBuildingSkeleton } from './EditBuildingSkeleton';

interface EditBuildingProps {
  buildingId?: number;
  onCreated?: () => void;
  onUpdated?: () => void;
  onCancelClick?: () => void;
}

const editBuildingZod = buildingZod
  .pick({
    name: true,
    status: true,
    actualCompleteDate: true,
    completeQuarter: true,
    completeYear: true,
  })
  .refine(
    (values) => {
      if (values.status === '1' && !values.actualCompleteDate) {
        return false;
      }
      return true;
    },
    {
      message: 'building.form.error.actualCompleteDate',
      path: ['actualCompleteDate'],
    }
  );

export const EditBuilding: FC<EditBuildingProps> = ({
  buildingId,
  onCreated,
  onUpdated,
  onCancelClick,
}) => {
  const { t } = useTranslation();
  const { objectId } = useParams<{ objectId: string }>();
  const { data: storedBuilding, isLoading } = useGetBuildingQuery(
    buildingId || skipToken
  );

  const [newBuildingEntrances, setNewBuildingEntrances] = useState<Entrance[]>(
    []
  );

  const { checkVersion, showVersionChangedPopup } = useVersionCheck(objectId);

  const { showSnackbar } = useSnackbar();

  const form = useForm<Building>({
    resolver: zodResolver(editBuildingZod),
    mode: 'onSubmit',
    defaultValues: {
      name: storedBuilding?.name || '',
      status: storedBuilding?.status || '',
      actualCompleteDate: storedBuilding?.actualCompleteDate || null,
      completeQuarter: storedBuilding?.completeQuarter || '',
      completeYear: storedBuilding?.completeYear || '',
    },
  });

  useEffect(() => {
    const subscription = form.watch((values) => {
      if (
        values.status &&
        ['2', '3', '4'].includes(values.status) &&
        values.actualCompleteDate
      ) {
        form.setValue('actualCompleteDate', null);
      }
    });
    return () => subscription.unsubscribe();
  }, [form]);

  const [createBuilding, { isLoading: isBuildingCreating }] =
    useCreateBuildingMutation();
  const [updateBuilding, { isLoading: isBuildingUpdating }] =
    useUpdateBuildingMutation();
  const [createEntrance, { isLoading: isEntranceCreating }] =
    useCreateEntranceMutation();
  const [updateEntrance, { isLoading: isEntranceUpdating }] =
    useUpdateEntranceMutation();
  const [createChess, { isLoading: isChessCreating }] =
    useCreateChessMutation();
  const [duplicateEntrance, { isLoading: isEntranceDuplicating }] =
    useDuplicateEntranceMutation();
  const [deleteEntrance] = useDeleteEntranceMutation();

  const hasEntrances =
    (storedBuilding?.entrances && storedBuilding.entrances.length > 0) ||
    newBuildingEntrances.length > 0;

  const handleUpdateBuildingClick = async (buildingData: Building) => {
    const isVersionChanged = await checkVersion();

    if (!isVersionChanged) {
      if (!hasEntrances) {
        form.setError('root', {
          message: t('building.form.error.addEntrance'),
        });
        return;
      }

      const preparedBuilding = { ...storedBuilding, ...buildingData };

      try {
        await updateBuilding(preparedBuilding).unwrap();
        onUpdated?.();
      } catch (error) {
        console.error(error);
        showSnackbar();
      }
    }
  };

  const handleCreateBuildingClick = async (buildingData: CreateBuilding) => {
    const isVersionChanged = await checkVersion();

    if (!isVersionChanged) {
      if (!hasEntrances) {
        form.setError('root', {
          message: t('building.form.error.addEntrance'),
        });
        return;
      }
      const entrances = newBuildingEntrances.map(
        ({ name, floorCount, riserCount }) => ({
          name,
          floorCount,
          riserCount,
        })
      );
      const newBuildingData = {
        ...buildingData,
        objectId: Number(objectId),
        entrances,
      };

      try {
        await createBuilding(newBuildingData).unwrap();
        onCreated?.();
      } catch (error) {
        console.error(error);
        showSnackbar();
      }
    }
  };

  const handleSaveEntrance = async (entrance: Entrance) => {
    const isVersionChanged = await checkVersion();

    if (!isVersionChanged) {
      if (!buildingId) {
        setNewBuildingEntrances((prev) => {
          const entranceIndex = prev.findIndex(
            (oldEntrance) => oldEntrance.id === entrance.id
          );

          if (entranceIndex !== -1) {
            return prev.map((oldEntrance, index) =>
              index === entranceIndex ? { ...entrance } : oldEntrance
            );
          } else {
            const newEntrance = { ...entrance, id: Date.now() };
            return [...prev, newEntrance];
          }
        });

        return;
      }

      try {
        if (entrance.id) {
          await updateEntrance(entrance).unwrap();
        } else {
          const newEntrance = await createEntrance({
            entrance: {
              ...entrance,
              buildingId: Number(buildingId),
            },
            objectId: Number(objectId),
          }).unwrap();
          await createChess({
            chess: {
              entranceId: newEntrance.id,
              floorsCount: Number(newEntrance.floorCount),
              risersCount: Number(newEntrance.riserCount),
            },
            objectId: Number(objectId),
            buildingId: Number(buildingId),
          });
        }
      } catch (error) {
        console.error(error);
        showSnackbar();
      }
    }
  };

  const handleRemoveEntrance = async (entrance: Entrance) => {
    const isVersionChanged = await checkVersion();

    if (!isVersionChanged) {
      if (!buildingId) {
        setNewBuildingEntrances((prev) =>
          prev.filter((oldEntrance) => oldEntrance.id !== entrance.id)
        );
        return;
      }

      try {
        if (storedBuilding) {
          await deleteEntrance({
            entrance,
            objectId: storedBuilding.objectId,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onDuplicateEntrance = async (entrance: Entrance) => {
    const isVersionChanged = await checkVersion();

    if (!isVersionChanged) {
      if (!buildingId) {
        setNewBuildingEntrances((prev) => {
          const foundEntrance = prev.find(
            (oldEntrance) => oldEntrance.id === entrance.id
          );
          if (foundEntrance) {
            const newEntrance = { ...foundEntrance, id: Date.now() };
            return [...prev, newEntrance];
          }
          return prev;
        });
        return;
      }
      duplicateEntrance(entrance);
    }
  };

  useEffect(() => {
    if (!isLoading && storedBuilding) {
      form.reset({
        name: storedBuilding?.name || '',
        status: storedBuilding?.status || '',
        actualCompleteDate: storedBuilding?.actualCompleteDate || '',
        completeQuarter: storedBuilding?.completeQuarter || '',
        completeYear: storedBuilding?.completeYear || '',
      });
    }
  }, [isLoading, storedBuilding, form]);

  useEffect(() => {
    if (hasEntrances) {
      form.clearErrors('root');
    }
  }, [form, hasEntrances]);

  if (isLoading) return <EditBuildingSkeleton />;

  return (
    <CardLayout
      header={{
        title: buildingId
          ? t('building.form.title.editingBuilding')
          : t('building.form.title.addingBuilding'),
        subtitle: t('building.form.subtitle'),
      }}
      footer={{
        onOkClick: buildingId
          ? form.handleSubmit(handleUpdateBuildingClick)
          : form.handleSubmit(handleCreateBuildingClick),
        onCancelClick: onCancelClick,
        okLabel: t('ui.common.save'),
        cancelLabel: t('ui.common.cancel'),
        okButtonColor: 'primary',
        okButtonLoading:
          isBuildingCreating ||
          isBuildingUpdating ||
          isEntranceCreating ||
          isChessCreating,
      }}
      errors={
        form.formState.errors.root?.message
          ? [form.formState.errors.root?.message]
          : []
      }
    >
      {showVersionChangedPopup()}
      <FormProvider {...form}>
        <BuildingEditForm
          building={storedBuilding}
          newBuildingEntrances={newBuildingEntrances}
          onSaveEntrance={handleSaveEntrance}
          onRemoveEntrance={handleRemoveEntrance}
          onDuplicateEntrance={onDuplicateEntrance}
          isEntranceSaving={
            isEntranceCreating || isEntranceUpdating || isEntranceDuplicating
          }
        />
      </FormProvider>
    </CardLayout>
  );
};
