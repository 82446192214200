import { $rtkApi, ApiTag } from 'shared/api/rtkApi';
import { ListData, ResponseData } from 'shared/types';
import { mapUserFromDto, mapDtoFromUser } from '../../lib/mapUser';
import { NewUser, User, UserDto } from '../types/userSchema';

export const userApi = $rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    createUser: builder.mutation<User, NewUser>({
      query: (user) => ({
        url: '/agency/users',
        method: 'POST',
        body: mapDtoFromUser(user),
      }),
      transformResponse: (response: ResponseData<UserDto>) =>
        mapUserFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, user) => [
        { type: ApiTag.user, id: ApiTag.user },
      ],
    }),
    updateUser: builder.mutation<User, User>({
      query: (user) => ({
        url: `/agency/users/${user.id}`,
        method: 'PUT',
        body: mapDtoFromUser(user),
      }),
      transformResponse: (response: ResponseData<UserDto>) =>
        mapUserFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, user) => [
        { type: ApiTag.user, id: user.id },
        { type: ApiTag.user, id: ApiTag.user },
      ],
    }),
    getUsers: builder.query<
      ListData<User>,
      {
        search: string;
        limit: number;
        offset: number;
      }
    >({
      query: (params) => ({
        url: `/users`,
        method: 'GET',
        params,
      }),
      providesTags: (result, error, page) =>
        result
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTag.user, id })),
              { type: ApiTag.user, id: ApiTag.user },
            ]
          : [{ type: ApiTag.user, id: ApiTag.user }],
      transformResponse: (response: ResponseData<ListData<UserDto>>) => ({
        total: response.data.total,
        items: response.data.items.map((data) => mapUserFromDto(data)),
      }),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
    }),
    getUser: builder.query<User, string | number>({
      query: (id) => ({
        url: `/users/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: ResponseData<UserDto>) =>
        mapUserFromDto(response.data),
      providesTags: (result, error, id) => [{ type: ApiTag.user, id }],
    }),
  }),
});

export const {
  useCreateUserMutation,
  useUpdateUserMutation,
  useGetUsersQuery,
  useGetUserQuery,
} = userApi;
