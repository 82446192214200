import { Lot } from 'entities/chess';
import {
  createLotsCountByPositionMap,
  getPositionMapKeyByPosition,
} from './positionMap';

export const getShowSplitButtonInDialog = (
  lots: Lot[],
  selectedLots: Lot[]
) => {
  const positionMap = createLotsCountByPositionMap(lots);
  const selectedPositionMap = createLotsCountByPositionMap(selectedLots);
  const selectedLotsPositions = selectedLots.flatMap(
    ({ positions }) => positions
  );

  return selectedLotsPositions.every((position) => {
    //для каждой выбранной позиции проверяем влезет ли в ячейку еще столько же лотов, сколько в ней уже есть
    const placedLotCount = positionMap.get(
      getPositionMapKeyByPosition(position)
    );
    const newLotCount = selectedPositionMap.get(
      getPositionMapKeyByPosition(position)
    );

    if (!placedLotCount || !newLotCount) {
      return false;
    }

    // Если выбран только один объединенный лот
    if (selectedLots.length === 1 && selectedLots[0].positions.length > 1) {
      return true;
    }

    const maxLotsInOneCell = 4;

    return placedLotCount + newLotCount <= maxLotsInOneCell;
  });
};
