import { Lot } from 'entities/chess';

export const updateRisersColspan = (
  lots: Lot[],
  risers: Lot[],
  floorCount: number
) => {
  risers.forEach((riser, riserIndex) => {
    let maxColspan = 1;

    for (let floor = 1; floor <= floorCount; floor++) {
      // Находим все позиции для данного стояка на данном этаже
      const positionsOnThisFloorAndRiser = lots
        .flatMap((lot) => lot.positions)
        .filter((pos) => pos.riser === riserIndex + 1 && pos.floor === floor);

      // Если на этом этаже и стояке больше позиций, чем текущее maxColspan, обновляем его
      if (positionsOnThisFloorAndRiser.length > maxColspan) {
        maxColspan = positionsOnThisFloorAndRiser.length;
      }
    }

    risers[riserIndex] = { ...riser, colspan: maxColspan };
  });
};
