import { LotDto, LotPosition } from '../model/types/lotSchema';

export const fillPositionsMap = (
  positionsMap: { [key: number]: LotPosition[] },
  grid: (number | null)[][],
  chessRisersIds: number[],
  lotsDto: LotDto[]
) => {
  const realRisersIndexes = Array.from(new Set(chessRisersIds));
  // Проходим по каждому этажу
  for (let floor = 0; floor < grid.length; floor++) {
    // Проходим по каждому стояку
    for (let riserIndex = 0; riserIndex < chessRisersIds.length; riserIndex++) {
      const lotId = grid[floor][riserIndex];
      if (lotId === null) {
        continue; // Пропускаем пустые ячейки
      }

      const lot = lotsDto.find((l) => l.id === lotId);
      if (!lot) {
        continue; // Пропускаем, если лот не найден
      }

      // Добавляем позицию лота в карту, если ее еще нет
      if (!positionsMap[lotId]) {
        positionsMap[lotId] = [];
      }

      const realRiserIndex = realRisersIndexes.indexOf(
        chessRisersIds[riserIndex]
      );
      // Проверяем, не дублируется ли уже позиция
      const existingPosition = positionsMap[lotId].find(
        (pos) => pos.floor === floor + 1 && pos.riser === realRiserIndex + 1
      );
      if (!existingPosition) {
        positionsMap[lotId].push({
          floor: floor + 1,
          riser: realRiserIndex + 1,
        });
      }
    }
  }
};
