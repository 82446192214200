import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'i18next';
import { Container, Typography } from '@mui/material';
import { useGetBuildingsQuery } from 'entities/building';
import { useCreateChessMutation } from 'entities/chess';
import { Entrance } from 'entities/entrance';
import { ChessPicker, ChessPickerSkeleton } from 'features/chess';
import { getRoute } from 'shared/const';
import { useRouteMatch } from 'shared/hooks/useRouteMatch';
import { useSnackbar } from 'shared/hooks/useSnackbar';
import { AccentColor } from 'shared/theme/colors';
import { SelectOption } from 'shared/types';
import { ButtonLink } from 'shared/ui/ButtonLink';
import { Col } from 'shared/ui/Col';
import { Row } from 'shared/ui/Row';
// eslint-disable-next-line boundaries/element-types
import { Chessboard } from 'widgets/Chessboard/Chessboard';
// eslint-disable-next-line boundaries/element-types
import { Plans } from 'widgets/Plans/Plans';
import { EmptyChessList } from './EmptyChessList';

export const ChessList: FC = () => {
  const [isChessFullscreen, setIsChessFullscreen] = useState<boolean>(false);
  const { objectId, buildingId, entranceId } = useParams<{
    objectId: string;
    buildingId: string;
    entranceId: string;
  }>();
  const { data: buildingsData, isLoading: isBuildingsLoading } =
    useGetBuildingsQuery({
      objectId: Number(objectId),
      limit: 30,
      offset: 0,
      search: '',
    });
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [createChess, { isLoading: isChessCreating }] =
    useCreateChessMutation();
  const routeMatch = useRouteMatch([
    getRoute.ObjectPlans(':objectId', ':buildingId', ':entranceId'),
  ]);

  const isPlansMode =
    routeMatch?.pattern?.path ===
    getRoute.ObjectPlans(':objectId', ':buildingId', ':entranceId');

  const buildingOptions: SelectOption[] = useMemo(() => {
    if (!buildingsData) return [];
    return buildingsData.items
      .filter(({ entrances }) => entrances && entrances.length > 0)
      .map((building) => ({
        value: String(building.id),
        label: building.name,
      }));
  }, [buildingsData]);

  const entranceOptionsMap: Record<string, SelectOption[]> = useMemo(() => {
    const optionsRecord: Record<string, SelectOption[]> = {};
    if (!buildingsData) return optionsRecord;
    buildingsData.items.forEach((building) => {
      optionsRecord[building.id.toString()] = building.entrances
        ? building.entrances.map((entrance) => ({
            value: entrance.id,
            label: entrance.name,
          }))
        : [];
    });
    return optionsRecord;
  }, [buildingsData]);

  const handleCreateChess = useCallback(
    async (entrance: Entrance) => {
      try {
        await createChess({
          chess: {
            entranceId: entrance.id,
            floorsCount: Number(entrance.floorCount),
            risersCount: Number(entrance.riserCount),
          },
          objectId: Number(objectId),
          buildingId: Number(buildingId),
        });
      } catch (error) {
        console.error(error);
        showSnackbar();
      }
    },
    [buildingId, createChess, objectId, showSnackbar]
  );

  useEffect(() => {
    if (
      !buildingId &&
      !entranceId &&
      buildingOptions.length > 0 &&
      entranceOptionsMap[buildingOptions[0].value]
    ) {
      const defaultBuildingId = buildingOptions[0].value;
      const defaultEntranceId =
        entranceOptionsMap[buildingOptions[0].value][0].value;
      const route = isPlansMode
        ? getRoute.ObjectPlans
        : getRoute.ObjectChessboard;
      navigate(route(objectId!, defaultBuildingId, defaultEntranceId));
    } else {
      const selectedBuilding = buildingsData?.items.find(
        (building) => building.id.toString() === buildingId
      );
      const selectedEntrance = selectedBuilding?.entrances?.find(
        (entrance) => entrance.id.toString() === entranceId
      );
      if (selectedEntrance && !selectedEntrance.isChessPresent) {
        handleCreateChess(selectedEntrance);
      }
    }
  }, [
    buildingId,
    buildingOptions,
    buildingsData?.items,
    entranceId,
    entranceOptionsMap,
    handleCreateChess,
    isPlansMode,
    navigate,
    objectId,
  ]);

  if (isBuildingsLoading)
    return (
      <Container>
        <ChessPickerSkeleton />
      </Container>
    );

  if (!objectId) return <div>No data</div>;

  if (
    !buildingId ||
    !entranceId ||
    !buildingOptions.length ||
    !entranceOptionsMap[buildingId]?.length
  )
    return (
      <EmptyChessList
        onCreateBuildingClick={() =>
          navigate(getRoute.ObjectBuildingCreate(objectId))
        }
      />
    );

  const handleModeChange = () => {
    const route = isPlansMode
      ? getRoute.ObjectChessboard
      : getRoute.ObjectPlans;
    navigate(route(objectId, buildingId, entranceId));
  };

  return (
    <Col spacing={4}>
      <Row
        justifyContent="space-between"
        maxWidth={1024}
        alignItems="center"
        sx={{ gap: 5, m: 'auto' }}
      >
        <ChessPicker
          buildingOptions={buildingOptions}
          entranceOptions={entranceOptionsMap}
          objectId={objectId}
          buildingId={buildingId}
          entranceId={entranceId}
          isChessboardMode={!isPlansMode}
          onModeChange={handleModeChange}
        />
        <ButtonLink
          underlineType="dotted"
          onClick={() => setIsChessFullscreen(true)}
        >
          <Typography variant="accentM" color={AccentColor.Process}>
            {t('chess.fullscreen')}
          </Typography>
        </ButtonLink>
      </Row>
      {isPlansMode ? (
        <Plans />
      ) : (
        <Chessboard
          isFullscreen={isChessFullscreen}
          onCloseFullscreen={() => setIsChessFullscreen(false)}
        />
      )}
    </Col>
  );
};
