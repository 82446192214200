import { FC } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  AddContractorForm,
  Contractor,
  addContractorZod,
  useCreateContractorMutation,
} from 'entities/contractor';
import { useSnackbar } from 'shared/hooks/useSnackbar';
import { CardLayout } from 'shared/ui/CardLayout';

type CreateContractorCardProps = {
  onSaved: () => void;
  onCancel: () => void;
};

export const CreateContractorCard: FC<CreateContractorCardProps> = ({
  onSaved,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [createContractor] = useCreateContractorMutation();
  const { showSnackbar } = useSnackbar();

  const form = useForm<Contractor>({
    resolver: zodResolver(addContractorZod),
    mode: 'onSubmit',
    defaultValues: {
      contractorName: '',
      inn: '',
    },
  });

  const handleSaveContractorClick = async (contractorData: Contractor) => {
    try {
      await createContractor(contractorData).unwrap();
      onSaved();
    } catch (error) {
      console.error(error);
      showSnackbar();
    }
  };

  return (
    <CardLayout
      footer={{
        okLabel: t('ui.common.createAndSave'),
        okButtonColor: 'secondary',
        onOkClick: form.handleSubmit(handleSaveContractorClick),
        onCancelClick: onCancel,
        cancelLabel: t('ui.common.cancel'),
      }}
      variant="secondary"
    >
      <FormProvider {...form}>
        <AddContractorForm />
      </FormProvider>
    </CardLayout>
  );
};
