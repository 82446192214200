import React, {
  useContext,
  useState,
  useCallback,
  createContext,
  ReactNode,
} from 'react';
import { useTranslation } from 'react-i18next';
import Alert, { AlertColor } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

type SnackbarMessage = {
  message?: string;
  severity?: AlertColor;
};

interface SnackbarContextType {
  showSnackbar: (config?: SnackbarMessage) => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined
);

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};

export const SnackbarProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [snackbarConfig, setSnackbarConfig] = useState<SnackbarMessage>({});

  const showSnackbar = useCallback(
    ({
      message = 'ui.common.error.saveDataError',
      severity = 'error',
    }: SnackbarMessage = {}) => {
      setSnackbarConfig({ message, severity });
      setOpen(true);
    },
    []
  );

  const handleClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
    },
    []
  );

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar open={open} autoHideDuration={8000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackbarConfig.severity}
          sx={{ width: '100%' }}
        >
          {snackbarConfig.message && t(snackbarConfig.message)}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
