import { FC, useEffect } from 'react';
import dayjs from 'dayjs';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Card, Container, useScrollTrigger } from '@mui/material';
import { dateRangeTypeOptions } from 'entities/analytics';
import { Col } from 'shared/ui/Col';
import { Row } from 'shared/ui/Row';
import { SelectField } from 'shared/ui/SelectField';
import { StyledDatePicker } from 'shared/ui/StyledDatePicker';
import { MarketAnalyticsCard } from './AnalyticsCards/MarketAnalyticsCard';
import { ObjectAnalyticsCard } from './AnalyticsCards/ObjectAnalyticsCard';

export const AnalyticsTab: FC = () => {
  const { t } = useTranslation();
  const form = useForm({
    defaultValues: {
      dateRangeType: dateRangeTypeOptions[2].value,
      dateRangeStart: new Date(),
      dateRangeEnd: new Date(),
    },
  });

  const { watch, setValue } = form;
  const selectedDateRangeType = watch('dateRangeType');

  useEffect(() => {
    if (!selectedDateRangeType) return;
    const today = dayjs().endOf('day');
    let startDate;
    let endDate = today;

    switch (selectedDateRangeType) {
      case 1: // Сегодня
        startDate = today;
        break;
      case 2: // Вчера
        startDate = today.subtract(1, 'day');
        endDate = today.subtract(1, 'day');
        break;
      case 3: // Неделя
        startDate = today.subtract(1, 'week').add(1, 'day');
        break;
      case 4: {
        // Месяц
        startDate = today.subtract(1, 'month').add(1, 'day');
        break;
      }
      case 5: {
        // Квартал
        const startOfQuarter = today.startOf('quarter');
        const endOfQuarter = today.endOf('quarter');
        const totalDaysInQuarter = endOfQuarter.diff(startOfQuarter, 'day');
        startDate = today.subtract(totalDaysInQuarter, 'day');
        break;
      }
      case 6: {
        // Год
        startDate = today.subtract(1, 'year').add(1, 'day');
        break;
      }
      default:
        startDate = today;
    }

    setValue('dateRangeStart', startDate.toDate());
    setValue('dateRangeEnd', endDate.toDate());
  }, [selectedDateRangeType, setValue]);

  const resetDateRange = () => {
    form.setValue('dateRangeType', '');
  };

  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 400,
  });

  return (
    <Container>
      <Col spacing={8} sx={{ mt: scrollTrigger ? '82px' : 0 }}>
        {/* <Card
        sx={{
          width: 'fit-content',
          p: 5,
          position: scrollTrigger ? 'fixed' : 'static',
          top: scrollTrigger ? 24 : 'auto',
          zIndex: scrollTrigger ? 100 : 1,
        }}
      >
        <FormProvider {...form}>
          <Row spacing={5}>
            <SelectField
              options={dateRangeTypeOptions}
              label={t('analytics.filter.label.period')}
              name={'dateRangeType'}
              sx={{ width: 160 }}
            />
            <StyledDatePicker
              label={t('analytics.filter.label.periodStart')}
              name="dateRangeStart"
              onChange={resetDateRange}
              sx={{ width: 240 }}
            />
            <StyledDatePicker
              label={t('analytics.filter.label.periodEnd')}
              name="dateRangeEnd"
              onChange={resetDateRange}
              sx={{ width: 240 }}
            />
          </Row>
        </FormProvider>
      </Card> */}

        <Col
          spacing={9}
          sx={{ maxWidth: 684 }}
          //  sx={{ width: '1343px', ml: '-159.5px' }}
        >
          <ObjectAnalyticsCard />
          {/* <MarketAnalyticsCard /> */}
        </Col>
      </Col>
    </Container>
  );
};
