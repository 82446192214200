import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import {
  decorationOptions,
  numberOfRoomsOptions,
  typeOptions,
} from 'shared/selectOptions/chessOptions';
import { BorderColor, TextColor } from 'shared/theme/colors';
import { Col } from 'shared/ui/Col';
import { NumericField } from 'shared/ui/NumericField';
import { Row } from 'shared/ui/Row';
import { SelectWithChips } from 'shared/ui/SelectWithChips';

export type RangeFilter = {
  from?: string;
  to?: string;
};

export type LotsFilters = {
  totalPrice: RangeFilter;
  pricePerMeter: RangeFilter;
  totalArea: RangeFilter;
  numberOfRooms: string[];
  lotsTypes: string[];
  decorations: string[];
  // registrations?: string[];
};

type FiltersDialogProps = {
  open: boolean;
  filters?: Partial<LotsFilters>;
  onClearFiltersClick: () => void;
  onSaveClick: (filters: Partial<LotsFilters>) => void;
  onCancelClick: () => void;
};

export const FiltersDialog: FC<FiltersDialogProps> = ({
  open,
  filters,
  onClearFiltersClick,
  onSaveClick,
  onCancelClick,
}) => {
  const { t } = useTranslation();
  const form = useForm<LotsFilters>({
    mode: 'onTouched',
    defaultValues: {
      decorations: filters?.decorations ?? [],
      numberOfRooms: filters?.numberOfRooms ?? [],
      pricePerMeter: {
        from: filters?.pricePerMeter?.from ?? '',
        to: filters?.pricePerMeter?.to ?? '',
      },
      // registrations: filters?.registrations ?? [],
      totalArea: {
        from: filters?.totalArea?.from ?? '',
        to: filters?.totalArea?.to ?? '',
      },
      totalPrice: {
        from: filters?.totalPrice?.from ?? '',
        to: filters?.totalPrice?.to ?? '',
      },
      lotsTypes: filters?.lotsTypes ?? [],
    },
  });

  useEffect(() => {
    form.reset({
      decorations: filters?.decorations ?? [],
      numberOfRooms: filters?.numberOfRooms ?? [],
      pricePerMeter: {
        from: filters?.pricePerMeter?.from ?? '',
        to: filters?.pricePerMeter?.to ?? '',
      },
      // registrations: filters?.registrations ?? [],
      totalArea: {
        from: filters?.totalArea?.from ?? '',
        to: filters?.totalArea?.to ?? '',
      },
      totalPrice: {
        from: filters?.totalPrice?.from ?? '',
        to: filters?.totalPrice?.to ?? '',
      },
      lotsTypes: filters?.lotsTypes ?? [],
    });
  }, [filters, form]);

  function getDirtyValues<
    DirtyFields extends Record<string, unknown>,
    Values extends Record<keyof DirtyFields, unknown>,
  >(dirtyFields: DirtyFields, values: Values): Partial<typeof values> {
    return Object.keys(dirtyFields).reduce((prev, key) => {
      // Unsure when RFH sets this to `false`, but omit the field if so.
      if (!dirtyFields[key]) return prev;

      return {
        ...prev,
        [key]:
          typeof dirtyFields[key] === 'object'
            ? getDirtyValues(
                dirtyFields[key] as DirtyFields,
                values[key] as Values
              )
            : values[key],
      };
    }, {});
  }

  const handleSaveClick = () => {
    const newFilters: Partial<LotsFilters> = getDirtyValues(
      form.formState.dirtyFields,
      form.getValues()
    );
    onSaveClick(newFilters);
  };

  return (
    <Dialog
      open={open}
      hideBackdrop
      scroll={'paper'}
      sx={{
        pointerEvents: 'none',
        '& .MuiDialog-container': {
          justifyContent: 'flex-end',
        },
      }}
      PaperProps={{
        sx: {
          width: 450,
          height: 773,
          pointerEvents: 'all',
          border: 'none',
          marginRight: 11,
          boxShadow: `0 0 0 1px ${BorderColor.Gray}, 0px 0px 8px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.08)`,
        },
      }}
    >
      <DialogContent
        dividers
        sx={{ p: 0, mt: 9, mx: 8, mb: 4, borderTop: 'none' }}
      >
        <FormProvider {...form}>
          <Col spacing={9}>
            <Typography variant={'headerM'}>
              {t('chess.filter.title')}
            </Typography>

            <Button
              size="small"
              variant="outlined"
              onClick={onClearFiltersClick}
            >
              {t('chess.filter.discardFilters')}
            </Button>
            <Col spacing={5}>
              <Typography variant="headerXS">
                {t('chess.filter.priceM2')}
              </Typography>
              <Row spacing={5} wrap={false} equalsChildrenWidth>
                <NumericField
                  label={t('chess.filter.from')}
                  name={'pricePerMeter.from'}
                  valueIsNumericString
                  maxLength={8}
                  showCharacterHintAtRemaining={1}
                  allowNegative={false}
                  decimalScale={0}
                />
                <NumericField
                  label={t('chess.filter.to')}
                  name={'pricePerMeter.to'}
                  valueIsNumericString
                  maxLength={8}
                  showCharacterHintAtRemaining={1}
                  allowNegative={false}
                  decimalScale={0}
                />
              </Row>
            </Col>
            <Col spacing={5}>
              <Typography variant="headerXS">
                {t('chess.filter.price')}
              </Typography>
              <Row spacing={5} wrap={false} equalsChildrenWidth>
                <NumericField
                  label={t('chess.filter.from')}
                  name={'totalPrice.from'}
                  valueIsNumericString
                  maxLength={8}
                  showCharacterHintAtRemaining={1}
                  allowNegative={false}
                  decimalScale={0}
                />
                <NumericField
                  label={t('chess.filter.to')}
                  name={'totalPrice.to'}
                  valueIsNumericString
                  maxLength={8}
                  showCharacterHintAtRemaining={1}
                  allowNegative={false}
                  decimalScale={0}
                />
              </Row>
            </Col>
            <Col spacing={5}>
              <Typography variant="headerXS">
                {t('chess.filter.area')}
              </Typography>
              <Row spacing={5} wrap={false} equalsChildrenWidth>
                <NumericField
                  label={t('chess.filter.from')}
                  name={'totalArea.from'}
                  valueIsNumericString
                  maxLength={8}
                  showCharacterHintAtRemaining={1}
                  allowNegative={false}
                  decimalScale={0}
                />
                <NumericField
                  label={t('chess.filter.to')}
                  name={'totalArea.to'}
                  valueIsNumericString
                  maxLength={8}
                  showCharacterHintAtRemaining={1}
                  allowNegative={false}
                  decimalScale={0}
                />
              </Row>
            </Col>
            <Col spacing={5}>
              <Typography variant="headerXS">
                {t('chess.filter.roominess')}
              </Typography>
              <SelectWithChips
                options={numberOfRoomsOptions}
                label={t('chess.filter.numberOfRooms')}
                name="numberOfRooms"
              />
            </Col>
            <Col spacing={5}>
              <Typography variant="headerXS">
                {t('chess.filter.placement')}
              </Typography>
              <SelectWithChips
                options={typeOptions}
                label={t('chess.filter.lotType')}
                name="lotsTypes"
              />
              <SelectWithChips
                options={decorationOptions}
                label={t('chess.filter.lot')}
                name="decorations"
              />
            </Col>
          </Col>
        </FormProvider>
      </DialogContent>

      <DialogActions
        sx={{
          p: 8,
          pb: 9,
          justifyContent: 'start',
          backgroundColor: TextColor.StaticWhite,
        }}
      >
        <Button color="secondary" onClick={handleSaveClick}>
          {t('ui.common.apply')}
        </Button>

        <Button variant="outlined" onClick={onCancelClick}>
          {t('ui.common.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
