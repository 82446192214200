import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useVersionCheck } from 'entities/object';
import {
  SavePlanData,
  createPlanZod,
  useCreatePlanMutation,
  useGetPlanQuery,
  useUpdatePlanMutation,
  editPlanZod,
} from 'entities/plan';
import { getLabelByValue } from 'shared/helpers/getLabelByValue';
import { useSnackbar } from 'shared/hooks/useSnackbar';
import { SelectOption } from 'shared/types';
import { CardLayout } from 'shared/ui/CardLayout';
import { ImageUploadField } from 'shared/ui/ImageUploadField';
import { SelectWithChips } from 'shared/ui/SelectWithChips';
import { EditPlanFormSkeleton } from './EditPlanFormSkeleton';

type EditPlanFormProps = {
  planId: number | null;
  entranceId: number | string;
  floorsOptions: SelectOption[];
  onSaved: VoidFunction;
  onCancel: VoidFunction;
};

export const EditPlanForm: FC<EditPlanFormProps> = ({
  entranceId,
  floorsOptions,
  planId,
  onSaved,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { objectId } = useParams<{ objectId: string }>();

  const { checkVersion, showVersionChangedPopup } = useVersionCheck(objectId);

  const form = useForm<SavePlanData>({
    resolver: zodResolver(planId ? editPlanZod : createPlanZod),
    mode: 'onSubmit',
    defaultValues: {
      entranceId: entranceId.toString(),
      name: '',
      floorIds: [],
    },
  });

  const { data: planData, isLoading: isPlanLoading } = useGetPlanQuery(
    planId ?? -1,
    { skip: !planId }
  );
  const [createPlan, { isLoading: isPlanCreating }] = useCreatePlanMutation();
  const [updatePlan, { isLoading: isPlanUpdating }] = useUpdatePlanMutation();
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (planData) {
      form.reset({
        floorIds: planData.floorIds,
        name: planData.name,
        entranceId: entranceId.toString(),
      });
    }
  }, [planData, entranceId, form]);

  const handleSavePlan = async (data: SavePlanData) => {
    const isVersionChanged = await checkVersion();

    if (!isVersionChanged) {
      try {
        if (planData) {
          await updatePlan({
            objectId,
            planData: {
              ...planData,
              ...data,
              name: data.floorIds
                .map((floorId) => getLabelByValue(floorsOptions, floorId))
                .join(','),
            },
          }).unwrap();
        } else {
          await createPlan({
            objectId,
            planData: {
              ...data,
              name: data.floorIds
                .map((floorId) => getLabelByValue(floorsOptions, floorId))
                .join(','),
            },
          }).unwrap();
        }
        onSaved();
      } catch (error) {
        console.error(error);
        showSnackbar();
      }
    }
  };

  if (isPlanLoading) return <EditPlanFormSkeleton />;

  return (
    <FormProvider {...form}>
      {showVersionChangedPopup()}
      <CardLayout
        header={{
          title: planData
            ? t('plan.dialog.editingPlan')
            : t('plan.dialog.addingPlan'),
        }}
        maxWidth={600}
        footer={{
          onOkClick: form.handleSubmit(handleSavePlan),
          onCancelClick: onCancel,
          okLabel: t('ui.common.save'),
          cancelLabel: t('ui.common.cancel'),
          okButtonLoading: isPlanCreating || isPlanUpdating,
        }}
      >
        <SelectWithChips
          name={'floorIds'}
          options={floorsOptions}
          label={t('plan.dialog.floors')}
          required
        />
        <ImageUploadField
          name={'image'}
          accept=".png"
          fileExtensions={['.png']}
          maxFileSize={20}
        />
      </CardLayout>
    </FormProvider>
  );
};
