import { useCallback, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import {
  DeveloperObjectOutletData,
  EditDeveloperObject,
  MAX_SALES_OFFICES,
  useGetCitiesQuery,
} from 'entities/object';
// eslint-disable-next-line boundaries/element-types
import {
  SalesOffice,
  SalesOfficeInfoCard,
  useCreateSalesOfficeMutation,
} from 'entities/salesOffice';
// eslint-disable-next-line boundaries/element-types
import { AddSalesOfficeCard } from 'features/salesOffice';
import {
  Cities,
  extractMicrodistrictsByCityName,
} from 'shared/helpers/getCityMicrodistrictOptions';
import { SelectOption } from 'shared/types';
import { Col } from 'shared/ui/Col';
import { MapInput } from 'shared/ui/MapInput';
import { NumericField } from 'shared/ui/NumericField';
import { Row } from 'shared/ui/Row';
import { SelectField } from 'shared/ui/SelectField';

export const ObjectLocationEditForm = () => {
  const { t } = useTranslation();
  const form = useFormContext<EditDeveloperObject>();
  const [isAddingSalesOffice, setIsAddingSalesOffice] =
    useState<boolean>(false);
  const [editableSalesOffice, setEditableSalesOffice] = useState<SalesOffice>();
  const [createSalesOffice, { isLoading: isSalesOfficeCreating }] =
    useCreateSalesOfficeMutation();

  const outletData = useOutletContext<DeveloperObjectOutletData>();

  let salesOffices: SalesOffice[] = [];

  if (outletData) {
    salesOffices = [...outletData.objectSalesOffices].filter(
      (item) => !item.isDeleted
    );
  } else {
    salesOffices = form.getValues('salesOffices') as SalesOffice[];
  }

  const citiesDataResult = useGetCitiesQuery();
  const citiesData = citiesDataResult.data;
  let sochiMicrodistrictOptions: SelectOption[] = [];

  if (citiesData) {
    sochiMicrodistrictOptions = extractMicrodistrictsByCityName(
      Cities.Sochi,
      citiesData
    );
  }

  const handleAddingSalesOffice = () => {
    setIsAddingSalesOffice(true);
  };

  const handleSalesOfficeAdded = async (editedSalesOffice: SalesOffice) => {
    if (
      salesOffices.some(
        (salesOffice) => salesOffice.id === editedSalesOffice.id
      )
    ) {
      if (outletData) {
        outletData.handleAddSalesOffice(editedSalesOffice);
      } else {
        const updatedSalesOffices = salesOffices.map((salesOffice) =>
          salesOffice.id === editedSalesOffice.id
            ? editedSalesOffice
            : salesOffice
        );
        form.setValue('salesOffices', updatedSalesOffices);
      }
    } else {
      if (outletData) {
        try {
          const result = await createSalesOffice(editedSalesOffice).unwrap();
          outletData.handleAddSalesOffice(result);
        } catch (error) {
          console.error(error);
        }
      } else {
        form.setValue('salesOffices', [...salesOffices, editedSalesOffice]);
      }
    }

    setIsAddingSalesOffice(false);
    setEditableSalesOffice(undefined);
  };

  const handleSalesOfficeAddingCancel = () => {
    setIsAddingSalesOffice(false);
    setEditableSalesOffice(undefined);
  };

  const handleDeleteClick = useCallback(
    (salesOffice: SalesOffice) => {
      if (outletData) {
        outletData.handleDeleteSalesOffice(salesOffice);
      } else {
        const objectFormSalesOffices = form.getValues('salesOffices');
        form.setValue(
          'salesOffices',
          objectFormSalesOffices.filter(
            (item) => (item as SalesOffice).id !== salesOffice.id
          )
        );
      }
    },
    [form, outletData]
  );

  const handleEditClick = (salesOffice: SalesOffice) => {
    if (outletData) {
      setEditableSalesOffice(salesOffice);
    } else {
      const matchingOffice = salesOffices.find(
        (office) => office.id === salesOffice.id
      );
      setEditableSalesOffice(matchingOffice);
    }
    setIsAddingSalesOffice(true);
  };

  const addedSalesOffices =
    isAddingSalesOffice || !salesOffices.length ? null : (
      <Col spacing={8}>
        {salesOffices.map((salesOffice) => {
          return (
            <SalesOfficeInfoCard
              key={salesOffice.id}
              salesOffice={salesOffice}
              onEditClick={handleEditClick}
              onRemoveClick={handleDeleteClick}
            />
          );
        })}
      </Col>
    );

  const getDefaultCoords = (storedObject?: {
    latitude: number | null;
    longitude: number | null;
  }): [number, number] | undefined => {
    if (storedObject?.latitude && storedObject?.longitude) {
      return [storedObject.latitude, storedObject.longitude];
    }
    return undefined;
  };

  return (
    <Col spacing={8} sx={{ mt: 3 }}>
      <MapInput
        defaultCoords={getDefaultCoords(outletData?.storedObject)}
        onChange={(value, latitude, longitude) => {
          form.setValue('address', value);
          form.setValue('latitude', latitude);
          form.setValue('longitude', longitude);
          form.trigger('address');
        }}
        error={!!form.formState.errors.address}
        required
      />
      {sochiMicrodistrictOptions.length > 0 && (
        <SelectField
          options={sochiMicrodistrictOptions}
          label={t('object.form.label.microdistrict')}
          required
          name={'microdistrict'}
          noTranslate
        />
      )}
      <Row spacing={5} equalsChildrenWidth>
        <NumericField
          label={t('object.form.label.seaDistance')}
          name="seaDistance"
          valueIsNumericString
          maxLength={5}
          showCharacterHintAtRemaining={1}
          allowNegative={false}
          decimalScale={0}
        />
      </Row>

      {/* TODO: тут импортируется компонент из фич, нужен рефакторинг */}
      {addedSalesOffices}

      {isAddingSalesOffice && (
        <AddSalesOfficeCard
          salesOffice={editableSalesOffice}
          onSaved={handleSalesOfficeAdded}
          onCancel={handleSalesOfficeAddingCancel}
          isSalesOfficeCreating={isSalesOfficeCreating}
        />
      )}
      {salesOffices.length <= MAX_SALES_OFFICES && (
        <Button
          size="small"
          disabled={isAddingSalesOffice}
          onClick={handleAddingSalesOffice}
        >
          {t('object.form.label.addSalesOffice')}
        </Button>
      )}
    </Col>
  );
};
