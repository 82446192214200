import { FC } from 'react';
import { Box } from '@mui/material';
import { BgColor } from 'src/shared/theme/colors';

type DisabledCellProps = {
  mergedToLeft?: boolean;
  mergedToTop?: boolean;
  mergedToRight?: boolean;
  mergedToBottom?: boolean;
  selected?: boolean;
  onClick?: () => void;
};
// TODO: нужен рефакторинг, используются фичи и ентитисы
export const EmptyCell: FC = () => {
  return (
    <Box
      sx={{
        bgcolor: BgColor.Gray,
        boxShadow:
          '-1px 0 0 0 #DBDBDB, 0 -1px 0 0 #DBDBDB, 1px 0 0 0 #DBDBDB, 0 1px 0 0 #DBDBDB;',
        p: '8px',
        display: 'flex',
      }}
    >
      Empty space
    </Box>
  );
};
