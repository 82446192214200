import { FC } from 'react';
import i18n from 'i18next';
import { InputAdornment, SxProps } from '@mui/material';
import { BgColor, TextColor } from 'shared/theme/colors';
import { SearchIcon } from './Icons';
import { StyledTextField } from './StyledTextField';

type SearchInputProps = {
  searchPlaceholder?: string;
  name: string;
  onSearchChange?: (value: string) => void;
  color?: 'white' | 'grey';
  sx?: SxProps;
};

export const SearchInput: FC<SearchInputProps> = ({
  searchPlaceholder = i18n.t('ui.common.search'),
  name,
  onSearchChange,
  color = 'grey',
  sx,
}) => {
  const bgColor = color === 'grey' ? BgColor.Input : TextColor.StaticWhite;

  return (
    <StyledTextField
      placeholder={searchPlaceholder}
      name={name}
      size="small"
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            unselectable={'on'}
            variant="standard"
          >
            <SearchIcon fontSize="small" />
          </InputAdornment>
        ),
        sx: {
          bgcolor: bgColor,
          '&:hover': {
            bgcolor: bgColor,
          },
          '&.Mui-focused': {
            bgcolor: bgColor,
          },
        },
      }}
      onKeyDown={(e) => {
        if (e.key !== 'Escape') {
          e.stopPropagation();
        }
      }}
      onChange={(e) => {
        const newValue = e.target.value;
        onSearchChange?.(newValue);
      }}
      sx={{ ...sx }}
    />
  );
};
