import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
} from '@mui/material';

interface CheckboxFieldProps extends Omit<FormControlLabelProps, 'control'> {
  name: string;
}

export const CheckboxField: FC<CheckboxFieldProps> = ({ name, ...props }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl>
          <FormControlLabel
            sx={{ m: 0, gap: 4 }}
            control={<Checkbox checked={field.value} {...field} />}
            {...props}
          />
          {error && error.message && (
            <FormHelperText>{t(error.message)}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};
