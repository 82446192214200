import { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { getRoute } from 'shared/const';
import { useAppSelector } from 'shared/hooks/hooks';

export const DevelopersRoute: FC<PropsWithChildren> = ({ children }) => {
  const user = useAppSelector((state) => state.profile.profile);
  const location = useLocation();

  if (!user) {
    return (
      <Navigate to={getRoute.AuthLogin()} state={{ from: location }} replace />
    );
  }

  if (user.developerId) {
    return <Navigate to={getRoute.Developer(user.developerId)} replace />;
  }

  return <>{children}</>;
};
