import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'shared/ui/Col';
import { FileUploadField } from 'shared/ui/FileUploadField';
import { StyledTextField } from 'shared/ui/StyledTextField';

export const DocumentEditForm: FC = () => {
  const { t } = useTranslation();

  return (
    <Col spacing={8}>
      <StyledTextField
        label={t('document.form.label.name')}
        required
        name="name"
        maxLength={30}
        showCharacterHintAtRemaining={10}
      />
      <FileUploadField
        showFileExtensions
        name="file"
        isSkipUpload
        maxFileSize={20}
      />
    </Col>
  );
};
