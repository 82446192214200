import { LotPosition } from 'entities/chess/model/types/lotSchema';
import { GridLot } from 'shared/ui/LotsGrid/types';

const arePositionsNeighbors = (
  pos1: LotPosition,
  pos2: LotPosition
): boolean => {
  const floorDiff = Math.abs(pos1.floor - pos2.floor);
  const riserDiff = Math.abs(pos1.riser - pos2.riser);

  return (
    (floorDiff === 0 && riserDiff === 0) ||
    (floorDiff === 1 && riserDiff === 0) ||
    (floorDiff === 0 && riserDiff === 1)
  );
};

const areLotsNeighbors = (
  lot1: { positions: LotPosition[] },
  lot2: { positions: LotPosition[] }
): boolean => {
  for (const pos1 of lot1.positions) {
    for (const pos2 of lot2.positions) {
      if (arePositionsNeighbors(pos1, pos2)) {
        return true;
      }
    }
  }
  return false;
};

const dfs = (
  node: number,
  visited: boolean[],
  adjacencyList: number[][]
): void => {
  visited[node] = true;
  for (const neighbor of adjacencyList[node]) {
    if (!visited[neighbor]) {
      dfs(neighbor, visited, adjacencyList);
    }
  }
};

export const areAllLotsNeighbors = (selectedLots: GridLot[]): boolean => {
  const adjacencyList: number[][] = Array(selectedLots.length)
    .fill(null)
    .map(() => []);

  for (let i = 0; i < selectedLots.length; i++) {
    for (let j = i + 1; j < selectedLots.length; j++) {
      if (areLotsNeighbors(selectedLots[i], selectedLots[j])) {
        adjacencyList[i].push(j);
        adjacencyList[j].push(i);
      }
    }
  }

  const visited: boolean[] = Array(selectedLots.length).fill(false);
  let clusterCount = 0;

  for (let i = 0; i < selectedLots.length; i++) {
    if (!visited[i]) {
      dfs(i, visited, adjacencyList);
      clusterCount++;
    }
  }

  if (clusterCount !== 1) {
    return false;
  }

  const allPositions = selectedLots.flatMap((lot) => lot.positions);
  const minFloor = Math.min(...allPositions.map((p) => p.floor));
  const maxFloor = Math.max(...allPositions.map((p) => p.floor));
  const minRiser = Math.min(...allPositions.map((p) => p.riser));
  const maxRiser = Math.max(...allPositions.map((p) => p.riser));

  const totalSelectedCells =
    (maxFloor - minFloor + 1) * (maxRiser - minRiser + 1);

  return (
    totalSelectedCells === allPositions.length ||
    (totalSelectedCells === 1 && allPositions.length > 1)
  );
};
