import { $rtkApi, ApiTag } from 'shared/api/rtkApi';
import { ResponseData } from 'shared/types';
// eslint-disable-next-line boundaries/element-types
import { sortByPositions } from 'widgets/Chessboard/lib/getSelectedLots';
import {
  mapChessFromDto,
  mapDtoFromNewChess,
  mapUpdatedChessToDto,
} from '../../lib/mapChess';
import { mapDtoFromLot } from '../../lib/mapLot';
import {
  Chess,
  ChessDto,
  Lot,
  NewChess,
  UpdatedChess,
} from '../types/lotSchema';

export const chessApi = $rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    createChess: builder.mutation<
      Chess,
      { chess: NewChess; objectId: number; buildingId: number }
    >({
      query: ({ chess }) => ({
        url: '/chess',
        method: 'POST',
        body: mapDtoFromNewChess(chess),
      }),
      transformResponse: (response: ResponseData<ChessDto>) =>
        mapChessFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, { objectId, buildingId, chess }) => [
        { type: ApiTag.chess },
        { type: ApiTag.chess, id: chess.entranceId },
        { type: ApiTag.building, id: buildingId },
        { type: ApiTag.objectPublicationErrors, id: objectId },
        { type: ApiTag.object, id: objectId },
      ],
    }),

    updateChess: builder.mutation<
      Chess,
      { chess: UpdatedChess; objectId: number; buildingId: number }
    >({
      query: ({ chess }) => ({
        url: `/chess/${chess.entranceId}`,
        method: 'PUT',
        body: { chess: mapUpdatedChessToDto(chess) },
      }),
      async onQueryStarted(
        { chess: { entranceId, ...patch } },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          chessApi.util.updateQueryData(
            'getChess',
            entranceId.toString(),
            (draft) => {
              draft.risers = patch.risers.map((newRiser, i) => ({
                ...newRiser,
                colspan: newRiser.colspan || 1,
                id: i + 1,
              }));

              draft.floors = patch.floors.map((newFloor, i) => ({
                ...newFloor,
                id: 'id' in newFloor && newFloor.id ? newFloor.id : i,
              }));
              draft.floors.sort(sortByPositions);

              draft.lots = patch.lots.map((newLot, i) => ({
                ...newLot,
                id: 'id' in newLot && newLot.id ? newLot.id : i,
                headId: newLot.positions[0].riser,
              }));
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      transformResponse: (response: ResponseData<ChessDto>) =>
        mapChessFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, { chess, objectId, buildingId }) => [
        { type: ApiTag.chess },
        { type: ApiTag.chess, id: chess.entranceId },
        { type: ApiTag.objectPublicationErrors, id: objectId },
        { type: ApiTag.building, id: buildingId },
        { type: ApiTag.object, id: objectId },
      ],
    }),

    updateLot: builder.mutation<Chess, { entranceId: number; lot: Lot }>({
      query: (params) => ({
        url: `/chess/lot/${params.lot.id}`,
        method: 'PUT',
        body: mapDtoFromLot(params.lot),
      }),
      transformResponse: (response: ResponseData<ChessDto>) =>
        mapChessFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, params) => [
        { type: ApiTag.chess, id: params.entranceId },
        { type: ApiTag.chess },
      ],
    }),

    getChess: builder.query<Chess, number | string>({
      query: (entranceId) => ({
        url: `/chess/${entranceId}`,
        method: 'GET',
      }),
      providesTags: (result, error, entranceId) =>
        result
          ? [{ type: ApiTag.chess, entranceId }, { type: ApiTag.chess }]
          : [{ type: ApiTag.chess }],
      transformResponse: (response: ResponseData<ChessDto>) =>
        mapChessFromDto(response.data),
    }),

    deleteChess: builder.mutation<void, string | number>({
      query: (entranceId) => ({
        url: `/chess/${entranceId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, entranceId) => [
        { type: ApiTag.chess },
        { type: ApiTag.chess, id: entranceId },
      ],
    }),
  }),
});

export const {
  useCreateChessMutation,
  useUpdateChessMutation,
  useUpdateLotMutation,
  useGetChessQuery,
  useDeleteChessMutation,
} = chessApi;
