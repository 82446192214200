import { FC } from 'react';
import { Container } from '@mui/material';
import { RectSkeleton } from 'shared/ui/Skeleton/RectSkeleton';

export const EditPlanFormSkeleton: FC = () => {
  return (
    <Container>
      <RectSkeleton width={600} height={360} />
    </Container>
  );
};
