import { z } from 'zod';

function isPhoneNumber(phone: string) {
  const numericPhone = phone.replace(/\D/g, '');
  return numericPhone.length === 11;
}

const optionalPhoneValidator = z
  .string()
  .optional()
  .transform((val) => val || null)
  .nullable()
  .refine((value) => value === null || isPhoneNumber(value), {
    message: 'ui.common.error.incorrectPhone',
  });

const requiredPhoneValidator = z
  .string({ required_error: 'ui.common.error.enterPhone' })
  .min(1, 'ui.common.error.enterPhone')
  .refine((value) => isPhoneNumber(value), {
    message: 'ui.common.error.incorrectPhone',
  });

export const phoneValidator = {
  optional: optionalPhoneValidator,
  required: requiredPhoneValidator,
};
