import {
  DeveloperUser,
  DeveloperUserDto,
  NewDeveloperUser,
} from '../model/types/developerUserSchema';

export const mapDeveloperUserFromDto = ({
  id,
  first_name,
  last_name,
  role_id,
  email,
  phone,
  position,
  developer_id,
}: DeveloperUserDto): DeveloperUser => ({
  id: id!,
  firstName: first_name,
  lastName: last_name || '',
  developerId: developer_id,
  roleId: role_id,
  phone: phone?.toString() || '',
  email,
  position,
});

export const mapDtoFromDeveloperUser = ({
  firstName,
  lastName,
  roleId,
  email,
  phone,
  position,
  developerId,
  ...rest
}: DeveloperUser | NewDeveloperUser): DeveloperUserDto => ({
  id: 'id' in rest ? rest.id : undefined,
  developer_id: developerId,
  first_name: firstName,
  last_name: lastName || null,
  role_id: roleId,
  phone: Number(phone),
  email,
  position,
});
