import { z } from 'zod';

export type EntranceDto = {
  id: number;
  pavilion_id: number;
  name: string;
  floor_amount: number | null;
  riser_amount: number | null;
  count_chess: number;
  min_area_all: number;
  min_price_all: number;
};

export type SaveEntranceDto = Omit<
  EntranceDto,
  'count_chess' | 'min_area_all' | 'min_price_all'
>;

export const entranceZod = z.object({
  id: z.number(),
  buildingId: z.number(),
  name: z.string().min(1, 'entrance.form.error.name').max(30),
  floorCount: z
    .string()
    .min(1, 'ui.common.errorEnterValue')
    .max(2)
    .refine((data) => parseInt(data) > 0, {
      message: 'entrance.form.error.floorMoreThanZero',
    }),
  riserCount: z
    .string()
    .min(1, 'ui.common.errorEnterValue')
    .max(2)
    .refine((data) => parseInt(data) > 0, {
      message: 'entrance.form.error.riserMoreThanZero',
    }),
  floorsNames: z.string().array().optional(),
  risersNames: z.string().array().optional(),
  lotsCount: z.number(),
  minAreaAll: z.number(),
  minPriceAll: z.number(),
});

export type Entrance = z.infer<typeof entranceZod>;
