import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Container, Typography } from '@mui/material';
import {
  Document,
  DocumentDetailsCard,
  useGetObjectDocumentsQuery,
} from 'entities/document';
import { PaletteColor } from 'shared/theme/colors';
import { Col } from 'shared/ui/Col';
import { DocumentsDetailsSkeleton } from './DocumentsDetailsSkeleton';
import { EditDocument } from './EditDocument';

type Mode = 'ADD' | 'EDIT' | 'NONE';

export const DocumentsDetails: FC = () => {
  const { t } = useTranslation();
  const { objectId } = useParams<{ objectId: string }>();
  const [mode, setMode] = useState<Mode>('NONE');
  const [editingId, setEditingId] = useState<number | undefined>();

  const {
    data: documentsData,
    isLoading: isDocumentsLoading,
    isError: isDocumentsLoadingError,
  } = useGetObjectDocumentsQuery({
    objectId: Number(objectId),
    limit: 30,
    offset: 0,
    search: '',
  });
  const documents = documentsData?.items;

  const handleAddingDocument = () => {
    setMode('ADD');
    setEditingId(undefined);
  };

  const handleAddingDocumentCancel = () => {
    setMode('NONE');
  };

  const handleEditClick = (document: Document) => {
    setMode('EDIT');
    setEditingId(document.id);
  };

  if (isDocumentsLoading) return <DocumentsDetailsSkeleton />;

  return (
    <Container>
      <Col spacing={8} maxWidth={606}>
        <Button
          color="secondary"
          size="small"
          onClick={handleAddingDocument}
          disabled={mode !== 'NONE'}
        >
          {t('document.addDocument')}
        </Button>
        {isDocumentsLoadingError && (
          <Typography variant={'paragraphS'} color={PaletteColor.Error}>
            {t('document.card.error.download')}
          </Typography>
        )}
        {mode === 'ADD' && (
          <EditDocument
            id={undefined}
            objectId={Number(objectId)}
            onCancelClick={handleAddingDocumentCancel}
            onSaved={handleAddingDocumentCancel}
          />
        )}
        {documents &&
          documents.map((document) =>
            mode === 'EDIT' && editingId === document.id ? (
              <EditDocument
                key={1}
                id={editingId}
                objectId={Number(objectId)}
                document={document}
                onCancelClick={handleAddingDocumentCancel}
                onSaved={handleAddingDocumentCancel}
              />
            ) : (
              <DocumentDetailsCard
                document={document}
                objectId={Number(objectId)}
                key={document.id}
                onEditClick={handleEditClick}
              />
            )
          )}
      </Col>
    </Container>
  );
};
