import { Lot } from 'entities/chess/model/types/lotSchema';

export const positionsToColspanRowspan = (
  lot: Lot,
  riser: Lot,
  lots: Lot[]
): { row: number; colspan: number; rowspan: number; step?: number } => {
  const floor = Math.max(...lot.positions.map((pos) => pos.floor));
  const riserNum = Math.min(...lot.positions.map((pos) => pos.riser));

  const isRiserHasSplittedLots = riser.colspan > 1;

  let isSplittedLot = false;
  let step: number | undefined;
  if (isRiserHasSplittedLots) {
    const lotsInCell = lots.filter((lot) =>
      lot.positions.some((pos) => pos.floor === floor && pos.riser === riserNum)
    );

    isSplittedLot = lotsInCell.length > 1;
    if (isSplittedLot) {
      // const steps = lotsInCell
      //   .map((lot) => lot.step)
      //   .filter(Boolean) as number[];
      //
      // const lastStep = steps.length
      //   ? Math.max(...steps)
      //   : lotsInCell.indexOf(lot);
      step = lotsInCell.indexOf(lot) + 1;
    }
  }

  let colspan = new Set(lot.positions.map(({ riser }) => riser)).size;

  if (isRiserHasSplittedLots && !isSplittedLot) {
    colspan = riser.colspan;
  }

  const rowspan = new Set(lot.positions.map(({ floor }) => floor)).size;

  return { row: floor, colspan, rowspan, step: step };
};
