import { FC } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  DocumentEditForm,
  createDocumentZod,
  useCreateObjectDocumentMutation,
  SaveDocument,
  Document,
  updateDocumentZod,
  useUpdateObjectDocumentMutation,
} from 'entities/document';
import { useVersionCheck } from 'entities/object';
import { useSnackbar } from 'shared/hooks/useSnackbar';
import { CardLayout } from 'shared/ui/CardLayout';

interface EditDocumentProps {
  id?: number;
  objectId: number;
  document?: Document;
  onSaved?: () => void;
  onCancelClick?: () => void;
}

export const EditDocument: FC<EditDocumentProps> = ({
  id,
  objectId,
  document,
  onSaved,
  onCancelClick,
}) => {
  const { t } = useTranslation();
  const [createDocument, { isLoading: isDocumentCreating }] =
    useCreateObjectDocumentMutation();
  const [updateDocument, { isLoading: isDocumentUpdating }] =
    useUpdateObjectDocumentMutation();

  const { checkVersion, showVersionChangedPopup } = useVersionCheck(objectId);

  const { showSnackbar } = useSnackbar();

  const form = useForm<SaveDocument>({
    resolver: zodResolver(
      document?.id
        ? updateDocumentZod.omit({ objectId: true })
        : createDocumentZod.omit({ objectId: true })
    ),
    mode: 'onSubmit',
    defaultValues: {
      name: document?.name || '',
      file: undefined,
    },
  });

  const handleSaveDocumentClick = async (documentData: SaveDocument) => {
    const isVersionChanged = await checkVersion();

    if (!isVersionChanged) {
      const formData = new FormData();
      if (documentData.file) {
        formData.append('file', documentData.file);
      }
      formData.append('name', documentData.name);

      try {
        if (document?.id && document?.hash) {
          await updateDocument({
            objectId,
            hash: document.hash,
            formData: formData,
          }).unwrap();
        } else {
          await createDocument({ objectId, formData: formData }).unwrap();
        }
        form.reset();
        onSaved?.();
      } catch (error) {
        console.error(error);
        showSnackbar();
      }
    }
  };

  return (
    <CardLayout
      header={{
        title: id
          ? t('document.form.editDocument')
          : t('document.form.addDocument'),
      }}
      footer={{
        onOkClick: form.handleSubmit(handleSaveDocumentClick),
        okLabel: t('ui.common.save'),
        okButtonColor: 'secondary',
        cancelLabel: t('ui.common.cancel'),
        onCancelClick: onCancelClick,
        okButtonLoading: isDocumentCreating || isDocumentUpdating,
      }}
      variant="secondary"
    >
      {showVersionChangedPopup()}
      <FormProvider {...form}>
        <DocumentEditForm />
      </FormProvider>
    </CardLayout>
  );
};
