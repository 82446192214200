import { z } from 'zod';

export type AlbumTypeDto = {
  id: number;
  name: string;
};

export const albumTypeZod = z.object({
  value: z.string(),
  label: z.string(),
});

export type AlbumType = z.infer<typeof albumTypeZod>;

export type AlbumDto = {
  id: number;
  name: string;
  date_create: string;
  date_update: string;
  building_id: number;
  order_id: number;
  type_id: number;
  year: number | null;
  month: number | null;
  album_images: { id: number; path: string; place: number }[];
};

export type SaveAlbumDto = {
  name: string;
  building_id: number;
  order_id: number;
  type_id: number;
  year: number | null;
  month: number | null;
};

export const albumZod = z.object({
  id: z.number(),
  name: z.string(),
  objectId: z.number(),
  dateCreate: z.string(),
  dateUpdate: z.string(),
  order: z.number(),
  type: z.string().min(1, 'album.form.error.type'),
  year: z.string().optional(),
  month: z.string().optional(),
  albumImages: z.array(
    z.object({ id: z.number(), path: z.string(), place: z.number() })
  ),
});

export const albumPhotosZod = z.object({
  photos: z
    .array(
      z
        .instanceof(File)
        .refine(
          (file) => file.size < 10 * 1024 * 1024,
          'album.form.error.photoSizeMax10MB'
        )
    )
    .min(1, 'document.form.label.uploadFile')
    .max(30, 'album.form.error.photoCountMax30')
    .refine(
      (files) => files.every((file) => file.size < 10 * 1024 * 1024),
      'album.form.error.photoSizeMax10MB'
    ),
});

export const editAlbumZod = albumZod
  .pick({
    name: true,
    objectId: true,
    order: true,
    type: true,
    year: true,
    month: true,
  })
  .refine(
    (values) => {
      if (values.type === '7' && !values.year) {
        return false;
      }
      return true;
    },
    {
      message: 'album.form.error.year',
      path: ['year'],
    }
  )
  .refine(
    (values) => {
      if (values.type === '7' && !values.month) {
        return false;
      }
      return true;
    },
    {
      message: 'album.form.error.month',
      path: ['month'],
    }
  );

export type Album = z.infer<typeof albumZod>;
export type EditAlbum = z.infer<typeof editAlbumZod>;

export type AlbumsPositionsDto = {
  album_ids: number[];
};

export type AlbumsPositions = {
  albumsIds: number[];
};

export type PhotosPositionsDto = {
  photos_ids: number[];
};

export type PhotosPositions = {
  photosIds: number[];
};
