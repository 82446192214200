import { TFunction } from 'i18next';
import { workingDaysOptions } from 'shared/selectOptions/workingDaysOptions';
import { getLabelByValue } from './getLabelByValue';

export const formatShowWeekDays = (
  workingDays: string,
  t: TFunction
): string => {
  const days = workingDays
    .split(',')
    .map(Number)
    .sort((a, b) => a - b);

  const result = [];
  let rangeStart: number | null = null;
  let rangeEnd: number | null = null;

  for (let i = 0; i < days.length; i++) {
    if (rangeStart === null) {
      rangeStart = days[i];
    }

    if (days[i + 1] - days[i] !== 1) {
      rangeEnd = days[i];
      if (rangeEnd - rangeStart >= 2) {
        result.push(
          t(getLabelByValue(workingDaysOptions, rangeStart.toString())) +
            '-' +
            t(getLabelByValue(workingDaysOptions, rangeEnd.toString()))
        );
      } else {
        for (let j = rangeStart; j <= rangeEnd; j++) {
          result.push(t(getLabelByValue(workingDaysOptions, j.toString())));
        }
      }
      rangeStart = null;
    }
  }

  return result.join(', ');
};
