import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Controller, FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormHelperText,
  Typography,
} from '@mui/material';
import { BgColor, PaletteColor, TextColor } from 'shared/theme/colors';
import { ButtonLink } from './ButtonLink';
import { Col } from './Col';
import { ImageIcon } from './Icons';
import { Row } from './Row';

interface ImageUploadFieldProps {
  title?: string;
  hideTitle?: boolean;
  description?: string;
  accept?: string;
  fileExtensions?: string[];
  multiple?: boolean;
  name: string;
  maxFileSize?: number;
  onLoadingChange?: (loading: boolean) => void;
  isImageLoading?: boolean;
  width?: string;
}

const loaderIcon = (
  <CircularProgress
    style={{
      width: '60px',
      height: '60px',
      marginBottom: '-4px',
      color: TextColor.Secondary,
    }}
  />
);

const imageIcon = (
  <ImageIcon
    style={{
      width: '60px',
      height: '60px',
      marginBottom: '-4px',
    }}
  />
);

const validateExtension = (fileName: string, fileExtensions: string[]) => {
  const allowedExtensions = new RegExp(
    `(${fileExtensions.join('|').replace('.', '\\.')})$`,
    'i'
  );

  return allowedExtensions.exec(fileName);
};

export const ImageUploadField: FC<ImageUploadFieldProps> = ({
  title,
  hideTitle = false,
  description,
  accept,
  fileExtensions,
  multiple = false,
  maxFileSize,
  onLoadingChange,
  name,
  isImageLoading = false,
}) => {
  const { t } = useTranslation();
  const form = useFormContext<FieldValues>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isDragActive, setDragActive] = useState<boolean>(false);

  const getIsFileAttached = () => {
    const formValue: File | File[] = form.getValues(name);
    return Array.isArray(formValue) ? formValue.length > 0 : !!formValue;
  };

  useEffect(() => {
    onLoadingChange?.(isImageLoading);
  }, [isImageLoading, onLoadingChange]);

  const onUploadButtonClick = () => {
    fileInputRef.current?.click();
  };

  const onDeleteButtonClick = () => {
    try {
      form.setValue(name, multiple ? [] : '', {
        shouldDirty: true,
        shouldValidate: true,
      });
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAccept = () => {
    if (accept) {
      return accept;
    }

    return '.png,.jpg';
  };

  const getFileExtensions = useCallback(() => {
    if (fileExtensions) {
      return fileExtensions;
    }

    return ['.png', '.jpg'];
  }, [fileExtensions]);

  const processFiles = useCallback(
    (newFiles: FileList, onChange: (...event: unknown[]) => void) => {
      const fileData = Array.from(newFiles);
      const existingFiles = form.getValues(name) || [];

      if (maxFileSize) {
        for (const file of fileData) {
          if (file.size > maxFileSize * 1024 * 1024) {
            form.setError(name, {
              message: t('ui.fileUploadField.errorMaxSize', {
                size: maxFileSize,
              }),
            });
            return;
          }
        }
      }

      if (!multiple) {
        onChange(fileData[0]);
        return;
      }

      const allFiles = [...existingFiles, ...fileData].filter(
        (file, index, self) =>
          index === self.findIndex((t) => t.name === file.name)
      );

      if (
        !allFiles.every((file) =>
          validateExtension(file.name, getFileExtensions())
        )
      ) {
        form.setError(name, {
          message: t('ui.fileUploadField.errorFormat'),
        });
        return;
      }

      onChange(allFiles);
    },
    [form, getFileExtensions, multiple, name, t]
  );

  const onFileChange = useCallback(
    (onChange: (...event: unknown[]) => void) =>
      async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.length) {
          processFiles(event.target.files, onChange);
        }
      },
    [processFiles]
  );

  const renderIcon = (files?: File | File[]) => {
    if (isImageLoading) {
      return loaderIcon;
    }

    if (
      (Array.isArray(files) && files.length) ||
      (files && !Array.isArray(files))
    ) {
      return (
        <img
          style={{
            width: '60px',
            height: '60px',
            marginBottom: '-4px',
            borderRadius: '8px',
          }}
          src={URL.createObjectURL(Array.isArray(files) ? files[0] : files)}
          alt={name}
        />
      );
    } else {
      return imageIcon;
    }
  };

  const renderTitle = () => {
    if (isImageLoading) {
      return (
        <ButtonLink underline="none">
          {t('ui.fileUploadField.uploading')}
        </ButtonLink>
      );
    }

    if (multiple) {
      return (
        <Row spacing={8}>
          <ButtonLink underlineType="dotted" onClick={onUploadButtonClick}>
            {title ?? t('ui.fileUploadField.upload')}
          </ButtonLink>
          {getIsFileAttached() && (
            <ButtonLink underlineType="dotted" onClick={onDeleteButtonClick}>
              {t('ui.common.delete')}
            </ButtonLink>
          )}
        </Row>
      );
    }

    return (
      <ButtonLink
        underlineType="dotted"
        onClick={
          getIsFileAttached() ? onDeleteButtonClick : onUploadButtonClick
        }
      >
        {getIsFileAttached()
          ? t('ui.common.delete')
          : title ?? t('ui.fileUploadField.upload')}
      </ButtonLink>
    );
  };

  const renderSubtitle = () => {
    if (!getIsFileAttached()) {
      return (
        <Typography variant="caption" color={TextColor.Tertiary}>
          {getFileExtensions()}
        </Typography>
      );
    }

    if (multiple) {
      const files: File[] = form.getValues(name);
      const fileNames = files.map((file: File) => file.name);

      return fileNames.map((fileName) => (
        <Typography key={fileName} variant="caption" color={TextColor.Tertiary}>
          {fileName}
        </Typography>
      ));
    }
    const file: File = form.getValues(name);
    const fileName = file.name;

    return (
      <Typography variant="caption" color={TextColor.Tertiary}>
        {fileName}
      </Typography>
    );
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = useCallback(
    (onChange: (...event: unknown[]) => void) =>
      (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
          processFiles(e.dataTransfer.files, onChange);
        }
      },
    [processFiles]
  );

  return (
    <Controller
      name={name}
      control={form.control}
      render={({ field: { ref, ...rest }, fieldState: { error } }) => (
        <FormControl variant="filled" fullWidth error={Boolean(error)}>
          <Card sx={{ ...(error ? { borderColor: PaletteColor.Error } : {}) }}>
            <CardContent
              sx={{
                position: 'relative',
                padding: 6,
                ':last-child': {
                  paddingBottom: 6,
                },
              }}
              onDragEnter={handleDragEnter}
            >
              {isDragActive && (
                <Box
                  onDragEnter={handleDragEnter}
                  onDragOver={handleDragEnter}
                  onDragLeave={handleDragEnter}
                  onDrop={handleDrop(rest.onChange)}
                  sx={{
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    background: BgColor.LightGray,
                  }}
                >
                  {t('ui.common.dropImage')}
                </Box>
              )}

              <Row flexWrap={'nowrap'} flexGrow={1} spacing={4}>
                {renderIcon(rest.value)}

                <Col spacing={2}>
                  {!hideTitle && renderTitle()}

                  <input
                    ref={fileInputRef}
                    type="file"
                    style={{ display: 'none' }}
                    accept={getAccept()}
                    onChange={onFileChange(rest.onChange)}
                    multiple={multiple}
                  />

                  {renderSubtitle()}

                  <Typography variant="paragraphS" color={TextColor.Secondary}>
                    {description}
                  </Typography>
                </Col>
              </Row>
            </CardContent>
          </Card>
          {error && error.message && (
            <FormHelperText>{t(error.message)}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};
