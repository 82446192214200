// eslint-disable-next-line no-restricted-imports
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { TextColor } from './colors';

export const typography: TypographyOptions = {
  allVariants: {},
  headerXS: {
    fontSize: 16,
    fontWeight: 600,
  },
  headerM: {
    fontSize: 24,
    fontWeight: 500,
  },
  headerL: {
    fontSize: 36,
    fontWeight: 500,
  },
  paragraphS: {
    fontSize: 14,
    fontWeight: 400,
  },
  paragraphM: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24.4px',
    color: TextColor.Secondary,
  },
  accentS: {
    fontSize: 14,
    fontWeight: 500,
  },
  accentM: {
    fontSize: 16,
    fontWeight: 500,
  },
  h1: undefined,
  h2: undefined,
  h3: undefined,
  h4: undefined,
  h5: undefined,
  h6: undefined,
  body1: undefined,
  body2: undefined,
  subtitle1: undefined,
  subtitle2: undefined,
  caption: {
    // captionM
    fontSize: 12,
    fontWeight: 500,
    color: TextColor.Tertiary,
    letterSpacing: 0,
  },
};

export const fontInter: string = ['Inter', 'sans-serif'].join(',');
