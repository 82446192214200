import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, Typography } from '@mui/material';
// eslint-disable-next-line boundaries/element-types
import { useGetDeveloperQuery } from 'entities/developer';
import { DeveloperObject } from 'entities/object';
import { getRoute } from 'shared/const';
import { getApiImage } from 'shared/helpers/getImageUrl';
import { TextColor } from 'shared/theme/colors';
import { CardLayout } from 'shared/ui/CardLayout';
import { Col } from 'shared/ui/Col';
import { ImageIcon } from 'shared/ui/Icons';
import { Row } from 'shared/ui/Row';
import { RectSkeleton } from 'shared/ui/Skeleton/RectSkeleton';

type ObjectDeveloperCardProps = {
  object: DeveloperObject;
};

export const ObjectDeveloperCard: FC<ObjectDeveloperCardProps> = ({
  object,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: developer } = useGetDeveloperQuery(
    object.developerId ?? skipToken
  );

  if (!developer) return <RectSkeleton />;

  return (
    <CardLayout maxWidth={344}>
      <Row spacing={5} wrap={false}>
        {developer.logo ? (
          <Avatar
            variant="circular"
            alt="developer logo"
            src={getApiImage(developer.logo)}
            sx={{ width: 32, height: 32 }}
          />
        ) : (
          <ImageIcon sx={{ width: 32, height: 32 }} />
        )}
        <Col spacing={3}>
          <Col spacing={2}>
            <Typography variant={'accentM'}>{developer.name}</Typography>
            <Typography variant={'accentM'} color={TextColor.Secondary}>
              {developer.inn
                ? t('developer.tab.previewCard.subtitle', {
                    inn: developer.inn,
                  })
                : ''}
            </Typography>
          </Col>
        </Col>
      </Row>
      <Button
        variant="outlined"
        size="small"
        fullWidth
        onClick={() => navigate(getRoute.DeveloperDetails(developer.id))}
      >
        {t('object.card.toDeveloper')}
      </Button>
    </CardLayout>
  );
};
