import { $rtkApi, ApiTag } from 'shared/api/rtkApi';
import { ListData, ResponseData } from 'shared/types';
import {
  mapContractorFromDto,
  mapDtoFromContractor,
} from '../../lib/mapContractor';
import { Contractor, ContractorDto } from '../types/contractorSchema';

export const contractorApi = $rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    createContractor: builder.mutation<Contractor, Contractor>({
      query: (contractor) => ({
        url: '/contractors',
        method: 'POST',
        body: mapDtoFromContractor(contractor),
      }),
      transformResponse: (response: ResponseData<ContractorDto>) =>
        mapContractorFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, contractor) => [
        { type: ApiTag.contractor, id: ApiTag.contractor },
      ],
    }),
    getContractors: builder.query<
      ListData<Contractor>,
      { limit: number; offset: number; search: string }
    >({
      query: (params) => ({
        url: '/contractors',
        method: 'GET',
        params: {
          limit: params.limit,
          offset: params.offset,
          name: params.search,
        },
      }),
      providesTags: (result, error, page) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: ApiTag.contractor,
                id,
              })),
              { type: ApiTag.contractor, id: ApiTag.contractor },
            ]
          : [{ type: ApiTag.contractor, id: ApiTag.contractor }],
      transformResponse: (response: ResponseData<ListData<ContractorDto>>) => ({
        total: response.data.total,
        items: response.data.items.map((data) => mapContractorFromDto(data)),
      }),
    }),
  }),
});

export const { useCreateContractorMutation, useGetContractorsQuery } =
  contractorApi;
