import { LotDto } from '../model/types/lotSchema';
export const fillLotsByFloorsIds = (
  chessRisersIds: number[],
  floorsAndLotsDto: LotDto[],
  chessFloorsCount: number
) => {
  const lotsByFloorsIds: (number | null)[][] = Array.from(
    { length: chessFloorsCount },
    () => []
  );
  let currentFloorIndex = chessFloorsCount;

  // map riserId : colspan
  const risersColspansMap: Map<number, number> = new Map<number, number>();
  chessRisersIds.forEach((riserId) => {
    const currentCount = risersColspansMap.get(riserId) || 0;
    risersColspansMap.set(riserId, currentCount + 1);
  });

  let lotsColspanSum = 0;
  let riserIndex = 0; // Начинаем поиск с первого стояка на этаже

  for (let i = 0; i < floorsAndLotsDto.length; i++) {
    const item = floorsAndLotsDto[i];
    if (item.type === 'number') {
      currentFloorIndex--; // Переходим на следующий этаж
      riserIndex = 0;
    } else {
      let placed = false;
      while (!placed && riserIndex < chessRisersIds.length) {
        const lotColspan = item.colspan || 1;
        const lotRowspan = item.rowspan || 1;

        // Проверяем, можно ли разместить лот на текущем стояке, на текущем этаже
        if (
          canPlaceLot(
            lotsByFloorsIds,
            currentFloorIndex,
            riserIndex,
            lotColspan
          )
        ) {
          // Размещаем лот на текущем этаже
          for (let r = 0; r < lotRowspan; r++) {
            for (let c = 0; c < lotColspan; c++) {
              lotsByFloorsIds[currentFloorIndex - r][riserIndex + c] = item.id;
            }
          }

          placed = true; // Отмечаем, что лот размещен
          lotsColspanSum += lotColspan; // Суммируем colspan размещенных лотов
          const currentRiserColspan =
            risersColspansMap.get(chessRisersIds[riserIndex]) || 1;
          const nextLot = floorsAndLotsDto[i + 1];
          // Проверяем, является ли этот лот последним в стояке
          const isLastLotInRiser =
            (item.step && (!nextLot || (nextLot.step || 0) < item.step)) ||
            currentRiserColspan === 1;

          if (isLastLotInRiser) {
            if (currentRiserColspan > lotsColspanSum) {
              const slotsToFill: number = currentRiserColspan - lotsColspanSum;

              for (let j = 0; j < slotsToFill; j++) {
                lotsByFloorsIds[currentFloorIndex][riserIndex + j + 1] = null;
              }
              riserIndex += slotsToFill + 1;
            } else {
              riserIndex += lotColspan;
            }
            // Сбрасываем сумму colspan для следующего стояка
            lotsColspanSum = 0;
            // Перемещаем индекс для следующего лота
          } else {
            riserIndex += lotColspan;
          }
        } else {
          // Если лот не поместился, смещаемся к следующему стояку
          riserIndex++;
        }
      }
    }
  }

  return lotsByFloorsIds;
};

function canPlaceLot(
  lotsByFloors: (number | null)[][],
  currentFloor: number,
  startRiser: number,
  colspan: number
) {
  for (let c = 0; c < colspan; c++) {
    if (lotsByFloors[currentFloor][startRiser + c] !== undefined) {
      return false; // Место занято
    }
  }
  return true; // Место свободно
}
