import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { getLabelByValue } from 'shared/helpers/getLabelByValue';
import {
  numberOfRoomsOptions,
  statusOptions,
} from 'shared/selectOptions/chessOptions';
import { AccentColor, BgColor, TextColor } from 'shared/theme/colors';
import { Col } from '../../Col';
import { FundLotIcon, PromoLotIcon } from '../../Icons';
import { Row } from '../../Row';
import { SmallChip } from '../../SmallChip';
import { getBorderForCell } from '../lib/getBorderForCell';
import { GridLot } from '../types';

type DataCellProps = {
  lot: GridLot;
  disabled: boolean;
  showEmpty?: boolean;
  mergedToLeft?: boolean;
  mergedToTop?: boolean;
  mergedToRight?: boolean;
  mergedToBottom?: boolean;
  selected?: boolean;
  hiddenByFilters?: boolean;
  polygonNumber?: number;
  onClick?: () => void;
};

const generateReservBgImage = (color: string) => {
  return `data:image/svg+xml,%3Csvg%20width%3D%22230%22%20height%3D%22118%22%20viewBox%3D%220%200%20230%20118%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cg%20clip-path%3D%22url(%23clip0_7391_101654)%22%3E%0A%3Cline%20opacity%3D%220.8%22%20x1%3D%2236.1716%22%20y1%3D%22138.172%22%20x2%3D%22200.172%22%20y2%3D%22-25.8284%22%20stroke%3D%22${encodeURIComponent(
    color
  )}%22%20stroke-width%3D%228%22%2F%3E%0A%3Cline%20opacity%3D%220.8%22%20x1%3D%224.17157%22%20y1%3D%22138.172%22%20x2%3D%22168.172%22%20y2%3D%22-25.8284%22%20stroke%3D%22${encodeURIComponent(
    color
  )}%22%20stroke-width%3D%228%22%2F%3E%0A%3Cline%20opacity%3D%220.8%22%20x1%3D%22-27.8284%22%20y1%3D%22138.172%22%20x2%3D%22136.172%22%20y2%3D%22-25.8284%22%20stroke%3D%22${encodeURIComponent(
    color
  )}%22%20stroke-width%3D%228%22%2F%3E%0A%3Cline%20opacity%3D%220.8%22%20x1%3D%22-59.8284%22%20y1%3D%22138.172%22%20x2%3D%22104.172%22%20y2%3D%22-25.8284%22%20stroke%3D%22${encodeURIComponent(
    color
  )}%22%20stroke-width%3D%228%22%2F%3E%0A%3Cline%20opacity%3D%220.8%22%20x1%3D%22-91.8284%22%20y1%3D%22138.172%22%20x2%3D%2272.1716%22%20y2%3D%22-25.8284%22%20stroke%3D%22${encodeURIComponent(
    color
  )}%22%20stroke-width%3D%228%22%2F%3E%0A%3Cline%20opacity%3D%220.8%22%20x1%3D%22-123.828%22%20y1%3D%22138.172%22%20x2%3D%2240.1716%22%20y2%3D%22-25.8284%22%20stroke%3D%22${encodeURIComponent(
    color
  )}%22%20stroke-width%3D%228%22%2F%3E%0A%3Cline%20opacity%3D%220.8%22%20x1%3D%2268.1716%22%20y1%3D%22138.172%22%20x2%3D%22232.172%22%20y2%3D%22-25.8284%22%20stroke%3D%22${encodeURIComponent(
    color
  )}%22%20stroke-width%3D%228%22%2F%3E%0A%3Cline%20opacity%3D%220.8%22%20x1%3D%22100.172%22%20y1%3D%22138.172%22%20x2%3D%22264.172%22%20y2%3D%22-25.8284%22%20stroke%3D%22${encodeURIComponent(
    color
  )}%22%20stroke-width%3D%228%22%2F%3E%0A%3Cline%20opacity%3D%220.8%22%20x1%3D%22132.172%22%20y1%3D%22138.172%22%20x2%3D%22296.172%22%20y2%3D%22-25.8284%22%20stroke%3D%22${encodeURIComponent(
    color
  )}%22%20stroke-width%3D%228%22%2F%3E%0A%3Cline%20opacity%3D%220.8%22%20x1%3D%22164.172%22%20y1%3D%22138.172%22%20x2%3D%22328.172%22%20y2%3D%22-25.8284%22%20stroke%3D%22${encodeURIComponent(
    color
  )}%22%20stroke-width%3D%228%22%2F%3E%0A%3Cline%20opacity%3D%220.8%22%20x1%3D%22196.172%22%20y1%3D%22138.172%22%20x2%3D%22360.172%22%20y2%3D%22-25.8284%22%20stroke%3D%22${encodeURIComponent(
    color
  )}%22%20stroke-width%3D%228%22%2F%3E%0A%3C%2Fg%3E%0A%3Cdefs%3E%0A%3CclipPath%20id%3D%22clip0_7391_101654%22%3E%0A%3Crect%20width%3D%22230%22%20height%3D%22118%22%20fill%3D%22white%22%2F%3E%0A%3C%2FclipPath%3E%0A%3C%2Fdefs%3E%0A%3C%2Fsvg%3E`;
};

const formatNumber = (number: string) => {
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const DataCell: FC<DataCellProps> = ({
  lot,
  disabled,
  showEmpty,
  mergedToLeft,
  mergedToTop,
  mergedToRight,
  mergedToBottom,
  selected,
  hiddenByFilters,
  polygonNumber,
  onClick,
}) => {
  const { t } = useTranslation();
  const boxShadow = useMemo(() => {
    return getBorderForCell(
      mergedToLeft,
      mergedToTop,
      mergedToRight,
      mergedToBottom
    );
  }, [mergedToBottom, mergedToLeft, mergedToRight, mergedToTop]);

  const { contentOpacity, bgColor } = useMemo(() => {
    const isFiltering = hiddenByFilters !== undefined;

    const contentOpacity = disabled ? 0.3 : 1;

    let bgColor: string = TextColor.StaticWhite;

    if (disabled) {
      bgColor = BgColor.Gray;
    }

    if (lot.status === 'soldStatus') {
      bgColor = '#E0E0E1';
    }

    if (isFiltering) {
      bgColor = hiddenByFilters ? '#e8e8e8' : TextColor.StaticWhite;
    }

    if (selected) {
      bgColor = AccentColor.Process;
    }
    return { contentOpacity, bgColor };
  }, [disabled, hiddenByFilters, lot.status, selected]);

  const getStatusChipVariant = (
    status: string
  ): 'done' | 'process' | 'temporary' | 'attention' | 'negative' => {
    switch (status) {
      case 'fundStatus':
        return 'attention';
      case 'saleStatus':
      case 'assignmentStatus':
        return 'done';
      case 'soldStatus':
      case 'reservStatus':
      case 'assignmentReservation':
      default:
        return 'temporary';
    }
  };

  const isDebug = false;

  const debugCellContent = (
    <Typography variant="paragraphS">
      <Row
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <Col spacing={3} sx={{ flexGrow: 1, opacity: contentOpacity }}>
          <Row>id: {lot.id}</Row>
          <Row>№${lot.lotNumber}</Row>
        </Col>
        <Col
          spacing={3}
          sx={{
            flexGrow: 1,
            opacity: contentOpacity,
          }}
        >
          <Row>row: {lot.row}</Row>
          <Row>colspan: {lot.colspan}</Row>
          <Row>rowspan: {lot.rowspan}</Row>
        </Col>
      </Row>
    </Typography>
  );

  const cellContent = (
    <>
      {lot.status === 'fundStatus' ? (
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          height={'100%'}
        >
          <FundLotIcon
            sx={{
              color: selected ? TextColor.StaticWhite : AccentColor.Temporary,
            }}
          />
        </Box>
      ) : (
        <Col spacing={3} sx={{ flexGrow: 1, opacity: contentOpacity }}>
          <Row
            sx={{
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant="caption"
              color={selected ? TextColor.StaticWhite : TextColor.Secondary}
            >
              {lot.lotNumber ? `№${lot.lotNumber}` : ''}
            </Typography>
            <Row spacing={4}>
              <Typography
                variant="caption"
                color={selected ? TextColor.StaticWhite : TextColor.Primary}
              >
                {lot.numberOfRooms
                  ? t(getLabelByValue(numberOfRoomsOptions, lot.numberOfRooms))
                  : ''}
              </Typography>
              <Typography
                variant="caption"
                color={selected ? TextColor.StaticWhite : TextColor.Secondary}
              >
                {lot.totalArea ? `${formatNumber(lot.totalArea)} м²` : ''}
              </Typography>
            </Row>
          </Row>
          <Row
            sx={{
              justifyContent: 'space-between',
              alignContent: 'end',
              height: '19px',
            }}
          >
            <Typography
              variant="accentM"
              color={selected ? TextColor.StaticWhite : TextColor.Primary}
            >
              {lot.totalPrice ? `${formatNumber(lot.totalPrice)} ₽` : ''}
            </Typography>
            <Typography
              variant="caption"
              color={selected ? TextColor.StaticWhite : TextColor.Secondary}
            >
              {lot.pricePerMeter
                ? `${formatNumber(lot.pricePerMeter)} ₽/м²`
                : ''}
            </Typography>
          </Row>
        </Col>
      )}

      <Row spacing={3} sx={{ opacity: contentOpacity }}>
        {polygonNumber !== undefined && (
          <SmallChip
            variant={'process'}
            icon="clip"
            label={t('chess.lotsGrid.polygonNumber', {
              polygonNumber,
            })}
            textColor={selected ? TextColor.StaticWhite : ''}
            bgColor={selected ? '#307BEA' : ''}
          />
        )}
        {lot.promotion === '1' && (
          <SmallChip
            variant="process"
            label={t('chess.lotsGrid.promotion')}
            textColor={selected ? TextColor.StaticWhite : ''}
            bgColor={selected ? '#307BEA' : ''}
          />
        )}
        {lot.status && (
          <SmallChip
            variant={getStatusChipVariant(lot.status)}
            label={t(getLabelByValue(statusOptions, lot.status))}
            textColor={selected ? TextColor.StaticWhite : ''}
            bgColor={selected ? '#307BEA' : ''}
          />
        )}
      </Row>
      {lot.promotion === '1' && (
        <Box sx={{ position: 'absolute', bottom: 0, right: 0, height: 24 }}>
          <PromoLotIcon
            sx={{ color: selected ? '#307BEA' : AccentColor.Process }}
          />
        </Box>
      )}
    </>
  );

  const renderCellContent = () => {
    if (showEmpty) {
      return null;
    }

    if (isDebug) {
      return debugCellContent;
    }

    return cellContent;
  };

  return (
    <Box
      sx={{
        bgcolor: bgColor,
        boxShadow: boxShadow,
        flex: 1,
        position: 'relative',
        p: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        ...(lot.status === 'reservStatus' ||
        lot.status === 'assignmentReservation'
          ? {
              backgroundImage: `url('${generateReservBgImage(
                selected ? '#307BEA' : BgColor.Gray
              )}');`,
            }
          : {}),
      }}
      onClick={onClick}
    >
      {renderCellContent()}
    </Box>
  );
};
