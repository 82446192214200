import { z } from 'zod';

const planZod = z.object({
  entranceId: z.string(),
  floorIds: z
    .array(z.number())
    .min(1, { message: 'ui.common.error.selectValue' }),
  name: z.string(),
});

export const createPlanZod = planZod.merge(
  z.object({
    image: z.instanceof(File, { message: 'ui.common.errorRequiredField' }),
  })
);

export const editPlanZod = planZod.merge(
  z.object({
    image: z.instanceof(File).optional(),
  })
);

export type SavePlanData = z.infer<typeof createPlanZod> & {
  id?: number;
};

export type PlanDto = {
  id: number;
  section_id: number;
  width: number;
  height: number;
  name: string;
  floor_ids: number[];
  created_at: string;
  updated_at: string;
};

export type Plan = {
  id: number;
  sectionId: number;
  width: number;
  height: number;
  name: string;
  floorIds: number[];
  createdAt: string;
  updatedAt: string;
};

export type PolygonDto = {
  id: number;
  plan_id: number;
  data: string;
  chess: number[];
};

export type CreatePolygonDto = {
  plan_id: number;
  data: string;
  lot_ids: number[];
};

export type UpdatePolygonDto = {
  id: number;
  plan_id: number;
  data: string;
  lot_ids: number[];
};

export type CreatePolygon = {
  planId: number;
  data: string;
};

export type Polygon = {
  id: number;
  planId: number;
  data: string;
  chess: number[];
};

export type UpdatePolygon = {
  id: number;
  planId: number;
  data: string;
  lotIds: number[];
};
