import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { TFunction } from 'i18next';
import { List, Typography } from '@mui/material';
import { getRoute } from 'shared/const';
import {
  ObjectPublicationError,
  PublicationErrorMessage,
} from '../model/types/objectPublicationErrorsSchema';

type ErrorMessageWithLinkProps = {
  objectId: number | undefined;
  buildingId: number | undefined;
  entranceId: number | undefined;
  errorsCount: number | undefined;
  name: string | undefined;
};

export const getObjectPublicationErrorsList = (
  errors: ObjectPublicationError[],
  t: TFunction
) => {
  const mapErrorMessages: Map<
    PublicationErrorMessage,
    ({
      objectId,
      buildingId,
      entranceId,
      errorsCount,
      name,
    }: ErrorMessageWithLinkProps) => ReactNode
  > = new Map([
    [
      PublicationErrorMessage.MUST_CREATE_PAVILION,
      () => (
        <Typography key={1}>
          {`• ${t('object.publication.error.noBuildingAndEntrance')}`}
        </Typography>
      ),
    ],
    [
      PublicationErrorMessage.HAS_NO_SECTIONS,
      ({ objectId, buildingId, name }: ErrorMessageWithLinkProps) => (
        <Typography key={buildingId}>
          {`• ${t('object.publication.error.noEntrance')} `}
          <Link
            to={
              buildingId && objectId
                ? getRoute.EditObjectBuilding(objectId, buildingId)
                : '#'
            }
          >
            {name}
          </Link>
        </Typography>
      ),
    ],
    [
      PublicationErrorMessage.HAS_NO_ALBUMS,
      () => (
        <Typography key={3}>
          {`• ${t('object.publication.error.noAlbumAndPhoto')}`}
        </Typography>
      ),
    ],
    [
      PublicationErrorMessage.MUST_CREATE_CHESS,
      ({
        objectId,
        buildingId,
        name,
        entranceId,
        errorsCount,
      }: ErrorMessageWithLinkProps) => (
        <Typography key={entranceId}>
          {`• ${t('object.publication.error.noLotInfoStart', {
            count: errorsCount || 2,
          })} `}
          <Link
            to={
              buildingId && objectId && entranceId
                ? getRoute.ObjectChessboard(objectId, buildingId, entranceId)
                : '#'
            }
          >
            {name}
          </Link>
          {`: ${t('object.publication.error.noLotInfoEnd')}`}
        </Typography>
      ),
    ],
    [
      PublicationErrorMessage.HAS_LOT_WITH_ERROR,
      ({
        objectId,
        buildingId,
        name,
        entranceId,
        errorsCount,
      }: ErrorMessageWithLinkProps) => (
        <Typography key={entranceId}>
          {`• ${t('object.publication.error.noLotInfoStart', {
            count: errorsCount || 2,
          })} `}
          <Link
            to={
              buildingId && objectId && entranceId
                ? getRoute.ObjectChessboard(objectId, buildingId, entranceId)
                : '#'
            }
          >
            {name}
          </Link>
          {`: ${t('object.publication.error.noLotInfoEnd')}`}
        </Typography>
      ),
    ],
    [
      PublicationErrorMessage.MUST_ADD_DOCUMENT,
      () => (
        <Typography key={5}>
          {`• ${t('object.publication.error.noDocument')}`}
        </Typography>
      ),
    ],
  ]);

  return (
    <List
      sx={{
        p: 6,
        fontSize: 14,
        fontWeight: 500,
      }}
    >
      <Typography sx={{ pb: 4 }}>{`${t(
        'object.publication.title'
      )}:`}</Typography>
      {errors.flatMap((error) =>
        error.messages.map((message) => {
          const ErrorMessageComponent = mapErrorMessages.get(message);
          return ErrorMessageComponent
            ? ErrorMessageComponent({
                objectId: error.objectId,
                buildingId: error.buildingId,
                name: error.name,
                entranceId: error.entranceId,
                errorsCount: error.errorsCount,
              })
            : null;
        })
      )}
    </List>
  );
};
