import { $rtkApi } from 'shared/api/rtkApi';
import { ResponseData } from 'shared/types';
import { mapCityFromDto } from '../../lib/mapCity';
import { City, CityDto } from '../types/citiesSchema';

export const cityApi = $rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getCities: builder.query<City[], void>({
      query: () => ({
        url: `/cities`,
        method: 'GET',
      }),
      transformResponse: (response: ResponseData<CityDto[]>) =>
        response.data.map((data) => mapCityFromDto(data)),
    }),
  }),
});

export const { useGetCitiesQuery } = cityApi;
