import { FC } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  editNewsPostZod,
  NewsPost,
  NewsPostEditForm,
  useCreateNewsPostMutation,
  useUpdateNewsPostMutation,
} from 'entities/newsPost';
import { useVersionCheck } from 'entities/object';
import { useSnackbar } from 'shared/hooks/useSnackbar';
import { CardLayout } from 'shared/ui/CardLayout';

interface EditNewsPostProps {
  id?: number;
  objectId?: string;
  newsPost?: NewsPost;
  onEditEnd: () => void;
}

export const EditNewsPost: FC<EditNewsPostProps> = ({
  id,
  newsPost,
  objectId,
  onEditEnd,
}) => {
  const { t } = useTranslation();
  const form = useForm<NewsPost>({
    resolver: zodResolver(editNewsPostZod),
    mode: 'onSubmit',
    defaultValues: {
      text: newsPost?.text || '',
    },
  });
  const { showSnackbar } = useSnackbar();

  const { checkVersion, showVersionChangedPopup } = useVersionCheck(objectId);

  const [createNewsPost, { isLoading: isNewsPostCreating }] =
    useCreateNewsPostMutation();
  const [updateNewsPost, { isLoading: isNewsPostUpdating }] =
    useUpdateNewsPostMutation();

  const handleSaveClick = async (newsPostData: NewsPost) => {
    const isVersionChanged = await checkVersion();

    if (!isVersionChanged) {
      try {
        const preparedNewsPost = { ...newsPost, ...newsPostData };
        id
          ? await updateNewsPost({
              objectId,
              newsPost: preparedNewsPost,
            }).unwrap()
          : await createNewsPost({
              objectId,
              newsPost: {
                ...newsPostData,
                id: Number(objectId),
              },
            }).unwrap();
        form.reset({
          text: '',
        });
        onEditEnd();
      } catch (error) {
        console.error(error);
        showSnackbar();
      }
    }
  };

  return (
    <CardLayout
      header={{ title: id ? t('news.form.editNews') : t('news.form.addNews') }}
      footer={{
        onOkClick: form.handleSubmit(handleSaveClick),
        onCancelClick: id ? onEditEnd : undefined,
        okLabel: t('ui.common.save'),
        okButtonColor: id ? 'primary' : 'secondary',
        cancelLabel: t('ui.common.cancel'),
        okButtonLoading: isNewsPostCreating || isNewsPostUpdating,
      }}
      variant="secondary"
      maxWidth={600}
    >
      {showVersionChangedPopup()}
      <FormProvider {...form}>
        <NewsPostEditForm />
      </FormProvider>
    </CardLayout>
  );
};
