import {
  CombinedState,
  configureStore,
  Reducer,
  ReducersMapObject,
} from '@reduxjs/toolkit';
// eslint-disable-next-line boundaries/element-types
import { objectVersionReducer } from 'entities/object';
// eslint-disable-next-line boundaries/element-types
import { profileReducer } from 'entities/profile';
// eslint-disable-next-line boundaries/element-types
import { searchReducer } from 'entities/search';
// eslint-disable-next-line boundaries/element-types
import { userReducer } from 'entities/user';
import { $rtkApi } from 'shared/api/rtkApi';
import { createReducerManager } from './reducerManager';
import { StateSchema } from './StateSchema';

export function createReduxStore(
  initialState: StateSchema,
  asyncReducers?: ReducersMapObject<StateSchema>
) {
  const rootReducer: ReducersMapObject<StateSchema> = {
    ...asyncReducers,
    profile: profileReducer,
    search: searchReducer,
    user: userReducer,
    objectVersion: objectVersionReducer,
    [$rtkApi.reducerPath]: $rtkApi.reducer,
  };

  const reducerManager = createReducerManager(rootReducer);
  // const extraArg: ThunkExtraArg = { api: $api };

  const store = configureStore({
    reducer: reducerManager.reduce as Reducer<CombinedState<StateSchema>>,
    //TODO: add changing by env
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat($rtkApi.middleware),
    //   {
    //   thunk: { extraArgument: extraArg },
    // }
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  store.reducerManager = reducerManager;

  return store;
}

export type AppDispatch = ReturnType<typeof createReduxStore>['dispatch'];
