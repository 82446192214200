import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'shared/ui/Col';
import { ExpandButton } from 'shared/ui/ExpandButton';
import { FileUploadField } from 'shared/ui/FileUploadField';
import { NumericField } from 'shared/ui/NumericField';
import { NumericPatternField } from 'shared/ui/NumericPatternField';
import { PhoneField } from 'shared/ui/PhoneField';
import { Row } from 'shared/ui/Row';
import { SelectField } from 'shared/ui/SelectField';
import { StyledTextField } from 'shared/ui/StyledTextField';
import { agencyTariffOptions } from '../model/consts';

interface AgencyEditFormProps {
  onToggleAdditionalData: (isShowAdditionalData: boolean) => void;
  isShowAdditionalData: boolean;
}

export const AgencyEditForm: FC<AgencyEditFormProps> = ({
  isShowAdditionalData,
  onToggleAdditionalData,
}) => {
  const { t } = useTranslation();

  return (
    <Col spacing={8}>
      <Row
        equalsChildrenWidth
        sx={{
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: 8, sm: 5 },
        }}
      >
        <StyledTextField
          label={t('agency.form.label.agencyName')}
          name="name"
          required
          maxLength={50}
          showCharacterHintAtRemaining={10}
        />
        <StyledTextField
          label={t('ui.common.site')}
          name="website"
          maxLength={70}
          showCharacterHintAtRemaining={10}
        />
      </Row>
      <Row
        equalsChildrenWidth
        sx={{
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: 8, sm: 5 },
        }}
      >
        <NumericField
          label={t('developer.form.label.inn')}
          name="inn"
          valueIsNumericString
          maxLength={12}
          allowNegative={false}
          decimalScale={0}
          required
        />
        <NumericPatternField
          label={t('ui.common.KPP')}
          name="kpp"
          format="## ## ## ###"
          mask="x"
          maxLength={9}
          required
        />
      </Row>
      <StyledTextField
        label={t('agency.form.label.agencyDescription')}
        name="description"
        required
        multiline
        maxLength={500}
        showCharacterHintAtRemaining={10}
      />
      <Row
        equalsChildrenWidth
        sx={{
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: 8, sm: 5 },
        }}
      >
        <StyledTextField
          label={t('agency.form.label.officeName')}
          name="officeName"
          required
          maxLength={100}
          showCharacterHintAtRemaining={10}
        />
        <StyledTextField
          label={t('agency.form.label.officeAddress')}
          name="address"
          required
          maxLength={100}
          showCharacterHintAtRemaining={10}
        />
      </Row>
      <Row
        equalsChildrenWidth
        sx={{
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: 8, sm: 5 },
        }}
      >
        <PhoneField
          name={'phone'}
          required
          label={t('agency.form.label.officePhone')}
        />
        <StyledTextField
          label={t('agency.form.label.officeEmail')}
          name="mail"
          maxLength={50}
          showCharacterHintAtRemaining={10}
        />
      </Row>
      <SelectField
        label={t('agency.form.label.currentTariff')}
        name={'tariff'}
        required
        options={agencyTariffOptions}
      />
      <FileUploadField
        name={'logo'}
        description={t('agency.form.label.agencyLogo')}
        fileType="image"
        sx={{ maxWidth: { xs: '100%', sm: 333 } }}
        maxFileSize={10}
      />
      <ExpandButton
        expanded={isShowAdditionalData}
        onClick={() => onToggleAdditionalData(!isShowAdditionalData)}
      >
        {t('user.form.label.additionalData')}
      </ExpandButton>
      {isShowAdditionalData && (
        <Col spacing={8}>
          <Row
            equalsChildrenWidth
            sx={{
              flexDirection: { xs: 'column', sm: 'row' },
              gap: { xs: 8, sm: 5 },
            }}
          >
            <StyledTextField
              label={t('ui.common.social.vk')}
              name="vk"
              maxLength={100}
              showCharacterHintAtRemaining={10}
            />
            <StyledTextField
              label={t('ui.common.social.ok')}
              name="ok"
              maxLength={100}
              showCharacterHintAtRemaining={10}
            />
          </Row>
          <Row
            equalsChildrenWidth
            sx={{
              flexDirection: { xs: 'column', sm: 'row' },
              gap: { xs: 8, sm: 5 },
            }}
          >
            <StyledTextField
              label={t('ui.common.social.facebook')}
              name="facebook"
              maxLength={100}
              showCharacterHintAtRemaining={10}
            />
            <StyledTextField
              label={t('ui.common.social.instagram')}
              name="instagram"
              maxLength={100}
              showCharacterHintAtRemaining={10}
            />
          </Row>
          <StyledTextField
            label={t('ui.common.social.youtube')}
            name="youtube"
            maxLength={100}
            showCharacterHintAtRemaining={10}
            sx={{ width: { xs: '100%', sm: '49%' } }}
          />
        </Col>
      )}
    </Col>
  );
};
