import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button } from '@mui/material';
import { TextColor } from 'shared/theme/colors';
import { Col } from 'shared/ui/Col';
import { NoChessIcon } from 'shared/ui/Icons';

type EmptyChessListProps = {
  onCreateBuildingClick: () => void;
};

export const EmptyChessList: FC<EmptyChessListProps> = ({
  onCreateBuildingClick,
}) => {
  const { t } = useTranslation();

  return (
    <Col
      spacing={9}
      sx={{ width: '252px', mt: '105px', mx: 'auto', alignItems: 'center' }}
    >
      <Col spacing={3} sx={{ alignItems: 'center' }}>
        <NoChessIcon />
        <Typography
          sx={{ textAlign: 'center' }}
          variant="accentS"
          color={TextColor.Secondary}
        >
          {t('chess.list.noChessTitle')}
        </Typography>
      </Col>
      <Button size="small" onClick={onCreateBuildingClick}>
        {t('chess.list.createBuilding')}
      </Button>
    </Col>
  );
};
