import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box } from '@mui/material';
import { TextColor } from 'shared/theme/colors';
import { HeaderCell } from './cells/HeaderCell';
import { isRiserHasMergedLots } from './lib/hasMergedLots';
import { PlusButton } from './PlusButton';
import { GridLot } from './types';

const Corner: FC = () => {
  return (
    <Box
      sx={{
        bgcolor: TextColor.StaticWhite,
        boxShadow: `0 0 0 1px #DBDBDB`,
        width: 35,
        height: 35,
        position: 'sticky',
        top: 0,
        left: 0,
      }}
    />
  );
};

type GridHeaderProps = {
  selectedRisers: number[];
  lots: GridLot[];
  risers: GridLot[];
  onHeaderCellClick: (riser: number) => void;
  onHeaderCellRename?: (riser: number, name: string) => void;
  onAddRiser?: (riser: number) => void;
};

export const GridHeader: FC<GridHeaderProps> = ({
  selectedRisers,
  lots,
  risers,
  onHeaderCellClick,
  onHeaderCellRename,
  onAddRiser,
}) => {
  const [showPlusButton, setShowPlusButton] = useState(false);
  const [plusButtonIndex, setPlusButtonIndex] = useState(0);
  const [plusButtonPosition, setPlusButtonPosition] = useState<{
    left: number;
    top: number;
  }>({
    left: 0,
    top: 0,
  });

  const hideButtonWithDelay = useRef<NodeJS.Timeout | null>(null);
  const gridRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const gridEl = gridRef?.current;
    const handleScroll = () => {
      if (gridEl) {
        setPlusButtonPosition({
          ...plusButtonPosition,
          top: gridEl.scrollTop,
        });
      }
    };

    gridEl?.addEventListener('scroll', handleScroll);

    return () => {
      gridEl?.removeEventListener('scroll', handleScroll);
    };
  }, [gridRef, plusButtonPosition]);

  const handleMouseLeave = () => {
    // Задержка в 200 мс перед скрытием кнопки
    // чтобы не скрывать кнопку когда курсор наводим на кнопку
    hideButtonWithDelay.current = setTimeout(() => {
      setShowPlusButton(false);
    }, 200);
  };

  const handleButtonMouseEnter = () => {
    // Отменить скрытие при наведении курсора на кнопку
    if (hideButtonWithDelay.current) {
      clearTimeout(hideButtonWithDelay.current);
    }
  };

  const handleMouseMove = useCallback((e: React.MouseEvent, riser: number) => {
    const buffer = 40;
    const target = e.currentTarget as HTMLElement;
    const rect = target.getBoundingClientRect();
    const gridEl = e.currentTarget.parentElement;

    if (!gridEl) {
      console.error('not found parent element for header cell');
      return;
    }

    if (!gridRef.current) {
      gridRef.current = gridEl;
    }

    const gridRect = gridEl.getBoundingClientRect();

    const isHoverLeftSide = e.clientX - rect.left < buffer;
    const isHoverRightSide = rect.right - e.clientX < buffer;

    if (isHoverLeftSide) {
      setShowPlusButton(true);
      setPlusButtonPosition({
        left: rect.left - gridRect.left + gridEl.scrollLeft - 36 / 2,
        top: gridEl.scrollTop,
      });
      setPlusButtonIndex(riser);
    } else if (isHoverRightSide) {
      setShowPlusButton(true);
      setPlusButtonPosition({
        left: rect.right - gridRect.left + gridEl.scrollLeft - 36 / 2,
        top: gridEl.scrollTop,
      });
      setPlusButtonIndex(riser + 1);
    } else {
      setShowPlusButton(false);
    }
  }, []);

  const headerCells: ReactNode[] = useMemo(() => {
    const cells: ReactNode[] = [];

    cells.push(<Corner key={'corner'} />);

    risers.forEach((riserCell, riserIndex) => {
      cells.push(
        <HeaderCell
          key={`header-${riserIndex}`}
          selected={selectedRisers.includes(riserIndex + 1)}
          value={riserCell.lotNumber}
          onClick={() => onHeaderCellClick(riserIndex + 1)}
          onRename={(name) => onHeaderCellRename?.(riserIndex + 1, name)}
          onMouseMove={(e) => handleMouseMove(e, riserIndex + 1)}
          onMouseLeave={handleMouseLeave}
        />
      );
    });

    return cells;
  }, [handleMouseMove, onHeaderCellClick, risers, selectedRisers]);

  return (
    <>
      {headerCells}
      {onAddRiser && showPlusButton && (
        <PlusButton
          left={plusButtonPosition.left}
          top={plusButtonPosition.top}
          onClick={() => onAddRiser?.(plusButtonIndex)}
          disabled={isRiserHasMergedLots(plusButtonIndex, lots)}
          onMouseEnter={handleButtonMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      )}
    </>
  );
};
