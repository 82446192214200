import React from 'react';
import { Box, BoxProps, SxProps, Theme, useTheme } from '@mui/material';

interface RowProps extends BoxProps {
  spacing?: number;
  equalsChildrenWidth?: boolean;
  childrenSx?: SxProps<Theme>;
  wrap?: boolean;
}

export const Row = ({
  children,
  spacing,
  wrap = true,
  equalsChildrenWidth,
  childrenSx,
  ...rest
}: RowProps) => {
  const theme = useTheme();
  const spacingValue = theme.spacing(spacing || 0);
  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap={wrap ? 'wrap' : 'nowrap'}
      gap={spacingValue}
      {...rest}
    >
      {React.Children.map(
        children,
        (child) =>
          child && (
            <Box
              flex={equalsChildrenWidth ? 1 : 'unset'}
              sx={{ maxWidth: '100%', ...childrenSx }}
            >
              {child}
            </Box>
          )
      )}
    </Box>
  );
};
