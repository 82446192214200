import { SelectOption } from 'shared/types';

export const statusOptions: SelectOption[] = [
  { value: '1', label: 'building.selectOptions.status.inUse' },
  { value: '4', label: 'building.selectOptions.status.frozen' },
  { value: '2', label: 'building.selectOptions.status.built' },
  { value: '3', label: 'building.selectOptions.status.underConstruction' },
];

export const quartersOptions: SelectOption[] = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
];

export const yearsOptions: SelectOption[] = [
  { value: '2023', label: '2023' },
  { value: '2024', label: '2024' },
  { value: '2025', label: '2025' },
  { value: '2026', label: '2026' },
  { value: '2027', label: '2027' },
  { value: '2028', label: '2028' },
  { value: '2029', label: '2029' },
  { value: '2030', label: '2030' },
];
