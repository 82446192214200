import { FC } from 'react';
import dayjs from 'dayjs';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { appendAsterisk } from 'shared/helpers/appendAsterisk';
import { PaletteColor } from 'shared/theme/colors';

export const DATE_FORMAT = 'DD MMMM YYYY';
export const DTO_DATE_FORMAT = 'YYYY-MM-DD';

interface StyledDatePickerProps extends DatePickerProps<dayjs.Dayjs> {
  name: string;
  label?: string;
  required?: boolean;
}

export const StyledDatePicker: FC<StyledDatePickerProps> = ({
  name,
  label,
  defaultValue,
  onChange,
  required,
  ...props
}) => {
  const { t } = useTranslation();
  const form = useFormContext();

  return (
    <Controller
      name={name || ''}
      control={form.control}
      defaultValue={defaultValue}
      render={({ field: { ref, ...rest }, fieldState: { error } }) => (
        <DatePicker
          label={appendAsterisk(label, required, !!error)}
          {...props}
          {...rest}
          value={rest.value ? dayjs(rest.value) : null}
          disabled={props.disabled}
          onChange={(newValue, context) => {
            const formattedDate = newValue?.format(DTO_DATE_FORMAT);
            rest.onChange(formattedDate);
            if (onChange) {
              onChange(newValue, context);
            }
          }}
          format={DATE_FORMAT}
          slotProps={{
            textField: {
              variant: 'filled',
              fullWidth: true,
              InputProps: { disableUnderline: true },
              error: !props.disabled && !!error,
              helperText: t(error?.message || ''),
              sx: {
                '& .MuiFilledInput-root': {
                  border:
                    !props.disabled && error
                      ? `1px solid ${PaletteColor.Error}`
                      : 'none',
                },
                ...props.sx,
              },
            },
          }}
        />
      )}
    />
  );
};
