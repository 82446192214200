import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { LOCAL_STORAGE_AUTH_KEY } from 'shared/const';

enum ApiUrl {
  Test = 'https://cp.nedvex.ru',
  Preprod = 'https://preprod.cp.nedvex.ru',
  Prod = 'https://cp.nedvex.pro',
}

enum Hostname {
  Prod = 'nedvex.pro',
  Preprod = 'preprod.nedvex.pro',
}

export const getBaseUrl = (): string => {
  switch (location.hostname) {
    case Hostname.Prod:
      return ApiUrl.Prod;
    case Hostname.Preprod:
      return ApiUrl.Preprod;
    default:
      return ApiUrl.Test;
  }
};

export const baseUrl = getBaseUrl();

export const baseApiUrl = `${baseUrl}/api`;

const baseQuery = fetchBaseQuery({
  baseUrl: baseApiUrl,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY) || '';
    headers.set('Accept', 'application/json');

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithAutoLogout: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    api.dispatch({
      type: 'user/logout',
    });
  }
  return result;
};

export enum ApiTag {
  profile = 'profile',
  user = 'user',
  agencyUser = 'agencyUser',
  developerUser = 'developerUser',
  agency = 'agency',
  developer = 'developer',
  object = 'object',
  objectPublicationErrors = 'objectPublicationErrors',
  building = 'building',
  objectDocument = 'objectDocument',
  chess = 'chess',
  plan = 'plan',
  polygon = 'polygon',
  contractor = 'contractor',
  albumType = 'albumType',
  album = 'album',
  newsPost = 'newsPost',
}

export const $rtkApi = createApi({
  reducerPath: 'rtkApi',
  baseQuery: baseQueryWithAutoLogout,
  tagTypes: [
    ApiTag.profile,
    ApiTag.user,
    ApiTag.agencyUser,
    ApiTag.developerUser,
    ApiTag.agency,
    ApiTag.developer,
    ApiTag.object,
    ApiTag.objectPublicationErrors,
    ApiTag.building,
    ApiTag.objectDocument,
    ApiTag.chess,
    ApiTag.plan,
    ApiTag.polygon,
    ApiTag.contractor,
    ApiTag.albumType,
    ApiTag.newsPost,
    ApiTag.album,
  ],
  endpoints: () => ({}),
});
