import { $rtkApi, ApiTag } from 'shared/api/rtkApi';
import { ListData, ResponseData } from 'shared/types';
import {
  mapDeveloperFromDto,
  mapDtoFromDeveloper,
} from '../../lib/mapDeveloper';
import { Developer, DeveloperDto } from '../types/developerSchema';

export const developerApi = $rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    createDeveloper: builder.mutation<Developer, Developer>({
      query: (developer) => ({
        url: '/developers',
        method: 'POST',
        body: mapDtoFromDeveloper(developer),
      }),
      transformResponse: (response: ResponseData<DeveloperDto>) =>
        mapDeveloperFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, developer) => [
        { type: ApiTag.developer, id: ApiTag.developer },
      ],
    }),
    updateDeveloper: builder.mutation<Developer, Developer>({
      query: (developer) => ({
        url: `/developers/${developer.id}`,
        method: 'PUT',
        body: mapDtoFromDeveloper(developer),
      }),
      transformResponse: (response: ResponseData<DeveloperDto>) =>
        mapDeveloperFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, developer) =>
        error
          ? []
          : [
              { type: ApiTag.developer, id: developer.id },
              { type: ApiTag.developer, id: ApiTag.developer },
            ],
    }),
    getDevelopers: builder.query<
      ListData<Developer>,
      { limit: number; offset: number; search: string }
    >({
      query: (params) => ({
        url: '/developers',
        method: 'GET',
        params,
      }),
      providesTags: (result, error, page) =>
        result
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTag.developer, id })),
              { type: ApiTag.developer, id: ApiTag.developer },
            ]
          : [{ type: ApiTag.developer, id: ApiTag.developer }],
      transformResponse: (response: ResponseData<ListData<DeveloperDto>>) => ({
        total: response.data.total,
        items: response.data.items.map((data) => mapDeveloperFromDto(data)),
      }),
    }),

    getDeveloper: builder.query<Developer, string | number>({
      query: (id) => ({
        url: `/developers/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: ResponseData<DeveloperDto>) =>
        mapDeveloperFromDto(response.data),
      providesTags: (result, error, id) => [{ type: ApiTag.developer, id }],
    }),
  }),
});

export const {
  useCreateDeveloperMutation,
  useUpdateDeveloperMutation,
  useGetDevelopersQuery,
  useGetDeveloperQuery,
} = developerApi;
