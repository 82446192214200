import { Lot } from 'entities/chess';
import { NewLot } from 'entities/chess/model/types/lotSchema';
import { sortByPositions } from './getSelectedLots';

export const addFloor = (
  lots: Lot[],
  floors: Lot[],
  addedFloor: number,
  risers: Lot[]
) => {
  // Увеличиваем значение floor на 1 для всех колонок, которые находятся выше или равны addedFloor
  const updateFloor = (lot: Lot) =>
    lot.positions.map((pos) => {
      if (pos.floor >= addedFloor) {
        return {
          ...pos,
          floor: pos.floor + 1,
        };
      }
      return pos;
    });

  const updatedLots = lots.map((lot) => {
    const positions = updateFloor(lot);
    return {
      ...lot,
      positions,
      row: Math.max(...positions.map((pos) => pos.floor)),
    } as NewLot;
  });

  const updatedFloors = floors.map((lot) => {
    const positions = updateFloor(lot);
    return {
      ...lot,
      positions,
      row: positions[0].floor,
    } as NewLot;
  });

  const newLots = new Array<Partial<Lot>>(risers.length)
    .fill({
      disabled: false,
      rowspan: 1,
    })
    .map((newLot, lotIndex) => {
      const riser = risers[lotIndex];
      return {
        positions: [{ riser: lotIndex + 1, floor: addedFloor }],
        type: 'flat',
        row: addedFloor,
        colspan: riser.colspan,
      } as Partial<NewLot>;
    });
  const sortedLots = [...(updatedLots as Lot[]), ...(newLots as Lot[])].sort(
    sortByPositions
  );

  const newFloors = [
    ...updatedFloors,
    {
      type: 'number',
      lotNumber: `${addedFloor}`,
      positions: [{ floor: addedFloor, riser: 0 }],
    } as Lot,
  ].map((floor) => ({
    ...floor,
    row: floor.positions[0].floor,
    colspan: 1,
    rowspan: 1,
  }));

  return { updatedFloors: newFloors, updatedLots: sortedLots };
};

export const deleteFloor = (
  lots: Lot[],
  floors: Lot[],
  deletedFloor: number,
  deletedLotsIds: number[]
) => {
  // Уменьшаем значение floor на 1 для всех колонок, которые находятся правее или равны addedRiser
  const updateFloor = (lot: Lot) =>
    lot.positions.map((pos) => {
      if (pos.floor >= deletedFloor) {
        return {
          ...pos,
          floor: pos.floor - 1,
        };
      }
      return pos;
    });

  const updatedFloors = floors
    .filter((lot) => lot.positions[0].floor !== deletedFloor)
    .map((lot) => {
      return {
        ...lot,
        positions: updateFloor(lot),
      } as NewLot;
    })
    .map((lot) => ({ ...lot, row: lot.positions[0].floor }));

  const updatedLots = lots
    .filter(({ id }) => !deletedLotsIds.includes(id))
    .map((lot) => {
      return {
        ...lot,
        positions: updateFloor(lot),
      };
    });

  const updatedLotsIds = updatedLots.map(({ id }) => id);

  const sortedLots = [
    ...lots.filter(
      ({ id }) => !updatedLotsIds.includes(id) && !deletedLotsIds.includes(id)
    ),
    ...updatedLots,
  ].sort(sortByPositions);

  return { updatedFloors, updatedLots: sortedLots };
};
