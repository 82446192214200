import { ChangeEvent, FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Pagination } from '@mui/material';
import {
  NewsPost,
  NewsPostDetailsCard,
  useDeleteNewsPostMutation,
  useGetNewsPostsQuery,
} from 'entities/newsPost';
import { useVersionCheck } from 'entities/object';
import { Col } from 'shared/ui/Col';
import { Row } from 'shared/ui/Row';
import { EditNewsPost } from './EditNewsPost';
import { NewsPostDetailsSkeleton } from './NewsPostDetailsSkeleton';

export const NewsPostDetails: FC = () => {
  const { objectId } = useParams<{ objectId: string }>();
  const [editingId, setEditingId] = useState<number | undefined>();
  const itemsPerPage = 15;
  const [page, setPage] = useState(1);

  const { checkVersion, showVersionChangedPopup } = useVersionCheck(objectId);

  const { data, isLoading } = useGetNewsPostsQuery({
    objectId: Number(objectId),
    limit: itemsPerPage,
    offset: (page - 1) * itemsPerPage,
  });

  const handleEditClick = (newsPost: NewsPost) => {
    if (editingId === newsPost.id) {
      setEditingId(undefined);
    } else {
      setEditingId(newsPost.id);
    }
  };

  const [deleteNewsPost] = useDeleteNewsPostMutation();

  const handleRemoveClick = async (newsPost: NewsPost) => {
    const isVersionChanged = await checkVersion();

    if (!isVersionChanged) {
      deleteNewsPost({ objectId, newsPostId: newsPost.id });
    }
  };

  const handleChangePage = (event: ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  const pageCount = data ? Math.ceil(data.total / itemsPerPage) : 0;

  if (isLoading) return <NewsPostDetailsSkeleton />;

  return (
    <Container>
      {showVersionChangedPopup()}
      <Col spacing={8}>
        {!editingId && (
          <EditNewsPost
            id={editingId}
            objectId={objectId}
            onEditEnd={() => setEditingId(undefined)}
          />
        )}
        {!editingId && pageCount > 1 && (
          <Row sx={{ width: '646px', justifyContent: 'center' }}>
            <Pagination
              count={pageCount}
              page={page}
              onChange={handleChangePage}
            />
          </Row>
        )}
        {data?.items.map((newsPost) =>
          editingId === newsPost.id ? (
            <EditNewsPost
              key={newsPost.id}
              id={editingId}
              objectId={objectId}
              newsPost={newsPost}
              onEditEnd={() => setEditingId(undefined)}
            />
          ) : (
            <NewsPostDetailsCard
              key={newsPost.id}
              newsPost={newsPost}
              onEditClick={() => handleEditClick(newsPost)}
              onRemoveClick={() => handleRemoveClick(newsPost)}
            />
          )
        )}
        {!editingId && pageCount > 1 && (
          <Row sx={{ width: '646px', justifyContent: 'center', mb: 8 }}>
            <Pagination
              count={pageCount}
              page={page}
              onChange={handleChangePage}
            />
          </Row>
        )}
      </Col>
    </Container>
  );
};
