import i18n from 'i18next';
import { SelectOption } from 'shared/types';

export const agencyTariffOptions: SelectOption[] = [
  {
    value: '96',
    label: 'agency.selectOption.agencyTariff.upTo10users',
    sublabel: i18n.t('ui.common.countMonths', { count: 1 }),
  },
  {
    value: '97',
    label: 'agency.selectOption.agencyTariff.upTo30users',
    sublabel: i18n.t('ui.common.countMonths', { count: 1 }),
  },
  {
    value: '98',
    label: 'agency.selectOption.agencyTariff.moreThan30users',
    sublabel: i18n.t('ui.common.countMonths', { count: 1 }),
  },
  {
    value: '99',
    label: 'agency.selectOption.agencyTariff.upTo10users',
    sublabel: i18n.t('ui.common.countMonths', { count: 6 }),
  },
  {
    value: '100',
    label: 'agency.selectOption.agencyTariff.upTo30users',
    sublabel: i18n.t('ui.common.countMonths', { count: 6 }),
  },
  {
    value: '101',
    label: 'agency.selectOption.agencyTariff.moreThan30users',
    sublabel: i18n.t('ui.common.countMonths', { count: 6 }),
  },
  {
    value: '102',
    label: 'agency.selectOption.agencyTariff.upTo10users',
    sublabel: i18n.t('ui.common.countMonths', { count: 12 }),
  },
  {
    value: '103',
    label: 'agency.selectOption.agencyTariff.upTo30users',
    sublabel: i18n.t('ui.common.countMonths', { count: 12 }),
  },
  {
    value: '104',
    label: 'agency.selectOption.agencyTariff.moreThan30users',
    sublabel: i18n.t('ui.common.countMonths', { count: 12 }),
  },
];

export const agencyPreviewCardTariffOptions: SelectOption[] = [
  {
    value: '96',
    label: 'agency.selectOption.agencyPreviewCardTariff.10users1month',
  },
  {
    value: '97',
    label: 'agency.selectOption.agencyPreviewCardTariff.30users1month',
  },
  {
    value: '98',
    label: 'agency.selectOption.agencyPreviewCardTariff.moreThan30users1month',
  },
  {
    value: '99',
    label: 'agency.selectOption.agencyPreviewCardTariff.10users6month',
  },
  {
    value: '100',
    label: 'agency.selectOption.agencyPreviewCardTariff.30users6month',
  },
  {
    value: '101',
    label: 'agency.selectOption.agencyPreviewCardTariff.moreThan30users6month',
  },
  {
    value: '102',
    label: 'agency.selectOption.agencyPreviewCardTariff.10users12month',
  },
  {
    value: '103',
    label: 'agency.selectOption.agencyPreviewCardTariff.30users12month',
  },
  {
    value: '104',
    label: 'agency.selectOption.agencyPreviewCardTariff.moreThan30users12month',
  },
];
