import { Component, ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ButtonLink } from './ButtonLink';
import { CardLayout } from './CardLayout';

interface Props extends WithTranslation {
  showReloadButton?: boolean;
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundaryComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Error:', error, errorInfo);
  }

  handleReload = () => {
    this.setState({ hasError: false });
    location.reload();
  };

  render() {
    if (this.state.hasError) {
      const { t } = this.props;
      return (
        <CardLayout disableBorder>
          <h1>{t('ui.errorBoundary.error')}</h1>
          {this.props.showReloadButton && (
            <ButtonLink onClick={this.handleReload}>
              {t('ui.errorBoundary.reload')}
            </ButtonLink>
          )}
        </CardLayout>
      );
    }

    return this.props.children;
  }
}

export const ErrorBoundary = withTranslation()(ErrorBoundaryComponent);
