import { ChangeEvent, FC } from 'react';
import i18n from 'i18next';
import { PatternFormat, PatternFormatProps } from 'react-number-format';
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { PaletteColor } from 'shared/theme/colors';
import { ClearTextFieldSvgIcon, ClockIcon } from './Icons';

type RangeTimeFieldProps = {
  onTimeChange: (value: string) => void;
} & PatternFormatProps &
  TextFieldProps;

export const RangeTimeField: FC<RangeTimeFieldProps> = ({
  onTimeChange,
  ...props
}) => {
  const onPatternFormatChange = (values: {
    formattedValue: string;
    value: string;
  }) => {
    const { formattedValue } = values;

    const times = formattedValue.replace(/ /g, '').split('-');
    if (times.length === 2) {
      let [start, end] = times;

      start = validateTimeAndFix(start);
      end = validateTimeAndFix(end);

      onTimeChange(`${start} - ${end}`);
    }
  };

  return (
    <PatternFormat
      {...props}
      customInput={TextField}
      placeholder={i18n.t('ui.rangeTimeField.placeholder')}
      mask="_"
      InputProps={{
        disableUnderline: true,
        endAdornment: props.value ? (
          <InputAdornment position="end">
            <IconButton
              size="small"
              onClick={() => {
                if (props.onChange) {
                  const e = {
                    target: {
                      value: '',
                      focus: () => null,
                    },
                  };

                  props.onChange(e as unknown as ChangeEvent<HTMLInputElement>);
                }
              }}
              sx={{ position: 'absolute', top: '13px', right: '7px' }}
            >
              <ClearTextFieldSvgIcon
                fontSize="inherit"
                style={{
                  width: '20px',
                  height: '20px',
                }}
              />
            </IconButton>
          </InputAdornment>
        ) : (
          <ClockIcon />
        ),
        sx: {
          border: props.error ? `1px solid ${PaletteColor.Error}` : 'none',
        },
      }}
      isAllowed={(values) => {
        const parts = values.value.match(/.{1,2}/g);
        if (
          parts &&
          (Number(parts[0]) < 0 ||
            Number(parts[0]) > 23 ||
            Number(parts[1]) < 0 ||
            Number(parts[1]) > 59 ||
            Number(parts[2]) < 0 ||
            Number(parts[2]) > 23 ||
            Number(parts[3]) < 0 ||
            Number(parts[3]) > 59)
        ) {
          return false;
        }
        return true;
      }}
      onValueChange={onPatternFormatChange}
    />
  );
};

function validateTimeAndFix(time: string) {
  if (!time) return '';
  let [hour, minute] = time.split(':');

  if (Number(hour) < 0) {
    hour = '00';
  } else if (Number(hour) > 23) {
    hour = '23';
  }

  if (Number(minute) < 0) {
    minute = '00';
  } else if (Number(minute) > 59) {
    minute = '59';
  }

  return `${hour}:${minute}`;
}
