import {
  FC,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box } from '@mui/material';
// eslint-disable-next-line boundaries/element-types
import { Polygon } from 'entities/plan';
import { DataCell } from './cells/DataCell';
import { EmptyCell } from './cells/EmptyCell';
import { FloorNumberCell } from './cells/FloorNumberCell';
import { getMergedCellBounds } from './lib/getMergedCellBounds';
import { isFloorHasMergedLots } from './lib/hasMergedLots';
import { PlusButton } from './PlusButton';
import { GridLot } from './types';

type RowsGeneratorProps = {
  lots: GridLot[];
  floors: GridLot[];
  polygons?: Polygon[];
  risersCount: number;
  filteredLotsIds?: number[];
  selectedFloors: number[];
  selectedLots: number[];
  handleCellClick: (lotId: number) => () => void;
  handleRowCellClick: (floor: number) => () => void;
  onRowRename?: (floor: number, name: string) => void;
  onAddFloor?: (index: number) => void;
};
// TODO: нужен рефакторинг, используются фичи и ентитисы
export const RowsGenerator: FC<RowsGeneratorProps> = ({
  lots,
  floors,
  polygons,
  risersCount,
  filteredLotsIds,
  selectedFloors,
  selectedLots,
  handleCellClick,
  handleRowCellClick,
  onRowRename,
  onAddFloor,
}) => {
  const [showPlusButton, setShowPlusButton] = useState(false);
  const [plusButtonPosition, setPlusButtonPosition] = useState<{
    left: number;
    top: number;
  }>({
    left: 0,
    top: 0,
  });
  const [plusButtonIndex, setPlusButtonIndex] = useState(0);
  const hideButtonWithDelay = useRef<NodeJS.Timeout | null>(null);
  const gridRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const gridEl = gridRef?.current;
    const handleScroll = () => {
      if (gridEl) {
        setPlusButtonPosition({
          ...plusButtonPosition,
          left: gridEl.scrollLeft,
        });
      }
    };

    gridEl?.addEventListener('scroll', handleScroll);

    return () => {
      gridEl?.removeEventListener('scroll', handleScroll);
    };
  }, [gridRef, plusButtonPosition]);

  const handleMouseLeave = () => {
    // Задержка в 200 мс перед скрытием кнопки
    hideButtonWithDelay.current = setTimeout(() => {
      setShowPlusButton(false);
    }, 200);
  };

  const handleButtonMouseEnter = () => {
    // Отменить задержку при наведении курсора на кнопку
    if (hideButtonWithDelay.current) {
      clearTimeout(hideButtonWithDelay.current);
    }
  };

  const handleMouseMove = useCallback((e: MouseEvent, floor: number) => {
    const buffer = 40;
    const target = e.currentTarget as HTMLElement;
    const rect = target.getBoundingClientRect();
    const gridEl = e.currentTarget.parentElement;

    if (!gridEl) {
      console.error('not found parent element for header cell');
      return;
    }

    if (!gridRef.current) {
      gridRef.current = gridEl;
    }

    const gridRect = gridEl.getBoundingClientRect();

    const isHoverTopSide = e.clientY - rect.top < buffer;
    const isHoverBottomSide = rect.bottom - e.clientY < buffer;

    if (isHoverTopSide) {
      setShowPlusButton(true);
      setPlusButtonPosition({
        top: rect.top - gridRect.top + gridEl.scrollTop - 36 / 2,
        left: gridEl.scrollLeft,
      });
      setPlusButtonIndex(floor + 1);
    } else if (isHoverBottomSide) {
      setShowPlusButton(true);
      setPlusButtonPosition({
        top: rect.bottom - gridRect.top + gridEl.scrollTop - 36 / 2,
        left: gridEl.scrollLeft,
      });
      setPlusButtonIndex(floor);
    } else {
      setShowPlusButton(false);
    }
  }, []);

  const handleAddRowClick = useCallback(() => {
    onAddFloor?.(plusButtonIndex);
  }, [onAddFloor, plusButtonIndex]);

  const rows = useMemo(() => {
    const placedIds: number[] = [];
    const resultRows: ReactNode[] = [];

    for (let rowIndex = 0; rowIndex < floors.length; rowIndex++) {
      const floor = floors.length - rowIndex;
      const floorCell = floors[rowIndex];
      const floorNumberCell = (
        <FloorNumberCell
          key={`row-${floorCell.id}`}
          selected={selectedFloors.includes(floor)}
          value={floorCell.lotNumber}
          onClick={handleRowCellClick(floor)}
          onRename={(name) => onRowRename?.(floor, name)}
          onMouseMove={(e) => handleMouseMove(e, floor)}
          onMouseLeave={handleMouseLeave}
        />
      );
      resultRows.push(floorNumberCell);
      // TODO сгенерить map {[col;row] : cellData}
      //  и получать ячейку напрямую, без .find при каждой итерации
      const rowLots = lots.filter(
        (lot) =>
          lot.positions &&
          lot.positions.some((position) => position.floor === floor)
      );

      for (let riserIndex = 0; riserIndex < risersCount; riserIndex++) {
        const riser = riserIndex + 1;
        const riserLots = rowLots.filter((lot) =>
          lot.positions.some((position) => position.riser === riser)
        );
        const riserCells = riserLots.map((lot) =>
          getCell(
            lot,
            selectedLots.includes(lot.id),
            placedIds,
            floor,
            riser,
            handleCellClick,
            filteredLotsIds,
            polygons
          )
        );
        const isSplittedCell = riserCells.length > 1;

        if (isSplittedCell) {
          resultRows.push(
            <Box
              key={`splitted-${floor}-${riser}`}
              display={'flex'}
              flexWrap={'nowrap'}
            >
              {riserCells}
            </Box>
          );
        } else {
          resultRows.push(riserCells);
        }
      }
    }

    return resultRows;
  }, [
    floors,
    selectedFloors,
    handleRowCellClick,
    lots,
    onRowRename,
    handleMouseMove,
    risersCount,
    selectedLots,
    handleCellClick,
    filteredLotsIds,
    polygons,
  ]);

  return (
    <>
      {rows}
      {onAddFloor && showPlusButton && (
        <PlusButton
          top={plusButtonPosition.top}
          left={plusButtonPosition.left}
          disabled={isFloorHasMergedLots(plusButtonIndex, lots)}
          onClick={handleAddRowClick}
          onMouseEnter={handleButtonMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      )}
    </>
  );
};

const getCell = (
  lot: GridLot | undefined,
  selected: boolean,
  placedIds: number[],
  floor: number,
  riser: number,
  handleCellClick: (lotId: number) => () => void,
  filteredLotsIds?: number[],
  polygons?: Polygon[]
) => {
  if (!lot) {
    return <EmptyCell />;
  }

  const lotPlaced = placedIds.includes(lot.id);

  const { mergedToLeft, mergedToRight, mergedToTop, mergedToBottom } =
    getMergedCellBounds(lot.positions, floor, riser);

  if (
    lotPlaced &&
    !mergedToLeft &&
    !mergedToRight &&
    !mergedToTop &&
    !mergedToBottom
  ) {
    console.error(
      `Ячейка имеет повторяющийся id: ${lot.id} но находится далеко от уже добавленной ячейки с таким же id`
    );
  }

  if (!lotPlaced) {
    placedIds.push(lot.id);
  }
  const polygonIndex = polygons?.findIndex((polygon) =>
    polygon.chess.includes(lot.id)
  );
  const hiddenByFilters = filteredLotsIds && !filteredLotsIds.includes(lot.id);
  return (
    <DataCell
      key={`${lot.id}-${floor}-${riser}`}
      lot={lot}
      showEmpty={lotPlaced}
      onClick={handleCellClick(lot.id)}
      mergedToLeft={mergedToLeft}
      mergedToRight={mergedToRight}
      mergedToTop={mergedToTop}
      mergedToBottom={mergedToBottom}
      selected={selected}
      hiddenByFilters={hiddenByFilters}
      disabled={lot.disabled}
      polygonNumber={
        polygonIndex !== undefined && polygonIndex > -1
          ? polygonIndex + 1
          : undefined
      }
    />
  );
};
