import { $rtkApi, ApiTag } from 'shared/api/rtkApi';
import { ListData, ResponseData } from 'shared/types';
import { Document } from '../types/documentSchema';

export const documentApi = $rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    createObjectDocument: builder.mutation<
      Document,
      { objectId: number; formData: FormData }
    >({
      query: ({ objectId, formData }) => ({
        url: `/buildings/${objectId}/file-upload`,
        method: 'POST',
        body: formData,
      }),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, { objectId }) => [
        { type: ApiTag.objectDocument },
        { type: ApiTag.objectPublicationErrors, id: objectId },
        { type: ApiTag.object, id: objectId },
      ],
    }),

    updateObjectDocument: builder.mutation<
      Document,
      { objectId: number; hash: string; formData: FormData }
    >({
      query: ({ objectId, hash, formData }) => ({
        url: `/buildings/${objectId}/file/${hash}`,
        method: 'POST',
        body: formData,
      }),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, { objectId }) => [
        { type: ApiTag.objectDocument },
        { type: ApiTag.object, id: objectId },
      ],
    }),

    getObjectDocuments: builder.query<
      ListData<Document>,
      { objectId: number; limit: number; offset: number; search: string }
    >({
      query: (params) => ({
        url: `/buildings/${params.objectId}/files`,
        method: 'GET',
        params: {
          document_id: params.objectId,
          name: params.search,
          limit: params.limit,
          offset: params.offset,
        },
      }),
      providesTags: (result, error, page) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: ApiTag.objectDocument,
                id,
              })),
              { type: ApiTag.objectDocument },
            ]
          : [{ type: ApiTag.objectDocument }],
      transformResponse: (response: ResponseData<ListData<Document>>) => ({
        total: response.data.total,
        items: response.data.items.map((data) => data),
      }),
    }),

    deleteObjectDocument: builder.mutation<
      void,
      { objectId: number; hash: string }
    >({
      query: ({ objectId, hash }) => ({
        url: `/buildings/${objectId}/file/${hash}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { objectId }) => [
        { type: ApiTag.objectDocument },
        { type: ApiTag.objectPublicationErrors, id: objectId },
        { type: ApiTag.object, id: objectId },
      ],
    }),
  }),
});

export const {
  useCreateObjectDocumentMutation,
  useUpdateObjectDocumentMutation,
  useGetObjectDocumentsQuery,
  useDeleteObjectDocumentMutation,
} = documentApi;
