import { $rtkApi, ApiTag } from 'shared/api/rtkApi';
import { ListData, PhotoData, ResponseData } from 'shared/types';
import { mapAlbumFromDto, mapDtoFromAlbum } from '../../lib/mapAlbumDto';
import { mapDtoFromAlbumsPositions } from '../../lib/mapAlbumsPositions';
import { mapDtoFromPhotosPositions } from '../../lib/mapPhotosPositions';
import {
  Album,
  AlbumDto,
  AlbumsPositions,
  EditAlbum,
  PhotosPositions,
} from '../types/albumSchema';

export const albumApi = $rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    createAlbum: builder.mutation<Album, EditAlbum>({
      query: (album) => ({
        url: '/albums',
        method: 'POST',
        body: mapDtoFromAlbum(album),
      }),
      transformResponse: (response: ResponseData<AlbumDto>) =>
        mapAlbumFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, album) => [
        { type: ApiTag.album },
        { type: ApiTag.objectPublicationErrors, id: album.objectId },
        { type: ApiTag.object, id: album.objectId },
      ],
    }),

    updateAlbum: builder.mutation<Album, EditAlbum & { id: number }>({
      query: (album) => ({
        url: `/albums/${album.id}`,
        method: 'PUT',
        body: mapDtoFromAlbum(album),
      }),
      transformResponse: (response: ResponseData<AlbumDto>) =>
        mapAlbumFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, album) => [
        { type: ApiTag.album },
        { type: ApiTag.album, id: album.id },
        { type: ApiTag.objectPublicationErrors, id: album.objectId },
        { type: ApiTag.object, id: album.objectId },
      ],
    }),

    getAlbums: builder.query<ListData<Album>, { objectId: number }>({
      query: (params) => ({
        url: `/albums`,
        method: 'GET',
        params: {
          building_id: params.objectId,
        },
      }),
      providesTags: (result, error, page) =>
        result
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTag.album, id })),
              { type: ApiTag.album },
            ]
          : [{ type: ApiTag.album }],
      transformResponse: (response: ResponseData<ListData<AlbumDto>>) => ({
        total: response.data.total,
        items: response.data.items.map((data) => mapAlbumFromDto(data)),
      }),
    }),

    getAlbum: builder.query<Album, string | number>({
      query: (id) => ({
        url: `/albums/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: ResponseData<AlbumDto>) =>
        mapAlbumFromDto(response.data),
      providesTags: (result, error, id) => [{ type: ApiTag.album, id }],
    }),

    deleteAlbum: builder.mutation<void, Album>({
      query: (album) => ({
        url: `/albums/${album.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, album) => [
        { type: ApiTag.album },
        { type: ApiTag.objectPublicationErrors, id: album.objectId },
        { type: ApiTag.object, id: album.objectId },
      ],
    }),

    changeAlbumsPositions: builder.mutation<
      void,
      { objectId: number; albumsPositions: AlbumsPositions }
    >({
      query: (params) => ({
        url: `/albums/positions/change/${params.objectId}`,
        method: 'POST',
        body: mapDtoFromAlbumsPositions(params.albumsPositions),
      }),
      async onQueryStarted(
        updatedAlbumsPositions,
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          albumApi.util.updateQueryData(
            'getAlbums',
            {
              objectId: updatedAlbumsPositions.objectId,
            },
            (draft) => {
              draft.items = draft.items.sort(
                (a, b) =>
                  updatedAlbumsPositions.albumsPositions.albumsIds.indexOf(
                    a.id
                  ) -
                  updatedAlbumsPositions.albumsPositions.albumsIds.indexOf(b.id)
              );
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, { objectId }) => [
        { type: ApiTag.album },
        { type: ApiTag.object, id: objectId },
      ],
    }),

    changePhotosPosition: builder.mutation<
      void,
      { objectId: number; albumId: number; photosPositions: PhotosPositions }
    >({
      query: (params) => ({
        url: `/albums/positions/change_images/${params.albumId}`,
        method: 'POST',
        body: mapDtoFromPhotosPositions(params.photosPositions),
      }),
      invalidatesTags: (result, error, { objectId }) => [
        { type: ApiTag.album },
        { type: ApiTag.object, id: objectId },
      ],
    }),

    uploadAlbumPhoto: builder.mutation<
      PhotoData,
      {
        objectId?: string | number;
        albumId: string | number;
        formData: FormData;
      }
    >({
      query: ({ albumId, formData }) => ({
        url: `/albums/${albumId}/photo-upload`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: (result, error, { albumId, objectId }) => [
        { type: ApiTag.album },
        { type: ApiTag.objectPublicationErrors, id: albumId },
        { type: ApiTag.object, id: objectId },
      ],
    }),

    deleteAlbumPhoto: builder.mutation<
      void,
      {
        objectId: string | number;
        albumId: string | number;
        photoId: string | number;
      }
    >({
      query: ({ albumId, photoId }) => ({
        url: `/albums/${albumId}/photo-delete/${photoId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { albumId, objectId }) => [
        { type: ApiTag.album, id: albumId },
        { type: ApiTag.objectPublicationErrors, id: albumId },
        { type: ApiTag.object, id: objectId },
      ],
    }),
  }),
});

export const {
  useCreateAlbumMutation,
  useUpdateAlbumMutation,
  useGetAlbumsQuery,
  useGetAlbumQuery,
  useDeleteAlbumMutation,
  useChangeAlbumsPositionsMutation,
  useChangePhotosPositionMutation,
  useUploadAlbumPhotoMutation,
  useDeleteAlbumPhotoMutation,
} = albumApi;
