import { redirect, RouteObject } from 'react-router-dom';
import {
  ObjectCharacteristicsEditForm,
  ObjectDetails,
  ObjectLocationEditForm,
  ObjectMainDataEditForm,
} from 'entities/object';
import { BuildingsDetails } from 'features/building';
import { DocumentsDetails } from 'features/document';
import { NewsPostDetails } from 'features/newsPost';
import { EditObject } from 'features/object';
import { getRoute } from 'shared/const';
import { AlbumTabs } from 'widgets/AlbumTabs';
import { AnalyticsTab } from 'widgets/Analytics';
import { ChessList } from 'widgets/ChessList';

export const editObjectTabsRoutes: RouteObject[] = [
  {
    index: true,
    loader: ({ params }) =>
      redirect(
        params.objectId
          ? getRoute.EditObjectDetails(params.objectId)
          : getRoute.Objects()
      ),
  },
  {
    path: getRoute.EditObjectDetails(':objectId'),
    element: <EditObject />,
    children: [
      {
        index: true,
        loader: ({ params }) =>
          redirect(
            params.objectId
              ? getRoute.EditObjectDetailsGeneral(params.objectId)
              : getRoute.Objects()
          ),
      },
      {
        path: getRoute.EditObjectDetailsGeneral(':objectId'),
        element: <ObjectMainDataEditForm />,
      },
      {
        path: getRoute.EditObjectDetailsLocation(':objectId'),
        element: <ObjectLocationEditForm />,
      },
      {
        path: getRoute.EditObjectDetailsCharacteristics(':objectId'),
        element: <ObjectCharacteristicsEditForm />,
      },
    ],
  },
  {
    path: getRoute.EditObjectBuildings(':objectId'),
    element: <BuildingsDetails />,
  },
  {
    path: getRoute.EditObjectBuilding(':objectId', ':buildingId'),
    element: <BuildingsDetails />,
  },
  {
    path: getRoute.EditObjectBuildingCreate(':objectId'),
    element: <BuildingsDetails />,
  },
  {
    path: getRoute.EditObjectPhotos(':objectId'),
    element: <AlbumTabs />,
  },
  {
    path: getRoute.EditObjectDocuments(':objectId'),
    element: <DocumentsDetails />,
  },
];

export const objectDetailsTabsRoutes: RouteObject[] = [
  {
    index: true,
    loader: ({ params }) =>
      redirect(
        params.objectId
          ? getRoute.ObjectAbout(params.objectId)
          : getRoute.Objects()
      ),
  },
  {
    path: getRoute.ObjectAbout(':objectId'),
    element: <ObjectDetails />,
  },
  {
    path: getRoute.ObjectBuildings(':objectId'),
    element: <BuildingsDetails />,
  },
  {
    path: getRoute.ObjectBuilding(':objectId', ':buildingId'),
    element: <BuildingsDetails />,
  },
  {
    path: getRoute.ObjectBuildingCreate(':objectId'),
    element: <BuildingsDetails />,
  },
  {
    path: getRoute.ObjectChessboards(':objectId'),
    element: <ChessList />,
    children: [
      {
        path: getRoute.ObjectChessboard(
          ':objectId',
          ':buildingId',
          ':entranceId'
        ),
        element: <ChessList />,
        children: [
          {
            path: getRoute.ObjectPlans(
              ':objectId',
              ':buildingId',
              ':entranceId'
            ),
            element: <ChessList />,
          },
        ],
      },
    ],
  },
  {
    path: getRoute.ObjectPhotos(':objectId'),
    element: <AlbumTabs />,
  },
  {
    path: getRoute.ObjectDocuments(':objectId'),
    element: <DocumentsDetails />,
  },
  {
    path: getRoute.ObjectNews(':objectId'),
    element: <NewsPostDetails />,
  },
  {
    path: getRoute.ObjectAnalytics(':objectId'),
    element: <AnalyticsTab />,
  },
  {
    path: getRoute.ObjectChanges(':objectId'),
    element: <div>changes</div>,
  },
];
