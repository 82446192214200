import { FC, useMemo, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
/* eslint-disable boundaries/element-types */
import { useGetObjectsQuery } from 'entities/object';
import {
  DeveloperUser,
  DeveloperUserInfoCard,
  developerUserApi,
} from 'entities/user';
import { CreateDeveloperUserCard } from 'features/user';
import { useDebounce } from 'shared/hooks/useDebounce';
import { usePopover } from 'shared/hooks/usePopover';
import { useScrollPaginator } from 'shared/hooks/useScrollPaginator';
import { Col } from 'shared/ui/Col';
import { Row } from 'shared/ui/Row';
import { SearchInput } from 'shared/ui/SearchInput';
import { RectSkeleton } from 'shared/ui/Skeleton/RectSkeleton';
import { Developer } from '../../model/types/developerSchema';

// TODO: нужен рефакторинг, используются фичи и ентитисы
export const DeveloperUsers: FC = () => {
  const { t } = useTranslation();
  const developer = useOutletContext<Developer>();

  const [searchText, setSearchText] = useState<string>('');
  const debouncedSearchText = useDebounce(searchText);
  const [editingDeveloperUser, setEditingDeveloperUser] =
    useState<DeveloperUser>();
  const [isEditingDeveloperUser, setIsEditingDeveloperUser] =
    useState<boolean>(false);
  const form = useForm();

  const { data: developerObjects, isFetching: isDeveloperObjectsFetching } =
    useGetObjectsQuery({
      limit: 0,
      offset: 0,
      search: '',
      developerId: developer.id,
    });

  const {
    queryResult: { isFetching: isDeveloperUsersFetching },
    items: developerUsers,
    isAllItemsLoaded,
    reset: resetUserList,
  } = useScrollPaginator(
    developerUserApi.useGetDeveloperUsersQuery,
    { search: debouncedSearchText, developer_id: developer.id },
    { headerHeight: 400, previewCardHeight: 100, amountPerRow: 1 }
  );

  const handleAddDeveloperUserClick = () => {
    setEditingDeveloperUser(undefined);
    setIsEditingDeveloperUser(true);
  };

  const handleUserSaved = () => {
    setEditingDeveloperUser(undefined);
    setIsEditingDeveloperUser(false);
    resetUserList();
  };

  const handleCancelEditingUser = () => {
    setEditingDeveloperUser(undefined);
    setIsEditingDeveloperUser(false);
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const handleEditClick = (user: DeveloperUser) => {
    setIsEditingDeveloperUser(true);
    setEditingDeveloperUser(user);
  };

  const isDeveloperUsersLimit = useMemo(() => {
    if (developerObjects && developerUsers) {
      return developerUsers.length >= developerObjects.total * 3;
    }
  }, [developerObjects, developerUsers]);

  const popoverContent = (
    <Typography sx={{ p: 5 }}>
      {t('developer.form.error.usersLimit')}
    </Typography>
  );

  const isAddUserButtonDisabled =
    isDeveloperUsersFetching ||
    isDeveloperObjectsFetching ||
    isEditingDeveloperUser ||
    isDeveloperUsersLimit;

  const { PopoverComponent, openPopover, closePopover } = usePopover({
    popoverContent,
  });

  return (
    <Col spacing={8}>
      <Row justifyContent="space-between">
        <Box
          onMouseEnter={isDeveloperUsersLimit ? openPopover : undefined}
          onMouseLeave={closePopover}
        >
          <Button
            variant={'contained'}
            color={'primary'}
            size={'small'}
            onClick={handleAddDeveloperUserClick}
            disabled={isAddUserButtonDisabled}
          >
            {t('developer.form.label.addEmployee')}
          </Button>
        </Box>
        <PopoverComponent />

        <FormProvider {...form}>
          <SearchInput
            name={'search'}
            searchPlaceholder={t('developer.form.label.searchByNameOrPhone')}
            onSearchChange={handleSearch}
            sx={{ width: 280 }}
          />
        </FormProvider>
      </Row>
      <Col spacing={8} width={648}>
        {isEditingDeveloperUser && (
          <CreateDeveloperUserCard
            developerUser={editingDeveloperUser}
            developerId={developer.id}
            onCancelClick={handleCancelEditingUser}
            onSaved={handleUserSaved}
          />
        )}

        {!isEditingDeveloperUser &&
          developerUsers.map((user) => {
            return (
              <DeveloperUserInfoCard
                key={user.id}
                user={user}
                onEditClick={handleEditClick}
                onDelete={resetUserList}
              />
            );
          })}
        {!isEditingDeveloperUser && !isAllItemsLoaded && (
          <RectSkeleton width={606} height={200} count={3} />
        )}
      </Col>
    </Col>
  );
};
