import { $rtkApi, ApiTag } from 'shared/api/rtkApi';
import { ListData, ResponseData } from 'shared/types';
import { mapDtoFromCreatePlanData, mapPlanFromDto } from '../../lib/mapPlan';
import { SavePlanData, Plan, PlanDto } from '../types/planSchema';

export const PlanApi = $rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    createPlan: builder.mutation<
      Plan,
      { objectId?: string | number; planData: SavePlanData }
    >({
      query: ({ planData }) => ({
        url: '/plans',
        method: 'POST',
        body: mapDtoFromCreatePlanData(planData),
      }),
      transformResponse: (response: ResponseData<PlanDto>) =>
        mapPlanFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, { objectId, planData }) => [
        { type: ApiTag.plan },
        { type: ApiTag.plan, id: planData.id },
        { type: ApiTag.object, id: objectId },
      ],
    }),

    updatePlan: builder.mutation<
      Plan,
      { objectId?: string | number; planData: SavePlanData }
    >({
      query: ({ planData }) => ({
        url: `/plans_update/${planData.id}`,
        method: 'POST',
        body: mapDtoFromCreatePlanData(planData),
      }),
      transformResponse: (response: ResponseData<PlanDto>) =>
        mapPlanFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, { objectId, planData }) => [
        { type: ApiTag.plan },
        { type: ApiTag.plan, id: planData.id },
        { type: ApiTag.object, id: objectId },
      ],
    }),

    getPlans: builder.query<ListData<Plan>, { entranceId: number | string }>({
      query: (params) => ({
        url: '/plans',
        method: 'GET',
        params: {
          section_id: params.entranceId,
        },
      }),
      providesTags: (result, error, page) =>
        result
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTag.plan, id })),
              { type: ApiTag.plan },
            ]
          : [{ type: ApiTag.plan }],
      transformResponse: (response: ResponseData<ListData<PlanDto>>) => ({
        total: response.data.total,
        items: response.data.items.map((data) => mapPlanFromDto(data)),
      }),
    }),

    getPlan: builder.query<Plan, string | number>({
      query: (id) => ({
        url: `/plans/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: ResponseData<PlanDto>) =>
        mapPlanFromDto(response.data),
      providesTags: (result, error, id) => [{ type: ApiTag.plan, id }],
    }),

    deletePlan: builder.mutation<
      void,
      { objectId?: string | number; planId: string | number }
    >({
      query: ({ planId }) => ({
        url: `/plans/${planId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { objectId, planId }) => [
        { type: ApiTag.plan },
        { type: ApiTag.plan, planId },
        { type: ApiTag.object, id: objectId },
      ],
    }),
  }),
});

export const {
  useCreatePlanMutation,
  useUpdatePlanMutation,
  useGetPlansQuery,
  useGetPlanQuery,
  useDeletePlanMutation,
} = PlanApi;
