import { FC } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { TextColor } from 'shared/theme/colors';
import { CardLayout } from 'shared/ui/CardLayout';
import { Col } from 'shared/ui/Col';
import { ProfileIcon } from 'shared/ui/Icons';
import { Row } from 'shared/ui/Row';
import { DeveloperObject } from '../../../model/types/objectSchema';

const formatDate = (inputDate: string | null) => {
  if (!inputDate) return '';
  const date = dayjs(inputDate);
  return date.format('HH:mm, DD.MM.YYYY');
};

type UpdateInfoCardProps = {
  object: DeveloperObject;
};

export const UpdateInfoCard: FC<UpdateInfoCardProps> = ({ object }) => {
  const { t } = useTranslation();

  const UpdateInfoField = ({
    title,
    date,
    userName,
  }: {
    title: string;
    date: string | null;
    userName: string | null;
  }) => {
    return (
      <Col spacing={3}>
        <Typography
          variant="accentM"
          color={TextColor.Secondary}
          sx={{ flexGrow: 1 }}
        >
          {title + ':'}
        </Typography>
        {date && (
          <Typography variant="accentM" color={TextColor.Primary}>
            {formatDate(date)}
          </Typography>
        )}
        {userName && (
          <Row spacing={3}>
            <ProfileIcon
              sx={{
                fontSize: '16px',
                verticalAlign: 'middle',
              }}
              inheritViewBox
            />
            <Typography variant="caption" color={TextColor.Secondary}>
              {t('object.card.infoUpdatedBy') + ' ' + userName}
            </Typography>
          </Row>
        )}
      </Col>
    );
  };

  return (
    <CardLayout maxWidth={344}>
      <UpdateInfoField
        title={t('object.card.objectInfoUpdated')}
        date={object.dateUpdate}
        userName={object.buildingUpdateManager}
      />
      <UpdateInfoField
        title={t('object.card.objectInfoCreated')}
        date={object.dateCreate}
        userName={object.buildingCreateManager}
      />
    </CardLayout>
  );
};
