import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { getRoute } from 'shared/const';
import { TextColor } from 'shared/theme/colors';
import { theme } from 'shared/theme/theme';
import { ButtonLink } from './ButtonLink';
import { CardLayout } from './CardLayout';
import { Col } from './Col';
import { ImageIcon } from './Icons';
import { Row } from './Row';

export type PreviewInfoCardProps = {
  id: number;
  logoSrc?: string;
  title: string;
  subtitle?: string;
  text?: string;
  onLinkClick?: () => void;
};

interface PreviewCardsGridProps {
  data: PreviewInfoCardProps[];
  isShowSkeleton: boolean;
  spacing?: number;
  fetchingError?: boolean;
}

export const PreviewCardsGrid: FC<PreviewCardsGridProps> = ({
  data,
  isShowSkeleton,
  spacing = 5,
  fetchingError,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  const skeletonCount = isLgUp ? 9 : isSmUp ? 4 : 2;

  if (fetchingError) {
    return (
      <CardLayout disableBorder>
        <Typography>{t('ui.common.error.fetchDataError')}</Typography>
        <ButtonLink onClick={() => navigate(getRoute.Main())}>
          {t('ui.common.returnToMain')}
        </ButtonLink>
      </CardLayout>
    );
  }

  return (
    <Grid container spacing={spacing} columns={{ xs: 4, sm: 8, lg: 12 }}>
      {data.map((item) => (
        <Grid item xs={4} key={item.id}>
          <Card sx={{ height: '100%' }}>
            <CardContent
              sx={{
                padding: 6,
                '&:last-child': {
                  paddingBottom: 6,
                },
              }}
            >
              <Row spacing={5} wrap={false}>
                {item.logoSrc !== undefined &&
                  (item.logoSrc ? (
                    <Avatar
                      variant="square"
                      alt={item.title}
                      src={item.logoSrc}
                      sx={{ width: 60, height: 60, borderRadius: 2 }}
                    />
                  ) : (
                    <ImageIcon sx={{ width: 60, height: 60 }} />
                  ))}
                <Col spacing={3}>
                  <Col spacing={2}>
                    <ButtonLink onClick={item.onLinkClick}>
                      <Typography variant={'accentS'}>{item.title}</Typography>
                    </ButtonLink>
                    {item.subtitle && (
                      <Typography
                        variant={'caption'}
                        color={TextColor.Tertiary}
                      >
                        {item.subtitle}
                      </Typography>
                    )}
                  </Col>
                  {item.text && (
                    <Typography
                      variant={'paragraphS'}
                      color={TextColor.Secondary}
                    >
                      {item.text}
                    </Typography>
                  )}
                </Col>
              </Row>
            </CardContent>
          </Card>
        </Grid>
      ))}
      {isShowSkeleton &&
        Array.from({ length: skeletonCount }).map((_, index) => (
          <Grid item xs={4} key={index}>
            <Skeleton
              variant="rectangular"
              height={120}
              sx={{ borderRadius: '8px' }}
            />
          </Grid>
        ))}
    </Grid>
  );
};
