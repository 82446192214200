import { SelectOption } from 'shared/types';

export const classOptions: SelectOption[] = [
  {
    label: 'analytics.selectOptions.class.class',
    value: 0,
  },
  {
    label: 'analytics.selectOptions.class.business',
    value: 3,
  },
  {
    label: 'analytics.selectOptions.class.comfort',
    value: 2,
  },
  {
    label: 'analytics.selectOptions.class.economy',
    value: 1,
  },
  {
    label: 'analytics.selectOptions.class.elite',
    value: 4,
  },
];

export const districtOptions: SelectOption[] = [
  {
    label: 'analytics.selectOptions.district.district',
    value: 0,
  },
  {
    label: 'analytics.selectOptions.district.adler',
    value: 1,
  },
  {
    label: 'analytics.selectOptions.district.center',
    value: 2,
  },
  {
    label: 'analytics.selectOptions.district.host',
    value: 3,
  },
  {
    label: 'analytics.selectOptions.district.lazar',
    value: 4,
  },
  {
    label: 'analytics.selectOptions.district.tuapse',
    value: 5,
  },
];

export const lotUnitTypeOptions: SelectOption[] = [
  {
    label: 'analytics.selectOptions.lotUnitType.pcs',
    value: 1,
  },
  {
    label: 'analytics.selectOptions.lotUnitType.rub',
    value: 2,
  },
  {
    label: 'analytics.selectOptions.lotUnitType.m2',
    value: 3,
  },
];

export const lotUnitSelectionOptions: SelectOption[] = [
  {
    label: 'analytics.selectOptions.lotUnitSelection.st',
    value: 1,
  },
  {
    label: 'analytics.selectOptions.lotUnitSelection.pcs',
    value: 2,
  },
];

export const dateRangeTypeOptions: SelectOption[] = [
  {
    label: 'analytics.selectOptions.dateRangeType.today',
    value: 1,
  },
  {
    label: 'analytics.selectOptions.dateRangeType.yesterday',
    value: 2,
  },
  {
    label: 'analytics.selectOptions.dateRangeType.week',
    value: 3,
  },
  {
    label: 'analytics.selectOptions.dateRangeType.month',
    value: 4,
  },
  {
    label: 'analytics.selectOptions.dateRangeType.quarter',
    value: 5,
  },
  {
    label: 'analytics.selectOptions.dateRangeType.year',
    value: 6,
  },
];
