// eslint-disable-next-line boundaries/element-types
import { sortByPositions } from 'widgets/Chessboard/lib/getSelectedLots';
import {
  Chess,
  ChessDto,
  CreateChessDto,
  Lot,
  LotPosition,
  NewChess,
  NewLotDto,
  UpdatedChess,
} from '../model/types/lotSchema';
import { fillPositionsMap } from './fillPositionsMap';
import { fillLotsByFloorsIds } from './gridHelper';
import { mapDtoFromLot, mapLotFromDto } from './mapLot';

export const defaultNewLotDtoData: NewLotDto = {
  disabled: 0,
  colspan: 1,
  rowspan: 1,
  registrations: [],
  type: 'flat',
  row: 0,
};

export const mapDtoFromNewChess = ({
  entranceId,
  floorsCount,
  risersCount,
}: NewChess): CreateChessDto => {
  const lots: NewLotDto[] = [];

  // добавляем первый стояк - корневой элемент
  lots.push({
    ...defaultNewLotDtoData,
    row: floorsCount + 1,
    type: 'head',
  });

  for (let riserIndex = 0; riserIndex < risersCount; riserIndex++) {
    const riser = riserIndex + 1;
    lots.push({
      ...defaultNewLotDtoData,
      num_apart: riser.toString(),
      row: floorsCount + 1,
      type: 'head',
    });
  }

  for (let rowIndex = 0; rowIndex < floorsCount; rowIndex++) {
    const floor = floorsCount - rowIndex;
    lots.push({
      ...defaultNewLotDtoData,
      num_apart: floor.toString(),
      type: 'number',
      row: floor,
    });
    for (let riserIndex = 0; riserIndex < risersCount; riserIndex++) {
      lots.push({
        ...defaultNewLotDtoData,
        row: floor,
        type: 'flat',
      });
    }
  }
  return {
    section_id: entranceId,
    chess: lots,
  };
};

export const mapChessFromDto = ({
  id,
  create_date,
  update_date,
  pavilion_id,
  floor_amount,
  riser_amount,
  name,
  chess,
  manager_update,
}: ChessDto): Chess => {
  const chessFloorsDto = chess.filter((lot) => lot.type === 'number');

  const chessFloorsCount = chessFloorsDto.length;

  const chessFloors = chessFloorsDto.map((lot, i) =>
    mapLotFromDto(lot, [
      {
        floor: chessFloorsCount - i,
        riser: 0,
      },
    ])
  );

  const risersDto = chess.filter((lot) => lot.type === 'head');

  // первый стояк - это корневой элемент, удаляем его
  const chessRisers = risersDto.slice(1).map((lot, lotIndex) =>
    mapLotFromDto(lot, [
      {
        floor: chessFloorsCount + 1,
        riser: lotIndex + 1,
      },
    ])
  );

  const chessRisersIds: number[] = [];

  chessRisers.forEach((riser) => {
    const colspan = riser.colspan || 1;
    for (let i = 0; i < colspan; i++) {
      chessRisersIds.push(riser.id);
    }
  });

  const floorsAndLotsDto = chess.filter(
    (lot) => (lot.type === 'flat' && !lot.child) || lot.type === 'number'
  );

  const lotsDto = floorsAndLotsDto.filter((lot) => lot.type === 'flat');

  const grid: (number | null)[][] = fillLotsByFloorsIds(
    chessRisersIds,
    floorsAndLotsDto,
    chessFloorsCount
  );

  const positionsMap: { [key: number]: LotPosition[] } = {};

  fillPositionsMap(positionsMap, grid, chessRisersIds, lotsDto);

  const chessLots: Lot[] = [];
  lotsDto.forEach((lot) => {
    // Получаем индекс стояка по grid
    let riserIndex = -1;
    for (let row = 0; row < grid.length; row++) {
      for (let col = 0; col < grid[row].length; col++) {
        if (grid[row][col] === lot.id) {
          riserIndex = col;
          break;
        }
      }
      if (riserIndex !== -1) break;
    }

    if (!positionsMap[lot.id]) {
      console.error('no positions for lot', lot.id);
    } else {
      const mappedLot = mapLotFromDto(lot, positionsMap[lot.id]);
      mappedLot.headId = chessRisersIds[riserIndex];

      chessLots.push(mappedLot);
    }
  });

  return {
    id,
    buildingId: pavilion_id,
    createDate: create_date,
    updateDate: update_date,
    floorAmount: floor_amount,
    riserAmount: riser_amount,
    name,
    lots: chessLots,
    floors: chessFloors,
    risers: chessRisers,
    managerUpdate: manager_update && {
      id: manager_update.id,
      email: manager_update.email,
      firstName: manager_update.first_name,
      lastName: manager_update.last_name,
    },
  };
};

export const mapUpdatedChessToDto = (chess: UpdatedChess) => {
  // добавляем первый стояк - угловой квадратик
  const cornerRiser = {
    ...defaultNewLotDtoData,
    row: chess.floors.length + 1,
    type: 'head',
  };

  const risersDto = chess.risers.map((lot) => ({
    ...mapDtoFromLot(lot),
    id: undefined,
  }));

  const floorsAndLotsDto = [
    ...chess.floors,
    ...chess.lots.map((lot) => ({
      ...lot,
      id: undefined,
    })),
  ]
    .sort(sortByPositions)
    .map((lot) => {
      const { id, ...lotWithoutId } = lot as Lot;
      const lotDto = mapDtoFromLot(lotWithoutId);

      if (lot.type === 'flat') {
        lotDto.head_name = risersDto[lot.positions[0].riser - 1].num_apart;
      }
      return lotDto;
    });

  return [cornerRiser, ...risersDto, ...floorsAndLotsDto];
};
