import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ObjectVersionSchema } from '../types/objectVersionSchema';

const initialState: ObjectVersionSchema = {
  version: '',
};

export const objectVersionSlice = createSlice({
  name: 'objectVersion',
  initialState,
  reducers: {
    updateObjectVersion: (state, action: PayloadAction<string>) => {
      state.version = action.payload;
    },
  },
});

export const { actions: objectVersionActions } = objectVersionSlice;
export const { reducer: objectVersionReducer } = objectVersionSlice;
