import { FC, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { objectZod, ObjectMainDataEditForm } from 'entities/object';
import { CardLayout } from 'shared/ui/CardLayout';

type StepOneProps = {
  onDone: () => void;
  onCancel: () => void;
  onStepValidityChange: (isStepValid: boolean) => void;
};

const stepOneZod = objectZod
  .pick({
    name: true,
    site: true,
    objectType: true,
    houseType: true,
    saleStatus: true,
    objectDescription: true,
    contractors: true,
  })
  .refine(
    (values) => {
      if (values.objectType === '1' && !values.houseType) {
        return false;
      }
      return true;
    },
    {
      message: 'object.form.error.houseType',
      path: ['houseType'],
    }
  );

const formFields = [
  'name',
  'site',
  'objectType',
  'houseType',
  'saleStatus',
  'objectDescription',
];

export const StepOne: FC<StepOneProps> = ({
  onDone,
  onCancel,
  onStepValidityChange,
}) => {
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState<boolean>(false);
  const form = useFormContext();

  const checkFormValidity = useCallback(() => {
    const [name, site, objectType, houseType, saleStatus, objectDescription] =
      form.getValues(formFields);

    if (objectType === '2' && houseType) {
      form.setValue('houseType', '');
    }

    setIsValid(
      stepOneZod.safeParse({
        name,
        site,
        objectType,
        houseType,
        saleStatus,
        objectDescription,
      }).success
    );
  }, [form]);

  useEffect(() => {
    const subscription = form.watch(() => {
      checkFormValidity();
    });

    return () => subscription.unsubscribe();
  }, [checkFormValidity, form, form.watch]);

  useEffect(() => {
    checkFormValidity();
    onStepValidityChange(isValid);
  }, [checkFormValidity, isValid, onStepValidityChange]);

  return (
    <CardLayout
      header={{
        title: t('object.create.stepOne.title'),
        subtitle: t('object.create.stepOne.form.subtitle'),
      }}
      footer={{
        onOkClick: isValid ? onDone : () => form.trigger(formFields),
        onCancelClick: onCancel,
        cancelLabel: t('ui.common.cancel'),
      }}
      width={648}
    >
      <ObjectMainDataEditForm />
    </CardLayout>
  );
};
