import { $rtkApi } from 'shared/api/rtkApi';
import { ResponseData } from 'shared/types';
import { LotStatusesStatistics } from '../types/objectAnalyticsSchema';

export const objectAnalyticsApi = $rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getLotStatusesStatistics: builder.query<
      LotStatusesStatistics,
      { objectId: number }
    >({
      query: ({ objectId }) => ({
        url: `/object-analytic/chess-status-statistic/${objectId}`,
        method: 'GET',
      }),
      transformResponse: (response: ResponseData<LotStatusesStatistics>) =>
        response.data,
    }),
  }),
});

export const { useGetLotStatusesStatisticsQuery } = objectAnalyticsApi;
