import { Lot } from 'entities/chess';
import { LotDataEqualsMap } from 'entities/chess/model/types/lotSchema';

/*
 * возвращает объект LotDataEqualsMap в котором каждое поле заполнено так:
 * если во всех лотах значение в этом поле одинаковое то в этом поле будет true
 * если значения у разных лотов разные в этом поле то в этом поле false
 */
export const createLotDataEqualsMap = (lots: Lot[]): LotDataEqualsMap => {
  if (lots.length === 0) {
    throw new Error('Lots array should not be empty.');
  }

  const areArraysEqual = (arr1: string[], arr2: string[]): boolean => {
    if (arr1.length !== arr2.length) return false;
    const arr1Sorted = arr1.toSorted();
    const arr2Sorted = arr2.toSorted();

    for (let i = 0; i < arr1.length; i++) {
      if (arr1Sorted[i] !== arr2Sorted[i]) return false;
    }
    return true;
  };

  const isEqualsValues = (
    values: (string | number | string[] | undefined)[]
  ): boolean => {
    const firstValue = values[0];
    const isArray = Array.isArray(firstValue);

    if (isArray) {
      for (const value of values) {
        if (
          !Array.isArray(value) ||
          !areArraysEqual(firstValue as string[], value)
        ) {
          return false;
        }
      }
    } else {
      for (const value of values) {
        if (firstValue !== value) {
          return false;
        }
      }
    }

    return true;
  };

  const lotDataEqualsMap: LotDataEqualsMap = {
    status: false,
    lotNumber: false,
    lotNumberingFormat: false,
    actualSaleDate: false,
    numberOfFloors: false,
    numberOfRooms: false,
    lotType: false,
    landArea: false,
    totalArea: false,
    kitchenArea: false,
    balconyArea: false,
    decoration: false,
    windowView: false,
    panorama: false,
    promotion: false,
    totalPrice: false,
    pricePerMeter: false,
    registration: false,
    buyingOptions: false,
    installmentTerms: false,
    type: false,
  };

  const lotKeys = Object.keys(lotDataEqualsMap) as Array<
    keyof LotDataEqualsMap
  >;

  for (const key of lotKeys) {
    const values = lots.map((lot) => lot[key]);

    lotDataEqualsMap[key] = isEqualsValues(values);
  }

  return lotDataEqualsMap as LotDataEqualsMap;
};
