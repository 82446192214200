import { ruRU } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { ruRU as dateRU } from '@mui/x-date-pickers/locales';
import { components } from './components';
import { palette } from './palette';
import { spacing } from './spacing';
import { typography } from './typography';

export const theme = createTheme(
  {
    spacing,
    palette,
    typography,
    components,
    breakpoints: {
      values: {
        xs: 0,
        sm: 560,
        md: 768,
        lg: 1024,
        xl: 1144,
      },
    },
  },
  ruRU,
  dateRU
);
