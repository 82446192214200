import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Agency,
  AgencyEditForm,
  createAgencyZod,
  useCreateAgencyMutation,
} from 'entities/agency';
import { getRoute } from 'shared/const';
import { CardLayout } from 'shared/ui/CardLayout';
import { ResultCard } from 'shared/ui/ResultCard';

export const CreateAgencyCard: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isShowAdditionalData, setIsShowAdditionalData] =
    useState<boolean>(false);
  const [createdAgencyId, setCreatedAgencyId] = useState<number | null>(null);

  const [
    createAgency,
    {
      isLoading: isAgencyCreating,
      isSuccess: isAgencySuccessfullyCreated,
      isError: isAgencyCreatingError,
    },
  ] = useCreateAgencyMutation();

  const form = useForm<Agency>({
    resolver: zodResolver(createAgencyZod),
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      website: '',
      inn: '',
      kpp: '',
      description: '',
      officeName: '',
      address: '',
      phone: '',
      mail: '',
      tariff: '',
      logo: '',
      facebook: '',
      instagram: '',
      ok: '',
      vk: '',
      youtube: '',
      color: '#000000',
    },
  });

  const handleCreateAgency = async (agencyData: Agency) => {
    try {
      const createdAgencyData = await createAgency(agencyData).unwrap();
      setCreatedAgencyId(createdAgencyData.id);
    } catch (error) {
      console.error(error);
    }
  };

  if (isAgencySuccessfullyCreated || isAgencyCreatingError) {
    return (
      <ResultCard
        isSuccess={isAgencySuccessfullyCreated}
        successProps={{
          title: t('agency.resultCard.success.title'),
          subtitle: t('agency.resultCard.success.subtitle'),
          primaryButton: {
            navigateTo: createdAgencyId
              ? getRoute.Agency(createdAgencyId)
              : getRoute.Agencies(),
          },
          secondaryButton: {
            label: t('agency.resultCard.addEmployees'),
            navigateTo: createdAgencyId
              ? getRoute.CreateAgencyUser(createdAgencyId)
              : getRoute.Agencies(),
          },
        }}
        errorProps={{
          title: t('agency.resultCard.error.title'),
          primaryButton: {
            navigateTo: getRoute.Agencies(),
          },
        }}
      />
    );
  }

  return (
    <CardLayout
      header={{ title: t('agency.tab.button.addAgency') }}
      footer={{
        onOkClick: form.handleSubmit(handleCreateAgency),
        onCancelClick: () => navigate(getRoute.Agencies()),
        okLabel: t('ui.common.create'),
        okButtonColor: 'primary',
        cancelLabel: t('ui.common.close'),
        okButtonLoading: isAgencyCreating,
      }}
      maxWidth={648}
      marginAuto
      paddingY={24}
    >
      <FormProvider {...form}>
        <AgencyEditForm
          isShowAdditionalData={isShowAdditionalData}
          onToggleAdditionalData={setIsShowAdditionalData}
        />
      </FormProvider>
    </CardLayout>
  );
};
