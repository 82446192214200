import { FC, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, Typography } from '@mui/material';
import { getLabelByValue } from 'shared/helpers/getLabelByValue';
import {
  decorationOptions,
  numberOfRoomsOptions,
  typeOptions,
} from 'shared/selectOptions/chessOptions';
import { SelectOption } from 'shared/types';
import { ButtonLink } from 'shared/ui/ButtonLink';
import { CircleCrossIcon } from 'shared/ui/Icons';
import { Row } from 'shared/ui/Row';
import { LotsFilters } from './FiltersDialog';
import { rangeFilterFormatter } from './lib/useRangeFilterFormatter ';

type FiltersBarProps = {
  lotsFilters?: Partial<LotsFilters>;
  onSelectAllClick: () => void;
  showSelectEmpty: boolean;
  onSelectEmptyClick: () => void;
  showSelectForSale: boolean;
  onSelectForSaleClick: () => void;
  onSetFiltersClick: () => void;
  onDeleteFilterClick: (key: keyof LotsFilters) => void;
};

export const FiltersBar: FC<FiltersBarProps> = ({
  lotsFilters,
  onSelectAllClick,
  showSelectEmpty,
  onSelectEmptyClick,
  showSelectForSale,
  onSelectForSaleClick,
  onSetFiltersClick,
  onDeleteFilterClick,
}) => {
  const { t } = useTranslation();

  const getSelectedFiltersText = (
    lotsFilters: Partial<LotsFilters>,
    onDeleteFilterClick: (key: keyof LotsFilters) => void
  ) => {
    const result: ReactNode[] = [];

    const getChip = (
      filterName: string,
      selectedLabels: string,
      key: keyof LotsFilters
    ) => {
      return (
        <Chip
          key={filterName}
          deleteIcon={<CircleCrossIcon />}
          sx={{ maxWidth: 200 }}
          label={
            <Typography
              variant="accentS"
              textOverflow={'ellipsis'}
              overflow={'hidden'}
            >
              {filterName} {selectedLabels}
            </Typography>
          }
          onDelete={() => onDeleteFilterClick(key)}
        />
      );
    };

    if (lotsFilters.pricePerMeter?.from || lotsFilters.pricePerMeter?.to) {
      result.push(
        getChip(
          t('chess.filter.priceM2'),
          rangeFilterFormatter(lotsFilters?.pricePerMeter || {}, t),
          'pricePerMeter'
        )
      );
    }

    if (lotsFilters.totalPrice?.from || lotsFilters.totalPrice?.to) {
      result.push(
        getChip(
          t('chess.filter.price'),
          rangeFilterFormatter(lotsFilters?.totalPrice || {}, t),
          'totalPrice'
        )
      );
    }

    if (lotsFilters.totalArea?.from || lotsFilters.totalArea?.to) {
      result.push(
        getChip(
          t('chess.filter.area'),
          rangeFilterFormatter(lotsFilters?.totalArea || {}, t),
          'totalArea'
        )
      );
    }

    const getLabels = (values: string[], options: SelectOption[]) =>
      values.map((value) => getLabelByValue(options, value)).join(', ');

    if (lotsFilters.numberOfRooms?.length) {
      const selectedLabels = getLabels(
        lotsFilters.numberOfRooms,
        numberOfRoomsOptions
      );
      result.push(
        getChip(
          t('chess.filter.numberOfRooms'),
          selectedLabels,
          'numberOfRooms'
        )
      );
    }

    if (lotsFilters.lotsTypes?.length) {
      const selectedLabels = getLabels(lotsFilters.lotsTypes, typeOptions);
      result.push(
        getChip(t('chess.filter.lotType'), selectedLabels, 'lotsTypes')
      );
    }

    if (lotsFilters.decorations?.length) {
      const selectedLabels = getLabels(
        lotsFilters.decorations,
        decorationOptions
      );
      result.push(
        getChip(t('chess.filter.area'), selectedLabels, 'decorations')
      );
    }

    // if (lotsFilters.registrations?.length) {
    //   const selectedLabels = getLabels(
    //     lotsFilters.registrations,
    //     registrationOptions
    //   );
    //   result.push(getChip('Оформление', selectedLabels, 'registrations'));
    // }

    return result;
  };

  const chips = useMemo(
    () =>
      lotsFilters && getSelectedFiltersText(lotsFilters, onDeleteFilterClick),
    [getSelectedFiltersText, lotsFilters, onDeleteFilterClick]
  );

  return (
    <Row spacing={5} maxWidth={1024} sx={{ m: 'auto' }}>
      <ButtonLink underlineType={'dotted'} onClick={onSelectAllClick}>
        {t('chess.filter.pickAll')}
      </ButtonLink>
      {showSelectEmpty && (
        <ButtonLink underlineType={'dotted'} onClick={onSelectEmptyClick}>
          {t('chess.filter.allEmpty')}
        </ButtonLink>
      )}
      {showSelectForSale && (
        <ButtonLink underlineType={'dotted'} onClick={onSelectForSaleClick}>
          {t('chess.filter.allInSale')}
        </ButtonLink>
      )}
      <ButtonLink underlineType={'dotted'} onClick={onSetFiltersClick}>
        {t('chess.filter.filterBy')}
      </ButtonLink>
      {chips}
    </Row>
  );
};
