import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Card } from '@mui/material';
import { getRoute } from 'shared/const';
import { SelectOption } from 'shared/types';
import { ChipWithSelect } from 'shared/ui/ChipWithSelect';
import { Row } from 'shared/ui/Row';

type ChessPickerProps = {
  buildingOptions: SelectOption[];
  entranceOptions: Record<string, SelectOption[]>;
  objectId: string;
  buildingId: string;
  entranceId: string;
  isChessboardMode: boolean;
  onModeChange: () => void;
};

export const ChessPicker: FC<ChessPickerProps> = ({
  buildingOptions,
  entranceOptions,
  objectId,
  buildingId,
  entranceId,
  isChessboardMode,
  onModeChange,
}) => {
  const { t } = useTranslation();
  const form = useForm({
    defaultValues: {
      building: buildingId,
      entrance: entranceId,
    },
  });
  const navigate = useNavigate();

  useEffect(() => {
    const subscription = form.watch((values, { name }) => {
      if (name === 'building' || name === 'entrance') {
        const buildingValue = values.building;
        const entranceValue = values.entrance;
        if (buildingValue && entranceValue) {
          const route = isChessboardMode
            ? getRoute.ObjectChessboard
            : getRoute.ObjectPlans;
          navigate(route(objectId, buildingValue, entranceValue));
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [form, isChessboardMode, navigate, objectId]);

  useEffect(() => {
    const subscription = form.watch((values, { name }) => {
      if (name === 'building') {
        const newBuildingValue = values.building;
        if (newBuildingValue) {
          const firstEntranceOption =
            entranceOptions[newBuildingValue][0].value.toString();
          if (firstEntranceOption) {
            form.setValue('entrance', firstEntranceOption, {
              shouldValidate: true,
            });
          }
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [entranceOptions, form]);

  return (
    <Card sx={{ width: 'fit-content', p: 4 }}>
      <FormProvider {...form}>
        <Row sx={{ gap: { xs: 4, md: 9 } }}>
          <Row spacing={4}>
            <ChipWithSelect
              options={buildingOptions}
              label={t('chess.list.building')}
              name="building"
            />
            <ChipWithSelect
              options={entranceOptions[buildingId]}
              label={t('chess.list.entrance')}
              name="entrance"
            />
          </Row>
          <Button size="small" variant="outlined" onClick={onModeChange}>
            {isChessboardMode
              ? t('chess.list.plans')
              : t('chess.list.chessboards')}
          </Button>
        </Row>
      </FormProvider>
    </Card>
  );
};
