import { FC, useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
/* eslint-disable boundaries/element-types */
import { objectTypeOptions, useGetObjectsQuery } from 'entities/object';
import { getRoute } from 'shared/const';
import { getMainPhotoUrl } from 'shared/helpers/getImageUrl';
import { getLabelByValue } from 'shared/helpers/getLabelByValue';
import { useDebounce } from 'shared/hooks/useDebounce';
import { Col } from 'shared/ui/Col';
import {
  PreviewCardsGrid,
  PreviewInfoCardProps,
} from 'shared/ui/PreviewCardsGrid';
import { Row } from 'shared/ui/Row';
import { SearchInput } from 'shared/ui/SearchInput';
import { Developer } from '../../model/types/developerSchema';

export const DeveloperObjects: FC = () => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState<string>('');
  const debouncedSearchText = useDebounce(searchText);

  const developer = useOutletContext<Developer>();
  const navigate = useNavigate();
  const form = useForm();

  const { data, isFetching: isDeveloperObjectsFetching } = useGetObjectsQuery({
    limit: 100,
    offset: 0,
    search: debouncedSearchText,
    developerId: developer.id,
  });

  const handleAddObjectClick = () => {
    navigate(getRoute.CreateObject(developer.id));
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const location = useLocation();

  const handleObjectClick = useCallback(
    (objectId: number) => {
      navigate(getRoute.ObjectAbout(objectId), { state: { from: location } });
    },
    [location, navigate]
  );

  const formattedDataForPreviewCards: PreviewInfoCardProps[] = useMemo(() => {
    return (
      data?.items.map((object) => ({
        ...object,
        id: object.id,
        logoSrc: object.mainPhotoHash
          ? getMainPhotoUrl(object.mainPhotoHash)
          : '',
        title: object.name,
        text: object.address,
        subtitle: t(getLabelByValue(objectTypeOptions, object.objectType)),
        onLinkClick: () => handleObjectClick(object.id),
      })) || []
    );
  }, [data?.items, handleObjectClick, t]);

  return (
    <Col spacing={8}>
      <Row
        sx={{
          justifyContent: 'space-between',
          flexDirection: { xs: 'column-reverse', sm: 'row' },
          gap: 6,
        }}
      >
        <Button
          variant={'contained'}
          color={'primary'}
          size={'small'}
          onClick={handleAddObjectClick}
          sx={{ width: '100%' }}
        >
          {t('developer.card.label.addObject')}
        </Button>
        <FormProvider {...form}>
          <SearchInput
            name={'search'}
            searchPlaceholder={t('ui.searchInput.byName')}
            onSearchChange={handleSearch}
            sx={{ width: { xs: '100%', sm: 280 } }}
          />
        </FormProvider>
      </Row>
      <PreviewCardsGrid
        data={formattedDataForPreviewCards}
        isShowSkeleton={isDeveloperObjectsFetching}
      />
    </Col>
  );
};
