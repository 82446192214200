import React, { createElement } from 'react';
import { Components, Theme } from '@mui/material';
import {
  CheckboxCheckedIcon,
  CheckboxIcon,
  ChevronDownIcon,
} from 'shared/ui/Icons';
import { BgColor, BorderColor, TextColor } from './colors';
import { fontInter } from './typography';

export const components: Components<Omit<Theme, 'components'>> = {
  MuiCssBaseline: {
    styleOverrides: `
      body {
        font-family: Inter, sans-serif;
      }

      /* Стилизация скроллбара */
      *::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      *::-webkit-scrollbar-track {
        background: transparent; 
      }
      *::-webkit-scrollbar-thumb {
        background: ${BorderColor.Scrollbar}; 
        border-radius: 8px;
      }
      *::-webkit-scrollbar-thumb:hover {
        background: ${BorderColor.Scrollbar};
      }
      ::-webkit-scrollbar-corner {
        background: transparent;
      }
    `,
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        fontFamily: fontInter,
      },
    },
    defaultProps: {
      variantMapping: {
        accentS: 'div',
        accentM: 'div',
        paragraphS: 'div',
        paragraphM: 'div',
        headerXS: 'div',
        headerM: 'div',
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
        borderRadius: '8px',
        border: `1px solid ${BorderColor.Gray}`,
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {},
    },
  },
  MuiCheckbox: {
    defaultProps: {
      icon: React.createElement(CheckboxIcon),
      checkedIcon: React.createElement(CheckboxCheckedIcon),
    },
    styleOverrides: {
      root: {
        width: '24px',
        height: '24px',
        color: `${BgColor.Gray}`,

        '.MuiSvgIcon-root': {},

        '.MuiTouchRipple-root': {},
      },
      colorPrimary: {},
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        border: 'none',
        boxShadow:
          '0px 0px 8px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.08)',
        borderRadius: '8px',
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        padding: '12px',
        '& span': {
          marginRight: '10px',
        },
      },
    },
  },
  MuiTouchRipple: {
    styleOverrides: {
      root: {
        paddingRight: 0,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        borderRadius: 8,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
      },
      sizeSmall: {},
      input: {
        paddingRight: 0,
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        borderRadius: 8,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
      },
      sizeSmall: {
        height: '36px',
        padding: '12px 8px 12px 12px',
        backgroundColor: BgColor.Input,
        '.MuiInputBase-input': {
          padding: '0 0 0 4px',
          fontSize: '14px',
          fontWeight: 400,
        },
        '&:hover': {
          backgroundColor: BgColor.Input,
        },
        '&.Mui-focused': {
          backgroundColor: BgColor.Input,
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {},
  },
  MuiInputLabel: {
    styleOverrides: {
      filled: {},
    },
  },
  MuiTextField: {
    defaultProps: {
      InputProps: {
        disableUnderline: true,
      },
      InputLabelProps: {
        style: {
          color: TextColor.Secondary,
        },
      },
    },
    styleOverrides: {
      root: {
        '& label.Mui-error': {
          color: TextColor.Secondary,
        },
        InputLabelProps: {
          style: {
            color: TextColor.Secondary,
          },
        },
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        padding: 0,
        border: 'none',
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiAutocomplete: {
    defaultProps: {
      popupIcon: createElement(ChevronDownIcon),
    },
  },
  MuiSelect: {
    defaultProps: {
      disableUnderline: true,
      IconComponent: ChevronDownIcon,
    },
    styleOverrides: {
      icon: {
        fontSize: '20px',
        marginRight: '4px',
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {},
    },
  },
  MuiStepIcon: {
    styleOverrides: {
      text: {
        fontFamily: fontInter,
      },
    },
  },
  MuiStepConnector: {
    styleOverrides: {
      line: {},
    },
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        height: 3,
      },
      root: {},
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        fontFamily: fontInter,
        padding: '24px',
        background: TextColor.StaticWhite,
        textTransform: 'none',
        ':first-of-type': {
          borderRadius: '8px 0 0 8px',
        },
        ':last-of-type': {
          borderRadius: '0 8px 8px 0',
        },
      },
    },
  },
  MuiChip: {
    defaultProps: {},
    styleOverrides: {
      iconColorPrimary: TextColor.Tertiary,
      root: {
        background: TextColor.Primary,
        color: TextColor.StaticWhite,
        borderRadius: '8px',
        '& .MuiChip-deleteIcon': {
          display: 'flex',
          padding: '6px',
          height: '100%',
          width: '32px',
          margin: 0,
          borderLeft: `1px solid ${TextColor.Secondary}`,
        },
        '& .MuiChip-label': {
          padding: '6px',
          paddingLeft: '16px',
          '& .MuiTypography-root': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        margin: 0,
        transform: 'translateY(3px)',
        fontFamily: fontInter,
        fontSize: '14px',
        fontWeight: 400,
        color: TextColor.Secondary,
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {},
    styleOverrides: {
      root: {},
    },
  },
  MuiButton: {
    defaultProps: {
      variant: 'contained',
      style: {},
    },
    variants: [
      {
        props: { variant: 'contained' },
        style: {},
      },
      {
        props: { variant: 'outlined' },
        style: {
          borderColor: TextColor.Tertiary,
        },
      },
      {
        props: { variant: 'text' },
        style: {
          padding: 0,
          margin: 0,
          height: 20,
          minWidth: 0,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    ],
    styleOverrides: {
      root: {
        borderRadius: '8px',
        fontFamily: fontInter,
        boxShadow: 'none',
        ':hover': {
          boxShadow: 'none',
        },
        ':active': {
          boxShadow: 'none',
        },
      },
      sizeLarge: {},
      sizeMedium: {
        letterSpacing: 'initial',
        textTransform: 'none',
        fontSize: '16px',
        height: '56px',
        padding: '16px 24px',
      },
      sizeSmall: {
        letterSpacing: 'initial',
        textTransform: 'none',
        height: '37px',
        padding: '10px 24px',
        fontSize: '14px',
        fontWeight: '500',
      },
    },
  },
  MuiContainer: {
    defaultProps: {
      disableGutters: true,
    },
  },
  MuiSkeleton: {
    defaultProps: {
      animation: 'wave',
    },
    styleOverrides: {
      root: {
        backgroundColor: BgColor.Input,
      },
    },
  },
};
