import { FC } from 'react';
import { useNavigate, useOutlet, useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@mui/material';
import { useGetAgencyQuery } from 'entities/agency';
import { getRoute } from 'shared/const';
import { getApiImage } from 'shared/helpers/getImageUrl';
import { useRouteMatch } from 'shared/hooks/useRouteMatch';
import { Col } from 'shared/ui/Col';
import { ErrorBoundary } from 'shared/ui/ErrorBoundary';
import { ImageIcon } from 'shared/ui/Icons';
import { DetailsPageHeader } from 'widgets/DetailsPageHeader';
import { AgencyDetailsPageSkeleton } from './AgencyDetailsPageSkeleton';

export const AgencyDetailsPage: FC = () => {
  const { t } = useTranslation();
  const { agencyId } = useParams<{ agencyId: string }>();

  const { data: agency, isLoading } = useGetAgencyQuery(agencyId ?? skipToken);

  const routeMatch = useRouteMatch([
    getRoute.AgencyDetails(':agencyId'),
    getRoute.AgencyUsers(':agencyId'),
  ]);
  const navigate = useNavigate();
  const currentTab = routeMatch?.pattern?.path;

  const outlet = useOutlet(agency);

  if (isLoading) return <AgencyDetailsPageSkeleton />;

  if (!agency || !agencyId) {
    navigate(getRoute.Agencies());
    return null;
  }

  const logo = agency.logo ? (
    <Avatar
      variant="square"
      alt={'logo'}
      src={getApiImage(agency.logo)}
      sx={{ width: 60, height: 60, borderRadius: 2 }}
    />
  ) : (
    <ImageIcon sx={{ width: 60, height: 60 }} />
  );

  const tabs = [
    {
      label: t('agency.detailsPage.tabLabel.details'),
      value: getRoute.AgencyDetails(':agencyId'),
      to: getRoute.AgencyDetails(agency.id),
    },
    {
      label: t('agency.detailsPage.tabLabel.employees'),
      value: getRoute.AgencyUsers(':agencyId'),
      to: getRoute.AgencyUsers(agency.id),
    },
  ];

  return (
    <Col maxWidth={'lg'} sx={{ m: 'auto', gap: { xs: 5, sm: 8 } }}>
      <DetailsPageHeader
        title={agency.name}
        subtitle={agency.address}
        selectedTab={currentTab}
        backButtonText={t('ui.common.back')}
        backButtonRoute={getRoute.Agencies()}
        logo={logo}
        tabs={tabs}
      />
      <ErrorBoundary key={currentTab} showReloadButton>
        {outlet}
      </ErrorBoundary>
    </Col>
  );
};
