import { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
/* eslint-disable boundaries/element-types */
import { Entrance, EntranceInfoCard } from 'entities/entrance';
import { EditEntranceCard } from 'features/entrance';
import { useConfirmationDialog } from 'shared/hooks/useConfirmationDialog';
import { Col } from 'shared/ui/Col';
import { RemovalConfirmationCard } from 'shared/ui/RemovalConfirmationCard';
import { Row } from 'shared/ui/Row';
import { SelectField } from 'shared/ui/SelectField';
import { StyledDatePicker } from 'shared/ui/StyledDatePicker';
import { StyledTextField } from 'shared/ui/StyledTextField';
import { quartersOptions, statusOptions, yearsOptions } from '../model/consts';
import { Building } from '../model/types/buildingSchema';

type BuildingEditFormProps = {
  building?: Building;
  newBuildingEntrances: Entrance[];
  onSaveEntrance: (entrance: Entrance) => void;
  onRemoveEntrance: (entrance: Entrance) => void;
  onDuplicateEntrance: (entrance: Entrance) => void;
  isEntranceSaving: boolean;
};

enum FormMode {
  initial = 'initial',
  addingEntrance = 'addingEntrance',
  editingEntrance = 'editingEntrance',
}

// TODO: нужен рефакторинг, используются фичи и ентитисы
export const BuildingEditForm: FC<BuildingEditFormProps> = ({
  building,
  newBuildingEntrances,
  onSaveEntrance,
  onRemoveEntrance,
  onDuplicateEntrance,
  isEntranceSaving,
}) => {
  const { t } = useTranslation();
  const [formMode, setFormMode] = useState<FormMode>(FormMode.initial);
  const [editableEntrance, setEditableEntrance] = useState<Partial<Entrance>>();
  const {
    isShowRemoveConfirmation,
    showRemoveConfirmation,
    hideRemoveConfirmation,
    confirmRemoveAction,
  } = useConfirmationDialog({
    onConfirm: () => handleDeleteEntranceClick(),
  });
  const form = useFormContext();
  const statusValue = form.watch('status');

  const handleAddEntranceClick = () => {
    setFormMode(FormMode.addingEntrance);
    setEditableEntrance({ buildingId: building?.id });
  };

  const handleEntranceSaved = (entrance: Entrance) => {
    setFormMode(FormMode.initial);
    onSaveEntrance(entrance);
  };

  const handleEntranceAddingCancel = (): void => {
    setFormMode(FormMode.initial);
    setEditableEntrance(undefined);
  };

  const handleDeleteEntranceClick = async () => {
    if (editableEntrance) {
      onRemoveEntrance(editableEntrance as Entrance);
      setEditableEntrance(undefined);
    }
  };

  const handleEditClick = (entrance: Entrance) => {
    setEditableEntrance(entrance);
    setFormMode(FormMode.editingEntrance);
  };

  const isFactDateFieldDisabled =
    statusValue === '2' ||
    statusValue === '3' ||
    statusValue === '4' ||
    statusValue === '';

  const entrances = building ? building.entrances : newBuildingEntrances;

  const addedEntrances = entrances && entrances.length > 0 && (
    <Col spacing={8}>
      {entrances.map((entrance: Entrance) =>
        isShowRemoveConfirmation && entrance.id === editableEntrance?.id ? (
          <RemovalConfirmationCard
            key={entrance.id}
            titleEnding={t('entrance.card.deleteEnding')}
            onRemoveClick={confirmRemoveAction}
            onCancelClick={hideRemoveConfirmation}
          />
        ) : (
          <EntranceInfoCard
            key={entrance.id}
            entrance={entrance}
            onEditClick={handleEditClick}
            onDeleteClick={() => {
              setEditableEntrance(entrance);
              showRemoveConfirmation();
            }}
            onDuplicateEntrance={onDuplicateEntrance}
            isEntranceDuplicating={isEntranceSaving}
          />
        )
      )}
    </Col>
  );

  return (
    <Col spacing={8}>
      <Row spacing={5} equalsChildrenWidth>
        <StyledTextField
          label={t('building.form.label.name')}
          required
          name="name"
          maxLength={30}
          showCharacterHintAtRemaining={10}
        />
        <SelectField
          options={statusOptions}
          label={t('building.form.label.status')}
          required
          name={'status'}
        />
      </Row>
      {!isFactDateFieldDisabled && (
        <StyledDatePicker
          label={t('building.form.label.actualCompleteDate')}
          required
          disabled={isFactDateFieldDisabled}
          name="actualCompleteDate"
        />
      )}
      <Col spacing={9}>
        <Row spacing={5} equalsChildrenWidth>
          <SelectField
            options={quartersOptions}
            label={t('building.form.label.completeQuarter')}
            required
            name={'completeQuarter'}
          />
          <SelectField
            options={yearsOptions}
            label={t('building.form.label.completeYear')}
            required
            name={'completeYear'}
          />
        </Row>

        {building?.entrances && building.entrances.length > 0 && (
          <Typography variant="headerXS">
            {t('building.form.entrances')}
          </Typography>
        )}
      </Col>

      {formMode !== FormMode.initial && editableEntrance ? (
        <EditEntranceCard
          entrance={editableEntrance}
          onEntranceSaved={handleEntranceSaved}
          onCancel={handleEntranceAddingCancel}
          isEntranceSaving={isEntranceSaving}
        />
      ) : (
        addedEntrances
      )}

      <Button
        size="small"
        disabled={formMode !== FormMode.initial}
        onClick={handleAddEntranceClick}
      >
        {t('building.form.addEntrance')}
      </Button>
    </Col>
  );
};
