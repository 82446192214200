import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SelectOption } from 'shared/types';
import { UserSchema } from '../types/userSchema';

const initialState: UserSchema = {
  userAgency: { value: '', label: '' },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserAgency: (state, action: PayloadAction<SelectOption>) => {
      state.userAgency = action.payload;
    },
  },
});

export const { actions: userActions } = userSlice;
export const { reducer: userReducer } = userSlice;
