import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Chip,
  FilledInput,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  SxProps,
  Typography,
} from '@mui/material';
import { appendAsterisk } from 'shared/helpers/appendAsterisk';
import { getLabelByValue } from 'shared/helpers/getLabelByValue';
import { PaletteColor, TextColor } from 'shared/theme/colors';
import { SelectOption } from 'shared/types';
import { CircleCrossIcon } from './Icons';
import { Row } from './Row';

interface SelectWithChipsProps extends Omit<SelectProps<string[]>, 'onChange'> {
  label: string;
  options: SelectOption[];
  required?: boolean;
  showCount?: boolean;
  sx?: SxProps;
}

export const SelectWithChips: FC<SelectWithChipsProps> = ({
  label,
  options,
  name,
  required,
  showCount = false,
  sx,
  ...props
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      name={name || ''}
      control={control}
      render={({ field: { ref, ...rest }, fieldState: { error } }) => {
        const handleDelete = (value: string) => () => {
          const newItems = rest.value.filter((item: string) => item !== value);
          rest.onChange(newItems);
        };

        return (
          <FormControl fullWidth variant="filled" error={Boolean(error)}>
            <InputLabel
              sx={{
                fontSize:
                  rest.value && rest.value.length > 0 ? '18.5px' : '16px',
              }}
            >
              <Typography
                sx={{
                  color: TextColor.Secondary,
                }}
              >
                {appendAsterisk(label, required, !!error)}
              </Typography>
            </InputLabel>
            <Select
              {...rest}
              {...props}
              fullWidth
              multiple
              input={<FilledInput />}
              sx={{
                border: error ? `1px solid ${PaletteColor.Error}` : 'none',
                ...sx,
              }}
              renderValue={(selected) =>
                showCount ? (
                  <Typography variant="accentS">
                    {t('ui.selectWithChips.selected') + ': ' + selected.length}
                  </Typography>
                ) : (
                  <Row
                    spacing={4}
                    sx={{
                      marginTop: selected.length > 0 ? '4px' : '0px',
                    }}
                  >
                    {selected.map((value: string) => (
                      <Chip
                        deleteIcon={<CircleCrossIcon />}
                        key={value}
                        onMouseDown={(event) => {
                          event.stopPropagation();
                        }}
                        label={
                          <Typography variant="accentS">
                            {t(getLabelByValue(options, value))}
                          </Typography>
                        }
                        onDelete={handleDelete(value)}
                      />
                    ))}
                  </Row>
                )
              }
              {...props}
            >
              {options.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{ justifyContent: 'space-between' }}
                >
                  <Typography>{t(option.label)}</Typography>
                  {option.sublabel && (
                    <Typography
                      sx={{
                        color: TextColor.Secondary,
                      }}
                    >
                      {t(option.sublabel)}
                    </Typography>
                  )}
                </MenuItem>
              ))}
            </Select>
            {error && error.message && (
              <FormHelperText>{t(error.message)}</FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};
