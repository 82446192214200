import { SavePlanData, Plan, PlanDto } from '../model/types/planSchema';

export const mapPlanFromDto = ({
  id,
  name,
  section_id,
  width,
  height,
  floor_ids,
  updated_at,
  created_at,
}: PlanDto): Plan => ({
  id,
  name,
  floorIds: floor_ids,
  sectionId: section_id,
  width,
  height,
  updatedAt: updated_at,
  createdAt: created_at,
});

export const mapDtoFromCreatePlanData = ({
  image,
  entranceId,
  floorIds,
  name,
}: SavePlanData): FormData => {
  const formData = new FormData();

  formData.append('name', name);
  formData.append('section_id', entranceId);
  floorIds.forEach((id) => formData.append('floor_ids[]', id.toString()));

  if (image) {
    formData.append('image', image);
  }

  return formData;
};
