import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { useLogoutMutation } from 'entities/profile';
import { TextColor } from 'shared/theme/colors';
import { Row } from 'shared/ui/Row';
import { SmallSquareButton } from 'shared/ui/SmallSquareButton';

export const LogoutButton: FC = () => {
  const { t } = useTranslation();

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] =
    useState<boolean>(false);

  const [logout] = useLogoutMutation();

  const handleCloseDialog = useCallback(() => {
    setIsConfirmDialogOpen(false);
  }, []);

  const handleConfirmLogout = useCallback(() => {
    logout();
    setIsConfirmDialogOpen(false);
  }, [logout]);

  const handleOpenDialog = useCallback(() => {
    setIsConfirmDialogOpen(true);
  }, []);

  return (
    <>
      <SmallSquareButton icon="logout" onClick={handleOpenDialog} />
      <Dialog open={isConfirmDialogOpen} onClose={handleCloseDialog}>
        <DialogContent sx={{ p: 8 }}>
          <DialogContentText
            variant="accentM"
            color={TextColor.Secondary}
            sx={{ pr: 5 }}
          >
            {t('auth.logout.confirmModalTitle')}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ px: 8, pb: 8, pt: 0, justifyContent: 'start' }}>
          <Row spacing={5}>
            <Button size="small" onClick={handleConfirmLogout} color="primary">
              {t('auth.logout.confirmButtonText')}
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={handleCloseDialog}
              color="primary"
            >
              {t('ui.common.cancel')}
            </Button>
          </Row>
        </DialogActions>
      </Dialog>
    </>
  );
};
