import { FC } from 'react';
import { Col } from 'shared/ui/Col';
import { Row } from 'shared/ui/Row';
import { RectSkeleton } from 'shared/ui/Skeleton/RectSkeleton';

export const AgencyDetailsPageSkeleton: FC = () => {
  return (
    <Col spacing={9} maxWidth={'lg'} sx={{ m: 'auto' }}>
      <RectSkeleton width={80} height={20} />
      <Row spacing={8}>
        <RectSkeleton width={60} height={60} />
        <Col spacing={4}>
          <RectSkeleton width={200} height={30} />
          <RectSkeleton width={400} height={20} />
        </Col>
      </Row>
      <RectSkeleton maxWidth={380} height={65} />
      <RectSkeleton maxWidth={648} height={500} />
    </Col>
  );
};
