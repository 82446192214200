import { SelectOption } from 'src/shared/types';

export const getLabelByValue = (
  options: SelectOption[],
  value: string | number
) => {
  const foundOption = options.find((option) => option.value == value);
  if (!foundOption) {
    console.error(
      `not found value ${value} in options ${JSON.stringify(options, null, 2)}`
    );
    return '';
  }
  return foundOption.label;
};
