import { FC, ReactNode, SyntheticEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Tab, TabProps, Tabs, Typography } from '@mui/material';
import { BackButton } from 'shared/ui/BackButton';
import { Col } from 'shared/ui/Col';
import { Row } from 'shared/ui/Row';

type DetailsPageHeaderProps = {
  title: string;
  subtitle?: string;
  logo?: ReactNode;
  backButtonText: string;
  backButtonRoute?: string | number;
  hideBackButton?: boolean;
  tabs: TabProps<typeof Link>[];
  selectedTab?: string;
  onTabChange?: (newTab: number) => void;
  beforeTitleFeatures?: ReactNode;
  afterTitleFeatures?: ReactNode;
};

export const DetailsPageHeader: FC<DetailsPageHeaderProps> = ({
  title,
  subtitle,
  logo,
  backButtonText,
  backButtonRoute = '..',
  hideBackButton,
  tabs,
  selectedTab,
  onTabChange,
  beforeTitleFeatures,
  afterTitleFeatures,
}) => {
  const navigate = useNavigate();

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    onTabChange?.(newValue);
  };

  const handleBackClick = () => {
    if (typeof backButtonRoute === 'number') {
      navigate(backButtonRoute);
    } else {
      navigate(backButtonRoute, { relative: 'path' });
    }
  };

  return (
    <Col spacing={8} maxWidth={1024} sx={{ m: 'auto' }}>
      {!hideBackButton && (
        <BackButton onBackClick={handleBackClick}>{backButtonText}</BackButton>
      )}

      {beforeTitleFeatures}
      <Row spacing={8} sx={{ alignItems: 'center' }}>
        {logo}
        <Col>
          <Typography sx={{ fontWeight: 500, fontSize: { xs: 24, sm: 36 } }}>
            {title}
          </Typography>
          {subtitle && (
            <Typography variant={'paragraphM'}>{subtitle}</Typography>
          )}
        </Col>
      </Row>

      {afterTitleFeatures}

      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons={false}
      >
        {tabs.map((tab, index) => (
          <Tab {...tab} component={Link} key={index} />
        ))}
      </Tabs>
    </Col>
  );
};
