import { Agency, AgencyDto } from '../model/types/agencySchema';

export const mapAgencyFromDto = ({
  id,
  name,
  website,
  inn,
  kpp,
  description,
  office_name,
  tariff,
  logo_id,
  vk,
  ok,
  facebok,
  instagram,
  youtube,
  address,
  phone,
  mail,
  color,
}: AgencyDto): Agency => ({
  id,
  name,
  website: website || '',
  inn: inn || '',
  kpp: kpp || '',
  description: description || '',
  tariff: tariff?.toString(),
  logo: logo_id,
  vk: vk || '',
  ok: ok || '',
  facebook: facebok || '',
  instagram: instagram || '',
  youtube: youtube || '',
  officeName: office_name,
  address,
  phone,
  mail: mail || '',
  color,
});

export const mapDtoFromAgency = ({
  id,
  name,
  website,
  inn,
  kpp,
  description,
  tariff,
  logo,
  vk,
  ok,
  facebook,
  instagram,
  youtube,
  officeName,
  address,
  phone,
  mail,
  color,
}: Agency): AgencyDto => {
  const agencyDto: AgencyDto = {
    id,
    name,
    inn,
    kpp,
    description,
    office_name: officeName,
    tariff: Number(tariff),
    logo_id: logo,
    address,
    phone,
    color,
  };

  if (website) agencyDto.website = website;
  if (mail) agencyDto.mail = mail;
  if (vk) agencyDto.vk = vk;
  if (ok) agencyDto.ok = ok;
  if (facebook) agencyDto.facebok = facebook;
  if (instagram) agencyDto.instagram = instagram;
  if (youtube) agencyDto.youtube = youtube;

  return agencyDto;
};
