import { FC } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardContent, Typography } from '@mui/material';
import { Plan } from 'entities/plan';
import { getPlanImage } from 'shared/helpers/getImageUrl';
import { getLabelByValue } from 'shared/helpers/getLabelByValue';
import { useConfirmationDialog } from 'shared/hooks/useConfirmationDialog';
import { SelectOption } from 'shared/types';
import { ButtonLink } from 'shared/ui/ButtonLink';
import { ButtonLoadingSpinner } from 'shared/ui/ButtonLoadingSpinner';
import { Col } from 'shared/ui/Col';
import { RemovalConfirmationCard } from 'shared/ui/RemovalConfirmationCard';
import { Row } from 'shared/ui/Row';

type PlanPreviewCardProps = {
  plan: Plan;
  floorsOptions: SelectOption[];
  onEditClick: (planId: number) => void;
  onOpenPlanClick: (planId: number) => void;
  onDeleteClick: (planId: number) => void;
  isPlanDeleting: boolean;
};

export const PlanPreviewCard: FC<PlanPreviewCardProps> = ({
  plan,
  floorsOptions,
  onOpenPlanClick,
  onEditClick,
  onDeleteClick,
  isPlanDeleting,
}) => {
  const { t } = useTranslation();
  const {
    isShowRemoveConfirmation,
    showRemoveConfirmation,
    hideRemoveConfirmation,
    confirmRemoveAction,
  } = useConfirmationDialog({
    onConfirm: () => onDeleteClick(plan.id),
  });

  return (
    <Card
      sx={{
        width: 333,
      }}
    >
      <CardContent
        sx={{
          '&.MuiCardContent-root': {
            padding: 6,
          },
        }}
      >
        <Col spacing={8}>
          <img
            width={301}
            height={180}
            src={getPlanImage(plan.id)}
            alt={'plan'}
          />
          <ButtonLink onClick={() => onOpenPlanClick(plan.id)}>
            {t('plan.title', {
              floors: plan.floorIds
                .map((id) => getLabelByValue(floorsOptions, id))
                .join(', '),
            })}
          </ButtonLink>
          {plan.updatedAt && (
            <Typography variant={'caption'}>
              {t('plan.dialog.updatedAt', {
                date: dayjs(plan.updatedAt).format('DD MMMM YYYYг., HH:mm'),
              })}
            </Typography>
          )}
          {isShowRemoveConfirmation ? (
            <RemovalConfirmationCard
              titleEnding={t('plan.dialog.planDeleteEnding')}
              onRemoveClick={confirmRemoveAction}
              onCancelClick={hideRemoveConfirmation}
            />
          ) : (
            <Row spacing={5}>
              <Button
                size="small"
                variant="contained"
                onClick={() => onEditClick(plan.id)}
              >
                {t('ui.common.edit')}
              </Button>

              <Button
                size="small"
                variant="outlined"
                onClick={showRemoveConfirmation}
                disabled={isPlanDeleting}
              >
                {t('ui.common.delete')}
                {isPlanDeleting && <ButtonLoadingSpinner />}
              </Button>
            </Row>
          )}
        </Col>
      </CardContent>
    </Card>
  );
};
