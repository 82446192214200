import { baseUrl, baseApiUrl } from 'shared/api/rtkApi';

const IMAGE_STORAGE_URL = baseUrl + '/photos/original/';
const MAIN_PHOTO_STORAGE_URL = baseApiUrl + '/albums/photo/original/';
const IMAGE_PLAN_STORAGE_URL = baseApiUrl + '/image/plan/';
const IMAGE_POLYGON_STORAGE_URL = baseApiUrl + '/image/polygon/';
const IMAGE_STORAGE_API_URL = baseApiUrl + '/images/';

export const getImageUrl = (path: string) => {
  return IMAGE_STORAGE_URL + path;
};

export const getMainPhotoUrl = (path: string) => {
  return MAIN_PHOTO_STORAGE_URL + path;
};
export const getApiImage = (hash: string) => {
  return IMAGE_STORAGE_API_URL + hash;
};

export const getPlanImage = (id: number | string) => {
  return IMAGE_PLAN_STORAGE_URL + id;
};

export const getPolygonImage = (id: number | string) => {
  return IMAGE_POLYGON_STORAGE_URL + id;
};
