import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  SxProps,
} from '@mui/material';
import { SelectOption } from 'shared/types';
import { StyledTextField } from './StyledTextField';

interface AutocompleteFieldProps {
  name: string;
  label: string;
  options: SelectOption[];
  isOptionsLoading?: boolean;
  onSearchChange?: (value: string) => void;
  sx?: SxProps;
}

export const AutocompleteField: FC<AutocompleteFieldProps> = ({
  label,
  name,
  options,
  isOptionsLoading,
  onSearchChange,
  sx,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value, ref, ...rest },
        fieldState: { error },
      }) => (
        <FormControl fullWidth sx={sx}>
          <Autocomplete
            {...rest}
            value={options.find((option) => option.value === value) || null}
            onChange={(event, newValue) => {
              onChange(newValue ? newValue.value : null);
            }}
            onInputChange={(event, newInputValue, reason) => {
              if (reason === 'input') onSearchChange?.(newInputValue);
            }}
            options={options}
            loading={isOptionsLoading}
            getOptionLabel={(option) => option.label || ''}
            fullWidth
            renderInput={(params) => (
              <StyledTextField {...params} label={label} />
            )}
          />
          {error && error.message && (
            <FormHelperText>{t(error.message)}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};
