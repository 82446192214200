import { TFunction } from 'i18next';

interface RangeFilterProps {
  from?: string;
  to?: string;
}

export const rangeFilterFormatter = (
  { from, to }: RangeFilterProps,
  t: TFunction
) => {
  const formatRange = () => {
    const range: string[] = [];
    if (from) {
      range.push(t('chess.filter.range.from', { from }));
    }
    if (to) {
      range.push(t('chess.filter.range.to', { to }));
    }

    return range.join(' ');
  };

  return formatRange();
};
