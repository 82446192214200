import { FC, useCallback } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Typography } from '@mui/material';
import { PasswordRestoration, passwordRestorationZod } from 'entities/profile';
import { getRoute } from 'shared/const';
import { BackButton } from 'shared/ui/BackButton';
import { CardLayout } from 'shared/ui/CardLayout';
import { Col } from 'shared/ui/Col';
import { StyledTextField } from 'shared/ui/StyledTextField';

export const RestorePassword: FC = () => {
  const { t } = useTranslation();
  const form = useForm<PasswordRestoration>({
    resolver: zodResolver(passwordRestorationZod),
    mode: 'onSubmit',
    defaultValues: {
      email: '',
    },
  });
  const navigate = useNavigate();

  const handleSendClick = useCallback(
    async (data: PasswordRestoration) => {
      if (data) {
        try {
          // TODO: запрос
          navigate(getRoute.AuthRestorePasswordDone());
        } catch (e) {
          console.error(e);
          form.setError('email', { message: e as string });
        }
      } else {
        console.error('Email is empty');
      }
    },
    [navigate, form]
  );

  return (
    <CardLayout
      maxWidth={368}
      footer={{
        okLabel: t('ui.common.send'),
        onOkClick: form.handleSubmit(handleSendClick),
      }}
    >
      <BackButton onBackClick={() => navigate(getRoute.AuthLogin())}>
        {t('ui.common.back')}
      </BackButton>
      <Col spacing={5}>
        <Typography variant={'headerXS'}>
          {t('auth.restoreForm.title')}
        </Typography>
        <Typography variant={'paragraphM'}>
          {t('auth.restoreForm.subtitle')}
        </Typography>
      </Col>
      <FormProvider {...form}>
        <StyledTextField
          label={t('ui.common.email')}
          type="email"
          name="email"
        />
      </FormProvider>
    </CardLayout>
  );
};
