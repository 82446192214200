import { ChangeEvent, FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormHelperText, TextField, TextFieldProps } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { getCharacterCountHint } from 'shared/helpers/getCharacterCountHint';
import { PaletteColor } from 'shared/theme/colors';
import { ClearTextFieldSvgIcon } from './Icons';

export type StyledTextFieldProps = Omit<TextFieldProps, 'value'> & {
  showCharacterHintAtRemaining?: number;
  disabledAdornment?: boolean;
  maxLength?: number;
  mapTextField?: boolean;
  innerRef?: (inputElement: HTMLElement) => void;
};

const getMultilineAdornmentStyle = (multiline?: boolean) =>
  multiline
    ? {
        '& .MuiButtonBase-root': {
          marginTop: '8px',
          marginRight: '-4px',
        },
        alignItems: 'flex-start',
      }
    : null;

export const StyledTextField: FC<StyledTextFieldProps> = ({
  disabledAdornment,
  variant = 'filled',
  showCharacterHintAtRemaining,
  maxLength,
  InputProps,
  inputProps,
  innerRef,
  mapTextField = false,
  onChange,
  defaultValue,
  ...props
}) => {
  const { t } = useTranslation();
  const { control, watch } = useFormContext();
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  const getHelperText = (value: string) => {
    if (props.helperText) {
      return props.helperText;
    }

    let helperText = '';
    if (maxLength) {
      if (showCharacterHintAtRemaining && value) {
        const remaining = maxLength - value.length;
        if (remaining > showCharacterHintAtRemaining) {
          return helperText;
        }
        helperText = getCharacterCountHint(
          maxLength,
          showCharacterHintAtRemaining,
          value.length,
          t
        );
      }
    }

    return helperText;
  };

  return (
    <Controller
      name={props.name || ''}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { ref, ...rest }, fieldState: { error } }) => (
        <>
          <TextField
            {...props}
            {...rest}
            onChange={(e) => {
              rest.onChange(e);
              onChange?.(e);
            }}
            ref={ref}
            fullWidth
            autoComplete="off"
            variant={variant}
            inputRef={innerRef}
            error={Boolean(error)}
            inputProps={{
              maxLength: maxLength,
              ...inputProps,
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            InputProps={{
              disableUnderline: true,
              endAdornment: !disabledAdornment && rest.value && (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => {
                      rest.onChange('');
                      // TODO: сделать красивше
                      if (onChange) {
                        const e = {
                          target: {
                            value: '',
                            focus: () => null,
                          },
                        };

                        onChange(e as unknown as ChangeEvent<HTMLInputElement>);
                      }
                    }}
                    sx={
                      mapTextField
                        ? { position: 'absolute', top: '5px', right: '10px' }
                        : {}
                    }
                  >
                    <ClearTextFieldSvgIcon
                      fontSize="inherit"
                      style={{
                        width: '20px',
                        height: '20px',
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
              sx: {
                border: error ? `1px solid ${PaletteColor.Error}` : 'none',
                '& .MuiButtonBase-root': {
                  width: '20px',
                  height: '20px',
                },
                ...getMultilineAdornmentStyle(props.multiline),
                ...InputProps?.sx,
              },
              ...InputProps,
            }}
            InputLabelProps={{
              ...(props.name
                ? { shrink: !!watch(props.name) || isFocused }
                : {}),
              sx: {
                '&.Mui-focused:not(.Mui-filled)': {
                  fontSize: '18px',
                  fontWeight: 400,
                },
                '&.Mui-filled': {
                  fontSize: '18px',
                  fontWeight: 400,
                },
              },
            }}
            sx={{
              '.MuiFormLabel-filled': {
                fontSize: '18px',
                fontWeight: 400,
              },
              '.MuiFormLabel-focused': {
                fontSize: '18px',
                fontWeight: 400,
              },
              ...props.sx,
            }}
          />
          {error ? (
            error.message && (
              <FormHelperText sx={{ color: PaletteColor.Error }}>
                {t(error.message)}
              </FormHelperText>
            )
          ) : (
            <FormHelperText>{getHelperText(rest.value)}</FormHelperText>
          )}
        </>
      )}
    />
  );
};
