import { LineChartData } from 'shared/ui/Analytics/AnalyticsLineChart';
import { PieChartData } from 'shared/ui/Analytics/AnalyticsPieChart';

export const mockAnalytics = {
  objectAnalytics: {
    objectStatistics: {
      lastUpdate: '12 мая',
      objectViewsCount: 175,
      objectViewsCountChange: 12,
      selectionsCountWithObject: 3,
      objectInFavoritesCount: 1,
      objectInFavoritesCountChange: 100,
    },
    lotPricesAndAreasStatistics: {
      lotsSoldOutPercentage: '65%',
      lotsSoldOutPercentageChange: 12,
      lotInSaleAveragePrice: '100 млн.',
      lotInSaleAveragePriceChange: 12,
      lotSoldAveragePrice: '120,2 млн.',
      lotSoldAveragePriceChange: -8,
      lotInSaleAverageArea: '123,41',
      lotInSaleAverageAreaChange: 12,
      lotSoldAverageArea: '112,1',
      lotSoldAverageAreaChange: 12,
    },
  },
  marketAnalytics: {
    averageCityPriceDynamics: {
      objectsCount: 274,
      objectsCountChange: 12,
      lotsInSaleArea: 274,
      lotsInSaleAreaChange: 12,
      lotsInSaleCount: '272 434',
      lotsInSaleCountChange: -8,
      areaAveragePrice: '123,41',
      areaAveragePriceChange: 12,
      lotInSaleAveragePrice: '12,1',
      lotInSaleAveragePriceChange: 12,
    },
  },
};

function getRandomInteger() {
  return Math.floor(Math.random() * 25) + 8;
}

export const mockLinesData: LineChartData[] = [
  { label: '07.07', line1: getRandomInteger(), line2: getRandomInteger() },
  { label: '08.07', line1: getRandomInteger(), line2: getRandomInteger() },
  { label: '09.07', line1: getRandomInteger(), line2: getRandomInteger() },
  { label: '10.07', line1: getRandomInteger(), line2: getRandomInteger() },
  { label: '11.07', line1: getRandomInteger(), line2: getRandomInteger() },
  { label: '12.07', line1: getRandomInteger(), line2: getRandomInteger() },
  { label: '13.07', line1: getRandomInteger(), line2: getRandomInteger() },
];

export const mockLineData: LineChartData[] = [
  { label: '07.07', line1: getRandomInteger() },
  { label: '08.07', line1: getRandomInteger() },
  { label: '09.07', line1: getRandomInteger() },
  { label: '10.07', line1: getRandomInteger() },
  { label: '11.07', line1: getRandomInteger() },
  { label: '12.07', line1: getRandomInteger() },
  { label: '13.07', line1: getRandomInteger() },
];

function getRandomPieInteger() {
  return Math.floor(Math.random() * 3000) + 100;
}

export const mockPieData: PieChartData[] = [
  { name: 'Group A', value: getRandomPieInteger(), color: '#fff' },
  { name: 'Group B', value: getRandomPieInteger(), color: '#fff' },
  { name: 'Group C', value: getRandomPieInteger(), color: '#fff' },
  { name: 'Group D', value: getRandomPieInteger(), color: '#fff' },
];
