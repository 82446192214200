import { PaletteColor } from 'shared/theme/colors';

export const appendAsterisk = (
  label: React.ReactNode,
  required: boolean | undefined,
  error: boolean
) => {
  const asterisk = (
    <span style={error ? { color: PaletteColor.Error } : {}}>*</span>
  );

  return required ? (
    <>
      {label} {asterisk}
    </>
  ) : (
    label
  );
};
