import { numberingMaskOption } from '../model/consts';
import { NumberingFormatType } from '../model/types/lotSchema';

export const getFormatByType = (type: NumberingFormatType) => {
  switch (type) {
    case NumberingFormatType.number:
      return { format: '1', placeholder: '1' };
    case NumberingFormatType.numberLetterNumber:
      return { format: '1A2', placeholder: '1A1' };
    case NumberingFormatType.numberNumber:
      return { format: '1-2', placeholder: '1-1' };
    case NumberingFormatType.numberSlashNumber:
      return { format: '1/2', placeholder: '1/1' };
    case NumberingFormatType.numberNumberLetter:
      return { format: '1/2A', placeholder: '1/1A' };
    case NumberingFormatType.letterNumberNumber:
      return { format: 'A1-2', placeholder: 'A1-1' };
    case NumberingFormatType.numberLetter:
      return { format: '1A', placeholder: '1A' };
    default:
      return { format: '', placeholder: '' };
  }
};

const splitFormat = (format: string) => {
  return format.split(/(?<=[1A2])|(?=[1A2])/);
};

export const generateMaskPart = (
  formatPart: string,
  currentValue: string
): (RegExp | string)[] => {
  const numberMask = /[0-9]/;
  const letterMask = /[a-zA-Zа-яА-Я]/;

  switch (formatPart) {
    case '1':
    case '2':
      return new Array(Math.min(currentValue.length || 1, 3)).fill(numberMask);
    case 'A':
      return [letterMask];
    default:
      return formatPart.split('');
  }
};

export const getMask = (value: string, format: string) => {
  const formatParts = splitFormat(format);
  const mask: (RegExp | string)[] = [];

  let currentIndex = 0;
  let currentValueSegment = '';

  for (let i = 0; i < formatParts.length; i++) {
    const formatPart = formatParts[i];

    switch (formatPart) {
      case '1':
      case '2':
        currentValueSegment = '';
        while (currentIndex < value.length && /\d/.test(value[currentIndex])) {
          currentValueSegment += value[currentIndex];
          currentIndex++;
        }
        mask.push(...generateMaskPart(formatPart, currentValueSegment));
        break;
      case 'A':
        currentValueSegment = value[currentIndex] || '';
        mask.push(...generateMaskPart(formatPart, currentValueSegment));
        currentIndex += 1;
        break;
      default: // для разделителей и прочих символов
        mask.push(formatPart);
        currentIndex += formatPart.length;
        break;
    }
  }

  return mask;
};
