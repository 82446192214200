import { baseApiUrl } from 'shared/api/rtkApi';

const FILE_STORAGE_URL = baseApiUrl + '/files/';

export const getFileUrl = (hash: string) => {
  return FILE_STORAGE_URL + hash;
};

export const getObjectFileUrl = (objectId: number, hash: string) => {
  return baseApiUrl + `/buildings/${objectId}/file/${hash}`;
};
