import { error } from 'console';
import { FC, useCallback, useMemo } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { Agency } from 'entities/agency';
import { agencyUserApi } from 'entities/user';
import { getRoute } from 'shared/const';
import { getFormattedPhone } from 'shared/helpers/getFormattedPhone';
import { useScrollPaginator } from 'shared/hooks/useScrollPaginator';
import { TextColor } from 'shared/theme/colors';
import { Col } from 'shared/ui/Col';
import { NoUsersIcon } from 'shared/ui/Icons';
import {
  PreviewCardsGrid,
  PreviewInfoCardProps,
} from 'shared/ui/PreviewCardsGrid';
import { Row } from 'shared/ui/Row';

export const AgencyUsers: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const agency = useOutletContext<Agency>();

  const {
    items: agencyUsers,
    isAllItemsLoaded,
    queryResult,
  } = useScrollPaginator(
    agencyUserApi.useGetAgencyUsersQuery,
    { agency_id: agency.id },
    { headerHeight: 400, previewCardHeight: 100, amountPerRow: 3 }
  );

  const handleCreateAgencyUserClick = () => {
    navigate(getRoute.CreateAgencyUser(agency.id));
  };

  const handleUserClick = useCallback(
    (userId: number) => () => {
      navigate(getRoute.AgencyUserDetails(agency.id, userId));
    },
    [agency.id, navigate]
  );

  const formattedDataForPreviewCards: PreviewInfoCardProps[] = useMemo(() => {
    return agencyUsers.map((user) => ({
      id: user.id,
      title: `${user.firstName} ${user.lastName}`,
      subtitle: user.position,
      text: getFormattedPhone(user.phone),
      onLinkClick: handleUserClick(user.id),
    }));
  }, [agencyUsers, handleUserClick]);

  return (
    <Col spacing={8}>
      <Row
        sx={{
          flexDirection: { xs: 'column', sm: 'row' },
        }}
      >
        <Button
          variant={'contained'}
          color={'primary'}
          size={'small'}
          onClick={handleCreateAgencyUserClick}
          sx={{ width: '100%' }}
        >
          {t('user.tab.button.addUser')}
        </Button>
      </Row>

      {isAllItemsLoaded && !agencyUsers.length ? (
        <Col
          spacing={9}
          sx={{ width: '252px', mt: '175px', mx: 'auto', alignItems: 'center' }}
        >
          <Col spacing={3} sx={{ alignItems: 'center' }}>
            <NoUsersIcon sx={{ fontSize: 40 }} />
            <Typography
              sx={{ textAlign: 'center' }}
              variant="accentS"
              color={TextColor.Secondary}
            >
              {t('user.tab.noUserTitle')}
            </Typography>
          </Col>
        </Col>
      ) : (
        <PreviewCardsGrid
          data={formattedDataForPreviewCards}
          isShowSkeleton={!isAllItemsLoaded}
          fetchingError={!!queryResult.error}
        />
      )}
    </Col>
  );
};
