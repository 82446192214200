/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  QueryDefinition,
} from '@reduxjs/toolkit/query';
import { ApiTag } from 'shared/api/rtkApi';
import { getLimitForCardRowsPagination } from 'shared/helpers/getLimitForCardRowsPagination';
import { ListData } from 'shared/types';
import { useWindowSize } from './useWindowSize';

export const useScrollPaginator = <
  R,
  Params extends Required<Pick<Params, 'limit' | 'offset'>> &
    Partial<Record<string, any>> & { search?: string },
>(
  useQuery: UseQuery<
    QueryDefinition<
      Params,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
      ApiTag,
      ListData<R>,
      'rtkApi'
    >
  >,
  args: Partial<Record<string, any>>,
  sizeOptions: {
    headerHeight: number;
    previewCardHeight: number;
    amountPerRow: number;
  }
) => {
  const [items, setItems] = useState<R[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(
    getLimitForCardRowsPagination(sizeOptions)
  );

  const windowSize = useWindowSize();

  useEffect(() => {
    setOffset(0);
    setItems(() => []);
  }, [args.search]);

  const queryResult = useQuery(
    {
      limit: offset === -1 ? items.length : limit,
      offset,
      ...args,
    } as Params,
    {
      skip: args.search
        ? args.search.length > 0 && args.search.length < 3
        : false,
    }
  );

  const refresh = () => {
    setOffset(-1);
  };

  useEffect(() => {
    const data = queryResult.data;
    if (!data) {
      setItems([]);
    } else {
      setItems((prev) => [...prev, ...data.items]);
    }
  }, [queryResult.data]);

  const isAllItemsLoaded =
    queryResult.data && items.length === queryResult.data.total;

  useEffect(() => {
    const onScroll = () => {
      const scrolledToBottom =
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - sizeOptions.previewCardHeight * 3;
      if (scrolledToBottom && !queryResult.isFetching && !isAllItemsLoaded) {
        setOffset(items.length);
      }
    };
    document.addEventListener('scroll', onScroll);

    return function () {
      document.removeEventListener('scroll', onScroll);
    };
  }, [
    items,
    isAllItemsLoaded,
    queryResult.isFetching,
    sizeOptions.previewCardHeight,
  ]);

  useEffect(() => {
    if (!isAllItemsLoaded) {
      const newLimit = getLimitForCardRowsPagination(sizeOptions);

      if (newLimit > items.length) {
        setLimit(newLimit);
        setOffset(items.length);
      }
    }
  }, [isAllItemsLoaded, items.length, sizeOptions, windowSize]);

  const reset = () => {
    setItems([]);
    setOffset(0);
    setLimit(getLimitForCardRowsPagination(sizeOptions));
  };

  return { queryResult, isAllItemsLoaded, items, reset };
};
