import {
  Album,
  AlbumDto,
  EditAlbum,
  SaveAlbumDto,
} from '../model/types/albumSchema';

export const mapAlbumFromDto = ({
  id,
  name,
  type_id,
  album_images,
  building_id,
  date_create,
  date_update,
  month,
  year,
  order_id,
}: AlbumDto): Album => ({
  id,
  name,
  type: type_id?.toString(),
  albumImages: album_images.map((image) => ({
    id: image.id,
    path: image.path,
    place: image.place,
  })),
  objectId: building_id,
  dateCreate: date_create,
  dateUpdate: date_update,
  month: month?.toString() ?? undefined,
  year: year?.toString() ?? undefined,
  order: order_id,
});

export const mapDtoFromAlbum = ({
  name,
  type,
  objectId,
  month,
  year,
  order,
}: EditAlbum): SaveAlbumDto => ({
  name,
  type_id: Number(type),
  building_id: objectId,
  month: month ? Number(month) : null,
  year: year ? Number(year) : null,
  order_id: order,
});
