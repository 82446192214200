import { ReactNode } from 'react';
import i18n from 'i18next';
import { initReactI18next, I18nextProvider } from 'react-i18next';
import enMessages from './locales/en.json';
import ruMessages from './locales/ru.json';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enMessages },
    ru: { translation: ruMessages },
  },
  lng: 'ru',
  fallbackLng: 'en',
  backend: { loadPath: 'src/app/providers/locales/{{ns}}.json' },
});

// eslint-disable-next-line react/display-name
export const I18nProvider = (component: () => ReactNode) => () => (
  <I18nextProvider i18n={i18n}>{component()}</I18nextProvider>
);
