import { SelectOption } from 'shared/types';

export const CONSTRUCTION_PROGRESS_TYPE = '7';

export const albumNameOptions: SelectOption[] = [
  { value: '2', label: 'album.selectOptions.name.views' },
  { value: '3', label: 'album.selectOptions.name.entryGroup' },
  { value: '1', label: 'album.selectOptions.name.house' },
  { value: '11', label: 'album.selectOptions.name.interiors' },
  {
    value: '5',
    label: 'album.selectOptions.name.residentialComplexInfrastructure',
  },
  { value: '6', label: 'album.selectOptions.name.districtInfrastructure' },
  { value: '9', label: 'album.selectOptions.name.layouts' },
  { value: '4', label: 'album.selectOptions.name.localArea' },
  { value: '10', label: 'album.selectOptions.name.SPA' },
  { value: '7', label: 'album.selectOptions.name.constructionProgress' },

  // добавим после mvp
  // { value: '10', label: 'Своё название' },
];

export const yearsOptions: SelectOption[] = [
  { value: '2019', label: '2019' },
  { value: '2020', label: '2020' },
  { value: '2021', label: '2021' },
  { value: '2022', label: '2022' },
  { value: '2023', label: '2023' },
  { value: '2024', label: '2024' },
];

export const monthsOptions: SelectOption[] = [
  { value: '1', label: 'album.selectOptions.month.1' },
  { value: '2', label: 'album.selectOptions.month.2' },
  { value: '3', label: 'album.selectOptions.month.3' },
  { value: '4', label: 'album.selectOptions.month.4' },
  { value: '5', label: 'album.selectOptions.month.5' },
  { value: '6', label: 'album.selectOptions.month.6' },
  { value: '7', label: 'album.selectOptions.month.7' },
  { value: '8', label: 'album.selectOptions.month.8' },
  { value: '9', label: 'album.selectOptions.month.9' },
  { value: '10', label: 'album.selectOptions.month.10' },
  { value: '11', label: 'album.selectOptions.month.11' },
  { value: '12', label: 'album.selectOptions.month.12' },
];
