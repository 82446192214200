import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { agencyPreviewCardTariffOptions } from 'entities/agency';
import { agencyApi } from 'entities/agency/model/api/AgencyService';
import { getRoute } from 'shared/const';
import { checkIsOptionExists } from 'shared/helpers/checkIsOptionExists';
import { getApiImage } from 'shared/helpers/getImageUrl';
import { getLabelByValue } from 'shared/helpers/getLabelByValue';
import { useDebounce } from 'shared/hooks/useDebounce';
import { useScrollPaginator } from 'shared/hooks/useScrollPaginator';
import { TextColor } from 'shared/theme/colors';
import { Col } from 'shared/ui/Col';
import { NoAgencyIcon } from 'shared/ui/Icons';
import {
  PreviewCardsGrid,
  PreviewInfoCardProps,
} from 'shared/ui/PreviewCardsGrid';
import { Row } from 'shared/ui/Row';
import { SearchInput } from 'shared/ui/SearchInput';

export const AgenciesTab: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const form = useForm<{
    search: string;
  }>({
    defaultValues: {
      search: '',
    },
  });

  const params = form.watch();
  const debouncedSearchText = useDebounce(params.search);

  const { items: agencies, isAllItemsLoaded } = useScrollPaginator(
    agencyApi.useGetAgenciesQuery,
    { search: debouncedSearchText },
    { headerHeight: 400, previewCardHeight: 110, amountPerRow: 3 }
  );

  const handleAddAgencyClick = () => {
    navigate(getRoute.CreateAgency());
  };

  const handleAgencyClick = useCallback(
    (agencyId: number) => () => {
      navigate(getRoute.AgencyDetails(agencyId));
    },
    [navigate]
  );

  const formattedDataForPreviewCards: PreviewInfoCardProps[] = useMemo(() => {
    return agencies.map((agency) => ({
      ...agency,
      id: agency.id,
      title: agency.name,
      subtitle: checkIsOptionExists(
        agency.tariff,
        agencyPreviewCardTariffOptions
      )
        ? t(getLabelByValue(agencyPreviewCardTariffOptions, agency.tariff))
        : '',
      text: agency.address,
      onLinkClick: handleAgencyClick(agency.id),
      logoSrc: getApiImage(agency.logo),
    }));
  }, [agencies, handleAgencyClick, t]);

  return (
    <Col spacing={8}>
      <Row
        sx={{
          justifyContent: 'space-between',
          flexDirection: { xs: 'column-reverse', sm: 'row' },
          gap: 6,
        }}
      >
        <Button
          variant={'contained'}
          color={'primary'}
          size={'small'}
          onClick={handleAddAgencyClick}
          sx={{ width: '100%' }}
        >
          {t('agency.tab.button.addAgency')}
        </Button>

        <FormProvider {...form}>
          <SearchInput
            name={'search'}
            searchPlaceholder={t('agency.tab.input.search')}
            sx={{ width: { xs: '100%', sm: 280 } }}
          />
        </FormProvider>
      </Row>
      {isAllItemsLoaded && !agencies.length ? (
        <Col
          spacing={9}
          sx={{ width: '252px', mt: '175px', mx: 'auto', alignItems: 'center' }}
        >
          <Col spacing={3} sx={{ alignItems: 'center' }}>
            <NoAgencyIcon sx={{ fontSize: 40 }} />
            <Typography
              sx={{ textAlign: 'center' }}
              variant="accentS"
              color={TextColor.Secondary}
            >
              {t('agency.tab.noAgencyTitle')}
            </Typography>
          </Col>
        </Col>
      ) : (
        <PreviewCardsGrid
          data={formattedDataForPreviewCards}
          isShowSkeleton={!isAllItemsLoaded}
        />
      )}
    </Col>
  );
};
