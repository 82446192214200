import { ReactNode } from 'react';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import updateLocale from 'dayjs/plugin/updateLocale';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ru';

dayjs.extend(updateLocale);
dayjs.extend(quarterOfYear);

dayjs.updateLocale('ru', {
  months: [
    'Января',
    'Февраля',
    'Марта',
    'Апреля',
    'Мая',
    'Июня',
    'Июля',
    'Августа',
    'Сентября',
    'Октября',
    'Ноября',
    'Декабря',
  ],
});

dayjs.locale('ru');

// eslint-disable-next-line react/display-name
export const DayjsProvider = (component: () => ReactNode) => () => (
  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ru'}>
    {component()}
  </LocalizationProvider>
);
