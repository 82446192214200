import { FC, useState } from 'react';
import i18n from 'i18next';
import linkifyHtml from 'linkify-html';
import { Box, SxProps, Typography } from '@mui/material';
import { TextColor } from 'shared/theme/colors';
import { SelectOption } from 'shared/types';
import { ButtonLink } from './ButtonLink';
import { Col } from './Col';
import { PhoneField } from './PhoneField';
import { SelectField } from './SelectField';
import { StyledTextField } from './StyledTextField';

type TextViewFieldProps = {
  title?: string;
  text?: string;
  name?: string;
  variant?: 'standard' | 'filled';
  isPhone?: boolean;
  labelByOptions?: SelectOption[];
  sx?: SxProps;
};

export const TextViewField: FC<TextViewFieldProps> = ({
  title,
  text,
  name,
  variant = 'standard',
  isPhone,
  labelByOptions,
  sx,
}) => {
  const [showFullText, setShowFullText] = useState(false);

  const toggleFullText = () => {
    setShowFullText(!showFullText);
  };

  const displayedText =
    showFullText || !text || text.length <= 200 ? text : text.substring(0, 200);

  if (variant === 'filled') {
    if (isPhone) {
      return (
        <Box sx={{ ...sx }}>
          <PhoneField name={name || ''} label={title} readOnly />
        </Box>
      );
    }

    if (labelByOptions) {
      return (
        <SelectField
          name={name}
          label={title || ''}
          options={labelByOptions}
          readOnly
        />
      );
    }

    return (
      <StyledTextField
        name={name}
        label={title}
        InputProps={{ readOnly: true }}
        disabledAdornment
        sx={{ ...sx }}
      />
    );
  }

  return (
    <Col spacing={4}>
      <Typography
        variant="paragraphS"
        color={TextColor.Secondary}
        sx={{ flexGrow: 1 }}
      >
        {title}
      </Typography>
      <>
        <Typography
          variant="paragraphM"
          color={TextColor.Primary}
          sx={{
            flexGrow: 1,
            overflowWrap: 'break-word',
            whiteSpace: 'pre-line',
          }}
          dangerouslySetInnerHTML={{ __html: linkifyHtml(`${displayedText}`) }}
        />
        {text && text.length > 200 && (
          <ButtonLink underlineType="dotted" onClick={toggleFullText}>
            {i18n.t(
              showFullText ? 'ui.textViewField.hide' : 'ui.textViewField.show'
            )}
          </ButtonLink>
        )}
      </>
    </Col>
  );
};
