import { SpacingOptions } from '@mui/system';

/**
 *
 *  [0]  =   0 px;
 *  [1]  =   1 px;
 *  [2]  =   2 px;
 *  [3]  =   4 px;
 *  [4]  =   8 px;
 *  [5]  =  12 px;
 *  [6]  =  16 px;
 *  [7]  =  20 px;
 *  [8]  =  24 px;
 *  [9]  =  32 px;
 * [10]  =  40 px;
 * [11]  =  48 px;
 * [12]  =  64 px;
 * [13]  =  72 px;
 * [14]  =  96 px;
 * [15]  = 128 px;
 *
 */
export const spacing: SpacingOptions = [
  0, 1, 2, 4, 8, 12, 16, 20, 24, 32, 40, 48, 64, 72, 96, 128,
];
