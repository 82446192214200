import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormHelperText, MenuItem, TextField } from '@mui/material';
import { appendAsterisk } from 'shared/helpers/appendAsterisk';
import { workingDaysOptions } from 'shared/selectOptions/workingDaysOptions';
import { PaletteColor } from 'shared/theme/colors';
import { RangeTimeField } from './RangeTimeField';
import { Row } from './Row';

interface WorkScheduleInputProps {
  daysFieldName: string;
  hoursFieldName: string;
  daysIsRequired?: boolean;
  hoursIsRequired?: boolean;
}

export type WorkScheduleRow = {
  workingDays: string[];
  workingHours: string;
};
export const initialWorkSchedule: WorkScheduleRow = {
  workingDays: [],
  workingHours: '',
};

export const WorkScheduleInput = ({
  daysFieldName,
  hoursFieldName,
  daysIsRequired = false,
  hoursIsRequired = false,
}: WorkScheduleInputProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Row spacing={5} equalsChildrenWidth>
      <Controller
        name={daysFieldName || ''}
        control={control}
        render={({ field: { ref, ...rest }, fieldState: { error } }) => (
          <>
            <TextField
              {...rest}
              select
              variant={'filled'}
              label={appendAsterisk(
                t('ui.workScheduleInput.days'),
                daysIsRequired,
                !!error
              )}
              error={Boolean(error)}
              InputProps={{
                sx: {
                  border: error ? `1px solid ${PaletteColor.Error}` : 'none',
                },
              }}
              SelectProps={{
                multiple: true,
                onChange: (event) => {
                  rest.onChange((event.target.value as string[]).join(','));
                },
                renderValue: (selectedValues) => {
                  const sortedWorkingDays = (selectedValues as string[]).sort(
                    (a, b) => {
                      return parseInt(a, 10) - parseInt(b, 10);
                    }
                  );
                  return sortedWorkingDays
                    .map((itemValue) =>
                      t(
                        workingDaysOptions.find(
                          (item) => item.value === itemValue
                        )?.label ?? ''
                      )
                    )
                    .join(', ');
                },
              }}
              value={rest.value ? rest.value.split(',') : []}
              fullWidth
            >
              {workingDaysOptions.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  <Checkbox
                    checked={Boolean(
                      rest?.value?.split(',').includes(item.value)
                    )}
                  />
                  {t(item.label)}
                </MenuItem>
              ))}
            </TextField>
            {error && error?.message && (
              <FormHelperText sx={{ color: PaletteColor.Error }}>
                {t(error.message)}
              </FormHelperText>
            )}
          </>
        )}
      />
      <Controller
        name={hoursFieldName || ''}
        control={control}
        render={({ field: { ref, ...rest }, fieldState: { error } }) => (
          <>
            <RangeTimeField
              {...rest}
              value={rest.value || ''}
              onTimeChange={rest.onChange}
              label={appendAsterisk(
                t('ui.workScheduleInput.hours'),
                hoursIsRequired,
                !!error
              )}
              variant="filled"
              fullWidth
              format="##:## - ##:##"
              error={Boolean(error)}
            />
            {error && error?.message && (
              <FormHelperText sx={{ color: PaletteColor.Error }}>
                {t(error.message)}
              </FormHelperText>
            )}
          </>
        )}
      />
    </Row>
  );
};
