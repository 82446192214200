import { z } from 'zod';

export type BuildingDto = {
  id: number;
  building_id: number;
  name: string;
  construction_ending: number | null;
  year_built: number | null;
  plan_date_built: string;
  order_id: number;
  building_status_id: number | null;
  sections: {
    id: number;
    pavilion_id: number;
    name: string;
    floor_amount: number | null;
    riser_amount: number | null;
    count_chess: number;
    min_area_all: number;
    min_price_all: number;
    is_chess_present: boolean;
  }[];
};

export type UpdateBuildingDto = Omit<BuildingDto, 'sections'>;

export type CreateBuildingDto = Omit<UpdateBuildingDto, 'id'> & {
  sections: {
    name: string;
    floor_amount: number | null;
    riser_amount: number | null;
  }[];
};

const buildingEntranceZod = z.object({
  id: z.number(),
  name: z.string(),
  floorCount: z.string(),
  riserCount: z.string(),
  buildingId: z.number(),
  minAreaAll: z.number(),
  minPriceAll: z.number(),
  lotsCount: z.number(),
  isChessPresent: z.boolean(),
});

const createBuildingEntranceZod = z.object({
  name: z.string(),
  floorCount: z.string(),
  riserCount: z.string(),
});

export const buildingZod = z.object({
  id: z.number(),
  objectId: z.number(),
  order: z.number(),
  name: z.string().min(1, 'building.form.error.name').max(30),
  status: z.string().min(1, 'building.form.error.status'),
  actualCompleteDate: z.string().nullish().optional(),
  completeQuarter: z.string().min(1, 'ui.common.errorRequiredField'),
  completeYear: z.string().min(1, 'ui.common.errorRequiredField'),
  entrances: z.array(buildingEntranceZod).optional(),
});

export const createBuildingZod = z.object({
  objectId: z.number(),
  order: z.number(),
  name: z.string().min(1, 'building.form.error.name').max(30),
  status: z.string().min(1, 'building.form.error.status'),
  actualCompleteDate: z.string().nullish().optional(),
  completeQuarter: z.string().min(1, 'ui.common.errorRequiredField'),
  completeYear: z.string().min(1, 'ui.common.errorRequiredField'),
  entrances: z.array(createBuildingEntranceZod).optional(),
});

export type Building = z.infer<typeof buildingZod>;
export type CreateBuilding = z.infer<typeof createBuildingZod>;

export type BuildingsPositionsDto = {
  pavilion_ids: number[];
};

export type BuildingsPositions = {
  buildingsIds: number[];
};
