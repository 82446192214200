import { FC } from 'react';
import { useOutlet } from 'react-router-dom';
import { Container } from '@mui/material';
import { useAppSelector } from 'shared/hooks/hooks';
import { ErrorBoundary } from 'shared/ui/ErrorBoundary';
import { Header } from 'widgets/Header';
import { SearchPage } from './SearchPage';

export const PageLayout: FC = () => {
  const searchText = useAppSelector((state) => state.search.searchText);
  const outlet = useOutlet();

  return (
    <Container maxWidth={false} sx={{ width: '95%' }}>
      <Header />
      <ErrorBoundary showReloadButton>
        {searchText ? <SearchPage /> : outlet}
      </ErrorBoundary>
    </Container>
  );
};
