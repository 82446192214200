import {
  Developer,
  DeveloperDto,
  DocumentTypeId,
} from '../model/types/developerSchema';

export const mapDeveloperFromDto = ({
  id,
  name,
  email,
  phone,
  corp_email,
  website,
  inn,
  description,
  logo_hash,
  lat,
  lng,
  address,
  developer_files,
  developer_schedules,
  first_name,
  last_name,
  phone_user,
  is_hidden,
}: DeveloperDto): Developer => ({
  id,
  name,
  corpEmail: corp_email ?? '',
  phone: phone?.toString() || '',
  website: website ?? '',
  inn: inn ?? '',
  description,
  logo: logo_hash ?? '',
  address: address ?? '',
  latitude: lat,
  longitude: lng,
  isHidden: Boolean(is_hidden),
  schedules:
    developer_schedules.length > 0
      ? developer_schedules.map((schedule) => ({
          workingDays: schedule.schedule ?? '',
          workingHours: `${schedule.time_from?.substring(
            0,
            5
          )} - ${schedule.time_to?.substring(0, 5)}`,
        }))
      : [],
  ownerEmail: email,
  //TODO: Поправить когда добавят на бэк
  ownerName: first_name,
  ownerLastName: last_name,
  ownerPhone: phone_user?.toString() ?? '',
  documentDirectorHash: getDocumentByType(
    developer_files,
    DocumentTypeId.director
  ),
  documentGenDirectorHash: getDocumentByType(
    developer_files,
    DocumentTypeId.genDirector
  ),
  documentNalogHash: getDocumentByType(developer_files, DocumentTypeId.nalog),
  documentPermissionsApproveHash: getDocumentByType(
    developer_files,
    DocumentTypeId.permissionsApprove
  ),
  documentUstavHash: getDocumentByType(developer_files, DocumentTypeId.ustav),
});

const getDocumentByType = (
  developerFiles: {
    name: string;
    hash: string;
    type_id: number;
  }[],
  type: DocumentTypeId
) => {
  const document = developerFiles.find((file) => file.type_id === type);
  if (document) {
    return document.hash;
  }
  return undefined;
};

export const mapDtoFromDeveloper = ({
  id,
  name,
  corpEmail,
  phone,
  website = '',
  inn,
  description = '',
  logo,
  address,
  latitude,
  longitude,
  schedules,
  ownerEmail,
  ownerName,
  ownerPhone,
  ownerLastName,
  documentDirectorHash,
  documentGenDirectorHash,
  documentNalogHash,
  documentPermissionsApproveHash,
  documentUstavHash,
  isHidden,
}: Developer): DeveloperDto => ({
  id,
  name,
  email: ownerEmail || '',
  phone: phone ? Number(phone) : null,
  corp_email: corpEmail || '',
  website,
  first_name: ownerName,
  last_name: ownerLastName,
  phone_user: Number(ownerPhone),
  inn: inn || '',
  is_hidden: isHidden ? 1 : 0,
  description,
  logo_hash: logo || '',
  lat: latitude,
  lng: longitude,
  address: address || '',
  developer_files: mapDtoFromDocument(
    documentDirectorHash,
    documentGenDirectorHash,
    documentNalogHash,
    documentPermissionsApproveHash,
    documentUstavHash
  ),
  developer_schedules: schedules?.map((schedule) => ({
    schedule: schedule.workingDays ?? '',
    time_from: schedule.workingHours
      ? schedule.workingHours.split(' - ')[0]
      : '',
    time_to: schedule.workingHours ? schedule.workingHours.split(' - ')[1] : '',
  })),
});

const mapDtoFromDocument = (
  documentDirectorHash?: string,
  documentGenDirectorHash?: string,
  documentNalogHash?: string,
  documentPermissionsApproveHash?: string,
  documentUstavHash?: string
) => {
  const documents: {
    name: string;
    hash: string;
    type_id: number;
  }[] = [];
  if (documentDirectorHash) {
    documents.push({
      hash: documentDirectorHash || '',
      name: 'documentDirector',
      type_id: DocumentTypeId.director,
    });
  }
  if (documentGenDirectorHash) {
    documents.push({
      hash: documentGenDirectorHash || '',
      name: 'documentGenDirector',
      type_id: DocumentTypeId.genDirector,
    });
  }
  if (documentNalogHash) {
    documents.push({
      hash: documentNalogHash || '',
      name: 'documentNalog',
      type_id: DocumentTypeId.nalog,
    });
  }
  if (documentPermissionsApproveHash) {
    documents.push({
      hash: documentPermissionsApproveHash || '',
      name: 'documentPermissionsApprove',
      type_id: DocumentTypeId.permissionsApprove,
    });
  }
  if (documentUstavHash) {
    documents.push({
      hash: documentUstavHash || '',
      name: 'documentUstav',
      type_id: DocumentTypeId.ustav,
    });
  }
  return documents;
};
