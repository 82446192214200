import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledTextField } from 'shared/ui/StyledTextField';

export const NewsPostEditForm: FC = () => {
  const { t } = useTranslation();

  return (
    <StyledTextField
      label={t('news.form.label.newsText')}
      required
      name="text"
      multiline
      maxLength={1000}
      showCharacterHintAtRemaining={30}
    />
  );
};
