import { TFunction } from 'i18next';

export const getCharacterCountHint = (
  maxChars: number,
  threshold: number,
  currentChars: number,
  t: TFunction
) => {
  const remainingChars = maxChars - currentChars;
  if (remainingChars > 0) {
    const remainingMessage = t('ui.characterCountHint.symbolLeft', {
      count: remainingChars,
    });

    if (remainingChars <= threshold) {
      return remainingMessage;
    } else {
      return ' ';
    }
  } else {
    return t('ui.characterCountHint.limitReached');
  }
};
