import { z } from 'zod';
import { emailValidator } from 'shared/helpers/emailValidator';
import { phoneValidator } from 'shared/helpers/phoneValidator';
import { SelectOption } from 'shared/types';

export type UserDto = {
  id?: number;
  first_name: string;
  last_name: string;
  email: string;
  position: string;
  phone: string;
  role_id: number;
  agency_id?: number | null;
  vk?: string | null;
  ok?: string | null;
  facebook?: string | null;
  youtube?: string | null;
  instagram?: string | null;
  tariff_id?: number | null;
  count_day?: number;
  facebok?: string | null;
};

export const userZod = z.object({
  id: z.number(),
  firstName: z.string().min(1, 'ui.common.error.firstName'),
  lastName: z.string().min(1, 'ui.common.error.lastName'),
  position: z.string().min(1, 'user.form.error.position'),
  email: emailValidator.required,
  agencyId: z.number().nullable().optional(),
  isAdmin: z.boolean(),
  tariff: z.number().nullable().optional(),
  countDay: z.string().optional(),
  phone: phoneValidator.required,
  vk: z.string().optional(),
  facebook: z.string().optional(),
  instagram: z.string().optional(),
  youtube: z.string().optional(),
  ok: z.string().optional(),
});

export const createUserZod = userZod.omit({
  id: true,
});

export type CreateUser = z.infer<typeof createUserZod>;
export type User = z.infer<typeof userZod>;
export type NewUser = Omit<User, 'id'>;

export interface UserSchema {
  userAgency?: SelectOption;
}
