import { $rtkApi } from 'shared/api/rtkApi';
import { ResponseData, SelectOption } from 'shared/types';
import { mapAlbumTypeFromDto } from '../../lib/mapAlbumTypesDto';
import { AlbumTypeDto } from '../types/albumSchema';

export const albumTypeApi = $rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getAlbumTypes: builder.query<SelectOption[], void>({
      query: () => ({
        url: `/album-types`,
        method: 'GET',
      }),
      transformResponse: (response: ResponseData<AlbumTypeDto[]>) =>
        response.data.map((data) => mapAlbumTypeFromDto(data)),
    }),
  }),
});

export const { useGetAlbumTypesQuery } = albumTypeApi;
