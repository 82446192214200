import { FC, PropsWithChildren } from 'react';
import { Button } from '@mui/material';
import { AccentColor } from 'shared/theme/colors';
import { ChevronDownIcon } from './Icons';

interface ExpandButtonProps {
  onClick: () => void;
  expanded: boolean;
  hideArrow?: boolean;
}

export const ExpandButton: FC<PropsWithChildren<ExpandButtonProps>> = ({
  children,
  onClick,
  expanded,
  hideArrow,
}) => {
  return (
    <Button
      variant="text"
      disableRipple
      sx={{ p: 0, minHeight: 0, minWidth: 0, color: AccentColor.Process }}
      onClick={onClick}
    >
      {children}
      {!hideArrow && (
        <ChevronDownIcon
          sx={{
            transform: expanded ? 'rotate(180deg)' : '',
            ml: '3px',
            fontSize: 20,
          }}
        />
      )}
    </Button>
  );
};
