import { createSlice } from '@reduxjs/toolkit';
import { LOCAL_STORAGE_AUTH_KEY } from 'shared/const';
import { authApi } from '../api/AuthService';
import { ProfileSchema } from '../types/profileSchema';

const initialState: ProfileSchema = {
  profile: undefined,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    logout: () => {
      localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, payload.token);
        state.profile = payload;
      }
    );
    builder.addMatcher(
      authApi.endpoints.profile.matchFulfilled,
      (state, { payload }) => {
        state.profile = payload;
      }
    );
    builder.addMatcher(
      authApi.endpoints.logout.matchFulfilled,
      (state, { payload }) => {
        state.profile = undefined;
        localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
      }
    );
  },
});

export const { actions: profileActions } = profileSlice;
export const { reducer: profileReducer } = profileSlice;
