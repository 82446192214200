import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { BgColor, TextColor } from 'shared/theme/colors';
import { fontInter } from 'shared/theme/typography';
import { SelectOption } from 'shared/types';
import { Col } from './Col';

interface SelectChipFieldProps {
  chipOptions: SelectOption[];
  label?: string;
  name: string;
  readonly?: boolean;
}

export const SelectChipField: FC<SelectChipFieldProps> = ({
  chipOptions,
  label,
  name,
  readonly,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name || ''}
      control={control}
      render={({ field: { ref, ...rest }, fieldState: { error } }) => (
        <Col spacing={5}>
          {label && <Typography variant="paragraphM">{label}</Typography>}
          {readonly ? (
            <Chip
              label={rest.value}
              variant={'filled'}
              sx={{
                fontFamily: fontInter,
                fontSize: '14px',
                fontWeight: 600,
                borderRadius: 10,
                m: 0,
                bgcolor: BgColor.Input,
                color: TextColor.Primary,
                '& .MuiChip-label': {
                  px: 6,
                  py: 4,
                },
              }}
            />
          ) : (
            <Stack direction="row" gap={4} flexWrap={'wrap'}>
              {chipOptions.map((option) => (
                <Chip
                  key={option.value}
                  label={option.label}
                  onClick={() => rest.onChange(option.value)}
                  variant={'filled'}
                  sx={{
                    fontFamily: fontInter,
                    fontSize: '14px',
                    fontWeight: 600,
                    borderRadius: 10,
                    m: 0,
                    bgcolor:
                      rest.value === option.value
                        ? TextColor.Primary
                        : BgColor.Input,
                    color:
                      rest.value === option.value
                        ? TextColor.StaticWhite
                        : TextColor.Primary,
                    '&:hover': {
                      bgcolor:
                        rest.value === option.value
                          ? TextColor.Primary
                          : BgColor.Input,
                    },
                    '& .MuiChip-label': {
                      px: 6,
                      py: 4,
                    },
                  }}
                />
              ))}
            </Stack>
          )}
        </Col>
      )}
    />
  );
};
