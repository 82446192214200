import { z } from 'zod';

function isTimeRange(timeRange: string) {
  const timeRangeRegex =
    /^([01]?[0-9]|2[0-3]):[0-5][0-9] - ([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
  return timeRangeRegex.test(timeRange);
}

const optionalTimeRangeValidator = z
  .string()
  .optional()
  .transform((val) => val || null)
  .nullable()
  .refine((value) => value === null || isTimeRange(value), {
    message: 'ui.common.error.incorrectTimeRange',
  });

const requiredTimeRangeValidator = z
  .string({ required_error: 'ui.common.error.enterTimeRange' })
  .min(1, 'ui.common.error.enterTimeRange')
  .regex(
    /^([01]?[0-9]|2[0-3]):[0-5][0-9] - ([01]?[0-9]|2[0-3]):[0-5][0-9]$/,
    'ui.common.error.incorrectTimeRange'
  );

export const timeRangeValidator = {
  optional: optionalTimeRangeValidator,
  required: requiredTimeRangeValidator,
};
