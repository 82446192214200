import { FC } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Col } from 'shared/ui/Col';
import { Row } from 'shared/ui/Row';
import { Developer } from '../../model/types/developerSchema';
import { AdditionalDataCard } from './infoCards/AdditionalDataCard';
import { DocumentsCard } from './infoCards/DocumentsCard';
import { MainDataCard } from './infoCards/MainDataCard';

export const DeveloperDetails: FC = () => {
  const developer = useOutletContext<Developer>();

  return (
    <Col spacing={8}>
      <Row wrap={false} spacing={9}>
        <Col width={648} spacing={8}>
          <MainDataCard developer={developer} />
          <AdditionalDataCard developer={developer} />
        </Col>
        <Col spacing={8}>
          <DocumentsCard developer={developer} />
          {/* <LoginDataCard developer={developer} /> */}
        </Col>
      </Row>
    </Col>
  );
};
