import { TFunction } from 'i18next';
import { Lot } from 'entities/chess';
import { Entrance } from 'entities/entrance';
import { GridSelection } from './getSelectedLots';
import { getShowMergeButtonInDialog } from './getShowMergeButtonInDialog';
import { getShowSplitButtonInDialog } from './getShowSplitButtonInDialog';

export const getLotsDialogOptions = (
  gridSelection: GridSelection,
  lots: Lot[],
  t: TFunction,
  entrance?: Entrance
) => {
  const { selectedLots } = gridSelection;
  const lotsCount = selectedLots.length;

  if (!entrance || !selectedLots || !lotsCount) {
    return {
      title: '',
      subtitle: '',
      showDeleteRiserButton: false,
      showDeleteFloorButton: false,
      showSplitButtonInDialog: false,
      showMergeButtonInDialog: false,
    };
  }

  const showSplitButtonInDialog = getShowSplitButtonInDialog(
    lots,
    selectedLots
  );

  const showMergeButtonInDialog = getShowMergeButtonInDialog(
    lots,
    selectedLots
  );
  const isAllLotsWithNumbering = selectedLots.every(({ lotNumber }) =>
    Boolean(lotNumber)
  );

  const title =
    lotsCount > 3 || !isAllLotsWithNumbering
      ? t('chess.dialog.title1', {
          length: selectedLots.length,
        })
      : t('chess.dialog.title2', {
          lotNumber: selectedLots.map(({ lotNumber }) => lotNumber).join(', '),
        });

  const getSubtitle = () => {
    const positions = selectedLots.flatMap(({ positions }) => positions);
    const floors = Array.from(
      new Set(positions.map(({ floor }) => floor))
    ).join(', ');
    const risers = Array.from(
      new Set(positions.map(({ riser }) => riser))
    ).join(', ');

    return t('chess.dialog.subtitle', {
      entrance: entrance.name,
      floors,
      risers,
    });
  };

  const subtitle = lotsCount > 3 ? '' : getSubtitle();

  const showDeleteRiserButton =
    gridSelection.selectionMode === 'risers' &&
    gridSelection.selection.length === 1 &&
    Number(entrance.riserCount) > 1;

  const showDeleteFloorButton =
    gridSelection.selectionMode === 'floors' &&
    gridSelection.selection.length === 1 &&
    Number(entrance.floorCount) > 1;

  return {
    title,
    subtitle,
    showDeleteRiserButton,
    showDeleteFloorButton,
    showMergeButtonInDialog,
    showSplitButtonInDialog,
  };
};
