import { FC, useState, KeyboardEvent, useCallback } from 'react';
import { Box } from '@mui/material';
import { useSingleAndDoubleClick } from 'shared/hooks/useSingleAndDoubleClick';
import { BgColor, TextColor } from 'src/shared/theme/colors';

type HeaderCellProps = {
  value?: string;
  onClick: () => void;
  onRename: (name: string) => void;
  onMouseMove: (e: React.MouseEvent) => void;
  onMouseLeave: () => void;
  selected?: boolean;
};

export const HeaderCell: FC<HeaderCellProps> = ({
  value,
  onClick,
  onRename,
  onMouseMove,
  onMouseLeave,
  selected,
}) => {
  const [name, setName] = useState(value ?? '');
  const [isRenaming, setIsRenaming] = useState(false);

  const handleSimpleClick = useCallback(() => {
    if (!isRenaming) {
      onClick();
    } else {
      if (name !== value) {
        onRename(name);
      }
      setIsRenaming(false);
    }
  }, [isRenaming, name, onClick, onRename, value]);

  const handleDoubleClick = useCallback(() => {
    if (!isRenaming) {
      setIsRenaming(true);
    } else {
      if (name !== value) {
        onRename(name);
      }
      setIsRenaming(false);
    }
  }, [isRenaming, name, onRename, value]);

  const handleClick = useSingleAndDoubleClick(
    handleSimpleClick,
    handleDoubleClick
  );

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (name !== value) {
        onRename(name);
      }
      setIsRenaming(false);
    }
  };

  const content = isRenaming ? (
    <input
      autoFocus
      maxLength={10}
      value={name}
      onChange={(e) => {
        setName(e.target.value);
      }}
      onClick={(e) => e.stopPropagation()}
      onKeyDown={handleKeyDown}
    />
  ) : (
    value
  );

  return (
    <Box
      onClick={handleClick}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      sx={{
        textAlign: 'center',
        bgcolor: selected ? BgColor.Input : TextColor.StaticWhite,
        boxShadow: `0 0 0 1px #DBDBDB`,
        p: '8px',
        position: 'sticky',
        zIndex: 1,
        top: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {content}
    </Box>
  );
};
