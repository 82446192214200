import { Box } from '@mui/material';
import { TextColor } from 'shared/theme/colors';
import { Col } from 'shared/ui/Col';
import { Row } from 'shared/ui/Row';
import { RectSkeleton } from 'shared/ui/Skeleton/RectSkeleton';

export const SalesOfficeEmployeeRowSkeleton = () => {
  return (
    <Col spacing={5} sx={{ px: 9 }}>
      <Row spacing={5}>
        <RectSkeleton height={56} width={260} />
        <Box
          sx={{
            width: 8,
            height: 28,
            borderBottom: `2px solid ${TextColor.Tertiary}`,
          }}
        />
        <RectSkeleton height={56} sx={{ width: 180 }} />
      </Row>
      <RectSkeleton height={56} sx={{ width: 472 }} />
    </Col>
  );
};
