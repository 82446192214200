import { z } from 'zod';

function isEmail(email: string) {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i;
  return emailRegex.test(email);
}

const optionalEmailValidator = z
  .string()
  .optional()
  .transform((val) => val || null)
  .nullable()
  .refine((value) => value === null || isEmail(value), {
    message: 'ui.common.error.incorrectEmail',
  });

const requiredEmailValidator = z
  .string({ required_error: 'ui.common.error.enterEmail' })
  .min(1, 'ui.common.error.enterEmail')
  .regex(
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i,
    'ui.common.error.incorrectEmail'
  );

export const emailValidator = {
  optional: optionalEmailValidator,
  required: requiredEmailValidator,
};
