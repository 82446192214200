import { $rtkApi } from 'shared/api/rtkApi';
import { DocumentData, ImageData, ResponseData } from 'shared/types';

const fileApi = $rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadImage: builder.mutation<ImageData, FormData>({
      query: (file) => ({
        url: '/images/upload',
        method: 'POST',
        body: file,
      }),
      transformResponse: (response: ResponseData<ImageData>) => response.data,
    }),
    getImage: builder.query<ImageData, string>({
      query: (hash) => ({
        url: `/images/${hash}`,
        method: 'GET',
      }),
    }),
    deleteImage: builder.mutation<void, string>({
      query: (hash) => ({
        url: `/images/${hash}`,
        method: 'DELETE',
      }),
    }),

    uploadDocument: builder.mutation<DocumentData, FormData>({
      query: (file) => ({
        url: '/files/upload',
        method: 'POST',
        body: file,
      }),
      transformResponse: (response: ResponseData<DocumentData>) =>
        response.data,
    }),
    getDocument: builder.query<DocumentData, string>({
      query: (hash) => ({
        url: `/files/${hash}`,
        method: 'GET',
      }),
    }),
    deleteDocument: builder.mutation<void, string>({
      query: (hash) => ({
        url: `/files/${hash}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useDeleteDocumentMutation,
  useGetDocumentQuery,
  useUploadDocumentMutation,
  useUploadImageMutation,
  useGetImageQuery,
  useDeleteImageMutation,
} = fileApi;
