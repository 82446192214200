import { FC, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Typography } from '@mui/material';
import { objectTypeOptions, useGetObjectsQuery } from 'entities/object';
import { searchActions } from 'entities/search';
import { getRoute } from 'shared/const';
import { getMainPhotoUrl } from 'shared/helpers/getImageUrl';
import { getLabelByValue } from 'shared/helpers/getLabelByValue';
import { useAppDispatch, useAppSelector } from 'shared/hooks/hooks';
import { useSnackbar } from 'shared/hooks/useSnackbar';
import { TextColor } from 'shared/theme/colors';
import { Col } from 'shared/ui/Col';
import { NoSearchResultsIcon } from 'shared/ui/Icons';
import {
  PreviewCardsGrid,
  PreviewInfoCardProps,
} from 'shared/ui/PreviewCardsGrid';

export const SearchPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const searchText = useAppSelector((state) => state.search.searchText);
  const dispatch = useAppDispatch();
  const { showSnackbar } = useSnackbar();

  const { data, isFetching, error } = useGetObjectsQuery(
    {
      search: searchText,
      limit: 100,
      offset: 0,
    },
    { skip: searchText.length >= 0 && searchText.length < 3 }
  );

  const handleObjectClick = useCallback(
    (objectId: number) => {
      dispatch(searchActions.updateSearchText(''));
      navigate(getRoute.ObjectAbout(objectId));
    },
    [dispatch, navigate]
  );

  useEffect(() => {
    if (error) {
      console.error(error);
      showSnackbar({ message: t('ui.common.error.fetchDataError') });
    }
  }, [error, t, showSnackbar]);

  const formattedDataForPreviewCards: PreviewInfoCardProps[] = useMemo(() => {
    return (
      data?.items.map((object) => ({
        ...object,
        id: object.id,
        logoSrc: object.mainPhotoHash
          ? getMainPhotoUrl(object.mainPhotoHash)
          : '',
        title: object.name,
        text: object.address,
        subtitle: t(getLabelByValue(objectTypeOptions, object.objectType)),
        onLinkClick: () => handleObjectClick(object.id),
      })) || []
    );
  }, [data?.items, handleObjectClick, t]);

  return (
    <Container>
      <Col spacing={8}>
        <Typography variant="headerL">
          {t(isFetching ? 'ui.common.searching' : 'searchPage.title')}
        </Typography>

        <PreviewCardsGrid
          data={formattedDataForPreviewCards}
          isShowSkeleton={isFetching}
        />

        {!isFetching && !data?.items.length && (
          <Col
            spacing={3}
            sx={{
              alignItems: 'center',
              mt: '200px',
              mx: 'auto',
              textAlign: 'center',
            }}
          >
            <NoSearchResultsIcon sx={{ fontSize: '44px' }} />
            <Typography
              variant="accentS"
              color={TextColor.Secondary}
              sx={{ width: '250px' }}
            >
              {t('searchPage.noResults')}
            </Typography>
          </Col>
        )}
      </Col>
    </Container>
  );
};
