import { Lot } from 'entities/chess';
import { NewLot } from 'entities/chess/model/types/lotSchema';
/*
 * В зависимости от selectionMode в GridSelection находится выбранные элементы
 * 'free' - selection содержит id лотов
 * 'columns' - selection содержит номера колонок
 * 'rows' - selection содержит номера строк
 */
export type GridSelection = {
  selection: number[];
  selectionMode: GridSelectionMode;
  selectedLots: Lot[];
};

/*
 * 'free' - выделяем конкретные ячейки, в стейте id
 * 'columns' - выделяем столбцы, в стейте riser[]
 * 'rows' - выделяем строки, в стейте floor[]
 */
export type GridSelectionMode = 'lots' | 'risers' | 'floors';

export const getSelectedLots = (
  selectionMode: GridSelectionMode,
  selection: number[],
  data: Lot[]
) => {
  switch (selectionMode) {
    case 'lots':
      return getSelectedLotsByIds(data, selection).sort(sortByPositions);
    case 'floors':
      return getSelectedLotsInRows(data, selection).sort(sortByPositions);
    case 'risers':
      return getSelectedLotsInColumns(data, selection).sort(sortByPositions);
  }
};

export const sortByPositions = (
  lotA: Lot | NewLot,
  lotB: Lot | NewLot,
  descFloors = true
) => {
  const floorA = Math.max(...lotA.positions.map((pos) => pos.floor));
  const floorB = Math.max(...lotB.positions.map((pos) => pos.floor));

  if (floorA !== floorB) {
    return descFloors ? floorB - floorA : floorA - floorB;
  }

  const riserA = Math.min(...lotA.positions.map((pos) => pos.riser));
  const riserB = Math.min(...lotB.positions.map((pos) => pos.riser));

  if (riserA !== riserB) {
    return riserA - riserB;
  }

  const stepA = lotA.step || 0;
  const stepB = lotB.step || 0;

  return stepA - stepB;
};

const getSelectedLotsByIds = (data: Lot[], selection: number[]) => {
  return data.filter((lot) =>
    selection.some((selectedId) => lot.id === selectedId)
  );
};

const getSelectedLotsInRows = (data: Lot[], selection: number[]) => {
  return data.filter((lot) =>
    selection.some((selectedRow) =>
      lot.positions.some(({ floor }) => floor === selectedRow)
    )
  );
};

const getSelectedLotsInColumns = (data: Lot[], selection: number[]) => {
  return data.filter((lot) =>
    selection.some((selectedColumn) =>
      lot.positions.some(({ riser }) => riser === selectedColumn)
    )
  );
};
