import { Lot } from 'entities/chess';
import { LotPosition } from 'entities/chess/model/types/lotSchema';
// Map {[floor_riser]: lotsCount}
export const createLotsCountByPositionMap = (
  data: Lot[]
): Map<string, number> => {
  return data.reduce((acc: Map<string, number>, lot) => {
    lot.positions.forEach((pos) => {
      // Создаем ключ для map в формате "floor_riser"
      const key = getPositionMapKeyByPosition(pos);

      // Увеличиваем значение по ключу или устанавливаем его в 1, если ранее не встречалось
      acc.set(key, (acc.get(key) || 0) + 1);
    });

    return acc;
  }, new Map());
};

export const getPositionMapKeyByPosition = (position: LotPosition) =>
  `${position.floor}_${position.riser}`;
