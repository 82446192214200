import { GridLot } from '../types';

export const generateGridTemplateColumns = (risers: GridLot[]) => {
  const baseWidth = 230;
  const firstColumnWidth = '35px';
  const columnsWidths = [firstColumnWidth];

  for (let columnIndex = 0; columnIndex < risers.length; columnIndex++) {
    columnsWidths.push(`${risers[columnIndex].colspan * baseWidth}px`);
  }

  return columnsWidths.join(' ');
};

const getLotsCountInCell = (lots: GridLot[], riser: number): number => {
  // Фильтрация данных, чтобы получить ячейки в этой колонке
  const riserLotsPositions = lots.flatMap((lot) =>
    lot.positions.filter((pos) => pos.riser === riser)
  );

  if (!riserLotsPositions.length) {
    return 1;
  }

  const lotsCountByFloors: {
    [key: number]: number;
  } = {};

  // Отслеживание количества ячеек на каждом этаже
  riserLotsPositions.forEach((lotPosition) => {
    const currentFloor = lotPosition.floor;
    lotsCountByFloors[currentFloor] =
      (lotsCountByFloors[currentFloor] || 0) + 1;
  });

  return Math.max(...Object.values(lotsCountByFloors));
};
