import { EntityState } from '@reduxjs/toolkit';
import { z } from 'zod';

export type NewsPostDto = {
  id: number;
  name: string;
  date_create: string;
  date_update: string;
  manager?: {
    id: number;
    first_name: string | null;
    last_name: string | null;
    email: string | null;
  };
};

export type SaveNewsPostDto = {
  building_id: number;
  name: string;
};

const managerZod = z
  .object({
    id: z.number(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    email: z.string().optional(),
  })
  .optional();

export const newsPostZod = z.object({
  id: z.number(),
  text: z.string().min(1, 'news.form.error.enterText'),
  dateCreate: z.string(),
  dateUpdate: z.string(),
  manager: managerZod,
});

export const editNewsPostZod = newsPostZod.pick({
  text: true,
});

export type NewsPost = z.infer<typeof newsPostZod>;

export interface NewsPostSchema extends EntityState<NewsPost> {
  isLoading: boolean;
  inited: boolean;
}
