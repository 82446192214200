import { Contractor, ContractorDto } from '../model/types/contractorSchema';

export const mapContractorFromDto = ({
  id,
  name,
  inn,
}: ContractorDto): Contractor => ({
  id,
  contractorName: name,
  inn: inn || '',
});

export const mapDtoFromContractor = ({
  id,
  contractorName,
  inn,
}: Contractor): ContractorDto => ({
  id,
  name: contractorName,
  inn: inn,
});
