import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Container } from '@mui/material';
import {
  Building,
  BuildingDetailsCard,
  BuildingsPositions,
  useChangeBuildingsPositionsMutation,
  useDuplicateBuildingMutation,
  useGetBuildingsQuery,
} from 'entities/building';
import { useVersionCheck } from 'entities/object';
import { getRoute } from 'shared/const';
import { useRouteMatch } from 'shared/hooks/useRouteMatch';
import { Col } from 'shared/ui/Col';
import { VerticalSortableList } from 'shared/ui/SortableList';
import { BuildingsDetailsSkeleton } from './BuildingsDetailsSkeleton';
import { EditBuilding } from './EditBuilding';

//TODO: перенести в виджеты т.к. тут используется фича CreateBuilding
export const BuildingsDetails: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { objectId, buildingId } = useParams<{
    objectId: string;
    buildingId: string;
  }>();

  const { checkVersion, showVersionChangedPopup } = useVersionCheck(objectId);

  const { data, isLoading } = useGetBuildingsQuery({
    objectId: Number(objectId),
    limit: 30,
    offset: 0,
    search: '',
  });

  const [changeBuildingsPositions] = useChangeBuildingsPositionsMutation();
  const [duplicateBuilding] = useDuplicateBuildingMutation();

  const routeMatch = useRouteMatch([
    getRoute.ObjectBuildingCreate(':objectId'),
    getRoute.EditObjectBuildingCreate(':objectId'),
    getRoute.EditObjectBuildings(':objectId'),
  ]);

  const isObjectBuildingCreating =
    routeMatch?.pattern?.path === getRoute.ObjectBuildingCreate(':objectId');
  const isEditObjectBuildingCreating =
    routeMatch?.pattern?.path ===
    getRoute.EditObjectBuildingCreate(':objectId');
  const isObjectEditing =
    routeMatch?.pattern?.path === getRoute.EditObjectBuildings(':objectId');

  const handlePositionChange = async (items: Building[]) => {
    const newBuildingsPositions: BuildingsPositions = {
      buildingsIds: items.map((item) => item.id),
    };

    changeBuildingsPositions({
      objectId: Number(objectId),
      buildingsPositions: newBuildingsPositions,
      versionCheck: () => checkVersion(),
    });
  };

  const handleAddingBuilding = () => {
    if (objectId) {
      if (isObjectEditing || isEditObjectBuildingCreating) {
        navigate(getRoute.EditObjectBuildingCreate(objectId));
      } else {
        navigate(getRoute.ObjectBuildingCreate(objectId));
      }
    }
  };

  const handleAddingBuildingCancel = () => {
    if (objectId) {
      if (isObjectEditing || isEditObjectBuildingCreating) {
        navigate(getRoute.EditObjectBuildings(objectId));
      } else {
        navigate(getRoute.ObjectBuildings(objectId));
      }
    }
  };

  const handleEditClick = (building: Building) => {
    if (objectId) {
      if (isObjectEditing) {
        navigate(getRoute.EditObjectBuilding(objectId, building.id));
      } else {
        navigate(getRoute.ObjectBuilding(objectId, building.id));
      }
    }
  };

  const handleDuplicateClick = async (building: Building) => {
    const isVersionChanged = await checkVersion();

    if (!isVersionChanged) {
      await duplicateBuilding(building);
    }
  };

  if (isLoading) return <BuildingsDetailsSkeleton />;

  return (
    <Container>
      {showVersionChangedPopup()}
      <Col spacing={8} maxWidth={buildingId ? 648 : 606}>
        <Button
          size="small"
          onClick={handleAddingBuilding}
          disabled={Boolean(
            isEditObjectBuildingCreating ||
              isObjectBuildingCreating ||
              buildingId
          )}
        >
          {t('building.addBuilding')}
        </Button>
        {isEditObjectBuildingCreating ||
        isObjectBuildingCreating ||
        buildingId ? (
          <EditBuilding
            buildingId={buildingId ? Number(buildingId) : undefined}
            onCancelClick={handleAddingBuildingCancel}
            onUpdated={handleAddingBuildingCancel}
            onCreated={handleAddingBuildingCancel}
          />
        ) : (
          <VerticalSortableList
            items={data?.items || []}
            onChange={handlePositionChange}
            renderItem={(building) => (
              <VerticalSortableList.Item id={building.id}>
                <BuildingDetailsCard
                  building={building}
                  objectId={objectId || ''}
                  key={building.id}
                  onEditClick={handleEditClick}
                  onDuplicateClick={handleDuplicateClick}
                />
              </VerticalSortableList.Item>
            )}
          />
        )}
      </Col>
    </Container>
  );
};
