import { EntityState } from '@reduxjs/toolkit';
import { z } from 'zod';
import { emailValidator } from 'shared/helpers/emailValidator';
import { phoneValidator } from 'shared/helpers/phoneValidator';
import { timeRangeValidator } from 'shared/helpers/timeRangeValidator';

export enum DocumentTypeId {
  nalog = 1,
  ustav = 2,
  director = 3,
  genDirector = 4,
  permissionsApprove = 5,
}

export type DeveloperDto = {
  id: number;
  name: string;
  phone: number | null;
  first_name: string;
  last_name: string;
  phone_user: number | null;
  corp_email: string;
  website: string;
  email: string;
  inn: string | null;
  description: string;
  logo_hash: string;
  lat: number | null;
  lng: number | null;
  address: string;
  is_hidden: number;
  developer_files: {
    name: string;
    hash: string;
    type_id: DocumentTypeId;
  }[];
  developer_schedules: {
    schedule: string | null;
    time_from: string | null;
    time_to: string | null;
  }[];
};

const schedulesZod = z.object({
  workingDays: z
    .string({ required_error: 'developer.form.error.workingDays' })
    .min(1, 'developer.form.error.workingDays'),
  workingHours: timeRangeValidator.required,
});

export type DeveloperSchedules = z.infer<typeof schedulesZod>;

export const documentZod = z
  .object({
    name: z.string().optional(),
    hash: z.string(),
    typeId: z.number(),
  })
  .optional();

export type DeveloperDocument = z.infer<typeof documentZod>;

export const developerZod = z.object({
  id: z.number(),
  name: z.string().min(1, 'developer.form.error.name'),
  inn: z
    .literal('')
    .or(z.string().min(10, { message: 'developer.form.error.inn' })),
  description: z.string().optional(),
  logo: z.string().optional(),
  address: z.string().optional(),
  latitude: z.number().nullable(),
  longitude: z.number().nullable(),
  corpEmail: emailValidator.optional,
  phone: phoneValidator.optional,
  isHidden: z.boolean(),
  website: z.string().optional(),
  ownerName: z
    .string({ required_error: 'ui.common.error.firstName' })
    .min(1, 'ui.common.error.firstName'),
  ownerLastName: z
    .string({ required_error: 'ui.common.error.lastName' })
    .min(1, 'ui.common.error.lastName'),
  ownerPhone: phoneValidator.required,
  ownerEmail: emailValidator.required,
  schedules: z.array(schedulesZod),
  documentNalogHash: z.string().optional(),
  documentUstavHash: z.string().optional(),
  documentDirectorHash: z.string().optional(),
  documentGenDirectorHash: z.string().optional(),
  documentPermissionsApproveHash: z.string().optional(),
});

export type Developer = z.infer<typeof developerZod>;

export interface DeveloperSchema extends EntityState<Developer> {
  isLoading: boolean;
  inited: boolean;
}

export const createDeveloperZod = developerZod.pick({
  name: true,
  inn: true,
  isHidden: true,
  description: true,
  logo: true,
  address: true,
  latitude: true,
  longitude: true,
  ownerEmail: true,
  ownerPhone: true,
  ownerName: true,
  ownerLastName: true,
  documentDirectorHash: true,
  documentGenDirectorHash: true,
  documentNalogHash: true,
  documentPermissionsApproveHash: true,
  documentUstavHash: true,
});

export type CreateDeveloper = z.infer<typeof createDeveloperZod>;

export const editDeveloperZod = developerZod.pick({
  name: true,
  inn: true,
  isHidden: true,
  description: true,
  logo: true,
  address: true,
  latitude: true,
  longitude: true,
  corpEmail: true,
  phone: true,
  website: true,
  schedules: true,
  documentDirectorHash: true,
  documentGenDirectorHash: true,
  documentNalogHash: true,
  documentPermissionsApproveHash: true,
  documentUstavHash: true,
  // TODO: edit user developer fields
  // ownerEmail: true,
  // ownerName: true,
  // ownerLastName: true,
  // ownerPhone: true,
});

export type EditDeveloper = z.infer<typeof editDeveloperZod>;
