import { useState, useCallback } from 'react';

type UseConfirmationDialogProps = {
  onConfirm: () => void;
};

export const useConfirmationDialog = ({
  onConfirm,
}: UseConfirmationDialogProps) => {
  const [isShowRemoveConfirmation, setIsShowRemoveConfirmation] =
    useState<boolean>(false);

  const showRemoveConfirmation = useCallback(() => {
    setIsShowRemoveConfirmation(true);
  }, []);

  const hideRemoveConfirmation = useCallback(() => {
    setIsShowRemoveConfirmation(false);
  }, []);

  const confirmRemoveAction = useCallback(() => {
    onConfirm();
    setIsShowRemoveConfirmation(false);
  }, [onConfirm]);

  return {
    isShowRemoveConfirmation,
    showRemoveConfirmation,
    hideRemoveConfirmation,
    confirmRemoveAction,
  };
};
