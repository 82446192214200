import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Box,
  FormControl,
  ListItemIcon,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  Typography,
} from '@mui/material';
import { getLabelByValue } from 'shared/helpers/getLabelByValue';
import { BgColor, TextColor } from 'shared/theme/colors';
import { SelectOption } from 'shared/types';
import { CheckboxCheckedClearIcon } from './Icons';
import { Row } from './Row';

type ChipWithSelectProps = {
  options: SelectOption[];
  label?: string;
  type?: 'contained' | 'outlined';
  onChange?: (event: SelectChangeEvent) => void;
} & Omit<SelectProps<string>, 'value' | 'defaultValue'>;

export const ChipWithSelect: FC<ChipWithSelectProps> = ({
  options,
  name,
  label = '',
  type = 'outlined',
  onChange,
  ...props
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const textColor =
    type === 'outlined' ? TextColor.Primary : TextColor.StaticWhite;
  const backgroundColor =
    type === 'outlined' ? TextColor.StaticWhite : TextColor.Primary;
  const borderColor =
    type === 'outlined' ? `1px solid ${TextColor.Quaternary}` : 'none';

  return (
    <Row
      spacing={5}
      sx={{
        border: borderColor,
        borderRadius: '8px',
        alignItems: 'center',
        height: '36px',
        pl: '16px',
        pr: '8px',
        background: props.disabled ? BgColor.Gray : backgroundColor,
      }}
    >
      <Controller
        name={name || ''}
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <FormControl fullWidth>
            <Select
              {...props}
              {...rest}
              variant="standard"
              sx={{
                pt: 2,
                '.MuiInput-input:focus': {
                  background: 'transparent',
                },
                '& .MuiSvgIcon-root': {
                  color: textColor,
                },
              }}
              onChange={(e) => {
                rest.onChange(e);
                onChange?.(e);
              }}
              renderValue={(value) => (
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: type === 'outlined' ? '600' : '500',
                    pr: '4px',
                  }}
                  color={textColor}
                >
                  {`${label}  ${t(getLabelByValue(options, value))}`}
                </Typography>
              )}
            >
              {options
                .filter((option) => !option.hidden)
                .map((option, index) => (
                  <MenuItem
                    key={index}
                    value={option.value}
                    disableRipple
                    sx={{
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                      '&&.Mui-selected': {
                        backgroundColor: 'transparent',
                      },
                      '&:active': {
                        backgroundColor: 'transparent',
                      },
                    }}
                  >
                    {rest.value === option.value ? (
                      <ListItemIcon>
                        {<CheckboxCheckedClearIcon />}
                      </ListItemIcon>
                    ) : (
                      <ListItemIcon>
                        <Box sx={{ height: '24px', width: '24px' }} />
                      </ListItemIcon>
                    )}
                    <Typography
                      variant="paragraphM"
                      sx={{
                        fontSize: '14px',
                        fontWeight: '600',
                        pr: '12px',
                      }}
                      color={TextColor.Primary}
                    >
                      {t(option.label)}
                    </Typography>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      />
    </Row>
  );
};
