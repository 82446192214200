import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, Typography } from '@mui/material';
import { TextColor } from 'shared/theme/colors';
import { getLabelByValue } from '../helpers/getLabelByValue';
import { SelectOption } from '../types';
import { Col } from './Col';
import { Row } from './Row';

type ChipsViewFieldProps = {
  title: string;
  selected?: string[];
  options?: SelectOption[];
};

export const ChipsViewField: FC<ChipsViewFieldProps> = ({
  title,
  selected,
  options,
}) => {
  const { t } = useTranslation();

  return (
    <Col spacing={4}>
      <Typography
        variant="paragraphS"
        color={TextColor.Secondary}
        sx={{ flexGrow: 1 }}
      >
        {title}
      </Typography>
      <Row spacing={4}>
        {selected?.map((item) => (
          <Chip
            key={item}
            label={t(getLabelByValue(options ?? [], item))}
            sx={{
              paddingRight: '16px',
              background: TextColor.StaticWhite,
              color: TextColor.Primary,
              border: `1px solid ${TextColor.Quaternary}`,
              fontSize: '14px',
              fontWeight: '600',
            }}
          />
        ))}
      </Row>
    </Col>
  );
};
