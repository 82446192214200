type getLimitForCardRowsPaginationProps = {
  headerHeight: number;
  previewCardHeight: number;
  amountPerRow: number;
};

export const getLimitForCardRowsPagination = ({
  headerHeight,
  previewCardHeight,
  amountPerRow,
}: getLimitForCardRowsPaginationProps): number => {
  const windowHeight = window.innerHeight;
  const count =
    Math.ceil((windowHeight - headerHeight) / previewCardHeight) * amountPerRow;
  return Math.max(12, count);
};
