import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Typography } from '@mui/material';
import {
  DealsAndReservationsDynamicsChart,
  LotStatusesStatisticsChart,
  LotStatusesStatisticsTable,
  mockAnalytics,
  useGetLotStatusesStatisticsQuery,
} from 'entities/analytics';
import { AnalyticMetricField } from 'shared/ui/Analytics/AnalyticMetricField';
import { AnalyticsBlockCard } from 'shared/ui/Analytics/AnalyticsBlockCard';
import { Col } from 'shared/ui/Col';
import { Row } from 'shared/ui/Row';

export const ObjectAnalyticsCard: FC = () => {
  const { t } = useTranslation();
  const { objectId } = useParams<{ objectId: string }>();

  const objectAnalyticsMockData = mockAnalytics.objectAnalytics;
  const objectStatistics = objectAnalyticsMockData.objectStatistics;
  const lotPricesAndAreasStatistics =
    objectAnalyticsMockData.lotPricesAndAreasStatistics;

  const { data } = useGetLotStatusesStatisticsQuery({
    objectId: Number(objectId),
  });

  const getObjectStatisticsContent = () => {
    return (
      <Row spacing={5}>
        <AnalyticMetricField
          title={t('analytics.object.metricTitle.lastUpdate')}
          value={objectStatistics.lastUpdate}
        />
        <AnalyticMetricField
          title={t('analytics.object.metricTitle.viewCount')}
          value={objectStatistics.objectViewsCount}
          percentageChange={objectStatistics.objectViewsCountChange}
        />
        <AnalyticMetricField
          title={t('analytics.object.metricTitle.selections')}
          value={objectStatistics.selectionsCountWithObject}
        />
        <AnalyticMetricField
          title={t('analytics.object.metricTitle.inFavorites')}
          value={objectStatistics.objectInFavoritesCount}
          percentageChange={objectStatistics.objectInFavoritesCountChange}
        />
      </Row>
    );
  };

  const getLotStatusesStatistics = () => {
    return (
      <Row sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        {data && <LotStatusesStatisticsChart data={data} />}
        {data && <LotStatusesStatisticsTable data={data} />}
      </Row>
    );
  };

  const getLotPricesAndAreasStatisticsContent = () => {
    return (
      <Row spacing={5}>
        <AnalyticMetricField
          title={t('analytics.object.metricTitle.soldPercentage')}
          value={lotPricesAndAreasStatistics.lotsSoldOutPercentage}
          percentageChange={
            lotPricesAndAreasStatistics.lotsSoldOutPercentageChange
          }
        />
        <AnalyticMetricField
          title={t('analytics.object.metricTitle.priceInSale')}
          value={lotPricesAndAreasStatistics.lotInSaleAveragePrice}
          percentageChange={
            lotPricesAndAreasStatistics.lotInSaleAveragePriceChange
          }
        />
        <AnalyticMetricField
          title={t('analytics.object.metricTitle.soldPrice')}
          value={lotPricesAndAreasStatistics.lotSoldAveragePrice}
          percentageChange={
            lotPricesAndAreasStatistics.lotSoldAveragePriceChange
          }
        />
        <AnalyticMetricField
          title={t('analytics.object.metricTitle.areaInSale')}
          value={lotPricesAndAreasStatistics.lotInSaleAverageArea}
          percentageChange={
            lotPricesAndAreasStatistics.lotInSaleAverageAreaChange
          }
        />
        <AnalyticMetricField
          title={t('analytics.object.metricTitle.soldArea')}
          value={lotPricesAndAreasStatistics.lotSoldAverageArea}
          percentageChange={
            lotPricesAndAreasStatistics.lotSoldAverageAreaChange
          }
        />
      </Row>
    );
  };

  const getDealsAndReservationsDynamics = () => {
    return (
      <Row sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <DealsAndReservationsDynamicsChart />
      </Row>
    );
  };

  return (
    <Card sx={{ py: 9, px: 8 }}>
      <Typography variant="headerM" sx={{ mb: 9 }}>
        {t('analytics.object.title')}
      </Typography>

      <Col spacing={9}>
        <Col spacing={8}>
          <Row spacing={8} equalsChildrenWidth>
            {/* <AnalyticsBlockCard
              title={t('analytics.object.blockTitle.objectStatistics')}
              afterTitleSpacing={9}
              height={322}
            >
              {getObjectStatisticsContent()}
            </AnalyticsBlockCard> */}
            <AnalyticsBlockCard
              title={t('analytics.object.blockTitle.lotStatuses')}
              height={322}
            >
              {getLotStatusesStatistics()}
            </AnalyticsBlockCard>
          </Row>
          {/* <Row spacing={8} equalsChildrenWidth>
            <AnalyticsBlockCard
              title={t('analytics.object.blockTitle.lotPricesAndAreas')}
              height={540}
            >
              {getLotPricesAndAreasStatisticsContent()}
            </AnalyticsBlockCard>
            <AnalyticsBlockCard
              title={t('analytics.object.blockTitle.dealsAndReservations')}
              afterTitleSpacing={8}
              height={540}
            >
              {getDealsAndReservationsDynamics()}
            </AnalyticsBlockCard>
          </Row> */}
        </Col>
      </Col>
    </Card>
  );
};
