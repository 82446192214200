import { GridLot } from '../types';

export const isRiserHasMergedLots = (riser: number, lots: GridLot[]) => {
  const mergedLots = lots.filter(
    ({ positions }) =>
      positions.length > 1 &&
      positions.some((position) => position.riser === riser) &&
      positions.some((position) => position.riser === riser - 1)
  );
  return mergedLots.length > 0;
};

export const isFloorHasMergedLots = (floor: number, lots: GridLot[]) => {
  const mergedLots = lots.filter(
    ({ positions }) =>
      positions.length > 1 &&
      positions.some((position) => position.floor === floor) &&
      positions.some((position) => position.floor === floor - 1)
  );
  return mergedLots.length > 0;
};
