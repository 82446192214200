import { useCallback } from 'react';
import {
  CONSTRUCTION_PROGRESS_TYPE,
  monthsOptions,
  yearsOptions,
} from '../model/consts';
import { Album } from '../model/types/albumSchema';

type UseAlbumProgressProps = {
  albums?: Album[];
  activeAlbum?: Album | null;
};

export const useGetAlbumProgressDates = ({
  albums,
  activeAlbum,
}: UseAlbumProgressProps) => {
  const getProgressYears = useCallback(() => {
    if (!albums) return [];

    const years: string[] = albums
      .filter(
        (album) => album.type === CONSTRUCTION_PROGRESS_TYPE && album.year
      )
      .map((album) => album.year!);

    return yearsOptions.map((option) => ({
      ...option,
      hidden: !years.includes(option?.value?.toString()),
    }));
  }, [albums]);

  const getProgressMonths = useCallback(() => {
    if (!albums) return [];

    const months: string[] = albums
      .filter(
        (album) =>
          album.type === CONSTRUCTION_PROGRESS_TYPE &&
          album.year === activeAlbum?.year &&
          album.month
      )
      .map((album) => album.month!);

    return monthsOptions.map((option) => ({
      ...option,
      hidden: !months.includes(option?.value?.toString()),
    }));
  }, [albums, activeAlbum]);

  return {
    getProgressYears,
    getProgressMonths,
  };
};
