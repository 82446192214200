import { ReactNode, useState } from 'react';
import {
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { AccentColor, TextColor } from 'shared/theme/colors';
import { Row } from 'shared/ui/Row';

export type StepWithContentProps = {
  label: string;
  subLabel: string;
  content: ReactNode;
};

type useStepperWithContentProps = {
  steps: StepWithContentProps[];
};

export const useStepperWithContent = ({
  steps,
}: useStepperWithContentProps) => {
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
  const [validStepsIndexes, setValidStepsIndexes] = useState<number[]>([]);

  const handleNextStep = () => {
    const newActiveStepIndex = activeStepIndex + 1;
    setActiveStepIndex(newActiveStepIndex);
  };

  const handleChangeStepsValidity = (isStepValid: boolean) => {
    if (isStepValid) {
      if (!validStepsIndexes.includes(activeStepIndex)) {
        const newValidStatus = [...validStepsIndexes, activeStepIndex];
        setValidStepsIndexes(newValidStatus);
      }
    } else {
      if (validStepsIndexes.includes(activeStepIndex)) {
        const newValidStepsIndexes = validStepsIndexes.filter(
          (step) => step !== activeStepIndex
        );
        setValidStepsIndexes(newValidStepsIndexes);
      }
    }
  };

  const handlePreviousStep = () => {
    setActiveStepIndex((prev) => prev - 1);
  };

  const handleSetActiveByLabelClick = (stepIndex: number) => () => {
    if (stepIndex === activeStepIndex) {
      return;
    }
    if (getIsStepAvailable(stepIndex)) {
      setActiveStepIndex(stepIndex);
    }
  };

  const getIsStepAvailable = (stepIndex: number) => {
    let firstInvalidStepIndex = -1;
    for (let i = 0; i < steps.length; i++) {
      if (!validStepsIndexes.includes(i)) {
        firstInvalidStepIndex = i;
        break;
      }
    }
    if (firstInvalidStepIndex > -1) {
      return stepIndex <= firstInvalidStepIndex;
    }
    return true;
  };

  const getIconColor = (stepIndex: number) => {
    if (activeStepIndex === stepIndex) {
      return TextColor.Secondary;
    } else if (validStepsIndexes.includes(stepIndex)) {
      return AccentColor.Positive;
    } else {
      return TextColor.Quaternary;
    }
  };

  const getConnectorColor = (stepIndex: number) => {
    if (stepIndex !== steps.length - 1) {
      if (activeStepIndex === stepIndex) {
        return `3px solid ${TextColor.Secondary}`;
      } else if (validStepsIndexes.includes(stepIndex)) {
        return `3px solid ${AccentColor.Positive}`;
      } else {
        return `3px solid ${TextColor.Quaternary}`;
      }
    }
  };

  const StepperWithContent = (
    <Row spacing={9} wrap={false}>
      {steps[activeStepIndex].content}
      <Stepper
        activeStep={activeStepIndex}
        sx={{
          '& .MuiStepConnector-root': {
            height: 0,
          },
          '& .MuiStepConnector-line': {
            borderLeft: 'none',
          },
          marginTop: '18px',
          marginLeft: '-1px',
        }}
        orientation="vertical"
      >
        {steps.map((step, stepIndex) => (
          <Step
            expanded
            key={stepIndex}
            completed={validStepsIndexes.includes(stepIndex)}
          >
            <StepLabel
              sx={{
                paddingBottom: '10px',
                paddingTop: '11px',
              }}
              StepIconProps={{
                style: {
                  width: 20,
                  height: 20,
                  fill: getIconColor(stepIndex),
                },
              }}
            >
              <Typography
                variant={
                  getIsStepAvailable(stepIndex) ? 'accentM' : 'paragraphM'
                }
                color={TextColor.Primary}
                sx={{
                  height: '23px',
                  marginLeft: '4px',
                  marginBottom: '3px',
                  cursor: getIsStepAvailable(stepIndex) ? 'pointer' : 'default',
                  textDecoration: getIsStepAvailable(stepIndex)
                    ? `underline 1px ${TextColor.Quaternary}`
                    : 'none',
                  textUnderlineOffset: '5px',
                }}
                onClick={handleSetActiveByLabelClick(stepIndex)}
              >
                {step.label}
              </Typography>
            </StepLabel>
            <StepContent
              sx={{
                borderLeft: getConnectorColor(stepIndex),
                marginLeft: '9px',
              }}
            >
              <Typography
                variant={'paragraphS'}
                color={TextColor.Secondary}
                sx={{
                  paddingLeft: 0,
                  paddingTop: '2px',
                  paddingBottom: '12px',
                  marginTop: '3px',
                  maxWidth: '312px',
                  position: 'relative',
                  top: '-12px',
                }}
              >
                {step.subLabel}
              </Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Row>
  );

  return {
    StepperWithContent,
    onPreviousStepClick: handlePreviousStep,
    onNextStepClick: handleNextStep,
    onStepValidityChange: handleChangeStepsValidity,
  };
};
