import { FC } from 'react';
import { Col } from 'shared/ui/Col';
import { RectSkeleton } from 'shared/ui/Skeleton/RectSkeleton';

export const NewsPostDetailsSkeleton: FC = () => {
  return (
    <Col spacing={9} maxWidth={'lg'} sx={{ m: 'auto' }}>
      <RectSkeleton maxWidth={600} height={210} />
    </Col>
  );
};
