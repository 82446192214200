import {
  Entrance,
  EntranceDto,
  SaveEntranceDto,
} from '../model/types/entranceSchema';

export const mapEntranceFromDto = ({
  id,
  name,
  pavilion_id,
  floor_amount,
  riser_amount,
  count_chess,
  min_area_all,
  min_price_all,
}: EntranceDto): Entrance => ({
  id,
  buildingId: pavilion_id,
  name: name || '',
  floorCount: floor_amount ? floor_amount?.toString() : '',
  riserCount: riser_amount ? riser_amount?.toString() : '',
  lotsCount: count_chess,
  minAreaAll: min_area_all,
  minPriceAll: min_price_all,
});

export const mapDtoFromEntrance = ({
  id,
  buildingId,
  floorCount,
  name,
  riserCount,
}: Entrance): SaveEntranceDto => ({
  id,
  pavilion_id: buildingId,
  name,
  floor_amount: Number(floorCount) || null,
  riser_amount: Number(riserCount) || null,
});
