import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, Typography } from '@mui/material';
import { Building, useDeleteBuildingMutation } from 'entities/building';
// eslint-disable-next-line boundaries/element-types
import { Entrance } from 'entities/entrance';
// eslint-disable-next-line boundaries/element-types
import { useVersionCheck } from 'entities/object';
import { getRoute } from 'shared/const';
import { getLabelByValue } from 'shared/helpers/getLabelByValue';
import { useConfirmationDialog } from 'shared/hooks/useConfirmationDialog';
import { TextColor } from 'shared/theme/colors';
import { ButtonLink } from 'shared/ui/ButtonLink';
import { Col } from 'shared/ui/Col';
import { RemovalConfirmationCard } from 'shared/ui/RemovalConfirmationCard';
import { Row } from 'shared/ui/Row';
import { SmallChip } from 'shared/ui/SmallChip';
import { SmallSquareButton } from 'shared/ui/SmallSquareButton';
import { VerticalSortableList } from 'shared/ui/SortableList';
import { statusOptions } from '../model/consts';

type BuildingDetailsCardProps = {
  objectId: string;
  building: Building;
  onEditClick: (building: Building) => void;
  onDuplicateClick: (building: Building) => void;
};

// TODO: нужен рефакторинг, используются фичи и ентитисы
export const BuildingDetailsCard: FC<BuildingDetailsCardProps> = ({
  objectId,
  building,
  onEditClick,
  onDuplicateClick,
}) => {
  const { t } = useTranslation();

  const { checkVersion, showVersionChangedPopup } = useVersionCheck(objectId);

  const [deleteBuilding] = useDeleteBuildingMutation();

  const handleDeleteBuilding = async (building: Building) => {
    const isVersionChanged = await checkVersion();

    if (!isVersionChanged) {
      try {
        await deleteBuilding(building).unwrap();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const {
    isShowRemoveConfirmation,
    showRemoveConfirmation,
    hideRemoveConfirmation,
    confirmRemoveAction,
  } = useConfirmationDialog({
    onConfirm: () => handleDeleteBuilding(building),
  });

  const navigate = useNavigate();

  const formatEntranceLotsInfo = useCallback(
    (entrance: Entrance) => {
      if (
        entrance.lotsCount !== null &&
        entrance.minAreaAll !== null &&
        entrance.minPriceAll !== null
      ) {
        return t('building.card.lotInfo', {
          count: entrance.lotsCount,
          minAreaAll: entrance.minAreaAll,
          minPriceAll: entrance.minPriceAll,
        });
      }
      return t('building.card.setLots');
    },
    [t]
  );

  const entrancesRows = useMemo(() => {
    return building?.entrances?.map((entrance) => {
      return (
        <Row spacing={8} key={entrance.id}>
          <Typography variant="accentS" color={TextColor.Secondary}>
            {t('building.card.entranceName', { entrance: entrance.name })}
          </Typography>
          <ButtonLink
            onClick={async () => {
              navigate(
                getRoute.ObjectChessboard(objectId, building.id, entrance.id)
              );
            }}
          >
            {formatEntranceLotsInfo(entrance)}
          </ButtonLink>
        </Row>
      );
    });
  }, [
    building?.entrances,
    building.id,
    t,
    formatEntranceLotsInfo,
    navigate,
    objectId,
  ]);

  const dateLabel = useMemo(() => {
    const { completeQuarter, completeYear } = building;
    return [
      t('building.card.completeQuarter', {
        completeQuarter,
      }),
      t('building.card.completeYear', {
        completeYear,
      }),
    ].join(', ');
  }, [building, t]);

  return (
    <Card
      sx={{
        width: 606,
        border: 'none',
        p: 8,
        position: 'relative',
      }}
    >
      {showVersionChangedPopup()}
      <Box
        sx={{
          position: 'absolute',
          top: 24,
          right: 6,
        }}
      >
        <VerticalSortableList.DragHandle />
      </Box>
      <Col spacing={8}>
        <Col spacing={5}>
          <Typography variant="headerXS">{building.name}</Typography>

          {building.status && (
            <SmallChip
              variant="process"
              label={t(getLabelByValue(statusOptions, building.status))}
            />
          )}
        </Col>
        {building.completeQuarter && building.completeYear && (
          <Typography variant="accentM" color={TextColor.Secondary}>
            {dateLabel}
          </Typography>
        )}
        {(building.entrances?.length || 0) > 0 && (
          <Col spacing={4}>{entrancesRows}</Col>
        )}
        {isShowRemoveConfirmation ? (
          <RemovalConfirmationCard
            titleEnding={t('building.card.deleteEnding')}
            onRemoveClick={confirmRemoveAction}
            onCancelClick={hideRemoveConfirmation}
          />
        ) : (
          <Row spacing={5}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => onDuplicateClick(building)}
            >
              {t('building.card.duplicate')}
            </Button>
            <SmallSquareButton
              icon="edit"
              onClick={() => onEditClick(building)}
            />
            <SmallSquareButton onClick={showRemoveConfirmation} />
          </Row>
        )}
      </Col>
    </Card>
  );
};
