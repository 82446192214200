import { useEffect, useState } from 'react';
import { SelectOption } from 'shared/types';
import { Album } from '../index';
import { monthsOptions, yearsOptions } from '../model/consts';

type UseFilteredAlbumDatesProps = {
  objectAddedAlbums: Album[];
  watchedMonth: string | undefined;
  watchedYear: string | undefined;
  currentAlbumMonth?: string;
  currentAlbumYear?: string;
};

export const useFilteredAlbumDates = ({
  objectAddedAlbums,
  watchedMonth,
  watchedYear,
  currentAlbumMonth,
  currentAlbumYear,
}: UseFilteredAlbumDatesProps) => {
  const [filteredMonthsOptions, setFilteredMonthsOptions] =
    useState<SelectOption[]>(monthsOptions);
  const [filteredYearsOptions, setFilteredYearsOptions] =
    useState<SelectOption[]>(yearsOptions);

  useEffect(() => {
    const usedDates = objectAddedAlbums
      .filter((album) => album.year)
      .map((album) => `${album.year}_${album.month}`);

    const newMonthsOptions = monthsOptions.filter((month) => {
      const value = `${watchedYear}_${month.value}`;
      return (
        !usedDates.includes(value) ||
        value === `${currentAlbumYear}_${currentAlbumMonth}`
      );
    });

    const newYearsOptions = yearsOptions.filter((year) => {
      const value = `${year.value}_${watchedMonth}`;
      return (
        !usedDates.includes(value) ||
        value === `${currentAlbumYear}_${currentAlbumMonth}`
      );
    });

    setFilteredMonthsOptions(newMonthsOptions);
    setFilteredYearsOptions(newYearsOptions);
  }, [
    objectAddedAlbums,
    watchedMonth,
    watchedYear,
    currentAlbumMonth,
    currentAlbumYear,
  ]);

  return {
    filteredMonthsOptions,
    filteredYearsOptions,
  };
};
