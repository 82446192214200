import { Lot } from 'entities/chess';
import { LotsFilters, RangeFilter } from '../FiltersDialog';

export const getFilteredLotsIds = (
  lotsFilters: Partial<LotsFilters> | undefined,
  lots: Lot[]
) => {
  if (lotsFilters) {
    const {
      totalPrice,
      pricePerMeter,
      totalArea,
      numberOfRooms,
      decorations,
      // registrations,
      lotsTypes,
    } = lotsFilters;

    const newFilteredLots = lots.filter((lot) => {
      if (
        !checkLotFieldWithRangeFilter(totalPrice, Number(lot.totalPrice)) ||
        !checkLotFieldWithRangeFilter(
          pricePerMeter,
          Number(lot.pricePerMeter)
        ) ||
        !checkLotFieldWithRangeFilter(totalArea, Number(lot.totalArea))
      ) {
        return false;
      }

      if (
        numberOfRooms?.length &&
        !numberOfRooms.some((filter) => filter === lot.numberOfRooms)
      ) {
        return false;
      }

      if (
        decorations?.length &&
        !decorations.some((filter) => filter === lot.decoration)
      ) {
        return false;
      }
      // Временно скрыто
      // if (
      //   registrations?.length &&
      //   (!chess.registration ||
      //     !registrations.some(
      //       (filter) => chess.registration?.some((reg) => filter === reg)
      //     ))
      // ) {
      //   return false;
      // }

      return !(
        lotsTypes?.length && !lotsTypes.some((filter) => filter === lot.lotType)
      );
    });

    return newFilteredLots.map(({ id }) => id);
  } else {
    return undefined;
  }
};

const checkLotFieldWithRangeFilter = (
  filter?: RangeFilter,
  lotField?: number
) => {
  if (filter && (filter.from || filter.to)) {
    if (lotField === undefined) {
      return false;
    }
    let result = false;

    if (filter.from) {
      result = lotField >= Number(filter.from);
      if (!result) {
        return false;
      }
    }
    if (filter.to) {
      result = lotField <= Number(filter.to);
    }
    return result;
  }
  return true;
};
