import { FC, ReactNode } from 'react';
import { Box, styled } from '@mui/material';
import { BgColor, TextColor } from 'shared/theme/colors';
import { ChessCornerIcon } from '../Icons';
import { generateGridTemplateColumns } from './lib/generateGridTemplateColumns';
import { GridLot } from './types';

const CornerAbsolute: FC = () => {
  return (
    <Box
      sx={{
        bgcolor: BgColor.Gray,
        position: 'absolute',
        zIndex: 2,
        top: 0,
        left: 0,
      }}
    >
      <Box
        sx={{
          bgcolor: TextColor.StaticWhite,
          boxShadow: `0 0 0 1px #DBDBDB inset`,
          borderRadius: '8px 0px 0px 0px',
          width: 37,
          height: 37,
          display: 'flex',
        }}
      >
        <ChessCornerIcon sx={{ m: 'auto', fontSize: 20 }} />
      </Box>
    </Box>
  );
};

type FlexGridBoxProps = {
  rowsCount: number;
  risers: GridLot[];
  children: ReactNode;
  editBarHeight?: number;
  isMdDown: boolean;
  editBarAndChessSpacingSum: number;
};

const FlexGridBoxWrapper = styled('div')(() => ({
  position: 'relative',
}));

const HEADER_CELL_SIZE = 35;
const ROW_HEIGHT = 118;
const MD_DOWN_GAP_TOP = 200;

const FlexGridBoxInner = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'rowsCount' &&
    prop !== 'risers' &&
    prop !== 'editBarHeight' &&
    prop !== 'isMdDown' &&
    prop !== 'editBarAndChessSpacingSum',
  name: 'FlexGridBox',
})<FlexGridBoxProps>(
  ({
    rowsCount,
    risers,
    editBarHeight,
    isMdDown,
    editBarAndChessSpacingSum,
  }) => ({
    display: 'grid',
    gridTemplateColumns: generateGridTemplateColumns(risers),
    gridTemplateRows: `${HEADER_CELL_SIZE}px repeat(${rowsCount}, ${ROW_HEIGHT}px)`,
    padding: '1px 20px 20px 1px',
    overflow: 'auto',
    maxWidth: '100%',
    width: 'fit-content',
    maxHeight: isMdDown
      ? window.innerHeight - MD_DOWN_GAP_TOP
      : editBarHeight &&
        window.innerHeight - editBarHeight - editBarAndChessSpacingSum,
    position: 'relative',
    borderRadius: 8,
    // Для последней ячейки в первой строке
    [`& > :nth-of-type(${risers.length + 1})`]: {
      borderTopRightRadius: 8,
    },

    // Для первой ячейки в последней строке
    [`& > :nth-of-type(${
      (rowsCount + 1) * (risers.length + 1) - risers.length
    })`]: {
      borderBottomLeftRadius: 8,
    },

    // Для последней ячейки в последней строке
    [`& > :nth-of-type(${(rowsCount + 1) * (risers.length + 1)})`]: {
      borderBottomRightRadius: 8,
    },
  })
);

export const FlexGridBox: FC<FlexGridBoxProps> = ({ children, ...props }) => (
  <FlexGridBoxWrapper>
    <CornerAbsolute />
    <FlexGridBoxInner {...props}>{children}</FlexGridBoxInner>
  </FlexGridBoxWrapper>
);
