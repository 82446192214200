import {
  ObjectPublicationError,
  ObjectPublicationErrorDto,
} from '../model/types/objectPublicationErrorsSchema';

export const mapPublicationErrorsFromDto = ({
  type,
  building_id,
  pavilion_id,
  section_id,
  name,
  messages,
  count_errors,
}: ObjectPublicationErrorDto): ObjectPublicationError => {
  let modifiedType = type;
  switch (type) {
    case 'building':
      modifiedType = 'object';
      break;
    case 'pavilion':
      modifiedType = 'building';
      break;
  }

  return {
    type: modifiedType,
    objectId: building_id,
    buildingId: pavilion_id,
    entranceId: section_id,
    name,
    messages,
    errorsCount: count_errors,
  };
};
