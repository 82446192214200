import { useEffect } from 'react';

/**
 * Выполняет callback только при первой отрисовке компонента
 * @param callback
 */
export function useInitialEffect(callback: () => void) {
  useEffect(() => {
    callback();
    // eslint-disable-next-line
  }, []);
}
