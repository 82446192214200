import { FC } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EditEntranceForm, Entrance, entranceZod } from 'entities/entrance';
import { CardLayout } from 'shared/ui/CardLayout';

type EditEntranceCardProps = {
  entrance: Partial<Entrance>;
  onEntranceSaved: (entrance: Entrance) => void;
  onCancel: () => void;
  isEntranceSaving: boolean;
};

const editEntranceZod = entranceZod.pick({
  name: true,
  floorCount: true,
  riserCount: true,
});

export const EditEntranceCard: FC<EditEntranceCardProps> = ({
  entrance,
  onEntranceSaved,
  onCancel,
  isEntranceSaving,
}) => {
  const { t } = useTranslation();
  const form = useForm<Entrance>({
    resolver: zodResolver(editEntranceZod),
    mode: 'onSubmit',
    defaultValues: {
      name: entrance.name || '',
      floorCount: entrance.floorCount || '',
      riserCount: entrance.riserCount || '',
    },
  });

  const handleSaveEntranceClick = (entranceData: Entrance) => {
    const preparedEntrance = { ...entrance, ...entranceData };
    onEntranceSaved(preparedEntrance);
  };

  return (
    <CardLayout
      header={{
        title: entrance.id ? entrance.name : t('entrance.form.newEntrance'),
      }}
      footer={{
        onOkClick: form.handleSubmit(handleSaveEntranceClick),
        okLabel: entrance.id ? t('ui.common.save') : t('ui.common.create'),
        okButtonColor: 'secondary',
        onCancelClick: onCancel,
        cancelLabel: t('ui.common.cancel'),
        okButtonLoading: isEntranceSaving,
      }}
      variant="secondary"
    >
      <FormProvider {...form}>
        <EditEntranceForm isCreating={!entrance.id} />
      </FormProvider>
    </CardLayout>
  );
};
