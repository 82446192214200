import { redirect, RouteObject } from 'react-router-dom';
import {
  DeveloperAdditionalDataEditForm,
  DeveloperDetails,
  DeveloperDocumentsEditForm,
  DeveloperUsers,
  DeveloperMainDataEditForm,
  DeveloperObjects,
} from 'entities/developer';
import { getRoute } from 'shared/const';

export const developerSubRoutes: RouteObject[] = [
  {
    index: true,
    loader: ({ params }) =>
      redirect(
        params.developerId
          ? getRoute.DeveloperObjects(params.developerId)
          : getRoute.Developers()
      ),
  },
  {
    path: getRoute.DeveloperObjects(':developerId'),
    element: <DeveloperObjects />,
  },
  {
    path: getRoute.DeveloperDetails(':developerId'),
    element: <DeveloperDetails />,
  },
  {
    path: getRoute.DeveloperUsers(':developerId'),
    element: <DeveloperUsers />,
  },
];

export const editDeveloperSubRoutes: RouteObject[] = [
  {
    index: true,
    loader: ({ params }) =>
      redirect(
        params.developerId
          ? getRoute.EditDeveloperMainDataTab(params.developerId)
          : getRoute.Developers()
      ),
  },
  {
    path: getRoute.EditDeveloperMainDataTab(':developerId'),
    element: <DeveloperMainDataEditForm />,
  },
  {
    path: getRoute.EditDeveloperAdditionalDataTab(':developerId'),
    element: <DeveloperAdditionalDataEditForm />,
  },
  {
    path: getRoute.EditDeveloperDocumentsTab(':developerId'),
    element: <DeveloperDocumentsEditForm />,
  },
];
