import { FC, useCallback } from 'react';
import linkifyHtml from 'linkify-html';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { formatDate } from 'shared/helpers/formatDate';
import { useConfirmationDialog } from 'shared/hooks/useConfirmationDialog';
import { TextColor } from 'shared/theme/colors';
import { CardLayout } from 'shared/ui/CardLayout';
import { RemovalConfirmationCard } from 'shared/ui/RemovalConfirmationCard';
import { Row } from 'shared/ui/Row';
import { SmallSquareButton } from 'shared/ui/SmallSquareButton';
import { NewsPost } from '../model/types/newsPostSchema';

type NewsPostDetailsCardProps = {
  newsPost: NewsPost;
  onEditClick: (newsPost: NewsPost) => void;
  onRemoveClick: (newsPost: NewsPost) => void;
};

export const NewsPostDetailsCard: FC<NewsPostDetailsCardProps> = ({
  newsPost,
  onEditClick,
  onRemoveClick,
}) => {
  const { t } = useTranslation();
  const {
    isShowRemoveConfirmation,
    showRemoveConfirmation,
    hideRemoveConfirmation,
    confirmRemoveAction,
  } = useConfirmationDialog({
    onConfirm: () => onRemoveClick(newsPost),
  });

  const handleEditClick = useCallback(() => {
    onEditClick(newsPost);
  }, [onEditClick, newsPost]);

  return (
    <CardLayout variant="secondary" maxWidth={600}>
      {newsPost.manager && (
        <Typography variant="paragraphS" color={TextColor.Secondary}>
          {newsPost.manager.firstName} {newsPost.manager.lastName} {' - '}
          {newsPost.manager.email}
        </Typography>
      )}
      <Typography
        variant="paragraphM"
        color={TextColor.Primary}
        sx={{ overflowWrap: 'break-word', whiteSpace: 'pre-line' }}
        dangerouslySetInnerHTML={{ __html: linkifyHtml(newsPost.text) }}
      />
      <Typography variant="accentS" color={TextColor.Tertiary}>
        {formatDate(newsPost.dateUpdate, t)}
      </Typography>
      {isShowRemoveConfirmation ? (
        <RemovalConfirmationCard
          titleEnding={t('news.card.newsDeleteEnding')}
          onRemoveClick={confirmRemoveAction}
          onCancelClick={hideRemoveConfirmation}
        />
      ) : (
        <Row spacing={5}>
          <SmallSquareButton icon="edit" onClick={handleEditClick} />
          <SmallSquareButton onClick={showRemoveConfirmation} />
        </Row>
      )}
    </CardLayout>
  );
};
