import { FC, ReactNode, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { getFormattedPhone } from 'shared/helpers/getFormattedPhone';
import { SelectOption } from 'shared/types';
import { ButtonLink } from 'shared/ui/ButtonLink';
import { Col } from 'shared/ui/Col';
import { PhoneField } from 'shared/ui/PhoneField';
import { Row } from 'shared/ui/Row';
import { VerticalSortableList } from 'shared/ui/SortableList';
import { StyledTextField } from 'shared/ui/StyledTextField';
import { WorkScheduleInput } from 'shared/ui/WorkScheduleInput';
import { SalesOffice } from '../model/types/salesOfficeSchema';
import { SalesOfficeEmployeeRow } from './SalesOfficeEmployeeRow';

const MAX_WORKING_SCHEDULES = 3;

type Props = {
  salesOffice?: SalesOffice;
};

export const EditSalesOfficeForm: FC<Props> = ({ salesOffice }) => {
  const { t } = useTranslation();
  const form = useFormContext<SalesOffice>();
  const [workingScheduleCount, setWorkingScheduleCount] = useState<number>(
    form.getValues('schedules')?.length || 1
  );
  const {
    fields: employeesFields,
    append: appendEmployee,
    remove: removeEmployee,
    move: moveEmployee,
  } = useFieldArray({
    name: 'employees',
    control: form.control,
  });
  const watchForm = useWatch({ control: form.control });

  const getSelectedEmployeeOption = (
    employeeId: number
  ): SelectOption | undefined => {
    const employee = salesOffice?.employees?.find(
      (emp) => emp.employeeId === employeeId
    );
    return employee
      ? {
          value: employee.employeeId,
          label: `${employee.lastName} ${employee.firstName}`,
          sublabel: employee.phone ? getFormattedPhone(employee.phone) : '',
        }
      : undefined;
  };

  const workingSchedules = () => {
    const result: ReactNode[] = [];
    for (let index = 0; index < workingScheduleCount; index++) {
      result.push(
        <WorkScheduleInput
          key={index}
          daysFieldName={`schedules[${index}].workingDays`}
          hoursFieldName={`schedules[${index}].workingHours`}
          daysIsRequired
        />
      );
    }
    return result;
  };

  const employees = watchForm.employees;
  const selectedEmployeeIds = employees?.length
    ? employees?.map((employee) => employee.employeeId || -1)
    : [];

  const handleMove = (activeIndex: number, overIndex: number) => {
    moveEmployee(activeIndex, overIndex);
  };

  return (
    <Col spacing={8}>
      <Typography variant={'headerXS'}>
        {form.getValues('id')
          ? t('salesOffice.form.title.editSalesOffice')
          : t('salesOffice.form.title.addSalesOffice')}
      </Typography>
      <Row spacing={5} equalsChildrenWidth>
        <PhoneField name={'phone'} required />
        <StyledTextField
          name="email"
          label={t('ui.common.email')}
          type="email"
          maxLength={50}
          showCharacterHintAtRemaining={10}
        />
      </Row>

      <StyledTextField
        name="address"
        label={t('salesOffice.form.label.address')}
        required
        maxLength={100}
        showCharacterHintAtRemaining={10}
      />
      {workingSchedules()}
      <Col spacing={9}>
        {workingScheduleCount < MAX_WORKING_SCHEDULES && (
          <ButtonLink
            underlineType="dotted"
            onClick={() => setWorkingScheduleCount((prev) => prev + 1)}
          >
            <Typography variant="accentS">
              {t('ui.common.addTimePeriod')}
            </Typography>
          </ButtonLink>
        )}
        <Typography variant="headerXS">
          {t('salesOffice.form.label.employees')}
        </Typography>
      </Col>
      <Col>
        <VerticalSortableList
          items={employeesFields}
          onMove={handleMove}
          renderItem={(employee, index) => {
            return (
              <VerticalSortableList.Item id={employee.id}>
                <SalesOfficeEmployeeRow
                  key={employee.id}
                  employeeFieldName={`employees.${index}.employeeId`}
                  positionFieldName={`employees.${index}.position`}
                  firstNameFieldName={`employees.${index}.firstName`}
                  lastNameFieldName={`employees.${index}.lastName`}
                  phoneFieldName={`employees.${index}.phone`}
                  onDeleteRow={() => removeEmployee(index)}
                  getSelectedEmployee={getSelectedEmployeeOption}
                  selectedEmployeeIds={selectedEmployeeIds}
                />
              </VerticalSortableList.Item>
            );
          }}
        />
        {!selectedEmployeeIds.some((id) => id === -1) && (
          <ButtonLink
            underlineType="dotted"
            onClick={() =>
              appendEmployee({
                employeeId: -1,
                position: '',
                firstName: '',
                lastName: '',
                order: 1,
              })
            }
          >
            <Typography variant="accentS">
              {t('salesOffice.form.label.addEmployee')}
            </Typography>
          </ButtonLink>
        )}
      </Col>
    </Col>
  );
};
