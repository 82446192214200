import { FC, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Agency,
  AgencyEditForm,
  AgencyInfoFields,
  useUpdateAgencyMutation,
  agencyZod,
} from 'entities/agency';
import { getRoute } from 'shared/const';
import { CardLayout } from 'shared/ui/CardLayout';
import { ResultCard } from 'shared/ui/ResultCard';

export const AgencyDetails: FC = () => {
  const agency = useOutletContext<Agency>();
  const { t } = useTranslation();

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isShowAdditionalData, setIsShowAdditionalData] =
    useState<boolean>(false);

  const [
    updateAgency,
    {
      isLoading: isAgencyUpdating,
      isSuccess: isAgencySuccessfullyUpdated,
      isError: isAgencyUpdatingError,
    },
  ] = useUpdateAgencyMutation();

  const form = useForm<Agency>({
    resolver: zodResolver(agencyZod),
    mode: 'onSubmit',
    defaultValues: {
      id: agency.id,
      name: agency.name,
      address: agency.address,
      logo: agency.logo,
      tariff: agency.tariff,
      description: agency.description,
      inn: agency.inn,
      kpp: agency.kpp,
      website: agency.website,
      mail: agency.mail,
      officeName: agency.officeName,
      phone: agency.phone,
      facebook: agency.facebook,
      instagram: agency.instagram,
      ok: agency.ok,
      vk: agency.vk,
      youtube: agency.youtube,
      color: '#000000',
    },
  });

  const handleUpdateAgency = (agency: Agency) => {
    try {
      updateAgency(agency);
    } catch (error) {
      console.error(error);
    }
  };

  if (isAgencySuccessfullyUpdated || isAgencyUpdatingError) {
    return (
      <ResultCard
        isSuccess={isAgencySuccessfullyUpdated}
        successProps={{
          primaryButton: {
            navigateTo: getRoute.Agencies(),
          },
        }}
        errorProps={{
          primaryButton: {
            navigateTo: getRoute.Agencies(),
          },
        }}
      />
    );
  }

  return (
    <CardLayout
      footer={{
        onOkClick: isEditMode
          ? form.handleSubmit(handleUpdateAgency)
          : () => setIsEditMode(true),
        onCancelClick: isEditMode ? () => setIsEditMode(false) : undefined,
        okLabel: isEditMode ? t('ui.common.save') : t('ui.common.edit'),
        okButtonColor: 'primary',
        cancelLabel: isEditMode ? t('ui.common.cancel') : '',
        okButtonLoading: isAgencyUpdating,
      }}
      maxWidth={648}
      paddingY={24}
    >
      <FormProvider {...form}>
        {isEditMode ? (
          <AgencyEditForm
            isShowAdditionalData={isShowAdditionalData}
            onToggleAdditionalData={setIsShowAdditionalData}
          />
        ) : (
          <AgencyInfoFields
            isShowAdditionalData={isShowAdditionalData}
            onToggleAdditionalData={setIsShowAdditionalData}
          />
        )}
      </FormProvider>
    </CardLayout>
  );
};
