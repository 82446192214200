import { z } from 'zod';
import { emailValidator } from 'shared/helpers/emailValidator';
import { phoneValidator } from 'shared/helpers/phoneValidator';

export type AgencyDto = {
  id: number;
  name: string;
  website?: string | null;
  inn: string;
  kpp: string;
  description: string;
  office_name: string;
  tariff: number;
  logo_id: string;
  vk?: string | null;
  ok?: string | null;
  facebok?: string | null;
  instagram?: string | null;
  youtube?: string | null;
  address: string;
  phone: string;
  mail?: string | null;
  color: string;
};

export const agencyZod = z.object({
  id: z.number(),
  name: z.string().min(1, { message: 'ui.common.error.enterName' }),
  website: z.string().optional(),
  inn: z.string().min(10, { message: 'ui.common.error.innLength' }),
  kpp: z.string().min(1, { message: 'ui.common.errorEnterValue' }),
  description: z
    .string()
    .min(1, { message: 'ui.common.error.enterDescription' }),
  tariff: z.string().min(1, { message: 'agency.form.error.tariff' }),
  logo: z.string().min(1, { message: 'ui.common.errorRequiredField' }),
  vk: z.string().nullable(),
  ok: z.string().nullable(),
  facebook: z.string().nullable(),
  instagram: z.string().nullable(),
  youtube: z.string().nullable(),
  officeName: z.string().min(1, { message: 'ui.common.error.enterName' }),
  address: z.string().min(1, { message: 'ui.mapInput.errorNoAddress' }),
  phone: phoneValidator.required,
  mail: emailValidator.optional,
  color: z.string(),
});

export const createAgencyZod = agencyZod.omit({
  id: true,
});

export type Agency = z.infer<typeof agencyZod>;
export type CreateAgency = z.infer<typeof createAgencyZod>;
export type NewAgency = Omit<Agency, 'id'>;
