import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SearchSchema } from '../types/searchSchema';

const initialState: SearchSchema = {
  searchText: '',
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    updateSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
  },
});

export const { actions: searchActions } = searchSlice;
export const { reducer: searchReducer } = searchSlice;
