import { FC, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import { Button, Container, Typography } from '@mui/material';
import { useGetChessQuery } from 'entities/chess';
import { useVersionCheck } from 'entities/object';
import {
  useDeletePlanMutation,
  useGetPlansQuery,
} from 'entities/plan/model/api/PlanService';
import { getRoute } from 'shared/const';
import { Col } from 'shared/ui/Col';
import { EditPlanForm } from './EditPlanForm';
import { PlanPreviewCard } from './PlanPreviewCard';
import { PlansSkeleton } from './PlansSkeleton';

export const Plans: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { objectId, buildingId, entranceId } = useParams<{
    objectId: string;
    buildingId: string;
    entranceId: string;
  }>();
  const { data, isFetching: isPlansFetching } = useGetPlansQuery(
    { entranceId: entranceId ?? -1 },
    { skip: !entranceId }
  );
  const { data: chess, isLoading: isLotsLoading } = useGetChessQuery(
    entranceId ?? skipToken
  );

  const { checkVersion, showVersionChangedPopup } = useVersionCheck(objectId);

  const [isEditing, setIsEditing] = useState(false);
  const [editingPlanId, setEditingPlanId] = useState<number | null>(null);
  const [deletePlan, { isLoading: isPlanDeleting }] = useDeletePlanMutation();

  const { allFloorsOptions, freeFloorsOptions } = useMemo(() => {
    if (!data || !chess) {
      return { allFloorsOptions: [], freeFloorsOptions: [] };
    }

    const usedFloors = data.items
      .filter((plan) => plan.id !== editingPlanId)
      .flatMap((plan) => plan.floorIds);

    const allFloorsOptions = chess.floors.map((floor) => ({
      value: floor.id,
      label: floor.lotNumber ?? '-',
    }));

    const freeFloorsOptions = allFloorsOptions.filter(
      (option) => !usedFloors.includes(option.value)
    );

    return { allFloorsOptions, freeFloorsOptions };
  }, [chess, data, editingPlanId]);

  const handleEditPlan = (planId: number) => {
    setIsEditing(true);
    setEditingPlanId(planId);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditingPlanId(null);
  };

  const handleDeletePlan = async (planId: number) => {
    const isVersionChanged = await checkVersion();

    if (!isVersionChanged) {
      deletePlan({ objectId, planId });
    }
  };

  if (isPlansFetching || isLotsLoading) return <PlansSkeleton />;

  if (!chess || !entranceId) {
    return (
      <Typography
        variant="caption"
        sx={{
          p: 2,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        No chess
      </Typography>
    );
  }

  return (
    <Container sx={{ mt: 5 }}>
      {showVersionChangedPopup()}
      <Col spacing={8}>
        <Button
          size="small"
          onClick={() => {
            setIsEditing(true);
          }}
          disabled={
            isEditing ||
            (data && data.items.length >= 25) ||
            !freeFloorsOptions.length
          }
        >
          {t('plan.dialog.addPlan')}
        </Button>
        {isEditing && (
          <EditPlanForm
            planId={editingPlanId}
            entranceId={entranceId}
            floorsOptions={freeFloorsOptions}
            onCancel={handleCancelEdit}
            onSaved={handleCancelEdit}
          />
        )}
        {!isEditing &&
          data &&
          data.items.map((plan) => (
            <PlanPreviewCard
              key={plan.id}
              plan={plan}
              floorsOptions={allFloorsOptions}
              onOpenPlanClick={(planId) =>
                navigate(
                  getRoute.LotsPlanSchema(
                    objectId!,
                    buildingId!,
                    entranceId!,
                    planId
                  )
                )
              }
              onEditClick={handleEditPlan}
              onDeleteClick={handleDeletePlan}
              isPlanDeleting={isPlanDeleting}
            />
          ))}
      </Col>
    </Container>
  );
};
