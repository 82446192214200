import { TFunction } from 'i18next';

export function formatDate(data: string, t: TFunction): string {
  const months = [
    'news.card.ofJanuary',
    'news.card.ofFebruary',
    'news.card.ofMarch',
    'news.card.ofApril',
    'news.card.ofMay',
    'news.card.ofJune',
    'news.card.ofJuly',
    'news.card.ofAugust',
    'news.card.ofSeptember',
    'news.card.ofOctober',
    'news.card.ofNovember',
    'news.card.ofDecember',
  ];

  const date = new Date(data);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${t(month)} ${t('news.card.year', { year })}`;
}
