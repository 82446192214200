import { FC, ReactNode, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { ButtonLink } from 'shared/ui/ButtonLink';
import { Col } from 'shared/ui/Col';
import { PhoneField } from 'shared/ui/PhoneField';
import { Row } from 'shared/ui/Row';
import { StyledTextField } from 'shared/ui/StyledTextField';
import { WorkScheduleInput } from 'shared/ui/WorkScheduleInput';

const MAX_WORKING_SCHEDULES = 3;

export const DeveloperAdditionalDataEditForm: FC = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const [workingScheduleCount, setWorkingScheduleCount] = useState<number>(
    form.getValues('schedules')?.length || 1
  );

  const workingSchedules = () => {
    const result: ReactNode[] = [];
    for (let index = 0; index < workingScheduleCount; index++) {
      result.push(
        <WorkScheduleInput
          key={index}
          daysFieldName={`schedules[${index}].workingDays`}
          hoursFieldName={`schedules[${index}].workingHours`}
          daysIsRequired
          hoursIsRequired
        />
      );
    }
    return result;
  };

  return (
    <Col spacing={8}>
      {workingSchedules()}
      {workingScheduleCount < MAX_WORKING_SCHEDULES && (
        <ButtonLink
          underlineType="dotted"
          onClick={() => setWorkingScheduleCount((prev) => prev + 1)}
        >
          <Typography variant="accentM">
            {t('ui.common.addTimePeriod')}
          </Typography>
        </ButtonLink>
      )}

      <Row spacing={5} equalsChildrenWidth>
        <PhoneField name={'phone'} label={t('ui.common.generalPhone')} />
        <StyledTextField
          label={t('ui.common.generalEmail')}
          name="corpEmail"
          type="email"
          maxLength={50}
          showCharacterHintAtRemaining={10}
        />
      </Row>
      <Row spacing={5} equalsChildrenWidth>
        <StyledTextField
          name="website"
          label={t('ui.common.site')}
          maxLength={100}
          showCharacterHintAtRemaining={10}
        />
        <Box />
      </Row>
    </Col>
  );
};
