import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'shared/ui/Col';
import { PhoneField } from 'shared/ui/PhoneField';
import { Row } from 'shared/ui/Row';
import { StyledTextField } from 'shared/ui/StyledTextField';

export const DeveloperLoginDataEditForm: FC = () => {
  const { t } = useTranslation();

  return (
    <Col spacing={8}>
      <Row spacing={5} equalsChildrenWidth>
        <StyledTextField
          name={'ownerName'}
          maxLength={20}
          showCharacterHintAtRemaining={10}
          label={t('developer.form.label.ownerName')}
          required
        />
        <StyledTextField
          name={'ownerLastName'}
          maxLength={20}
          showCharacterHintAtRemaining={10}
          label={t('developer.form.label.ownerLastName')}
          required
        />
      </Row>
      <Row spacing={5} equalsChildrenWidth>
        <PhoneField name={'ownerPhone'} required />
        <StyledTextField
          label={t('developer.form.label.ownerEmail')}
          required
          name={'ownerEmail'}
          maxLength={50}
          showCharacterHintAtRemaining={10}
        />
      </Row>
    </Col>
  );
};
