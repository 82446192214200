import { $rtkApi, ApiTag } from 'shared/api/rtkApi';
import { ListData, ResponseData } from 'shared/types';
import { mapDtoFromObject, mapObjectFromDto } from '../../lib/mapObject';
import { mapPublicationErrorsFromDto } from '../../lib/mapObjectPublicationErrors';
import { objectVersionActions } from '../slice/objectVersionSlice';
import {
  ObjectPublicationError,
  ObjectPublicationErrorDto,
} from '../types/objectPublicationErrorsSchema';
import {
  DeveloperObject,
  EditDeveloperObject,
  ObjectDto,
} from '../types/objectSchema';
import { ObjectVersionSchema } from '../types/objectVersionSchema';

export const objectApi = $rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    createObject: builder.mutation<DeveloperObject, EditDeveloperObject>({
      query: (object) => ({
        url: '/buildings',
        method: 'POST',
        body: mapDtoFromObject(object),
      }),
      transformResponse: (response: ResponseData<ObjectDto>) =>
        mapObjectFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, object) => [
        { type: ApiTag.object, id: ApiTag.object },
      ],
    }),
    updateObject: builder.mutation<DeveloperObject, EditDeveloperObject>({
      query: (object) => ({
        url: `/buildings/${object.id}`,
        method: 'PUT',
        body: mapDtoFromObject(object),
      }),
      transformResponse: (response: ResponseData<ObjectDto>) =>
        mapObjectFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, object) =>
        error
          ? []
          : [
              { type: ApiTag.object, id: object.id },
              { type: ApiTag.object, id: ApiTag.object },
              { type: ApiTag.objectPublicationErrors, id: object.id },
            ],
    }),
    getObjects: builder.query<
      ListData<DeveloperObject>,
      { limit: number; offset: number; search: string; developerId?: number }
    >({
      query: (params) => ({
        url: `/buildings`,
        method: 'GET',
        params: {
          developer_id: params.developerId,
          name: params.search,
          limit: params.limit,
          offset: params.offset,
        },
      }),
      providesTags: (result, error, page) =>
        result
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTag.object, id })),
              { type: ApiTag.object, id: ApiTag.object },
            ]
          : [{ type: ApiTag.object, id: ApiTag.object }],
      transformResponse: (response: ResponseData<ListData<ObjectDto>>) => ({
        total: response.data.total,
        items: response.data.items.map((data) => mapObjectFromDto(data)),
      }),
    }),

    getObject: builder.query<DeveloperObject, string | number>({
      query: (id) => ({
        url: `/buildings/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: ResponseData<ObjectDto>) =>
        mapObjectFromDto(response.data),
      providesTags: (result, error, id) => [{ type: ApiTag.object, id }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(objectVersionActions.updateObjectVersion(data.version));
        } catch (error) {
          console.error('Failed to update object version', error);
        }
      },
    }),

    updateObjectPublished: builder.mutation<
      DeveloperObject,
      { objectId: number; publish: boolean }
    >({
      query: ({ objectId, publish }) => {
        return {
          url: `/buildings/public/${objectId}`,
          method: 'PATCH',
          body: { published: Number(publish) },
        };
      },
      transformResponse: (response: ResponseData<ObjectDto>) =>
        mapObjectFromDto(response.data),
      invalidatesTags: (result, error, { objectId }) => [
        { type: ApiTag.object, id: objectId },
        { type: ApiTag.object, id: ApiTag.object },
      ],
    }),

    getObjectPublicationErrors: builder.query<
      ObjectPublicationError[],
      string | number
    >({
      query: (id) => ({
        url: `/buildings/${id}/check`,
        method: 'GET',
      }),
      transformResponse: (
        response: ResponseData<ObjectPublicationErrorDto[]>
      ) => response.data.map((data) => mapPublicationErrorsFromDto(data)),
      providesTags: (result, error, id) => [
        { type: ApiTag.objectPublicationErrors, id },
      ],
    }),

    getObjectVersion: builder.query<ObjectVersionSchema, string | number>({
      query: (id) => ({
        url: `/buildings/get-version/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: ResponseData<ObjectVersionSchema>) =>
        response.data,
    }),
  }),
});

export const {
  useCreateObjectMutation,
  useUpdateObjectMutation,
  useGetObjectsQuery,
  useGetObjectQuery,
  useUpdateObjectPublishedMutation,
  useGetObjectPublicationErrorsQuery,
  useGetObjectVersionQuery,
} = objectApi;
