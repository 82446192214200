import React, { useState, useCallback } from 'react';
import { Popover } from '@mui/material';

type UsePopoverProps = {
  popoverContent: React.ReactNode;
};

export const usePopover = ({ popoverContent }: UsePopoverProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openPopover = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const closePopover = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const PopoverComponent = () => (
    <Popover
      sx={{ pointerEvents: 'none' }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={closePopover}
      disableRestoreFocus
    >
      {popoverContent}
    </Popover>
  );

  return {
    PopoverComponent,
    openPopover,
    closePopover,
    isPopoverOpen: Boolean(anchorEl),
  };
};
