import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery } from '@mui/material';
import { searchActions } from 'entities/search';
import { useAppDispatch } from 'shared/hooks/hooks';
import { useDebounce } from 'shared/hooks/useDebounce';
import { theme } from 'shared/theme/theme';
import { SearchInput } from 'shared/ui/SearchInput';
import { SmallSquareButton } from 'shared/ui/SmallSquareButton';

interface HeaderSearchProps {
  isSearchExpanded: boolean;
  onToggleSearchExpansion: () => void;
}

export const HeaderSearch: FC<HeaderSearchProps> = ({
  isSearchExpanded,
  onToggleSearchExpansion,
}) => {
  const dispatch = useAppDispatch();
  const isScreenXsSize = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText);

  useEffect(() => {
    dispatch(
      searchActions.updateSearchText(
        debouncedSearchText.length >= 3 ? debouncedSearchText : ''
      )
    );
  }, [debouncedSearchText, dispatch]);

  return (
    <Box sx={{ width: '100%', display: 'flex' }}>
      {(!isScreenXsSize || isSearchExpanded) && (
        <SearchInput
          name="searchText"
          searchPlaceholder={t('header.search.placeholder')}
          onSearchChange={setSearchText}
          color="white"
          sx={{ width: isSearchExpanded ? '100%' : 300 }}
        />
      )}
      {(isScreenXsSize || isSearchExpanded) && (
        <SmallSquareButton
          onClick={onToggleSearchExpansion}
          icon={isSearchExpanded ? 'close' : 'search'}
          iconSize={isSearchExpanded ? 12 : 20}
          sx={{ ml: 6, width: isSearchExpanded ? 40 : {} }}
        />
      )}
    </Box>
  );
};
