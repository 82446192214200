// eslint-disable-next-line boundaries/element-types
import { City } from 'entities/object';
import { SelectOption } from 'shared/types';

export enum Cities {
  Sochi = 1,
  Crimea = 2,
  Krasnodar = 3,
  Turkey = 4,
}

export function extractMicrodistrictsByCityName(
  cityId: Cities,
  citiesData: City[]
): SelectOption[] {
  const city = citiesData.find((city) => city.id === cityId);
  if (!city) {
    return [];
  }

  const sortedMicrodistricts = city.districts
    .flatMap((district) => district.microdistricts)
    .sort((a, b) => a.position - b.position);

  return sortedMicrodistricts.map((microdistrict) => ({
    value: microdistrict.id?.toString(),
    label: microdistrict.name,
  }));
}
