import { FC } from 'react';
import { Navigate, useOutlet } from 'react-router-dom';
import { useLocation } from 'react-router';
import { getRoute } from 'shared/const';
import { useAppSelector } from 'shared/hooks/hooks';

export const RequireAuth: FC = () => {
  const outlet = useOutlet();
  const user = useAppSelector((state) => state.profile.profile);
  const location = useLocation();

  if (!user) {
    return (
      <Navigate to={getRoute.AuthLogin()} state={{ from: location }} replace />
    );
  }

  return <>{outlet}</>;
};
