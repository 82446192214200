import { $rtkApi, ApiTag } from 'shared/api/rtkApi';
import { ListData, ResponseData } from 'shared/types';
import {
  mapDtoFromCreatePolygon,
  mapDtoFromUpdatePolygon,
  mapPolygonFromDto,
} from '../../lib/mapPolygon';
import {
  CreatePolygon,
  Polygon,
  PolygonDto,
  UpdatePolygon,
} from '../types/planSchema';

export const polygonApi = $rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    createPolygon: builder.mutation<
      Polygon,
      { objectId?: string | number; polygon: CreatePolygon }
    >({
      query: ({ polygon }) => ({
        url: '/poligons',
        method: 'POST',
        body: mapDtoFromCreatePolygon(polygon),
      }),
      transformResponse: (response: ResponseData<PolygonDto>) =>
        mapPolygonFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, { objectId }) => [
        { type: ApiTag.polygon },
        { type: ApiTag.object, id: objectId },
      ],
      async onQueryStarted({ polygon }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          polygonApi.util.updateQueryData(
            'getPolygons',
            polygon.planId,
            (draft) => {
              draft.items.push({ ...polygon, id: -1, chess: [] });
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    updatePolygon: builder.mutation<
      Polygon,
      { objectId?: string | number; polygon: UpdatePolygon }
    >({
      query: ({ polygon }) => ({
        url: `/poligons/${polygon.id}`,
        method: 'PUT',
        body: mapDtoFromUpdatePolygon(polygon),
      }),
      transformResponse: (response: ResponseData<PolygonDto>) =>
        mapPolygonFromDto(response.data),
      transformErrorResponse: (response) =>
        (response.data as { messages: string }).messages,
      invalidatesTags: (result, error, { objectId, polygon }) => [
        { type: ApiTag.polygon },
        { type: ApiTag.polygon, id: polygon.id },
        { type: ApiTag.object, id: objectId },
      ],
    }),
    getPolygons: builder.query<ListData<Polygon>, string | number>({
      query: (planId) => ({
        url: '/poligons',
        method: 'GET',
        params: {
          plan_id: planId,
        },
      }),
      providesTags: (result, error, page) =>
        result
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTag.polygon, id })),
              { type: ApiTag.polygon, id: ApiTag.polygon },
            ]
          : [{ type: ApiTag.polygon, id: ApiTag.polygon }],
      transformResponse: (response: ResponseData<ListData<PolygonDto>>) => ({
        total: response.data.total,
        items: response.data.items
          .map((data) => mapPolygonFromDto(data))
          .reverse(),
      }),
    }),

    getPolygon: builder.query<Polygon, string | number>({
      query: (id) => ({
        url: `/poligons/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: ResponseData<PolygonDto>) =>
        mapPolygonFromDto(response.data),
      providesTags: (result, error, id) => [{ type: ApiTag.polygon, id }],
    }),
    deletePolygon: builder.mutation<
      void,
      { objectId?: string | number; polygonId: string | number }
    >({
      query: ({ polygonId }) => ({
        url: `/poligons/${polygonId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { objectId, polygonId }) => [
        { type: ApiTag.polygon },
        { type: ApiTag.polygon, polygonId },
        { type: ApiTag.object, id: objectId },
      ],
    }),
  }),
});

export const {
  useCreatePolygonMutation,
  useUpdatePolygonMutation,
  useGetPolygonsQuery,
  useGetPolygonQuery,
  useDeletePolygonMutation,
} = polygonApi;
